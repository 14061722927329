import React from 'react'
import CheckBox from '../../../../Common/CheckBox/CheckBox'
import {
  BIZ_BOARD_APP_DOWNLOAD_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_NOTICE_TYPE,
  BIZBOARD_COPY_ALLOWED_CHAR,
} from '../bizBoardUtils'
import InputTextArea from '../../../../Common/InputTextArea/InputTextArea'
import {
  checkNotEmpty,
  hasOnlyKeyboardChars,
  validateInputLength,
} from '../../../../../utils/regexUtils'
import { showErrorMessage } from '../../../../../utils/alertUtils'
import PropTypes from 'prop-types'
import { Tooltip } from '../../../../../resources/locale'
import DynamicToolTip from '../../../../Tooltip/DynamicTooltip'

const BizBoardBannerNotice = ({
  formData,
  templateData,
  validationState,
  handleFormChange,
  handleTemplateChange,
}) => {
  const { appDownloadType, noticeType } = templateData
  const useAppDownload = appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE

  const isSubCopyEmpty = React.useMemo(
    () =>
      !formData.description || String(formData.description).trim().length === 0,
    [formData.description]
  )

  const handleNoticeChange = React.useCallback(
    e => {
      const { value } = e.target

      const isValid =
        hasOnlyKeyboardChars(value, BIZBOARD_COPY_ALLOWED_CHAR) &&
        validateInputLength(value, 0, 50)

      if (isValid) {
        handleFormChange('notice', value)
      }
    },
    [handleFormChange]
  )

  const handleNoticeTypeChange = React.useCallback(
    type => () => {
      const turnOn = noticeType !== type

      if (type === BIZ_BOARD_NOTICE_TYPE.LEGAL) {
        if (!isSubCopyEmpty && useAppDownload && turnOn) {
          showErrorMessage(
            '앱 아이콘 선택 시, 서브카피가 있는 상태에서 법적 고지문을 선택할 수 없습니다.'
          )
          return
        }
      } else if (type === BIZ_BOARD_NOTICE_TYPE.TALK_LANDING) {
        if (useAppDownload && turnOn) {
          showErrorMessage(
            '앱 아이콘 선택 시, 채팅방 랜딩 고지문을 선택할 수 없습니다.'
          )
          return
        }
      }
      if (turnOn) {
        handleTemplateChange('noticeType', type)
        handleFormChange('notice', '')
      } else {
        handleTemplateChange('noticeType', BIZ_BOARD_NOTICE_TYPE.NONE)
        handleFormChange('notice', null)
      }
    },
    [
      handleFormChange,
      handleTemplateChange,
      isSubCopyEmpty,
      noticeType,
      useAppDownload,
    ]
  )

  const errorMessageNotice =
    validationState[BIZ_BOARD_FORM_VALIDATION_KEY.NOTICE]

  return (
    <div className="bnrimg_area type_check">
      <strong className="tit_bnrimg">
        안내 문구<span className="txt_sub">(선택)</span>
      </strong>
      <div className="bnrimg_con">
        <CheckBox
          label="법적 고지문"
          id="inputCheck|notice|legal"
          isChecked={noticeType === BIZ_BOARD_NOTICE_TYPE.LEGAL}
          onChange={handleNoticeTypeChange(BIZ_BOARD_NOTICE_TYPE.LEGAL)}
        />
        <CheckBox
          label="채팅방 랜딩 고지문"
          id="inputCheck|notice|talkLanding"
          isChecked={noticeType === BIZ_BOARD_NOTICE_TYPE.TALK_LANDING}
          onChange={handleNoticeTypeChange(BIZ_BOARD_NOTICE_TYPE.TALK_LANDING)}>
          <DynamicToolTip
            content={Tooltip(
              'create_ads.v2.ad_content.biz_board.notice.talk_landing'
            )}>
            <a className="link_g link_help">
              <span className="ico_comm ico_help">도움말</span>
            </a>
          </DynamicToolTip>
        </CheckBox>
        <InputTextArea
          id="inputTextArea|notice"
          className="box_textarea2"
          placeholder="고지가 필요한 내용을 입력하세요."
          onChange={handleNoticeChange}
          value={formData.notice}
          error={checkNotEmpty(errorMessageNotice)}
          maxLength={50}
        />
        {errorMessageNotice && (
          <p className="txt_error">{errorMessageNotice}</p>
        )}
      </div>
    </div>
  )
}

BizBoardBannerNotice.propTypes = {
  formData: PropTypes.object,
  templateData: PropTypes.object,
  validationState: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
}

export default BizBoardBannerNotice
