import React from 'react'
import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { push, replace } from 'connected-react-router'
import {
  initStoredAdAccountInfo,
  setObserverInfo,
  withdrawDspAccount,
} from '../../utils/authenticationUtils'
import { keyMirror } from '../../utils/utils'
import { getAgencyAdAccountSelectorItems } from './mAdAccountListSelector'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import { closeAllPopup, openPopupByProxy, POPUP_KEY } from './mPopup'
import * as Sentry from '@sentry/browser'
import { setInvoiceEmail } from '../settings/mInvoice'
import {
  toCatalogDetailPath,
  toCatalogProductDetailPath,
  toCatalogProductSetPath,
  toErrorPath,
  toInvitePath,
  toMyAdAccountListPath,
  toWithdrawSuccessPath,
} from '../../utils/router/routeUtils'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
} from '../../utils/regexUtils'
import {
  dspLoginCheck,
  handleDspLoginException,
} from './commonActions/aInitializeDsp'
import { fromKakaoBizCenterLink } from '../../utils/app/services/kakaoBizCenter'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'

const User = keyMirror({
  SET_DSP_LOGIN_INFO: null,
  SET_DSP_LOGIN_INFO_WITHDRAWAL: null,

  SET_DSP_USER_INFO: null,

  SET_INVITE_LIST: null,

  SET_IS_VALID_USER_INFO_BY_KEY: null,
  INIT_USER_INFO_VALIDATION_ERROR_KEYS: null,

  OWN_IDENTITY_INFO: null,
})

const initialState = fromJS({
  validationErrorKeys: {},

  userInfoReadOnly: {},

  // form
  userInfo: {
    id: -1,
    kakaoEmail: '',
    name: '',
    email: '@',
    phone: '--',
    kakaoPayAgreement: false,
    withdrawalDate: null,
    kakaoAccountId: -1,
    marketingAgreement: false,
    promotionAgreement: false,
    promotionAgreementDate: '',
    privacyPolicyEntrustAgreement: false,
    serviceAgreement: false,
  },
  inviteList: [],
  phoneNumber: '',
  loginFailInfo: {},
  isVisibleBrowserAlert: false,
  ownIdentityInfo: {},
})

export default createReducer(initialState, {
  [User.SET_DSP_LOGIN_INFO]: (state, { data }) =>
    state.mergeIn(['userInfo'], fromJS(data)),

  [User.SET_DSP_LOGIN_INFO_WITHDRAWAL]: (state, { data }) =>
    state.set('loginFailInfo', fromJS(data)),

  [User.SET_DSP_USER_INFO]: (state, { data }) =>
    state.withMutations(s =>
      s
        .set('userInfoReadOnly', fromJS(data))
        .mergeIn(['userInfo'], fromJS(data))
    ),

  [User.SET_INVITE_LIST]: (state, { data }) =>
    state.set('inviteList', fromJS(data || [])),

  [User.SET_IS_VALID_USER_INFO_BY_KEY]: (state, { data }) => {
    const { validationErrorKeys } = state
    const { key, isValid, message } = data
    const newErrorKeys = isValid
      ? validationErrorKeys.delete(key)
      : validationErrorKeys.set(key, message)
    return state.set('validationErrorKeys', newErrorKeys)
  },

  [User.INIT_USER_INFO_VALIDATION_ERROR_KEYS]: state => {
    return state.set(
      'validationErrorKeys',
      initialState.get('validationErrorKeys')
    )
  },

  [User.OWN_IDENTITY_INFO]: (state, { data }) =>
    state.set('ownIdentityInfo', fromJS(data)),
})

export function setDspLoginInfo(data) {
  return { type: User.SET_DSP_LOGIN_INFO, data }
}

export function setDspLoginInfoWithdrawal(data) {
  return { type: User.SET_DSP_LOGIN_INFO_WITHDRAWAL, data }
}

export function setDspUserInfo(data) {
  return { type: User.SET_DSP_USER_INFO, data }
}

function getPinNumberExceptionMessage(e) {
  const { errorCode, message } = e?.response?.data || {}

  switch (Number(errorCode)) {
    case 1101:
      return '이 휴대폰 번호로 가입한 사용자가 존재합니다.'
    case 1102:
      return '인증 유효시간 10분을 초과하였습니다. 재발송을 통하여 다시 인증하세요.'
    case 1103:
      return '인증번호가 발송된 휴대폰 번호를 입력하세요.'
    case 1201:
      return '인증번호 발송 최대 횟수를 초과하여 발송이 불가합니다. 24시간 후에 다시 시도하세요.'
    case 1202:
      return '인증 실패가 10회를 초과하였습니다. 재발송을 통하여 다시 인증하세요.'
    case 1203:
      return '인증번호가 일치하지 않습니다. 확인 후 다시 인증하세요.'
    default:
      return message
  }
}

/**
 *  1.1 내 DSP 계정 정보 조회
 */
export function fetchMyDspAccount() {
  return async (dispatch, getState, api) => {
    try {
      const userInfoResponse = await api.user.fetchMyDspAccount()
      const userInfo = userInfoResponse.data || {}

      dispatch(setDspUserInfo(userInfo))
      dispatch(setInvoiceEmail(userInfo.email))
    } catch (e) {
      dispatch(push(toErrorPath()))
    }
  }
}

/**
 *  1.8 광고계정 초대 목록 조회
 */
function receiveDspAccountInviteList(data) {
  const inviteList =
    data?.map(({ id: adAccountMemberId, adAccount }) => {
      const { id: adAccountId, masterEmail, name } = adAccount

      return {
        adAccountMemberId,
        adAccountId,
        adAccountMasterEmail: masterEmail,
        adAccountName: name,
      }
    }) || []

  return { type: User.SET_INVITE_LIST, data: inviteList }
}

export function getDspAccountInviteList() {
  return (dispatch, getState, api) => {
    return api.user.getDspAccountInviteList().then(response => {
      const { content: inviteList } = response.data || []
      dispatch(receiveDspAccountInviteList(inviteList))
    })
  }
}

/**
 * 로그인 후 member 인 adAccount 가 0개 일 경우 초대 리스트를 검색한다.
 */
export function getDspAccountInviteListWhenLoginSuccess() {
  return (dispatch, getState, api) => {
    return api.user
      .getDspAccountInviteList()
      .then(response => {
        const { content: inviteList } = response.data || []
        const invited = inviteList.length > 0

        /**
         * FIXME kakao for biz 에서 직접적으로 랜딩하는 페이지(광고계정 생성, 광고계정 멤버 요청)에 대해 예외 처리.
         */
        if (!fromKakaoBizCenterLink()) {
          if (invited) {
            dispatch(receiveDspAccountInviteList(inviteList))
            dispatch(push(toInvitePath()))
          } else {
            dispatch(push(toMyAdAccountListPath()))
          }
        }
      })
      .catch(e => {
        Sentry.captureException(e)
      })
  }
}

export function publishPinNumberForWithdrawal(onSuccess, onFail) {
  return (dispatch, getState, api) => {
    return api.user
      .publishPinNumberForWithdrawal()
      .then(response => {
        const popupProxy = (
          <PopupProxy>
            <strong className="tit_layer">인증번호 발송</strong>
            <p className="txt_layer">
              인증번호를 발송하였습니다.
              <br />
              인증번호가 오지 않으면 입력하신 번호가 맞는지
              <br />
              확인하세요.
            </p>
          </PopupProxy>
        )

        dispatch(openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, popupProxy))

        onSuccess && onSuccess(response)
      })
      .catch(e => {
        const pinNumberExceptionMessage = getPinNumberExceptionMessage(e)
        onFail && onFail(pinNumberExceptionMessage)
      })
  }
}

/**
 * 1.13 점유인증 번호 검증(탈퇴)
 */
export function verifyPinNumberForWithdrawal(pin, onSuccess, onFail) {
  return (dispatch, getState, api) => {
    return api.user
      .verifyPinNumberForWithdrawal(pin)
      .then(response => {
        onSuccess && onSuccess(response)
        showSuccessMessage('휴대폰 번호 인증에 성공하였습니다.')
      })
      .catch(e => {
        const pinNumberExceptionMessage = getPinNumberExceptionMessage(e)
        onFail && onFail(pinNumberExceptionMessage)
        if (checkEmpty(pinNumberExceptionMessage)) {
          showErrorMessage(e.message)
        }
      })
  }
}

/**
 * 1.14 모먼트 탈퇴 가능 여부 체크
 */
export function checkWithdrawalAccount(onSuccess, onFail) {
  return (dispatch, getState, api) => {
    const {
      user: {
        userInfo: { id: dspAccountId },
      },
    } = getState()

    return api.user
      .checkWithdrawalAccount(dspAccountId)
      .then(response => {
        onSuccess && onSuccess(response)
      })
      .catch(e => {
        onFail && onFail(e)
      })
  }
}

/**
 * 1.15 모먼트 탈퇴
 */
export function withdrawalAccount(onSuccess, onFail) {
  return (dispatch, getState, api) => {
    const {
      user: {
        userInfo: { id: dspAccountId },
      },
    } = getState()

    return api.user
      .withdrawalAccount(dspAccountId)
      .then(response => {
        onSuccess && onSuccess(response)

        withdrawDspAccount()

        dispatch(closeAllPopup())
        dispatch(push(toWithdrawSuccessPath()))

        onSuccess && onSuccess(response)
      })
      .catch(e => {
        onFail && onFail(e)

        const { errorCode } = e?.response?.data || {}
        if (checkNotEmpty(errorCode)) {
          const popupProxy = (
            <PopupProxy>
              <strong className="tit_layer">회원 탈퇴 실패</strong>
              <p className="txt_layer">회원 탈퇴가 불가능합니다.</p>
            </PopupProxy>
          )
          dispatch(openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, popupProxy))
        }
      })
  }
}

export function setIsValidUserInfoByKey(key, isValid, message) {
  return {
    type: User.SET_IS_VALID_USER_INFO_BY_KEY,
    data: { key, isValid, message },
  }
}

export function initUserInfoValidationErrorKeys() {
  return {
    type: User.INIT_USER_INFO_VALIDATION_ERROR_KEYS,
  }
}

export function agencyLogin(adaccountid) {
  return async dispatch => {
    initStoredAdAccountInfo()

    try {
      await dispatch(dspLoginCheck())

      dispatch(getAgencyAdAccountSelectorItems(adaccountid))
    } catch (e) {
      dispatch(handleDspLoginException({ e }))
    }
  }
}

/**
 * 어드민에서 Observer 모드로 로그인
 * @param searchParams {URLSearchParams}
 */
export function otpLogin(searchParams) {
  const otp = searchParams.get('otp')
  const accountId = searchParams.get('accountId')
  const adAccountId = searchParams.get('adAccountId')
  const serviceType = searchParams.get('serviceType')
  const campaignId = searchParams.get('campaignId')
  const adGroupId = searchParams.get('adGroupId')
  const creativeId = searchParams.get('creativeId')
  const catalogId = searchParams.get('catalogId')
  const itemId = searchParams.get('itemId')
  const productSetId = searchParams.get('productSetId')

  setObserverInfo(adAccountId, serviceType)

  return (dispatch, getState, api) => {
    return api.user
      .otpLogin(accountId, otp, serviceType, adAccountId)
      .then(response => {
        dispatch(setDspLoginInfo(response.data))
        // -- ROUTING --
        /**
         * 심사 -> 소재 대시보드로 랜딩
         */
        if (checkNoneEmpty(campaignId, adGroupId, creativeId)) {
          dispatch(
            replace(
              DashboardRouter.Path.Creative({
                adAccountId,
                summaryId: creativeId,
                rowIds: [creativeId],
              })
            )
          )
          /**
           * 어드민  -> 그룹 대시보드로 랜딩
           */
        } else if (checkNoneEmpty(campaignId, adGroupId)) {
          dispatch(
            replace(
              DashboardRouter.Path.AdGroup({
                adAccountId,
                summaryId: adGroupId,
                rowIds: [adGroupId],
              })
            )
          )
          /**
           * 어드민 -> 캠페인 대시보드로 랜딩
           */
        } else if (checkNoneEmpty(campaignId)) {
          dispatch(
            replace(
              DashboardRouter.Path.Campaign({
                adAccountId,
                summaryId: campaignId,
                rowIds: [campaignId],
              })
            )
          )
          /**
           * 어드민 -> 카탈로그 상세(상품 ID 필터 적용) 랜딩
           */
        } else if (checkNoneEmpty(catalogId, itemId)) {
          dispatch(
            replace(toCatalogProductDetailPath(adAccountId, catalogId, itemId))
          )
          /**
           * 어드민 -> 카탈로그 세트 랜딩
           */
        } else if (checkNoneEmpty(catalogId, productSetId)) {
          dispatch(
            replace(
              toCatalogProductSetPath(adAccountId, catalogId, productSetId)
            )
          )
          /**
           * 어드민 -> 카탈로그 상세 랜딩
           */
        } else if (checkNoneEmpty(catalogId)) {
          dispatch(replace(toCatalogDetailPath(adAccountId, catalogId)))
        } else {
          /**
           *  어드민 광고계정 리스트 -> 광고계정 대시보드로 랜딩.
           */
          dispatch(replace(DashboardRouter.Path.AdAccount({ adAccountId })))
        }
      })
  }
}
