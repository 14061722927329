import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../../utils/utils'
import { fromJS, Map } from 'immutable'
import { replace } from 'connected-react-router'
import {
  COHORT_TRIM_KEY_PATH_LIST,
  fetchCohortBaseAdsAdGroupInfo,
  handleCohortException,
  setIsValidCohortByKey,
} from '../mCohort'
import { toTargetingCohortPath } from '../../../utils/router/routeUtils'
import { Trim } from '../../../utils/formTrim'
import { Validation } from '../../../validators/validation'
import {
  COHORT_FORM_VALIDATION_KEY,
  COHORT_FORM_VALIDATION_KEY_PATH,
  COHORT_FORM_VALIDATOR,
} from '../../../validators/targeting/cohortFormValidator'
import { hideLoading, showLoading } from '../../common/mLoading'

/**
 * 광고 반응 상세
 */
const CohortDetail = keyMirror(
  {
    STORE_DETAIL: null,
    INIT_DETAIL: null,
    STORE_ADGROUPS: null,
    SET_COHORT_BASE_ADS_WITH_AD_GROUP_INFO: null,
  },
  'COHORT_DETAIL'
)

// initial state
const initialState = fromJS({
  cohortDetailData: {
    adAccountId: null,
    name: null,
    collectDuration: null,
    cohortBaseAds: [],
  },
  cohortAdGroups: {
    content: [],
    totalElements: 0,
  },
  populationScore: null,
  cohortBaseAdsWithAdGroupInfo: [],
})

// reducer
export default createReducer(initialState, {
  [CohortDetail.STORE_DETAIL]: (state, { payload }) =>
    state.set('cohortDetailData', fromJS(payload || {})),

  [CohortDetail.INIT_DETAIL]: state => state.merge(initialState),

  [CohortDetail.STORE_ADGROUPS]: (state, { payload }) =>
    state.set('cohortAdGroups', fromJS(payload || {})),

  [CohortDetail.SET_COHORT_BASE_ADS_WITH_AD_GROUP_INFO]: (state, { payload }) =>
    state.set('cohortBaseAdsWithAdGroupInfo', fromJS(payload || [])),
})

const storeCohortDetail = payload => ({
  type: CohortDetail.STORE_DETAIL,
  payload,
})

export function fetchCohortDetail(adAccountId, cohortId) {
  return async (dispatch, getState, api) => {
    try {
      const {
        data,
        data: { cohortBaseAds },
      } = await api.cohort.fetchCohortDetail(adAccountId, cohortId)
      dispatch(storeCohortDetail(data))
      dispatch(
        fetchCohortBaseAdsAdGroupInfo({
          adAccountId,
          cohortBaseAdsWithOutAdGroupInfo: cohortBaseAds,
          onSuccess: setCohortBaseAdsWithAdGroupInfo,
        })
      )
    } catch (e) {
      handleCohortException(dispatch, e, adAccountId)
    }
  }
}

export const initCohortDetail = () => ({
  type: CohortDetail.INIT_DETAIL,
})

const storeCohortDetailAdGroups = payload => ({
  type: CohortDetail.STORE_ADGROUPS,
  payload,
})

const setCohortBaseAdsWithAdGroupInfo = payload => ({
  type: CohortDetail.SET_COHORT_BASE_ADS_WITH_AD_GROUP_INFO,
  payload,
})

export function fetchCohortDetailAdGroups(
  adAccountId,
  cohortId,
  searchOption,
  onFinish
) {
  return (dispatch, getState, api) => {
    dispatch(showLoading('fetchCohortDetailAdGroups'))

    return api.cohort
      .fetchCohortAdGroup(adAccountId, cohortId, searchOption)
      .then(response => {
        dispatch(storeCohortDetailAdGroups(response.data))
        onFinish && onFinish()
      })
      .catch(e => {
        onFinish && onFinish()
      })
      .finally(() => {
        dispatch(hideLoading('fetchCohortDetailAdGroups'))
      })
  }
}

export function deleteCohort(adAccountId, cohortId, audienceType) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())
      await api.cohort.deleteCohort(adAccountId, cohortId)

      dispatch(replace(toTargetingCohortPath(adAccountId, audienceType)))
    } catch (e) {
      handleCohortException(dispatch, e, adAccountId)
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function modifyCohortName(adAccountId, cohortId, requestBody, onFinish) {
  return async (dispatch, getState, api) => {
    const formData = Trim(Map(requestBody), COHORT_TRIM_KEY_PATH_LIST)

    const validationResult = Validation(
      formData,
      COHORT_FORM_VALIDATION_KEY,
      COHORT_FORM_VALIDATION_KEY_PATH,
      COHORT_FORM_VALIDATOR,
      getState,
      setIsValidCohortByKey,
      dispatch
    )

    if (!validationResult) return

    try {
      await api.cohort.modifyCohortName(adAccountId, cohortId, requestBody)
      onFinish && onFinish()
    } catch (e) {
      handleCohortException(dispatch, e)
    }
  }
}
