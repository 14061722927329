import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { replace } from 'connected-react-router'
import DashboardRouter from '../dashboardRouter'

const _DashboardRedirect = () => {
  const dispatch = useDispatch()

  const {
    adaccountid: adAccountId,
    campaignid: campaignId,
    adgroupid: adGroupId,
    adcreativeid: creativeId,
  } = useParams()

  React.useEffect(() => {
    if (adAccountId > 0) {
      if (creativeId > 0) {
        dispatch(
          replace(
            DashboardRouter.Path.Creative({
              adAccountId,
              summaryId: creativeId,
              rowIds: [creativeId],
            })
          )
        )
      } else if (adGroupId > 0) {
        dispatch(
          replace(
            DashboardRouter.Path.AdGroup({
              adAccountId,
              summaryId: adGroupId,
              rowIds: [adGroupId],
            })
          )
        )
      } else if (campaignId > 0) {
        dispatch(
          replace(
            DashboardRouter.Path.Campaign({
              adAccountId,
              summaryId: campaignId,
              rowIds: [campaignId],
            })
          )
        )
      } else {
        dispatch(replace(DashboardRouter.Path.AdAccount({ adAccountId })))
      }
    }
  }, [adAccountId, adGroupId, campaignId, creativeId, dispatch])

  return null
}

const DashboardRedirect = React.memo(_DashboardRedirect)

export default DashboardRedirect
