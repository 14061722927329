import React from 'react'
import * as Sentry from '@sentry/browser'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import cx from 'classnames'
import { v4 as uuid } from 'uuid'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useCmpVideoUpload } from './useCmpVideoUpload'
import {
  showErrorMessage,
  showPromiseMessage,
  showSuccessMessage,
} from '../../../../../../utils/alertUtils'
import { CreativeGuideTooltip } from '../../../Form/Common'
import { NumberUtils } from '../../../../../../utils/numberUtils'
import { cancelRequest } from '../../../../../../utils/cancellation/cancellation'
import PopupButtonGroup from '../../../../../Common/ButtonGroup/PopupButtonGroup'
import CreativeFormatEnum from '../../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../../enums/CreativeUploadAssetPropertyEnum'
import CampaignTypeEnum from '../../../../../../enums/CampaignTypeEnum'
import PopupHOC from '../../../../../Popup/PopupHOC'
import { keyMirror } from '../../../../../../utils/utils'
import CreativeAssetPropertyEnum from '../../../../../../enums/CreativeAssetPropertyEnum'
import AdConstraintsHelper from '../../../../../../utils/helper/helper-adConstraints'
import CmpAssetLibraryGuide from '../CmpAssetLibraryGuide'
import {
  deleteCmpAssetLibraryVideoUploadItem,
  getCmpAssetLibraryVideoEncodingProcessForKakaoTv,
  getCmpAssetLibraryVideoInfoForKakaoTv,
  initCmpAssetLibraryVideoByKeyPath,
  uploadCmpAssetLibraryVideoForDisplay,
  uploadCmpAssetLibraryVideoForMessage,
  uploadCmpAssetLibraryVideoThumbnailForKakaoTv,
} from '../../../../../../modules/cmp/mCmpAssetLibraryVideo'
import CmpAssetLibraryVideoDisplayRecent from './Display/CmpAssetLibraryVideoDisplayRecent'
import CmpAssetLibraryVideoMessageRecent from './Message/CmpAssetLibraryVideoMessageRecent'
import CmpAssetLibraryVideoDisplayUpload from './Display/CmpAssetLibraryVideoDisplayUpload'
import CmpAssetLibraryVideoMessageUpload from './Message/CmpAssetLibraryVideoMessageUpload'
import {
  CMP_ASSET_LIBRARY_VIDEO_TYPE,
  CmpAssetLibraryUtils,
  UPLOAD_INITIAL_STATUS,
} from '../cmpAssetLibraryUtils'
import RequestLock from '../../../../../../utils/requestLock'
import CreativeAPI from '../../../../../../modules-api/advertise/adCreativeApi'
import { fromJS, List } from 'immutable'
import {
  hideLoading,
  showLoading,
} from '../../../../../../modules/common/mLoading'
import {
  getCreativeVideoUploadExceptionMessageByErrorCode,
  handleCreativeVideoUploadException,
} from '../../../../../../modules/advertise/creativeActions/aCreativeCommonV2'
import useVideoEncoding from '../../../Form/Viewable/VideoNative/useVideoEncoding'
import { checkUrl } from '../../../../../../utils/regexUtils'
import MessageAPI from '../../../../../../modules-api/message/messageApi'
import { useIsMounted } from '../../../../../../utils/hook/useIsMounted'
import { CreativeHelper } from '../../../../../../utils/helper/helper-creative'
import VideoSourceEnum from '../../../../../../enums/VideoSourceEnum'
import BizBoardSubTypeEnum from '../../../../../../enums/BizBoardSubTypeEnum'

const VIDEO_PROCESSING_MESSAGE = {
  PENDING: '동영상을 변환하는 중입니다.',
  SUCCESS: '동영상 변환이 완료되었습니다.',
  ERROR:
    '동영상을 처리 하는 중, 문제가 발생하였습니다. 잠시 후, 다시 홍보동영상을 등록하세요.',
}

const TAB = keyMirror({
  RECENT_DISPLAY: null,
  RECENT_MESSAGE: null,
  UPLOAD: null,
})

const TAB_ITEMS = [
  { key: TAB.RECENT_DISPLAY, name: '디스플레이 동영상' },
  { key: TAB.RECENT_MESSAGE, name: '메시지 동영상' },
  { key: TAB.UPLOAD, name: '직접 업로드' },
]

const selector = ({
  adConstraints: { creativeAssetConstraints },
  cmpAssetLibrary: {
    video: { selectedItems },
  },
  creativeV2: {
    common: {
      campaign: { contractInfo: { contractProductItems } = {} },
    },
  },
}) => {
  return {
    creativeAssetConstraints,
    selectedItems,
    contractProductItems,
  }
}

const CmpAssetLibraryVideo = ({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyTypeForDisplay = CreativeAssetPropertyEnum.Type.VIDEO,
  creativeAssetPropertyTypeForMessage = CreativeAssetPropertyEnum.Type
    .KAKAO_TV_VIDEO_CONTENT,
  creativeUploadAssetPropertyType = CreativeUploadAssetPropertyEnum.Type.VIDEO,
  guideTooltipContent,
  onSave,
  close,
}) => {
  const isMessage = CreativeFormatEnum.isMessage(creativeFormat)

  const dispatch = useDispatch()
  const { selectedItems, creativeAssetConstraints, contractProductItems } =
    useSelector(selector, shallowEqual)
  const { subType } = contractProductItems?.first() || {}

  const isMounted = useIsMounted()
  const isMotionBoard = BizBoardSubTypeEnum.isMotionBoard(subType)
  const isCustomBoard = BizBoardSubTypeEnum.isCustomBoard(subType)
  const isAvailableMessageVideo =
    creativeUploadAssetPropertyType !==
      CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO &&
    creativeFormat !== CreativeFormatEnum.Type.RICH_NATIVE

  const currTabItems = React.useMemo(
    () =>
      TAB_ITEMS.filter(({ key }) =>
        key === TAB.RECENT_MESSAGE ? isAvailableMessageVideo : true
      ),
    [isAvailableMessageVideo]
  )

  const [currTabKey, setCurrTabKey] = React.useState(TAB.UPLOAD)

  const currTabIndex = currTabItems.findIndex(({ key }) => key === currTabKey)

  React.useEffect(() => {
    return () => {
      dispatch(initCmpAssetLibraryVideoByKeyPath({ keyPath: ['recent'] }))
      dispatch(
        initCmpAssetLibraryVideoByKeyPath({ keyPath: ['recentMessage'] })
      )
      dispatch(
        initCmpAssetLibraryVideoByKeyPath({ keyPath: ['selectedItems'] })
      )
      dispatch(initCmpAssetLibraryVideoByKeyPath({ keyPath: ['viewState'] }))

      if (isMessage) {
        // 메시지의 경우 동일한 vid 로 재요청이 불가하여 업로드 리스트 삭제
        dispatch(initCmpAssetLibraryVideoByKeyPath({ keyPath: ['upload'] }))
      }
    }
  }, [dispatch, isMessage])

  const assetConstraintForDisplay = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getAssetConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType: creativeAssetPropertyTypeForDisplay,
      }),
    [
      creativeAssetConstraints,
      creativeAssetPropertyTypeForDisplay,
      creativeFormat,
    ]
  )

  const assetConstraintForMessage = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getAssetConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType: creativeAssetPropertyTypeForMessage,
      }),
    [
      creativeAssetConstraints,
      creativeAssetPropertyTypeForMessage,
      creativeFormat,
    ]
  )

  const uploadSizeConstraintsForDisplay = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getUploadSizeConstraints({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType: creativeAssetPropertyTypeForDisplay,
      }) ?? List(),
    [
      creativeAssetConstraints,
      creativeAssetPropertyTypeForDisplay,
      creativeFormat,
    ]
  )

  const uploadSizeConstraintsForMessage = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getUploadSizeConstraints({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType: creativeAssetPropertyTypeForMessage,
      }) ?? List(),
    [
      creativeAssetConstraints,
      creativeAssetPropertyTypeForMessage,
      creativeFormat,
    ]
  )

  const onUpload = React.useCallback(
    ({ files, cancelKey, onProgress, onFinish }) => {
      if (isMessage) {
        dispatch(
          uploadCmpAssetLibraryVideoForMessage({
            adAccountId,
            files,
            cancelKey,
            isMountedRef: isMounted,
            onProgress,
            onFinish,
          })
        )
      } else {
        dispatch(
          uploadCmpAssetLibraryVideoForDisplay({
            adAccountId,
            creativeUploadAssetPropertyType,
            uploadSizeConstraints: uploadSizeConstraintsForDisplay,
            files,
            cancelKey,
            onProgress,
            onFinish,
          })
        )
      }
    },
    [
      isMessage,
      dispatch,
      adAccountId,
      creativeUploadAssetPropertyType,
      isMounted,
      uploadSizeConstraintsForDisplay,
    ]
  )

  const cmpVideoUpload = useCmpVideoUpload({
    onUpload,
    creativeUploadAssetPropertyType,
  })

  const { startEncoding, cancelEncoding } = useVideoEncoding()

  const {
    videoUploadState,
    setVideoUploadState,
    videoUploadCancelKey,
    fileCount,
  } = cmpVideoUpload

  const requestLockKey = 'VIDEO_LIBRARY_SAVE'
  const saveCancelKey = uuid()

  React.useEffect(() => {
    return () => {
      cancelRequest(saveCancelKey)
    }
  }, [saveCancelKey])

  /**
   * 비디오 인코딩, 변환 등 공통적인 일련의 과정을 onSave 전에 처리한다.
   * 라이브러리 외부에서는 최종적으로 완료된 video asset 을 취한다.
   *
   * 1. 디스플레이 소재에서 오픈 시
   *  - 디스플레이 소재에서 `최근 사용한 디스플레이 에셋` 선택
   *    1. videoPresets 로 프리셋 요청 & 응답을 videoPresets 대체.
   *
   *  - 디스플레이 소재에서 `최근 사용한 메시지 에셋` 선택 시
   *    1. clipLinkId + fileName 을 이용하여 tenth 업로드 요청
   *    2. 1의 응답으로 video asset 요청
   *    3. 2의 응답을 사용
   *
   *  - 디스플레이 소재에서 `직접 업로드한 에셋` 선택 시
   *    1. transCodeIds 를 이용하여 인코딩 요청
   *    2. 1의 인코딩 완료 후 원본 item 사용(인코딩된 결과를 사용하는 방식 아님)
   *
   * 2. 메시지 소재에서 오픈 시
   *  - 메시지 소재에서 `최근 사용한 디스플레이 에셋` 선택 시
   *    1. downloadUrl & fileName 을 이용하여 kakaoTv 업로드 & 인코딩 요청
   *    2. 1의 업로드 요청에 대한 응답을 받기 위해 polling 수행
   *    3. 2의 응답으로 thumbnail 요청
   *    4. 2, 3의 응답을 조합하여 video asset 생성
   *    5. 1의 인코딩 요청에 대한 응답을 받기 위해 polling 수행
   *    6. 5의 인코딩 완료 후 4를 사용
   *
   *  - 메시지 소재에서 `최근 사용한 메시지 에셋` 선택 시(kakaoTV url 을 이용한 영상 가져오기와 동일함)
   *    1. thumbnail, clipLinkId 를 이용하여 chatbubbleVideos, chatbubbleImages 에 각각 업로드
   *    2. 1의 응답을 조합하여 video asset 생성 후 사용
   *
   *  - 메시지 소재에서 `직접 업로드한 에셋` 선택 시
   *    1. vid, fileName 을 이용하여 chatbubbleVideos(shared)에 업로드 & 인코딩 요청
   *    2. 1의 응답에서 thumbnail 를 이용하여 chatbubbleImages 에 업로드
   *    3. 1의 인코딩 요청에 대한 응답을 받기 위해 polling 수행
   *    4. 3의 인코딩 완료 후 1, 2의 응답을 조합하여 video asset 생성 후 사용
   */
  const onOK = React.useCallback(async () => {
    const item = selectedItems.first()

    if (item) {
      let nextVideoItem = null

      if (isMessage) {
        /**
         * 메시지 소재에서 open
         */
        const { cmpVideoType, clipLinkId } = item

        if (cmpVideoType === CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY) {
          /**
           * 메시지 소재에서 `최근 사용한 디스플레이 에셋` 가져오기
           */
          await RequestLock.acquire({
            key: requestLockKey,
            executor: done =>
              showPromiseMessage({
                promiseFn: () =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const {
                        url: videoAssetUrl,
                        originalFileName: videoAssetFileName = '',
                        uploadThumbnail: videoAssetUploadThumbnail,
                      } = item

                      const protocolUrl = checkUrl(videoAssetUrl)
                        ? videoAssetUrl
                        : `http:${videoAssetUrl}`

                      const downloadUrl = protocolUrl.replace('?download', '')

                      const { data: videoData } =
                        await CreativeAPI.uploadMessageCreativeVideoUrlToKakaoTv(
                          {
                            adAccountId,
                            body: {
                              title: videoAssetFileName.substring(0, 45),
                              downloadUrlList: [downloadUrl],
                            },
                          }
                        )

                      await dispatch(
                        getCmpAssetLibraryVideoInfoForKakaoTv({
                          adAccountId,
                          vid: videoData.vid,
                          isMountedRef: isMounted,
                          onSuccess: ({ thumbnailUrlMap, duration }) => {
                            const thumbnailUrls = Object.values(thumbnailUrlMap)

                            if (thumbnailUrls.length === 0) {
                              reject()
                            } else {
                              dispatch(
                                uploadCmpAssetLibraryVideoThumbnailForKakaoTv({
                                  adAccountId,
                                  creativeFormat,
                                  thumbnail: thumbnailUrls[0],
                                  onSuccess: thumbnail => {
                                    dispatch(
                                      getCmpAssetLibraryVideoEncodingProcessForKakaoTv(
                                        {
                                          adAccountId,
                                          vid: videoData.vid,
                                          isMountedRef: isMounted,
                                          onSuccess: () => {
                                            resolve({
                                              videoItem: fromJS({
                                                // videoData 의 duration 이 0이기 때문에 movieInfo 에서 채워줘야 함
                                                video: {
                                                  ...videoData,
                                                  duration,
                                                },
                                                thumbnail:
                                                  CreativeHelper.Image.toAPI(
                                                    thumbnail
                                                  ),
                                                uploadThumbnail:
                                                  videoAssetUploadThumbnail,
                                                messageVideoTitle:
                                                  videoData.name,
                                                cmpVideoType:
                                                  item.get('cmpVideoType'),
                                                videoUUID:
                                                  item.get('videoUUID'),
                                              }),
                                            })
                                          },
                                          onError: reject,
                                        }
                                      )
                                    )
                                  },
                                  onError: reject,
                                })
                              )
                            }
                          },
                          onError: reject,
                        })
                      )
                    } catch (e) {
                      const { errorCode, message } = e?.response?.data || {}

                      const errorMessage =
                        getCreativeVideoUploadExceptionMessageByErrorCode({
                          errorCode,
                          defaultMessage: message,
                        })

                      reject(errorMessage)
                    }
                  }),
                onLoading: () => {
                  dispatch(showLoading())

                  return '동영상을 변환하는 중입니다.'
                },
                onSuccess: ({ videoItem }) => {
                  nextVideoItem = videoItem.set(
                    'videoSourceType',
                    VideoSourceEnum.Type.CMP_DISPLAY
                  )

                  return '동영상 변환이 완료되었습니다.'
                },
                onError: () => {
                  return '동영상을 처리 하는 중, 문제가 발생하였습니다. 잠시 후, 다시 홍보동영상을 등록하세요.'
                },
                onFinally: () => {
                  dispatch(hideLoading())

                  done()
                },
              }),
          })
        } else {
          if (clipLinkId > 0) {
            /**
             * 메시지 소재에서 `최근 사용한 메시지 에셋` 가져오기
             */
            await RequestLock.acquire({
              key: requestLockKey,
              executor: done =>
                showPromiseMessage({
                  promiseFn: () =>
                    new Promise(async (resolve, reject) => {
                      const {
                        autoThumbnail: { url: autoThumbnailUrl },
                        uploadThumbnail,
                      } = item

                      const videoMeta = {}

                      // live 는 들어올 수 없다.
                      videoMeta.thumbnail = autoThumbnailUrl
                      videoMeta.id = clipLinkId
                      videoMeta.isLive = false
                      videoMeta.isLoad = true
                      videoMeta.isLink = true

                      try {
                        const { data: videoData } =
                          await MessageAPI.uploadMessageVideo(
                            adAccountId,
                            videoMeta
                          )

                        const { data: thumbnailData } =
                          await MessageAPI.uploadMessageVideoThumbnail(
                            adAccountId,
                            creativeFormat,
                            videoData.thumbnail
                          )

                        resolve({
                          videoItem: fromJS({
                            video: videoData,
                            thumbnail: CreativeHelper.Image.toAPI(
                              thumbnailData.successFiles?.[0] || {}
                            ),
                            uploadThumbnail,
                            cmpVideoType: item.get('cmpVideoType'),
                            videoUUID: item.get('videoUUID'),
                          }),
                        })
                      } catch (e) {
                        dispatch(handleCreativeVideoUploadException(e))

                        reject()
                      }
                    }),
                  onLoading: () => {
                    dispatch(showLoading())

                    return VIDEO_PROCESSING_MESSAGE.PENDING
                  },
                  onSuccess: ({ videoItem }) => {
                    nextVideoItem = videoItem.set(
                      'videoSourceType',
                      VideoSourceEnum.Type.CMP_MESSAGE
                    )

                    return VIDEO_PROCESSING_MESSAGE.SUCCESS
                  },
                  onError: () => {
                    return VIDEO_PROCESSING_MESSAGE.ERROR
                  },
                  onFinally: () => {
                    dispatch(hideLoading())

                    done()
                  },
                }),
            })
          } else {
            /**
             * 메시지 소재에서 `직접 업로드한 에셋` 가져오기
             */
            await RequestLock.acquire({
              key: requestLockKey,
              executor: done =>
                showPromiseMessage({
                  promiseFn: () =>
                    new Promise(async (resolve, reject) => {
                      try {
                        const {
                          vid: videoAssetVid,
                          originalFileName: videoAssetFileName = '',
                        } = item

                        const { data: videoData } =
                          await MessageAPI.uploadMessageVideoShared(
                            adAccountId,
                            {
                              defaultThumbnailIndex: 0,
                              isLoad: false,
                              reservedVideo: { vid: videoAssetVid },
                              title: videoAssetFileName.substring(0, 45),
                            }
                          )

                        if (videoData.thumbnail) {
                          await dispatch(
                            uploadCmpAssetLibraryVideoThumbnailForKakaoTv({
                              adAccountId,
                              creativeFormat,
                              thumbnail: videoData.thumbnail,
                              onSuccess: thumbnail => {
                                dispatch(
                                  getCmpAssetLibraryVideoEncodingProcessForKakaoTv(
                                    {
                                      adAccountId,
                                      vid: videoData.vid,
                                      isMountedRef: isMounted,
                                      onSuccess: () => {
                                        resolve({
                                          videoItem: fromJS({
                                            video: videoData,
                                            thumbnail: {
                                              fileSize: thumbnail.fileSize,
                                              imageHeight: thumbnail.height,
                                              imageWidth: thumbnail.width,
                                              imageHash: thumbnail.md5sum,
                                              url: thumbnail.downloadUrl,
                                              mimeType: thumbnail.mimeType,
                                              originalFileName:
                                                thumbnail.originalFileName,
                                            },
                                            messageVideoTitle: videoData.name,
                                            cmpVideoType:
                                              item.get('cmpVideoType'),
                                            videoUUID: item.get('videoUUID'),
                                          }),
                                        })
                                      },
                                      onError: reject,
                                    }
                                  )
                                )
                              },
                              onError: reject,
                            })
                          )
                        } else {
                          reject()
                        }
                      } catch (e) {
                        const { errorCode, message } = e?.response?.data || {}

                        reject(
                          getCreativeVideoUploadExceptionMessageByErrorCode({
                            errorCode,
                            defaultMessage: message,
                          })
                        )
                      }
                    }),
                  onLoading: () => {
                    dispatch(showLoading())

                    return VIDEO_PROCESSING_MESSAGE.PENDING
                  },
                  onSuccess: ({ videoItem }) => {
                    nextVideoItem = videoItem.set(
                      'videoSourceType',
                      VideoSourceEnum.Type.CMP_UPLOAD
                    )

                    return VIDEO_PROCESSING_MESSAGE.SUCCESS
                  },
                  onError: () => {
                    // 가져오기 실패한 경우 재사용이 불가하여 제거한다. 동일 vid 로 재요청 불가.
                    dispatch(deleteCmpAssetLibraryVideoUploadItem({ item }))

                    return VIDEO_PROCESSING_MESSAGE.ERROR
                  },
                  onFinally: () => {
                    dispatch(hideLoading())

                    done()
                  },
                }),
            })
          }
        }
      } else {
        // 디스플레이 소재에서 open
        const { cmpVideoType, transCodeIds: videoAssetTransCodeIds } = item

        if (cmpVideoType === CMP_ASSET_LIBRARY_VIDEO_TYPE.MESSAGE) {
          /**
           * 디스플레이 소재에서 `최근 사용한 메시지 에셋` 가져오기
           */
          await RequestLock.acquire({
            key: requestLockKey,
            executor: done =>
              showPromiseMessage({
                promiseFn: () =>
                  new Promise(async (resolve, reject) => {
                    const {
                      clipLinkId,
                      name: fileName,
                      uploadThumbnail,
                      cmpVideoType,
                      videoUUID,
                    } = item

                    try {
                      /**
                       * profile: "HIGH"
                       * protocol: "mp4"
                       * status: "complete"
                       * thumbnail: "https://thumb.kakaocdn.net/dna/kamp-sbox/source/sv0he536ataryoapm7qp15dwt/thumbs/thumb.jpg?credential=TuMuFGKUIcirOSjFzOpncbomGFEIdZWK&expires=33201981075&signature=hJZ345QS4nRkSIcrerfcaxMPxxs%3D"
                       * url: "https://kamp.kakaocdn.net/dna/kamp-sbox/vod/sv0he536ataryoapm7qp15dwt/mp4/mp4_720P_2M_T1.mp4?credential=TuMuFGKUIcirOSjFzOpncbomGFEIdZWK&expires=1649743295&signature=eJNTZxZsOT2kuSgM2vhgjdYPR1s%3D"
                       */
                      const videoDownloadInfoResponse =
                        await CreativeAPI.getCreativeVideoDownloadInfoByClipLinkId(
                          {
                            adAccountId,
                            clipLinkId,
                          }
                        )

                      const { url: videoDownloadUrl } =
                        videoDownloadInfoResponse.data

                      const tenthVideoResponse =
                        await CreativeAPI.uploadCreativeVideoBySignedUrlAndFileName(
                          {
                            adAccountId,
                            fileName,
                            signedUrl: videoDownloadUrl,
                            transcodeType:
                              CmpAssetLibraryUtils.getTranscodeType({
                                campaignType,
                                creativeUploadAssetPropertyType,
                              }),
                          }
                        )

                      const tenthVideo = tenthVideoResponse.data

                      if (tenthVideo) {
                        // tenth 업로드 응답으로 videoAsset 요청

                        tenthVideo.trans_code_type =
                          CmpAssetLibraryUtils.getTranscodeType({
                            campaignType,
                            creativeUploadAssetPropertyType,
                          })
                        const videoAssetResponse =
                          await CreativeAPI.getCreativeVideoInfoByFileName({
                            adAccountId,
                            fileName: encodeURIComponent(fileName),
                            body: tenthVideo,
                          })

                        const videoAsset = videoAssetResponse.data
                        startEncoding({
                          adAccountId,
                          transCodeIds: videoAsset.transCodeIds,
                          onSuccess: () => {
                            resolve({
                              videoItem: fromJS({
                                ...videoAsset,
                                uploadThumbnail,
                                cmpVideoType,
                                videoUUID,
                              }),
                            })
                          },
                          onError: reject,
                          onCancel: reject,
                        })
                      } else {
                        reject()
                      }
                    } catch (e) {
                      reject()
                    }
                  }),
                onLoading: () => {
                  dispatch(showLoading())

                  return VIDEO_PROCESSING_MESSAGE.PENDING
                },
                onSuccess: ({ videoItem }) => {
                  nextVideoItem = videoItem.set(
                    'videoSourceType',
                    VideoSourceEnum.Type.CMP_MESSAGE
                  )

                  return VIDEO_PROCESSING_MESSAGE.SUCCESS
                },
                onError: e => {
                  const errorMessage =
                    getCreativeVideoUploadExceptionMessageByErrorCode(e)
                  cancelEncoding()

                  return errorMessage || VIDEO_PROCESSING_MESSAGE.ERROR
                },
                onFinally: () => {
                  dispatch(hideLoading())

                  done()
                },
              }),
          })
        } else {
          /**
           * 디스플레이 소재에서 `직접 업로드한 에셋` 가져오기
           */
          if (videoAssetTransCodeIds?.count() > 0) {
            await RequestLock.acquire({
              key: requestLockKey,
              executor: done =>
                showPromiseMessage({
                  promiseFn: () =>
                    new Promise((resolve, reject) => {
                      startEncoding({
                        adAccountId,
                        transCodeIds: videoAssetTransCodeIds,
                        onSuccess: () => resolve({ videoItem: item }),
                        onError: reject,
                        onCancel: reject,
                      })
                    }),
                  onLoading: () => {
                    dispatch(showLoading())

                    return VIDEO_PROCESSING_MESSAGE.PENDING
                  },
                  onSuccess: ({ videoItem }) => {
                    nextVideoItem = videoItem.set(
                      'videoSourceType',
                      VideoSourceEnum.Type.CMP_UPLOAD
                    )

                    return VIDEO_PROCESSING_MESSAGE.SUCCESS
                  },
                  onError: e => {
                    const errorMessage =
                      getCreativeVideoUploadExceptionMessageByErrorCode(e)
                    cancelEncoding()

                    return errorMessage || VIDEO_PROCESSING_MESSAGE.ERROR
                  },
                  onFinally: () => {
                    dispatch(hideLoading())

                    done()
                  },
                }),
            })
          } else {
            /**
             * 디스플레이 소재에서 `최근 사용한 디스플레이 에셋` 가져오기
             */
            const { videoPresets: cmpVideoPresets } = item

            await RequestLock.acquire({
              key: '',
              executor: done =>
                showPromiseMessage({
                  promiseFn: () =>
                    new Promise(async (resolve, reject) => {
                      try {
                        const { data: creativeVideoPresets } =
                          await CreativeAPI.getCreativeVideoPresetsByCmpVideoPresets(
                            { adAccountId, cmpVideoPresets }
                          )

                        resolve({
                          videoItem: item.set(
                            'videoPresets',
                            fromJS(creativeVideoPresets)
                          ),
                        })
                      } catch (e) {
                        reject()
                      }
                    }),
                  onLoading: () => {
                    dispatch(showLoading())

                    return VIDEO_PROCESSING_MESSAGE.PENDING
                  },
                  onSuccess: ({ videoItem }) => {
                    nextVideoItem = videoItem.set(
                      'videoSourceType',
                      VideoSourceEnum.Type.CMP_DISPLAY
                    )

                    return VIDEO_PROCESSING_MESSAGE.SUCCESS
                  },
                  onError: () => {
                    return VIDEO_PROCESSING_MESSAGE.ERROR
                  },
                  onFinally: () => {
                    dispatch(hideLoading())

                    done()
                  },
                }),
            })
          }
        }
      }

      if (nextVideoItem) {
        onSave({
          item: nextVideoItem,
          onSuccess: message => {
            showSuccessMessage(message || '소재 요소 등록이 완료되었습니다.')

            close()
          },
          onFailure: message => {
            showErrorMessage(message || '소재 요소 등록에 실패했습니다.')
          },
          close,
        })
      }
    } else {
      showErrorMessage('동영상을 선택하세요.')
    }
  }, [
    adAccountId,
    cancelEncoding,
    close,
    creativeFormat,
    dispatch,
    isMessage,
    isMounted,
    onSave,
    selectedItems,
    startEncoding,
    creativeUploadAssetPropertyType,
  ])

  const GuideView = React.useMemo(() => {
    return (
      <CmpAssetLibraryGuide
        campaignType={campaignType}
        creativeFormat={creativeFormat}
        creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
        uploadGuideTooltip={<CreativeGuideTooltip {...guideTooltipContent} />}
        isMotionBoard={isMotionBoard}
        isCustomBoard={isCustomBoard}
      />
    )
  }, [
    creativeUploadAssetPropertyType,
    campaignType,
    creativeFormat,
    guideTooltipContent,
    isMotionBoard,
    isCustomBoard,
  ])

  // assertion error
  if (!assetConstraintForDisplay || !assetConstraintForMessage) {
    // const errorMessage = `Constraint not found: ${creativeFormat} ${creativeAssetPropertyTypeForDisplay} ${creativeAssetPropertyTypeForMessage}`
    const errorMessage = '사용가능한 소재 유형이 없습니다.'

    Sentry.captureException(
      JSON.stringify({
        errorMessage,
        creativeFormat,
        creativeAssetPropertyTypeForDisplay,
        creativeAssetPropertyTypeForMessage,
        creativeAssetConstraints: creativeAssetConstraints?.toJS(),
      })
    )

    showErrorMessage(errorMessage)

    close()
  }

  const saveSizeConstraints = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getSaveSizeConstraints({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType: creativeAssetPropertyTypeForDisplay,
      }) ?? List(),
    [
      creativeAssetConstraints,
      creativeAssetPropertyTypeForDisplay,
      creativeFormat,
    ]
  )

  const validateVideoSize = React.useCallback(
    ({ originWidth, originHeight }) => {
      return CmpAssetLibraryUtils.isImageSizeValid({
        sizeConstraints: saveSizeConstraints,
        originWidth,
        originHeight,
      })
    },
    [saveSizeConstraints]
  )

  return (
    <div className="inner_basic_layer">
      <div className="layer_head">
        <strong className="tit_layer">소재 라이브러리</strong>
      </div>
      <div className="layer_body">
        <strong className="screen_out">소재 라이브러리 종류</strong>
        <Tabs
          onSelect={nextTabIndex => {
            const nextTabKey = currTabItems.find(
              (tab, index) => index === nextTabIndex
            ).key
            setCurrTabKey(nextTabKey)
          }}
          selectedIndex={currTabIndex}>
          <TabList className="tab_g8">
            {currTabItems.map(({ key, name }, tabIndex) => {
              return (
                <Tab
                  key={key}
                  className={cx({ on: currTabIndex === tabIndex })}>
                  <a className="link_tab">
                    {name}
                    {currTabIndex === tabIndex && (
                      <span className="screen_out">선택됨</span>
                    )}
                  </a>
                </Tab>
              )
            })}
          </TabList>
          <TabPanel>
            <CmpAssetLibraryVideoDisplayRecent
              adAccountId={adAccountId}
              creativeFormat={creativeFormat}
              creativeAssetPropertyType={creativeAssetPropertyTypeForDisplay}
              assetConstraint={assetConstraintForDisplay}
              renderGuideView={GuideView}
              validateVideoSize={validateVideoSize}
            />
          </TabPanel>
          {isAvailableMessageVideo && (
            <TabPanel>
              <CmpAssetLibraryVideoMessageRecent
                adAccountId={adAccountId}
                creativeFormat={creativeFormat}
                creativeAssetPropertyType={creativeAssetPropertyTypeForMessage}
                assetConstraint={assetConstraintForMessage}
                renderGuideView={GuideView}
              />
            </TabPanel>
          )}
          <TabPanel>
            {isMessage ? (
              <CmpAssetLibraryVideoMessageUpload
                creativeUploadAssetPropertyType={
                  creativeUploadAssetPropertyType
                }
                uploadSizeConstraints={uploadSizeConstraintsForMessage}
                renderGuideView={
                  <CmpAssetLibraryGuide
                    campaignType={campaignType}
                    creativeFormat={creativeFormat}
                    creativeUploadAssetPropertyType={
                      creativeUploadAssetPropertyType
                    }
                    uploadGuideTooltip={
                      <CreativeGuideTooltip {...guideTooltipContent} />
                    }
                    isMotionBoard={isMotionBoard}
                    isCustomBoard={isCustomBoard}
                  />
                }
                {...cmpVideoUpload}
              />
            ) : (
              <CmpAssetLibraryVideoDisplayUpload
                creativeUploadAssetPropertyType={
                  creativeUploadAssetPropertyType
                }
                uploadSizeConstraints={uploadSizeConstraintsForDisplay}
                renderGuideView={
                  <CmpAssetLibraryGuide
                    campaignType={campaignType}
                    creativeFormat={creativeFormat}
                    creativeUploadAssetPropertyType={
                      creativeUploadAssetPropertyType
                    }
                    uploadGuideTooltip={
                      <CreativeGuideTooltip {...guideTooltipContent} />
                    }
                    isMotionBoard={isMotionBoard}
                    isCustomBoard={isCustomBoard}
                  />
                }
                {...cmpVideoUpload}
                validateVideoSize={validateVideoSize}
              />
            )}
          </TabPanel>
        </Tabs>
        {videoUploadState?.progress > 0 && (
          <div className="layer_upload">
            <div className="inner_layer">
              <span className="txt_upload">
                {NumberUtils.withCommas(fileCount)}개 동영상 업로드 중
              </span>
              <span className="load_wrap">
                <span className="load_bg">
                  <span
                    className="load_bar"
                    style={{ width: `${videoUploadState.progress * 100}%` }}>
                    {videoUploadState.progress}%
                  </span>
                </span>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => {
                    cancelRequest(videoUploadCancelKey)

                    setVideoUploadState(UPLOAD_INITIAL_STATUS)
                  }}>
                  <span className="ico_comm ico_del">삭제</span>
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="layer_foot">
        {selectedItems.count() > 0 && (
          <span className="txt_select">
            <button
              type="button"
              className="btn_del"
              onClick={() => {
                dispatch(
                  initCmpAssetLibraryVideoByKeyPath({
                    keyPath: ['selectedItems'],
                  })
                )
              }}>
              <span className="ico_comm ico_del">삭제</span>
            </button>
            선택된 동영상
            <em className="num_select">
              {NumberUtils.withCommas(selectedItems.count())}
            </em>
          </span>
        )}
        <PopupButtonGroup
          okButtonLabel="확인"
          hasBack={false}
          isEnabledOK={
            selectedItems.count() > 0 && videoUploadState?.progress === 0
          }
          onOK={onOK}
          onCancel={close}
        />
        <a className="btn_close" onClick={close}>
          <span className="ico_comm ico_close">닫기</span>
        </a>
      </div>
    </div>
  )
}

CmpAssetLibraryVideo.propTypes = {
  adAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()).isRequired,
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyTypeForDisplay: PropTypes.oneOf(
    CreativeAssetPropertyEnum.values()
  ).isRequired,
  creativeAssetPropertyTypeForMessage: PropTypes.oneOf(
    CreativeAssetPropertyEnum.values()
  ).isRequired,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  //
  guideTooltipContent: PropTypes.shape({
    formats: PropTypes.array,
    sizes: PropTypes.array,
    extras: PropTypes.array,
  }),
  onSave: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default PopupHOC(CmpAssetLibraryVideo, {
  subClassName: 'material_layer',
})
