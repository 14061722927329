import { Map } from 'immutable'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
  hasFullWidthWhitespace,
  isPositiveIntegerSequence,
  isUndefinedOrNull,
  validateInputLength,
} from '../../../../utils/regexUtils'
import { keyMirror } from '../../../../utils/utils'

import CampaignTypeEnum from '../../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../../enums/GoalEnum'
import { IS_NOT_VALID, IS_VALID } from '../../../../validators/validation'
import AdGroupConstraints from '../../../../utils/constraints/constraints-adGroup'
import { NumberUtils } from '../../../../utils/numberUtils'
import CampaignConstraints from '../../../../utils/constraints/constraints-campaign'

export const NEW_CAMPAIGN_TRIM_KEY_PATH_LIST = [['name']]

export const NEW_CAMPAIGN_FORM_VALIDATION_KEY = keyMirror({
  CAMPAIGN_TYPE: null,
  GOAL: null,
  AVAILABLE_CAMPAIGN_TYPE_GOAL: null,
  OBJECTIVE_VALUE: null, // 광고목표대상 trackId || profileId
  TRACK_ID: null, // 전환추적 trackId
  DAILY_BUDGET: null,
  CONTRACT_BUDGET: null,
  CAMPAIGN_NAME: null,
  CONTRACT_ID: null,
})

export const NEW_CAMPAIGN_FORM_VALIDATION_KEY_PATH = Map({
  CAMPAIGN_TYPE: ['campaignTypeGoal', 'campaignType'],
  GOAL: ['campaignTypeGoal', 'goal'],
  AVAILABLE_CAMPAIGN_TYPE_GOAL: ['campaignTypeGoal'],
  OBJECTIVE_VALUE: ['objective', 'value'],
  TRACK_ID: ['trackId'],
  DAILY_BUDGET: ['dailyBudget'],
  CONTRACT_BUDGET: ['contractBudget'],
  CAMPAIGN_NAME: ['name'],
  CONTRACT_ID: ['contractId'],
})

export const NEW_CAMPAIGN_FORM_VALIDATOR = Map({
  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.CAMPAIGN_TYPE]: campaignType => {
    if (!checkNotEmpty(campaignType)) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.GOAL]: goal => {
    if (!checkNotEmpty(goal)) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.AVAILABLE_CAMPAIGN_TYPE_GOAL]: (
    campaignTypeGoal,
    selectedCampaignTypeGoalItem
  ) => {
    const { campaignType, goal } = campaignTypeGoal

    if (
      checkNoneEmpty(campaignType, goal) &&
      checkEmpty(selectedCampaignTypeGoalItem)
    ) {
      return IS_NOT_VALID('사용가능한 캠페인 유형과 목표가 아닙니다.')
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.CAMPAIGN_NAME]: name => {
    if (!checkNotEmpty(name)) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    }

    if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
    }

    if (!validateInputLength(name, 1, 50)) {
      return IS_NOT_VALID('캠페인 이름은 1~50자까지 입력가능합니다.')
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.TRACK_ID]: (
    trackId,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
          objective,
        },
        campaignViewState: { isConversionNotSet },
      },
      adConstraints: {
        campaignConstraints: { trackConstraints },
      },
    } = getState()

    const { type: objectiveType } = objective || {}

    const isConversionRequired = CampaignConstraints.isConversionRequired({
      campaignType,
      goal,
      trackConstraints,
      objectiveType,
    })

    if (
      isConversionRequired &&
      !isConversionNotSet &&
      !checkNotEmpty(trackId)
    ) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.OBJECTIVE_VALUE]: (value, formData) => {
    const {
      campaignTypeGoal: { campaignType, goal },
      objective,
    } = formData

    const { detailType } = objective || {}
    const isNeedValidation = AdGroupConstraints.isObjectiveRequired({
      campaignType,
      goal,
    })

    if (isNeedValidation && !checkNoneEmpty(value, detailType)) {
      return IS_NOT_VALID('필수 입력 항목입니다.')
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.DAILY_BUDGET]: (
    dailyBudget,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignViewState: { isDailyBudgetUnlimited },
      },
      adConstraints: {
        campaignConstraints: { campaignDailyBudgetConstraint },
      },
    } = getState()

    const campaignDailyBudgetIsAvailable = ({
      campaignDailyBudgetConstraint,
    }) => {
      if (checkEmpty(campaignDailyBudgetConstraint)) return false
      return Object.keys(campaignDailyBudgetConstraint.toJS()).every(
        k => !isUndefinedOrNull(campaignDailyBudgetConstraint.get(k))
      )
    }
    const isAvailableDailyBudget = campaignDailyBudgetIsAvailable({
      campaignDailyBudgetConstraint,
    })

    if (!isAvailableDailyBudget) {
      return IS_VALID()
    }

    return validateCampaignDailyBudget(
      dailyBudget,
      isDailyBudgetUnlimited,
      campaignDailyBudgetConstraint
    )
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.CONTRACT_BUDGET]: (
    contractBudget,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
      },
      adConstraints: {
        campaignConstraints: { campaignContractBudgetConstraint },
      },
    } = getState()

    if (
      !(
        (campaignType === CampaignTypeEnum.Type.KAKAO_TV ||
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED) &&
        goal === GoalEnum.Type.REACH
      )
    ) {
      return IS_VALID()
    }

    if (!CampaignTypeEnum.isContractCampaignType(campaignType)) {
      const unit = 10
      const { min, max } = campaignContractBudgetConstraint

      if (
        !(
          isPositiveIntegerSequence(contractBudget) &&
          contractBudget >= min &&
          contractBudget <= max &&
          contractBudget % unit === 0
        )
      ) {
        return IS_NOT_VALID(
          `${NumberUtils.toShorten(min)}원 이상 ${NumberUtils.toShorten(
            max
          )}원 이하 ${NumberUtils.toShorten(unit)}원 단위로 입력하세요.`
        )
      }
    }

    return IS_VALID()
  },

  [NEW_CAMPAIGN_FORM_VALIDATION_KEY.CONTRACT_ID]: (
    contractId,
    formData,
    getState
  ) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType },
        },
      },
      contract: {
        viewState: { isNewContract },
      },
    } = getState()

    if (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04) {
      if (checkEmpty(contractId) || contractId === -1) {
        return IS_NOT_VALID('필수 입력 항목입니다.')
      }
    }

    if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
      if (!isNewContract && (checkEmpty(contractId) || contractId === -1)) {
        return IS_NOT_VALID('필수 입력 항목입니다.')
      }
    }

    return IS_VALID()
  },
})

export const validateCampaignDailyBudget = (
  dailyBudget,
  isUnlimited,
  campaignDailyBudgetConstraint
) => {
  const { max, min } = campaignDailyBudgetConstraint || {}
  const unit = 10

  if (!isUnlimited) {
    if (
      !(
        isPositiveIntegerSequence(dailyBudget) &&
        dailyBudget >= min &&
        dailyBudget <= max &&
        dailyBudget % unit === 0
      )
    )
      return IS_NOT_VALID(
        `${NumberUtils.toShorten(min)}원 이상 ${NumberUtils.toShorten(
          max
        )}원 이하 ${NumberUtils.toShorten(unit)}원 단위로 입력하세요.`
      )
  }
  return IS_VALID()
}
