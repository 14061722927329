import { keyMirror } from '../../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { fromJS, Map } from 'immutable'
import { hideLoading, showLoading } from '../../common/mLoading'
import { handlePixelAndSdkException } from '../../../components/Linkage/PixelAndSdk/Utils/pixelAndSdk'
import { showErrorMessage, showSuccessMessage } from '../../../utils/alertUtils'
import { isUndefinedOrNull } from '../../../utils/regexUtils'
import { replace } from 'connected-react-router'
import { toTargetingPixelPath } from '../../../utils/router/routeUtils'

const PixelAndSdkDetail = keyMirror(
  {
    SET_BASIC_INFO: null,
    SET_LIST_BY_KEY_PATH: null,
    CLEAR: null,
  },
  'PIXEL_AND_SDK_DETAIL'
)

const initialState = fromJS({
  basicInfo: {},
})

export default createReducer(initialState, {
  [PixelAndSdkDetail.SET_BASIC_INFO]: (state, { info }) =>
    state.set('basicInfo', Map(info)),

  [PixelAndSdkDetail.CLEAR]: state =>
    state.set('basicInfo', initialState.get('basicInfo')),
})

export const clearPixelAndSdkDetail = () => ({
  type: PixelAndSdkDetail.CLEAR,
})

const setBasicInfo = info => ({
  type: PixelAndSdkDetail.SET_BASIC_INFO,
  info,
})

export const requestMemberDetail = (
  adAccountId,
  trackId,
  onSuccess = () => {}
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      await api.bconV2.requestMemberToTrack(adAccountId, trackId)

      onSuccess()
      showSuccessMessage('멤버 요청이 완료되었습니다.')
    } catch (e) {
      console.error(e.message)
      handlePixelAndSdkException({ e })
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const fetchDetailBasicInfo = (adAccountId, trackId) => {
  return async (dispatch, getState, api) => {
    const loadingKey = 'fetchDetailBasicInfo'

    try {
      dispatch(showLoading(loadingKey))

      const response = await api.bconV2.fetchDetailBasicInfo(
        adAccountId,
        trackId
      )

      const { body } = response.data

      // 삭제된 픽셀, 존재하지 않는 픽셀 조회시 연동관리 페이지로 이동
      if (isUndefinedOrNull(body) || !isUndefinedOrNull(body?.deletedAt)) {
        dispatch(replace(toTargetingPixelPath(adAccountId)))
        showErrorMessage('해당 픽셀 & SDK가 존재하지 않습니다.')
      } else {
        dispatch(setBasicInfo(body))
      }
    } catch (e) {
      console.error(e.message)
    } finally {
      dispatch(hideLoading(loadingKey))
    }
  }
}

export const fetchDetailLinkageServiceList = (
  adAccountId,
  trackId,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.bconV2.fetchDetailLinkageServiceList(
        adAccountId,
        trackId
      )

      const { trackRights } = response.data.body
      onSuccess(trackRights)
    } catch (e) {
      console.error(e.message)
      onFail()
    }
  }
}

export const fetchDetailCollectedEventList = (
  adAccountId,
  trackId,
  onSuccess = () => {},
  onFail = () => {}
) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.bconV2.fetchTrackCollectedEventList({
        trackId,
        adAccountId,
      })

      const { body } = response.data
      onSuccess(body)
    } catch (e) {
      console.error(e.message)
      onFail()
    }
  }
}

export const fetchDetailUsageCampaignList = ({
  adAccountId,
  trackId,
  page = 0,
  campaignTypeGoalId = -1,
  onSuccess = () => {},
  onFail = () => {},
}) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        ...(campaignTypeGoalId !== -1 && { campaignTypeGoalId }),
        page,
        size: 10,
      }

      const { data } = await api.pixelV2.fetchDetailUsageCampaignList(
        adAccountId,
        trackId,
        queryParams
      )
      onSuccess(data)
    } catch (e) {
      console.error(e.message)
      onFail()
    }
  }
}

export const fetchDetailUsageAdGroupList = ({
  adAccountId,
  trackId,
  page = 0,
  campaignTypeGoalId = -1,
  onSuccess = () => {},
  onFail = () => {},
}) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        ...(campaignTypeGoalId !== -1 && { campaignTypeGoalId }),
        page,
        size: 10,
      }

      const { data } = await api.pixelV2.fetchDetailUsageAdGroupList(
        adAccountId,
        trackId,
        queryParams
      )
      onSuccess(data)
    } catch (e) {
      console.error(e.message)
      onFail()
    }
  }
}
