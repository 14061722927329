import { CmpAPI } from '../../modules-api/advertise/cmpApi'
import { CmpRequestUtils } from '../../components/AdvertiseV2/Creative/Cmp/cmpRequestUtils'
import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { showErrorMessage } from '../../utils/alertUtils'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { v4 as uuid } from 'uuid'
import { openPopup, POPUP_KEY } from '../common/mPopup'
import axios from 'axios'
import {
  CMP_ASSET_LIBRARY_CANCEL_KEY_EDITOR,
  CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT,
  CMP_ASSET_LIBRARY_FILTER_OPTION,
  CMP_ASSET_LIBRARY_FILTER_TYPE,
  CmpAssetLibraryUtils,
} from '../../components/AdvertiseV2/Creative/Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'
import AdConstraintsHelper from '../../utils/helper/helper-adConstraints'
import { checkNotEmpty } from '../../utils/regexUtils'
import { CMP_SUB_ASSET_TYPE } from '../../components/AdvertiseV2/Creative/Cmp/cmpUtils'
import {
  CMP_EDITOR_OPENER,
  CmpEditorUtils,
} from '../../components/AdvertiseV2/Creative/Cmp/CmpEditor/cmpEditorUtils'

const CmpAssetLibraryImage = keyMirror(
  {
    SET_RECENT_DATA: null,
    SET_RECENT_SEARCH_OPTION: null,

    SET_EDITOR_DATA: null,
    SET_EDITOR_SEARCH_OPTION: null,

    ADD_UPLOAD_ITEMS: null,
    SET_UPLOAD_ITEMS: null,
    DELETE_UPLOAD_ITEM: null,
    UPDATE_UPLOAD_ITEM: null,

    SET_CATALOG_ITEMS: null,
    SET_CATALOG_PRODUCT_DATA: null,
    SET_CATALOG_PRODUCT_SEARCH_OPTION: null,

    SELECT_ITEM: null,
    MODIFY_ITEM: null,
    ADD_SELECTED_ITEMS: null,
    SET_SELECTED_ITEMS: null,
    DELETE_SELECTED_ITEM: null,

    // cropper
    SET_EDITED_ITEMS: null,

    SET_VIEW_STATE: null,
    INIT_BY_KEY_PATH: null,

    CLEAR: null,
  },
  'CMP_ASSET_LIBRARY_IMAGE'
)

const initialState = fromJS({
  recent: {
    searchOptions: {
      searchText: '',
      filterType: CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE,
      filterCondition: {
        ratios: [],
        openers: CMP_EDITOR_OPENER.MESSAGE,
        width: {
          id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
          value: 1,
          label: '전체',
        },
        height: {
          id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
          value: 1,
          label: '전체',
        },
      },
    },
    recentData: {
      query: '', // 조회한 당시의 searchText 를 보관. itemView name 과 매칭한다.
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  editor: {
    searchOptions: {
      searchText: '',
      filterType: CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE,
      filterCondition: {
        ratios: [],
        width: {
          id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
          value: 1,
          label: '전체',
        },
        height: {
          id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
          value: 1,
          label: '전체',
        },
      },
    },
    editorData: {
      query: '', // 조회한 당시의 searchText 를 보관. itemView name 과 매칭한다.
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  upload: {
    uploadItems: [], // { imageUUID, url, imageWidth, imageHeight, imageHash, originalFileName... }
  },

  catalog: {
    searchOptions: {
      catalogId: null,
      order: 'CREATED_DATE', // CREATED_DATE | DISCOUNT_RAGE
      searchType: 'TITLE', // TITLE | ITEM_ID
      searchValue: '',
    },
    catalogItems: [], // [{ id, pixelAndSdkName }]
    productData: {
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  /**
   * [image]
   */
  selectedItems: [],

  /**
   * {
   *   [imageUUID]: image
   * }
   */
  editedItemsMap: {},

  viewState: {
    isFetchingRecent: false,
    isFetchingEditor: false,
    isUploading: false,
    isFetchingCatalog: false,
    isFetchingCatalogProduct: false,
  },
})

export default createReducer(initialState, {
  [CmpAssetLibraryImage.SET_RECENT_DATA]: (state, { data }) =>
    state.setIn(['recent', 'recentData'], fromJS(data)),

  [CmpAssetLibraryImage.SET_RECENT_SEARCH_OPTION]: (
    state,
    { keyPath, value }
  ) =>
    state.setIn(
      ['recent', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),

  [CmpAssetLibraryImage.SET_EDITOR_DATA]: (state, { data }) =>
    state.setIn(['editor', 'editorData'], fromJS(data)),

  [CmpAssetLibraryImage.SET_EDITOR_SEARCH_OPTION]: (
    state,
    { keyPath, value }
  ) =>
    state.setIn(
      ['editor', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),

  [CmpAssetLibraryImage.ADD_UPLOAD_ITEMS]: (state, { items }) =>
    state.updateIn(['upload', 'uploadItems'], prev =>
      fromJS(items).concat(prev)
    ),

  [CmpAssetLibraryImage.SET_UPLOAD_ITEMS]: (state, { items }) =>
    state.setIn(['upload', 'uploadItems'], fromJS(items)),

  [CmpAssetLibraryImage.DELETE_UPLOAD_ITEM]: (state, { item: _item }) => {
    const item = fromJS(_item)

    return state.withMutations(s =>
      s
        .updateIn(['upload', 'uploadItems'], prev =>
          prev.filter(({ imageUUID }) => imageUUID !== item.get('imageUUID'))
        )
        .update('selectedItems', prev =>
          prev.filter(({ imageUUID }) => imageUUID !== item.get('imageUUID'))
        )
    )
  },

  [CmpAssetLibraryImage.UPDATE_UPLOAD_ITEM]: (
    state,
    { index, keyPath, value }
  ) =>
    state.updateIn(['upload', 'uploadItems', index], prev =>
      prev.setIn(coerceToArray(keyPath), fromJS(value))
    ),

  [CmpAssetLibraryImage.SET_CATALOG_ITEMS]: (state, { items }) =>
    state.setIn(['catalog', 'catalogItems'], fromJS(items)),

  [CmpAssetLibraryImage.SET_CATALOG_PRODUCT_DATA]: (state, { data }) =>
    state.setIn(['catalog', 'productData'], fromJS(data)),

  [CmpAssetLibraryImage.SET_CATALOG_PRODUCT_SEARCH_OPTION]: (
    state,
    { keyPath, value }
  ) =>
    state.setIn(
      ['catalog', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),
  [CmpAssetLibraryImage.MODIFY_ITEM]: (state, { item: _item }) => {
    const item = fromJS(_item)

    return state.update('selectedItems', prev => {
      const index = prev.findIndex(
        v => v?.get('imageUUID') === item.get('imageUUID')
      )
      return index >= 0 ? prev : prev.push(item)
    })
  },
  [CmpAssetLibraryImage.SELECT_ITEM]: (state, { item: _item }) => {
    const item = fromJS(_item)

    return state.update('selectedItems', prev => {
      const index = prev.findIndex(
        v => v.get('imageUUID') === item.get('imageUUID')
      )
      return index >= 0 ? prev.delete(index) : prev.push(item)
    })
  },
  [CmpAssetLibraryImage.ADD_SELECTED_ITEMS]: (state, { items = [] }) =>
    state.update('selectedItems', prev => prev.concat(fromJS(items))),

  [CmpAssetLibraryImage.SET_SELECTED_ITEMS]: (state, { items }) =>
    state.withMutations(s => s.set('selectedItems', fromJS(items))),

  [CmpAssetLibraryImage.DELETE_SELECTED_ITEM]: (state, { item: _item }) => {
    const item = fromJS(_item)

    return state.withMutations(s =>
      s.update('selectedItems', prev =>
        prev.filter(v => v.get('imageUUID') !== item.get('imageUUID'))
      )
    )
  },
  [CmpAssetLibraryImage.SET_EDITED_ITEMS]: (state, { key, items = [] }) => {
    const nextItems = fromJS(items)

    return state.update('editedItemsMap', prevEditedItemsMap =>
      nextItems.isEmpty()
        ? prevEditedItemsMap.delete(key)
        : prevEditedItemsMap.set(key, nextItems)
    )
  },

  [CmpAssetLibraryImage.SET_VIEW_STATE]: (state, { keyPath, value }) =>
    state.setIn(['viewState', ...coerceToArray(keyPath)], fromJS(value)),

  [CmpAssetLibraryImage.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(
      coerceToArray(keyPath),
      initialState.getIn(coerceToArray(keyPath))
    ),

  [CmpAssetLibraryImage.CLEAR]: () => initialState,
})

function setCmpAssetLibraryImageRecentData({ data }) {
  return {
    type: CmpAssetLibraryImage.SET_RECENT_DATA,
    data,
  }
}

export function setCmpAssetLibraryImageRecentSearchOption({ keyPath, value }) {
  return {
    type: CmpAssetLibraryImage.SET_RECENT_SEARCH_OPTION,
    keyPath,
    value,
  }
}

function setCmpAssetLibraryImageEditorData({ data }) {
  return {
    type: CmpAssetLibraryImage.SET_EDITOR_DATA,
    data,
  }
}

export function setCmpAssetLibraryImageEditorSearchOption({ keyPath, value }) {
  return {
    type: CmpAssetLibraryImage.SET_EDITOR_SEARCH_OPTION,
    keyPath,
    value,
  }
}

export function addCmpAssetLibraryImageUploadItems({ items }) {
  return {
    type: CmpAssetLibraryImage.ADD_UPLOAD_ITEMS,
    items,
  }
}

export function setCmpAssetLibraryImageUploadItems({ items }) {
  return {
    type: CmpAssetLibraryImage.SET_UPLOAD_ITEMS,
    items,
  }
}

export function deleteCmpAssetLibraryImageUploadItem({ item }) {
  return {
    type: CmpAssetLibraryImage.DELETE_UPLOAD_ITEM,
    item,
  }
}

export function updateCmpAssetLibraryImageUploadItem({
  index,
  keyPath,
  value,
}) {
  return {
    type: CmpAssetLibraryImage.UPDATE_UPLOAD_ITEM,
    index,
    keyPath,
    value,
  }
}

function setCmpAssetLibraryImageCatalogItems({ items }) {
  return {
    type: CmpAssetLibraryImage.SET_CATALOG_ITEMS,
    items,
  }
}

function setCmpAssetLibraryImageCatalogProductData({ data }) {
  return {
    type: CmpAssetLibraryImage.SET_CATALOG_PRODUCT_DATA,
    data,
  }
}

export function setCmpAssetLibraryImageCatalogProductSearchOption({
  keyPath,
  value,
}) {
  return {
    type: CmpAssetLibraryImage.SET_CATALOG_PRODUCT_SEARCH_OPTION,
    keyPath,
    value,
  }
}

export function selectCmpAssetLibraryImageItem({ item }) {
  return {
    type: CmpAssetLibraryImage.SELECT_ITEM,
    item,
  }
}

export function modifyCmpAssetLibraryImageItem({ item }) {
  return {
    type: CmpAssetLibraryImage.MODIFY_ITEM,
    item,
  }
}

export function addCmpAssetLibraryImageSelectedItems({ items }) {
  return {
    type: CmpAssetLibraryImage.ADD_SELECTED_ITEMS,
    items,
  }
}

export function setCmpAssetLibraryImageSelectedItems({ items }) {
  return {
    type: CmpAssetLibraryImage.SET_SELECTED_ITEMS,
    items,
  }
}

export function deleteCmpAssetLibraryImageSelectedItem({ item }) {
  return {
    type: CmpAssetLibraryImage.DELETE_SELECTED_ITEM,
    item,
  }
}

export function setCmpAssetLibraryEditedItems({ key, items }) {
  return {
    type: CmpAssetLibraryImage.SET_EDITED_ITEMS,
    key,
    items,
  }
}

export function setCmpAssetLibraryImageViewState({ keyPath, value }) {
  return {
    type: CmpAssetLibraryImage.SET_VIEW_STATE,
    keyPath,
    value,
  }
}

export function initCmpAssetLibraryImageByKeyPath({ keyPath }) {
  return {
    type: CmpAssetLibraryImage.INIT_BY_KEY_PATH,
    keyPath,
  }
}

export function clearCmpAssetLibraryImage() {
  return {
    type: CmpAssetLibraryImage.CLEAR,
  }
}

export function getCmpAssetLibraryImageRecentData({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyType,
  specificRatio,
  page = 0,
  size = 50,
}) {
  return async (dispatch, getState) => {
    const {
      cmpAssetLibrary: {
        image: {
          recent: {
            searchOptions: {
              searchText,
              filterType,
              filterCondition: {
                width: { value: widthValue },
                height: { value: heightValue },
                ratios,
              },
            },
          },
        },
      },
      adConstraints: { creativeAssetConstraints },
    } = getState()

    const isMessage = CreativeFormatEnum.isMessage(creativeFormat)

    const constraint = AdConstraintsHelper.Creative.getAssetConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    })

    if (constraint) {
      const {
        cmpAssetProperty: { assetType },
        saveConstraint: { sizeConstraints },
      } = constraint

      let requestBody = {}
      /**
       * backend 의 subAssetTypes 안쓰는 이유
       * https://jira.daumkakao.com/browse/KAMOQA-19248 이슈로 인해 MAIN, PROFILE 로 고정
       * 모션보드의 오브젝트 이미지 추가로 OBJET 추가
       */
      const subAssetTypes = isMessage
        ? [
            CMP_SUB_ASSET_TYPE.MAIN,
            CMP_SUB_ASSET_TYPE.AUTO_THUMBNAIL,
            CMP_SUB_ASSET_TYPE.UPLOAD_THUMBNAIL,
            CMP_SUB_ASSET_TYPE.PROFILE,
          ]
        : [
            CMP_SUB_ASSET_TYPE.MAIN,
            CMP_SUB_ASSET_TYPE.PROFILE,
            CMP_SUB_ASSET_TYPE.OBJET,
          ]

      /**
       * specificRatio 이 있다면 동영상이 있는 상황
       * 동영상이 있는 상황에서는 해당 동영상 비율과 맞는 썸네일 이미지만 불러와야함
       * https://jira.daumkakao.com/browse/KAMOQA-24900
       */
      const mediaDimension = checkNotEmpty(specificRatio)
        ? sizeConstraints
            ?.filter(({ ratio: { eq } }) => eq === specificRatio)
            ?.toJS()
        : sizeConstraints?.toJS() ?? []

      if (filterType === CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE) {
        requestBody = CmpRequestUtils.RequestCreator.saveImageAssets({
          assetType,
          subAssetTypes,
          fileName: searchText,
          mediaDimension,
          isAvailableBizBoardImage:
            CmpAssetLibraryUtils.Recent.isAvailableBizBoardImageOnAvailable({
              campaignType,
              creativeFormat,
              creativeAssetPropertyType,
            }),
        })
      } else {
        requestBody = CmpRequestUtils.RequestCreator.uploadImageAssets({
          assetType,
          subAssetTypes,
          fileName: searchText,
          mediaRatioIn: ratios.map(({ value }) => value).toJS(),
          mediaRatioNotIn: ratios.some(
            ({ id }) => id === CMP_ASSET_LIBRARY_FILTER_OPTION.UNKNOWN
          )
            ? CmpAssetLibraryUtils.Recent.ratioValues()
            : undefined,
          mediaDimension: [
            {
              width: { min: widthValue },
              height: { min: heightValue },
            },
          ],
          isAvailableBizBoardImage:
            CmpAssetLibraryUtils.Recent.isAvailableBizBoardImageOnCustom({
              campaignType,
              creativeFormat,
              creativeAssetPropertyType,
            }),
        })
      }

      dispatch(
        setCmpAssetLibraryImageViewState({
          keyPath: ['isFetchingRecent'],
          value: true,
        })
      )

      const cancelTokenSource = createCancellation(
        CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT
      )

      try {
        const response = await CmpAPI.getImageAssets({
          adAccountId,
          requestBody,
          pageRequest: {
            page,
            size,
          },
          cancelTokenSource,
        })

        dispatch(
          setCmpAssetLibraryImageRecentData({
            data: { ...response.data, query: searchText },
          })
        )
      } catch (e) {
        if (!axios.isCancel(e)) {
          showErrorMessage(e.response?.data?.message || e.message)
        }
      } finally {
        dispatch(
          setCmpAssetLibraryImageViewState({
            keyPath: ['isFetchingRecent'],
            value: false,
          })
        )

        deleteCancellation(cancelTokenSource)
      }
    }
  }
}

export function getCmpAssetLibraryImageEditorData({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyType,
  specificRatio,
  page = 0,
  size = 50,
}) {
  return async (dispatch, getState) => {
    const {
      cmpAssetLibrary: {
        image: {
          editor: {
            searchOptions: {
              searchText,
              filterType,
              filterCondition: {
                width: { value: widthValue },
                height: { value: heightValue },
                ratios,
              },
            },
          },
        },
      },
      adConstraints: { creativeAssetConstraints },
    } = getState()

    const isMessage = CreativeFormatEnum.isMessage(creativeFormat)

    const constraint = AdConstraintsHelper.Creative.getAssetConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    })

    if (constraint) {
      const {
        saveConstraint: { sizeConstraints },
      } = constraint

      let params = {}

      const mediaDimension = checkNotEmpty(specificRatio)
        ? sizeConstraints
            ?.filter(({ ratio: { eq } }) => eq === specificRatio)
            ?.toJS()
        : sizeConstraints?.toJS() ?? []

      if (filterType === CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE) {
        params = {
          fileName: searchText,
          mediaDimension,
          cancelTokenSource,
          pageIndex: page,
          pageSize: size,
        }
      } else {
        params = {
          fileName: searchText,
          mediaRatioIn: ratios.map(({ value }) => value).toJS(),
          mediaRatioNotIn: ratios.some(
            ({ id }) => id === CMP_ASSET_LIBRARY_FILTER_OPTION.UNKNOWN
          )
            ? CmpAssetLibraryUtils.Recent.ratioValues()
            : undefined,
          mediaDimension: [
            {
              width: { min: widthValue },
              height: { min: heightValue },
            },
          ],
          pageIndex: page,
          pageSize: size,
        }
      }

      dispatch(
        setCmpAssetLibraryImageViewState({
          keyPath: ['isFetchingEditor'],
          value: true,
        })
      )

      const cancelTokenSource = createCancellation(
        CMP_ASSET_LIBRARY_CANCEL_KEY_EDITOR
      )

      try {
        const response = await CmpEditorUtils.createHttpRequestHandler({
          adAccountId,
        }).getItems({ ...params, cancelTokenSource })

        dispatch(
          setCmpAssetLibraryImageEditorData({
            data: { ...response.data, query: searchText },
          })
        )
      } catch (e) {
        if (!axios.isCancel(e)) {
          showErrorMessage(e.response?.data?.message || e.message)
        }
      } finally {
        dispatch(
          setCmpAssetLibraryImageViewState({
            keyPath: ['isFetchingEditor'],
            value: false,
          })
        )

        deleteCancellation(cancelTokenSource)
      }
    }
  }
}

export function uploadCmpAssetLibraryImages({
  adAccountId,
  creativeUploadAssetPropertyType,
  uploadSizeConstraints,
  needEdit = false,
  creativeFormat,
  guideTooltip,
  files,
  cancelKey = uuid(),
  onlyPNG24 = false,
  multiSelect = true,
  onProgress = () => undefined,
  onFinish = () => undefined,
}) {
  return async (dispatch, getState, api) => {
    const {
      creativeV2: {
        common: {
          adGroup: { id: adGroupId },
        },
      },
    } = getState()

    const cancelTokenSource = createCancellation(cancelKey)
    const formData = new FormData()

    Array.from(files).forEach(file => {
      formData.append('files', file)
    })

    formData.append('creativeFormat', creativeFormat)
    formData.append('needEdit', String(needEdit))

    const isMessage = CreativeFormatEnum.isMessage(creativeFormat)

    if (!isMessage) {
      formData.append('adGroupId', adGroupId)
      formData.append('assetPropertyType', creativeUploadAssetPropertyType)
      formData.append('png24', String(onlyPNG24))
    }

    dispatch(
      setCmpAssetLibraryImageViewState({
        keyPath: ['isUploading'],
        value: true,
      })
    )

    try {
      const API = isMessage
        ? api.message.uploadMessageImages
        : !creativeUploadAssetPropertyType
        ? api.adCreative.uploadCreativeImagesNoConstraint
        : api.adCreative.uploadCreativeImages

      const response = await API(
        adAccountId,
        formData,
        onProgress,
        cancelTokenSource,
        creativeFormat
      )

      const { successFiles, invalidFiles } = response?.data || {}

      if (successFiles?.length > 0) {
        const uploadedImages = successFiles.map(image => {
          image['imageUUID'] = uuid()
          return image
        })

        const imageAssets = CmpAssetLibraryUtils.Upload.filterByConstraint({
          uploadAssets: fromJS(uploadedImages),
          creativeUploadAssetPropertyType,
          uploadSizeConstraints,
        })

        if (imageAssets.count() > 0) {
          dispatch(addCmpAssetLibraryImageUploadItems({ items: imageAssets }))

          if (multiSelect) {
            dispatch(
              addCmpAssetLibraryImageSelectedItems({ items: imageAssets })
            )
          } else {
            dispatch(
              setCmpAssetLibraryImageSelectedItems({
                items: [imageAssets.first()],
              })
            )
          }
        } else {
          showErrorMessage('소재 제약조건에 맞는 이미지가 없습니다.')
        }
      }

      if (invalidFiles?.length > 0) {
        dispatch(
          openPopup(POPUP_KEY.MULTI_IMAGE_UPLOAD_FAILURE_SUMMARY, {
            summary: fromJS(invalidFiles),
            guideTooltip,
          })
        )
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        showErrorMessage(e.response?.data?.message || e.message)
      }
    } finally {
      deleteCancellation(cancelTokenSource)

      dispatch(
        setCmpAssetLibraryImageViewState({
          keyPath: ['isUploading'],
          value: false,
        })
      )

      onFinish()
    }
  }
}

export function getCmpAssetLibraryImageCatalogItems({ adAccountId }) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(
        setCmpAssetLibraryImageViewState({
          keyPath: ['isFetchingCatalog'],
          value: true,
        })
      )

      const authTracksResponse =
        await api.bconV2.fetchLinkedTrackListWithoutPaging(adAccountId)

      const pixelAndSdkIds =
        authTracksResponse.data.body?.content?.map(({ trackId }) => trackId) ||
        []

      if (pixelAndSdkIds.length > 0) {
        const catalogIdsResponse = await api.catalog.getCatalogIds(
          adAccountId,
          pixelAndSdkIds
        )

        dispatch(
          setCmpAssetLibraryImageCatalogItems({
            items: catalogIdsResponse.data || [],
          })
        )
      }
    } catch (e) {
      showErrorMessage('카탈로그 목록을 가져오는 중 문제가 발생하였습니다.')
    } finally {
      dispatch(
        setCmpAssetLibraryImageViewState({
          keyPath: ['isFetchingCatalog'],
          value: false,
        })
      )
    }
  }
}

export function getCmpAssetLibraryImageCatalogProductData({
  adAccountId,
  pageRequest = {
    page: 0,
    size: 50,
  },
}) {
  return async (dispatch, getState, api) => {
    const {
      cmpAssetLibrary: {
        image: {
          catalog: {
            searchOptions: { catalogId, order, searchType, searchValue },
          },
        },
      },
    } = getState()

    if (catalogId) {
      dispatch(
        setCmpAssetLibraryImageViewState({
          keyPath: ['isFetchingCatalogProduct'],
          value: true,
        })
      )

      try {
        const response = await api.catalog.getCatalogProductsByCatalogId(
          adAccountId,
          catalogId,
          order,
          checkNotEmpty(searchValue) ? searchType : null,
          encodeURIComponent(searchValue),
          pageRequest
        )

        response.data.content = response.data.content?.map(v => {
          // 로드할 때 마다 아이템의 id 가 바뀌면 안되기 때문에 product id 를 사용한다.
          v.image['imageUUID'] = v.id
          return v
        })

        dispatch(
          setCmpAssetLibraryImageCatalogProductData({
            data: response.data || {},
          })
        )
      } catch (e) {
        showErrorMessage(
          '카탈로그 상품 목록을 가져오는 중 문제가 발생하였습니다.'
        )
      } finally {
        dispatch(
          setCmpAssetLibraryImageViewState({
            keyPath: ['isFetchingCatalogProduct'],
            value: false,
          })
        )
      }
    } else {
      dispatch(
        initCmpAssetLibraryImageByKeyPath({
          keyPath: ['catalog', 'productData'],
        })
      )
    }
  }
}
