import React, { useLayoutEffect } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Switch } from 'react-router-dom'
import { replace } from 'connected-react-router'
import Gnb from '../../components/Navigation/Gnb/Gnb'
import Lnb from '../../components/Navigation/Lnb/Lnb'
import Footer from '../../components/Footer/Footer'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import AdAccountExtraEnum from '../../enums/AdAccountExtraEnum'
import { toInvalidApproachPath } from '../../utils/router/routeUtils'

const MessageManagementV2 = React.lazy(() =>
  import('../../components/Message/MessageManagementV2')
)
const MessageDetailV2 = React.lazy(() =>
  import('../../components/Message/MessageDetailV2')
)
const MessageCreate = React.lazy(() =>
  import('../../components/Message/MessageCreate')
)
const MessageModify = React.lazy(() =>
  import('../../components/Message/MessageModify')
)

const selector = ({
  adAccount: {
    adAccountInfo: { extras },
  },
}) => {
  return { extras: extras?.toJS() }
}

const MessageWrapper = () => {
  const { extras } = useSelector(selector, shallowEqual)
  const dispatch = useDispatch()
  const isNoPermission =
    extras && extras[AdAccountExtraEnum.Type.MESSAGE_AD_PARTNER]

  useLayoutEffect(() => {
    if (isNoPermission) {
      dispatch(replace(toInvalidApproachPath()))
    }
  }, [dispatch, isNoPermission])

  return (
    <div id="kakaoWrap">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb />
        <React.Suspense fallback="">
          <Switch>
            <Route
              exact
              path="/:adaccountid(\d+)/message/"
              component={MessageManagementV2}
            />
            <Route
              exact
              path="/:adaccountid(\d+)/message/create"
              component={MessageCreate}
            />
            <Route
              exact
              path="/:adaccountid(\d+)/message/:messageTemplateId(\d+)"
              component={MessageDetailV2}
            />
            <Route
              exact
              path="/:adaccountid(\d+)/message/:messageTemplateId(\d+)/modify"
              component={MessageModify}
            />
          </Switch>
        </React.Suspense>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

MessageWrapper.propTypes = {
  location: ReactRouterPropTypes.location,
  history: ReactRouterPropTypes.history,
}

export default MessageWrapper
