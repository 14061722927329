import { keyMirror } from '../../utils/utils'
import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import {
  closeAllPopup,
  openPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../common/mPopup'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import React from 'react'
import { TalkChannelMessageHelper } from '../../utils/helper/helper-talkChannelMessage'
import { MESSAGE_GUIDE_URL } from '../../utils/app/inhouse'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { hideLoading, showLoading } from '../common/mLoading'
import { replace } from 'connected-react-router'
import { toMessageListPath } from '../../utils/router/routeUtils'
import { Validation } from '../../validators/validation'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../validators/message/messageFormValidationKey'
import {
  getMessageValidatorByMessageType,
  MESSAGE_FORM_VALIDATION_KEY_PATH,
} from '../../validators/message/messageFormValidator'
import {
  carouselCommerceInvalidOrderingDialog,
  deletedLandingPostMessageDialog,
  exceedMessageCountDialog,
  finishedLandingTypeMessageDialog,
  unavailableDeleteMessageDialog,
  unavailableInvalidSpecialCharacterUrlDialog,
  unavailableInvalidUrlDialog,
  unavailableSaveByDomainMismatchDialog,
} from '../../components/Message/Dialog/MessageDialogV2'
import TalkChannelMessageEnum from '../../enums/TalkChannelMessageEnum'

export const MESSAGE_MAX_COUNT = 100

export const CAROUSEL_MESSAGE_SLIDE_MIN = 1
export const CAROUSEL_MESSAGE_SLIDE_MAX = 6
export const COUPON_MAX_COUNT = 100
export const PERSONAL_MESSAGE_PHRASE_MAX_LENGTH = 1000
export const IS_VARIABLE_IMAGE = 'isVariableImage'

const TalkChannelMessageCommon = keyMirror(
  {
    INITIALIZE: null,
    INITIALIZE_MESSAGE_DETAIL: null,
    CHANGE_VALUE_BY_KEY: null,
    MESSAGE_COMMON_CLEAR: null,
    SET_MESSAGE_TEMPLATE_LIST: null,
    SET_PERSONAL_MESSAGE_VARIABLES: null,

    // validation
    SET_MESSAGE_IS_VALID: null,
    SET_MESSAGE_VALIDATION_EXTRA: null,
    INIT_MESSAGE_VALIDATION: null,
  },
  'TALK_CHANNEL_MESSAGE_COMMON'
)

const initialState = fromJS({
  messageForm: {},
  bizBoardList: {
    content: [],
    totalElements: 0,
    number: 0,
  },
  adViewList: {
    content: [],
    totalElements: 0,
    number: 0,
  },
  messageTemplateListByPlusFriend: {
    hasNext: false,
    lastIndex: 0,
    messageElements: [],
    totalCount: 0,
  },

  validationErrorKeys: null,
  validationExtra: {},

  personalMessageVariables: {},
})

export const MESSAGE_TRIM_KEY_PATH = [['name'], ['title']]

export default createReducer(initialState, {
  [TalkChannelMessageCommon.INITIALIZE]: state => state.merge(initialState),

  [TalkChannelMessageCommon.INITIALIZE_MESSAGE_DETAIL]: (state, { data }) => {
    const newMessageForm = fromJS(data)
    const { creativeFormat } = newMessageForm
    if (creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE) {
      return state.set(
        'messageForm',
        newMessageForm
          .update('buttonAssetGroups', buttonAssetGroups =>
            TalkChannelMessageHelper.Carousel.initializeCarouselFeedButtonAssetGroups(
              buttonAssetGroups,
              newMessageForm.get('itemAssetGroups').count() * 2
            )
          )
          .update('couponBookAssetGroups', couponBookAssetGroups =>
            TalkChannelMessageHelper.initializeCarouselFeedCouponBookAssetGroups(
              couponBookAssetGroups,
              newMessageForm.get('itemAssetGroups').count()
            )
          )
      )
    } else {
      return state.set(
        'messageForm',
        newMessageForm.update('couponBookAssetGroups', couponBookAssetGroups =>
          TalkChannelMessageHelper.initializeCouponBookAssetGroups(
            couponBookAssetGroups
          )
        )
      )
    }
  },

  [TalkChannelMessageCommon.CHANGE_VALUE_BY_KEY]: (state, { key, value }) =>
    state.set(key, fromJS(value)),

  [TalkChannelMessageCommon.SET_MESSAGE_IS_VALID]: (
    state,
    { key, isValid, message }
  ) => {
    const { validationErrorKeys } = state
    const errorKeys = !validationErrorKeys ? Map() : validationErrorKeys

    const newErrorKeys = isValid
      ? errorKeys.delete(key)
      : errorKeys.set(key, message)

    return state.set('validationErrorKeys', newErrorKeys)
  },

  [TalkChannelMessageCommon.SET_MESSAGE_VALIDATION_EXTRA]: (
    state,
    { extra }
  ) => {
    const { validationExtra } = state
    return state.set('validationExtra', validationExtra.merge(fromJS(extra)))
  },

  [TalkChannelMessageCommon.INIT_MESSAGE_VALIDATION]: state =>
    state.withMutations(s =>
      s
        .set('validationErrorKeys', initialState.get('validationErrorKeys'))
        .set('validationExtra', initialState.get('validationExtra'))
    ),

  [TalkChannelMessageCommon.MESSAGE_COMMON_CLEAR]: () => initialState,

  [TalkChannelMessageCommon.SET_MESSAGE_TEMPLATE_LIST]: (state, { data }) => {
    return state.update('messageTemplateListByPlusFriend', prev =>
      prev.merge(fromJS(data))
    )
  },

  [TalkChannelMessageCommon.SET_PERSONAL_MESSAGE_VARIABLES]: (
    state,
    { data }
  ) => {
    return state.set('personalMessageVariables', fromJS(data))
  },
})

/**
 * 메시지 템플릿 삭제
 */
export function deleteMessageById(adAccountId, messageTemplateId) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())
    try {
      await api.message.deleteMessage(adAccountId, messageTemplateId)
      showSuccessMessage('변경사항이 적용되었습니다.')
      dispatch(replace(toMessageListPath(adAccountId)))
    } catch (e) {
      handleMessageError({ error: e?.response?.data, dispatch })
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const checkTestMessageValidation = ({
  adAccountId,
  messageForm,
  validationResultSetter,
  isNeedValidation,
  spamValidationResultSetter,
  validationResultInitiator,
  ageVerification,
}) => {
  return async (dispatch, getState, api) => {
    const { creativeFormat, profileId } = messageForm
    dispatch(showLoading())
    try {
      const { data: plusFriendResponse } = await api.user.getPlusFriendProfile(
        adAccountId,
        profileId
      )
      const { authority } = plusFriendResponse

      if (!authority) {
        showErrorMessage(
          '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
        )
        dispatch(hideLoading())
      } else {
        const trimmedMessageForm =
          TalkChannelMessageHelper.toTrimForm(messageForm)

        if (isNeedValidation) {
          dispatch(validationResultInitiator()) // validation전에 기존의 결과 초기화

          const validationResult = Validation(
            trimmedMessageForm,
            MESSAGE_FORM_VALIDATION_KEY,
            MESSAGE_FORM_VALIDATION_KEY_PATH,
            getMessageValidatorByMessageType({ creativeFormat }),
            getState,
            validationResultSetter,
            dispatch
          )

          if (!validationResult) {
            showErrorMessage(
              '테스트 발송을 실행할 수 없습니다. 입력한 항목을 확인해주세요.'
            )
          } else {
            const response = await api.message.validateSpamMessage(
              adAccountId,
              TalkChannelMessageHelper.toAPI(messageForm)
            )
            const { isFiltered = false } = response?.data || {}

            if (isFiltered) {
              // 스팸 필터에 걸림
              const spamValidationResult =
                TalkChannelMessageHelper.Spam.convertSpamResult({
                  spamResult: response.data,
                  creativeFormat,
                  messageForm,
                })
              dispatch(showSpamFilterPopUp())
              dispatch(spamValidationResultSetter(spamValidationResult))
            } else {
              // 스팸필터 통과
              dispatch(
                openPopup(POPUP_KEY.SEND_TEST_MESSAGE, {
                  adAccountId,
                  messageForm: trimmedMessageForm,
                  ageVerification,
                })
              )
            }
          }
        } else {
          dispatch(
            openPopup(POPUP_KEY.SEND_TEST_MESSAGE, {
              adAccountId,
              messageForm: trimmedMessageForm,
              ageVerification,
            })
          )
        }
        dispatch(hideLoading())
      }
    } catch (e) {
      const { message } = e?.response?.data || {}
      showErrorMessage(message)
      dispatch(hideLoading())
    }
  }
}

export function setPersonalMessageVariables(data) {
  return {
    type: TalkChannelMessageCommon.SET_PERSONAL_MESSAGE_VARIABLES,
    data,
  }
}

export function submitTestMessage(
  adAccountId,
  phoneNumbers,
  ageVerification = false,
  messageForm,
  onFinish
) {
  return async (dispatch, getState, api) => {
    try {
      await api.message.submitTestMessage({
        adAccountId,
        ageVerification,
        requestBody: {
          ...TalkChannelMessageHelper.toAPI(messageForm),
          phoneNumbers,
        },
      })
      showSuccessMessage(' 테스트 메시지가 발송되었습니다.')
    } catch (e) {
      if (e?.isLocked) {
        // post request lock
        console.warn('submitTestMessage is lock')
      } else {
        handleMessageError({
          error: e?.response?.data,
          dispatch,
          isTestMessageSend: true,
        })
      }
    } finally {
      if (typeof onFinish === 'function') {
        onFinish()
      }
    }
  }
}

export function setMessageTemplateListByPlusFriend(data) {
  return dispatch => {
    dispatch({
      type: TalkChannelMessageCommon.SET_MESSAGE_TEMPLATE_LIST,
      data,
    })
  }
}

export function setIsValidMessageByKey(key, isValid, message, extra = {}) {
  return dispatch => {
    dispatch({
      type: TalkChannelMessageCommon.SET_MESSAGE_IS_VALID,
      key,
      isValid,
      message,
    })

    if (Object.keys(extra).length > 0) {
      dispatch({
        type: TalkChannelMessageCommon.SET_MESSAGE_VALIDATION_EXTRA,
        key,
        extra,
      })
    }
  }
}

export function setSpamValidationResult(spamValidationResults) {
  return dispatch => {
    dispatch({
      type: TalkChannelMessageCommon.SET_MESSAGE_VALIDATION_EXTRA,
      extra: { spamValidationResults },
    })
  }
}

export const changeMessageCommonValueByKey = (key, value) => ({
  type: TalkChannelMessageCommon.CHANGE_VALUE_BY_KEY,
  key,
  value,
})

export const initializeMessageCommon = () => ({
  type: TalkChannelMessageCommon.INITIALIZE,
})

export const initializeMessageDetail = data => ({
  type: TalkChannelMessageCommon.INITIALIZE_MESSAGE_DETAIL,
  data,
})

export function initMessageValidation() {
  return {
    type: TalkChannelMessageCommon.INIT_MESSAGE_VALIDATION,
  }
}

export function clearCommonMessage() {
  return {
    type: TalkChannelMessageCommon.MESSAGE_COMMON_CLEAR,
  }
}

/**
 * 소재- 비즈보드 랜딩. 페이징 x
 */
export function getPlusFriendMessageListByProfileId(
  adAccountId,
  profileId,
  limit = 20,
  onSuccess,
  onFail
) {
  return async (dispatch, getState, api) => {
    try {
      const { data } =
        await api.message.fetchMessageListExcludeAdviewByProfileId(
          adAccountId,
          profileId,
          limit
        )

      dispatch(setMessageTemplateListByPlusFriend(data))

      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } catch (e) {
      if (typeof onFail === 'function') {
        onFail()
      }
      handleMessageError({ error: e?.response?.data, dispatch })
    }
  }
}

/**
 * 소재 - 메시지 템플릿 가져오기
 */

export function getMessageTemplateImportList(
  adAccountId,
  profileId,
  adGroupId,
  limit = 20,
  onSuccess,
  onFail
) {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.message.fetchMessageListByProfileId(
        adAccountId,
        profileId,
        adGroupId,
        limit
      )

      dispatch(setMessageTemplateListByPlusFriend(data))

      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } catch (e) {
      if (typeof onFail === 'function') {
        onFail()
      }
      handleMessageError({ error: e?.response?.data, dispatch })
    }
  }
}

/**
 *  채널 고객센터 연락처 저장
 */
export const addTalkChannelCsInfo = (
  adAccountId,
  profileId,
  csInfo,
  onRefresh
) => {
  return async (dispatch, getState, api) => {
    try {
      await api.message.addTalkChannelCsInfo(adAccountId, profileId, csInfo)

      showSuccessMessage('카카오톡 채널 정보가 업데이트 되었습니다.')
      dispatch(closeAllPopup())
      onRefresh()
    } catch (e) {
      showErrorMessage('카카오톡 채널 정보 업데이트에 실패했습니다.')
    }
  }
}

export const showSpamFilterPopUp = () => {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">집행 가이드 위배</strong>
          <p className="txt_layer">
            <a
              target="_blank"
              href={MESSAGE_GUIDE_URL}
              rel="noopener noreferrer"
              className="fc_emph">
              메시지 집행 가이드
            </a>
            에 맞지 않은 문구/이미지가 포함된 <br />
            메시지는 등록할 수 없습니다.
          </p>
        </PopupProxy>
      )
    )
  }
}

export const handleMessageError = ({ error, dispatch, ...rest }) => {
  const { errorCode, message } = error || {}
  switch (errorCode) {
    case 34500: {
      showErrorMessage('메시지가 존재하지 않습니다.')

      if (rest.adAccountId > 0) {
        dispatch(replace(toMessageListPath(rest.adAccountId)))
      }

      break
    }

    case 84007: {
      showErrorMessage(
        '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
      )
      break
    }

    case 86001: {
      showErrorMessage(message)
      break
    }

    case 34504: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          exceedMessageCountDialog(MESSAGE_MAX_COUNT)
        )
      )
      break
    }

    case 34513: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          unavailableDeleteMessageDialog()
        )
      )
      break
    }

    // 캐러셀 커머스 유형에서 buttonAssetGroups 와 itemAssetGroups ordering이 일치하지 않을 경우 에러
    case 34565: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          carouselCommerceInvalidOrderingDialog(message)
        )
      )
      break
    }

    case 39203: {
      showErrorMessage('삭제된 애드뷰가 있습니다. 애드뷰의 상태를 확인하세요.')
      break
    }

    case 33133: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          unavailableInvalidSpecialCharacterUrlDialog(rest)
        )
      )
      break
    }

    case 33401: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          unavailableSaveByDomainMismatchDialog(rest)
        )
      )
      break
    }

    case 34585: {
      showErrorMessage('해당 영상은 메시지 홍보 동영상으로 사용할 수 없습니다.')
      break
    }

    case 34586: {
      showErrorMessage(
        '해당 영상은 연령인증 메시지에서만 활용할 수 있습니다.\n광고그룹 설정에서 연령인증 메시지 여부를 확인하세요.'
      )
      break
    }

    case 34519:
    case 34520: {
      showErrorMessage('삭제된 동영상입니다.')
      break
    }

    case 34533: // 카카오톡 채널에 고객센터 연락처 누락
    case 34534: // 카카오톡 채널의 상태가 변경 (휴면 제재 등)
    case 34535: {
      // 카카오톡 채널의 홈노출 off
      showErrorMessage(message)
      break
    }

    // 종료된 쿠폰/비즈니스폼
    case 34537:
    case 34539: {
      const landingType =
        errorCode === 34537
          ? TalkChannelMessageEnum.Landing.Type.CHANNEL_COUPON
          : TalkChannelMessageEnum.Landing.Type.BIZ_FORM

      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          finishedLandingTypeMessageDialog(landingType)
        )
      )
      break
    }

    // 삭제된 소식
    case 34543: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          deletedLandingPostMessageDialog()
        )
      )
      break
    }

    // 랜딩 URL 포맷 오류.
    case 93001: {
      dispatch(
        openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, unavailableInvalidUrlDialog())
      )
      break
    }

    case 90006: {
      showErrorMessage('잘못된 요청입니다.')
      break
    }

    /**
     * 기존에 있던 디폴트 에러처리는 중복으로 인해 제거함
     *  (errorCode >= 34501 && errorCode <= 34555) || errorCode === 33401
     */
    default: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">시스템 오류</strong>
            <p className="txt_layer">
              시스템 오류가 발생하였습니다.
              <br />
              잠시 후 다시 시도하세요.
              <br />
              시스템 오류가 반복될 경우 처음부터 다시 진행하세요.
              <br />
              (errorCode: {errorCode} message: {message})
            </p>
          </PopupProxy>
        )
      )
      break
    }
  }
}
