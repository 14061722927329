import {
  initObserverInfo,
  initStoredAdAccountInfo,
  isObserved,
  login,
} from '../../../utils/authenticationUtils'
import { setDspLoginInfo, setDspUserInfo } from '../mUser'
import { fetchInitGnbNoticeList } from '../../notice/mNotice'
import { checkNotEmpty } from '../../../utils/regexUtils'
import {
  fetchAdAccountCashInfoFromListSelector,
  getAdAccountSelectorItems,
  getAdAccountSelectorRefresh,
} from '../mAdAccountListSelector'
import momentSessionStorage, {
  SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT,
  SESSION_KEY_OBSERVER_ADACCOUNT_ID,
} from '../../../utils/storage/momentSessionStorage'
import {
  toErrorPath,
  toServiceUnavailablePath,
} from '../../../utils/router/routeUtils'
import { push, replace } from 'connected-react-router'
import { setInvoiceEmail } from '../../settings/mInvoice'
import {
  kakaoBizCenterIntegratedJoinURL,
  kakaoBizCenterIntegratedNewJoinURL,
} from '../../../utils/app/services/kakaoBizCenter'
import DashboardRouter from '../../../components/DashboardV3/dashboardRouter'
import * as Sentry from '@sentry/browser'

/**
 * DSP 로그인 check.
 */
export function dspLoginCheck() {
  return async (dispatch, getState, api) => {
    const header = momentSessionStorage.getItem(
      SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT
    )
      ? { isAgreement: false }
      : {}

    const response = await api.user.advertiserLogin(header)

    dispatch(setDspLoginInfo(response.data || {}))
  }
}

/**
 * DSP 로그인 check exception handler.
 * - dspLoginCheck 와 별개로 분리한 이유는 상황에 따라 exception 을 다르게 처리할 needs 가 있기 때문.
 */
export function handleDspLoginException({ e }) {
  return dispatch => {
    if (e && e.response) {
      const { status, data } = e.response
      const returnUrl = window.location.href

      if (Number(status) === 401) {
        const { errorCode } = data || {}

        switch (Number(errorCode)) {
          // 비즈 통합 계정 가입
          case 2001:
          case 1301:
          case 1302:
          case 1501:
          case 1502:
          case 1503:
          case 1504:
          case 1505:
          case 1506:
          case 1509: {
            window.location.href = kakaoBizCenterIntegratedJoinURL(returnUrl)
            return
          }

          // 크럭스 미가입자 + 비즈 통합 가입 다음에 하기
          case 1507: {
            window.location.href = kakaoBizCenterIntegratedNewJoinURL(returnUrl)
            return
          }

          // 크럭스 기가입자 + 비즈 통합 가입 다음에 하기
          case 1508: {
            momentSessionStorage.setItem(
              SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT,
              'true'
            )
            window.location.reload()
            return
          }

          default: {
            login({ redirectBack: true })
          }
        }
      }
    } else {
      Sentry.captureException(`handleDspLoginException: ${e}`)
      dispatch(push(toServiceUnavailablePath()))
    }
  }
}

/**
 * DSP 초기화.
 */
export function initializeDsp({ adAccountId = undefined }) {
  return dispatch => {
    if (isObserved()) {
      dispatch(initializeByObserver({ adAccountId }))
    } else {
      dispatch(initialize({ adAccountId }))
    }
  }
}

/**
 * - private -
 * Observer 로서의 DSP 초기화.
 */
function initializeByObserver({ adAccountId }) {
  return (dispatch, getState) => {
    const {
      router: {
        location: { pathname },
      },
    } = getState()

    dispatch(deployDspByLocation({ pathname, adAccountId }))
  }
}

/**
 * - private-
 * 기본 DSP 초기화.
 */
function initialize({ adAccountId }) {
  return async (dispatch, getState) => {
    const {
      router: {
        location: { pathname },
      },
    } = getState()

    initStoredAdAccountInfo()
    initObserverInfo()

    try {
      await dispatch(dspLoginCheck())
      dispatch(initializeDspInfoOnly({ pathname, adAccountId }))
    } catch (e) {
      dispatch(handleDspLoginException({ e }))
    }
  }
}

/**
 * authWrapper 에서 DSP 의 정보만 update 필요할 경우(dsp login 절차 생략).
 */
export function initializeDspInfoOnly({ pathname, adAccountId = undefined }) {
  return async (dispatch, getState, api) => {
    if (!isObserved()) {
      try {
        const dspAccountInfoResponse = await api.user.fetchMyDspAccount()
        const dspAccountInfo = dspAccountInfoResponse.data || {}

        if (adAccountId > 0) {
          // 반드시 adAccountId 가 존재할 경우에만 실행.
          dispatch(setDspUserInfo(dspAccountInfo))
          dispatch(setInvoiceEmail(dspAccountInfo.email))
          dispatch(fetchAdAccountCashInfoFromListSelector(adAccountId))
        }

        dispatch(fetchInitGnbNoticeList(dspAccountInfo.id))
      } catch (e) {
        dispatch(push(toErrorPath()))
      }
    }

    dispatch(deployDspByLocation({ pathname, adAccountId }))
  }
}

function deployDspByLocation({ pathname, adAccountId }) {
  return dispatch => {
    dispatch(
      DashboardRouter.Path.isDashboard({ pathname })
        ? deployDspOnDashboard({ adAccountId })
        : deployDspNotOnDashboard({ adAccountId })
    )
  }
}

/**
 * 대시보드 화면 초기화.
 */
function deployDspOnDashboard({ adAccountId }) {
  return dispatch => {
    if (checkNotEmpty(adAccountId)) {
      dispatch(getAdAccountSelectorItems(adAccountId))
    } else {
      if (isObserved()) {
        const adId = momentSessionStorage.getItem(
          SESSION_KEY_OBSERVER_ADACCOUNT_ID
        )
        dispatch(replace(DashboardRouter.Path.AdAccount({ adAccountId: adId })))
      } else {
        dispatch(getAdAccountSelectorItems())
      }
    }
  }
}

/**
 * 대시보드 이외의 화면 초기화.
 */
function deployDspNotOnDashboard({ adAccountId }) {
  return dispatch => {
    dispatch(
      checkNotEmpty(adAccountId)
        ? getAdAccountSelectorRefresh(adAccountId)
        : getAdAccountSelectorItems()
    )
  }
}

/**
 * 카카오모먼트 로고 클릭, error 페이지 등에서 홈으로 가기
 * - DSP 가입 여부 판단.
 */
export function moveToMomentMainPage() {
  return async dispatch => {
    try {
      await dispatch(dspLoginCheck())

      // dsp 가입된 경우 기존 initial flow
      dispatch(getAdAccountSelectorItems(undefined, true))
    } catch (e) {
      dispatch(handleDspLoginException({ e }))
    }
  }
}
