import { keyMirror } from '../utils/utils'

/**
 * 광고 단위의 대시보드 상태
 * tableRow.dashboardStatus
 */
export default {
  Type: keyMirror({
    READY: null,
    LIVE: null,
    FINISHED: null,
    OPERATING: null,
    OFF: null,
    DELETED: null,
    EXCEED_DAILY_BUDGET: null,
    OUT_OF_BALANCE: null,
    INVALID_DATE: null,
    SYSTEM_CONFIG_VOID: null,
    WAITING: null,
    APPROVED: null,
    CANCELED: null,
    REJECTED: null,
    MONITORING_REJECTED: null,
    MODIFICATION_WAITING: null,
    MODIFICATION_REJECTED: null,
    MESSAGE_PAUSE: null,
    ADACCOUNT_UNAVAILABLE: null,
    CAMPAIGN_UNAVAILABLE: null,
    ADGROUP_UNAVAILABLE: null,
    NO_AVAILABLE_CREATIVE: null,
    UNAPPROVED: null,
    STOPPED: null,
    SYSTEM_CONFIG_ADMIN_STOP: null,
    SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP: null,
  }),

  Name: {
    READY: '집행 예정',
    LIVE: '집행 중',
    FINISHED: '집행 완료',
    OPERATING: '운영 가능',
    OFF: '사용자 OFF',
    DELETED: '삭제',
    EXCEED_DAILY_BUDGET: '운영 불가(예산 초과)',
    OUT_OF_BALANCE: '운영 불가(잔액 부족)',
    INVALID_DATE: '운영 불가(기간 오류)',
    SYSTEM_CONFIG_VOID: '운영 불가(콘텐츠 오류)',
    WAITING: '심사 중',
    APPROVED: '심사 승인',
    CANCELED: '운영 불가(계약 해지)',
    REJECTED: '심사 보류',
    MONITORING_REJECTED: '모니터링 보류',
    MODIFICATION_WAITING: '수정사항 심사 중',
    MODIFICATION_REJECTED: '수정사항 심사 보류',
    MESSAGE_PAUSE: '운영 불가(일시 중지)',
    ADACCOUNT_UNAVAILABLE: '운영 불가',
    CAMPAIGN_UNAVAILABLE: '운영 불가',
    ADGROUP_UNAVAILABLE: '운영 불가',
    NO_AVAILABLE_CREATIVE: '운영 불가(집행가능 소재없음)',
    UNAPPROVED: '운영 불가(심사 미승인)',
    STOPPED: '운영 불가(관리자 정지)', // 광고계정
    SYSTEM_CONFIG_ADMIN_STOP: '운영 불가(관리자 정지)', // 캠페인, 광고그룹, 소재
    SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP: '운영 불가(연결 서비스 제한)',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  valuesOfCampaign() {
    return [
      this.Type.READY,
      this.Type.LIVE,
      this.Type.FINISHED,
      this.Type.SYSTEM_CONFIG_ADMIN_STOP,
      this.Type.SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP,
      this.Type.EXCEED_DAILY_BUDGET,
      this.Type.ADACCOUNT_UNAVAILABLE,
      this.Type.OFF,
    ]
  },

  valuesOfAdGroup() {
    return [
      this.Type.READY,
      this.Type.LIVE,
      this.Type.FINISHED,
      this.Type.SYSTEM_CONFIG_ADMIN_STOP,
      this.Type.SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP,
      this.Type.EXCEED_DAILY_BUDGET,
      this.Type.CANCELED,
      this.Type.MESSAGE_PAUSE,
      this.Type.CAMPAIGN_UNAVAILABLE,
      this.Type.NO_AVAILABLE_CREATIVE,
      this.Type.OFF,
    ]
  },

  valuesOfCreative() {
    return [
      this.Type.OPERATING,
      this.Type.SYSTEM_CONFIG_ADMIN_STOP,
      this.Type.UNAPPROVED,
      this.Type.INVALID_DATE,
      this.Type.SYSTEM_CONFIG_VOID,
      this.Type.SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP,
      this.Type.ADGROUP_UNAVAILABLE,
      this.Type.OFF,
    ]
  },

  valuesOfCreativeReview() {
    return [
      this.Type.APPROVED,
      this.Type.WAITING,
      this.Type.REJECTED,
      this.Type.MONITORING_REJECTED,
      this.Type.MODIFICATION_WAITING,
      this.Type.MODIFICATION_REJECTED,
    ]
  },

  isActive(type) {
    return [this.Type.OPERATING, this.Type.LIVE].includes(type)
  },

  hasReviewDenyReason(type) {
    return [
      this.Type.REJECTED,
      this.Type.MONITORING_REJECTED,
      this.Type.MODIFICATION_REJECTED,
    ].includes(type)
  },
}
