import { goBack } from 'connected-react-router'
import {
  changeCampaignFormByKey,
  changeCampaignViewStateByKeyV2,
  clearAllCampaign,
  initCampaignValidationErrorKeys,
  receiveCampaignInfo,
  receivePlusFriendProfileInfoByCampaignId,
} from '../modules/advertise/mCampaignV2'
import {
  hideLoading,
  LOADING_KEY,
  showLoading,
} from '../modules/common/mLoading'
import { handleCampaignFormExceptionV2 } from '../modules/advertise/campaignActions/aCampaign'
import { checkNotEmpty, isUndefinedOrNull } from '../utils/regexUtils'
import { getCampaignAndAdGroupConstraints } from '../modules/advertise/mAdConstraints'
import CampaignTypeEnum from '../enums/CampaignTypeEnum'
import GoalEnum from '../enums/GoalEnum'
import CampaignTypeGoalConstraints from '../utils/constraints/constraints-campaignTypeGoal'
import { showErrorMessage } from '../utils/alertUtils'
import ObjectiveTypeEnum from '../enums/ObjectiveTypeEnum'
import moment from 'moment'
import { getPlusFriendProfileList } from '../modules/settings/account/mAccount'
import AdGroupConstraints from '../utils/constraints/constraints-adGroup'
import {
  clearTrackListByAdAccount,
  fetchCampaignTrackList,
} from '../modules/linkage/pixelAndSDK/mPixelAndSDKV2'
import { PIXEL_AND_SDK_LINKAGE_STATUS } from '../components/Linkage/PixelAndSdk/Utils/pixelAndSdk'
import { batchAdGroupOnUnmountV2 } from './batch-new-adGroup'
import CampaignConstraints from '../utils/constraints/constraints-campaign'
import { clearContract } from '../modules/advertise/mContract'
import UserConfigEnum from '../enums/UserConfigEnum'

/**
 * 캠페인 생성/수정 배치
 *
 * @param adAccountId {number}
 * @param campaignId {number | undefined}
 */
function batchCampaignOnMountV2(adAccountId, campaignId = undefined) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.SETUP_AD_CAMPAIGN))
    dispatch(batchAdGroupOnUnmountV2())

    const isNew = !campaignId || campaignId === -1

    if (isNew) {
      const {
        campaignV2: {
          isFormDirty,
          campaignForm: {
            campaignTypeGoal: { campaignType, goal },
            objective,
          },
        },
        adConstraints: {
          campaignConstraints: {
            objectiveTypeConstraints,
            campaignContractBudgetConstraint,
          },
        },
      } = getState()

      if (!isFormDirty) {
        dispatch(initCampaignValidationErrorKeys())
      }

      const { type: objectiveType } = objective || {}

      /**
       * Set default objective type select
       */
      const objectiveRequired = AdGroupConstraints.isObjectiveRequired({
        campaignType,
        goal,
      })

      if (objectiveRequired) {
        if (objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL) {
          try {
            await dispatch(getPlusFriendProfileList())
          } catch (e) {
            console.log(e.message)
          }
        }

        const defaultObjectiveType =
          CampaignConstraints.isAvailableObjectiveTypes({
            constraint: objectiveTypeConstraints,
          }).first()

        dispatch(
          changeCampaignFormByKey('objective', {
            type: defaultObjectiveType,
            detailType: '',
            value: '',
            description: '',
          })
        )
      }

      /**
       * 비즈보드 CPT, 비즈보드 X 방문(도달)인 경우에 전환 추적 미설정 default value를 false로 변경
       */
      const defaultConversionRequiredValue =
        CampaignConstraints.isConversionDefaultRequired({ campaignType, goal })

      dispatch(
        changeCampaignViewStateByKeyV2(
          'isConversionNotSet',
          defaultConversionRequiredValue
        )
      )

      /**
       * Set campaign name
       */
      dispatch(
        changeCampaignFormByKey(
          'name',
          `${CampaignTypeEnum.getName(campaignType)}_${GoalEnum.getName(
            goal
          )}_${moment().format('YYYYMMDDHHmm')}`
        )
      )

      /**
       * Set kakao tv contractBudget
       */
      if (
        campaignType === CampaignTypeEnum.Type.KAKAO_TV &&
        goal === GoalEnum.Type.REACH
      ) {
        const { guide } = campaignContractBudgetConstraint || {}
        dispatch(changeCampaignFormByKey('contractBudget', guide))
      }

      dispatch(hideLoading(LOADING_KEY.SETUP_AD_CAMPAIGN))
    } else {
      try {
        const campaignResponse = await api.campaign.fetchCampaignInfoById(
          adAccountId,
          campaignId
        )

        const campaign = campaignResponse?.data || {}
        const { campaignTypeGoal } = campaign || {}
        const { id, campaignType, goal } = campaignTypeGoal || {}

        const noLongerSupported = CampaignTypeGoalConstraints.noLongerSupported(
          {
            campaignType,
            goal,
          }
        )

        if (
          campaign.userConfig === UserConfigEnum.Type.DEL ||
          noLongerSupported
        ) {
          showErrorMessage('삭제되었거나 지원 종료된 광고입니다.')
          dispatch(goBack())
        } else {
          /**
           * Fetch campaign, adGroup constraints
           */

          try {
            await dispatch(
              getCampaignAndAdGroupConstraints(
                adAccountId,
                id,
                campaign.objective?.type,
                campaign.objective?.detailType
              )
            )
          } catch (e) {
            dispatch(goBack())
          }

          /**
           * Fetch trackRights
           */
          await dispatch(fetchCampaignTrackList({ adAccountId }))

          /**
           * Fetch track info, track status 처리
           */
          if (!isUndefinedOrNull(campaign.trackId)) {
            const {
              data: { body },
            } = await api.bconV2.fetchDetailBasicInfo(
              adAccountId,
              campaign.trackId
            )

            const { adAccountLinkStatus, deletedAt } = body
            const status = !isUndefinedOrNull(deletedAt)
              ? PIXEL_AND_SDK_LINKAGE_STATUS.DELETED
              : PIXEL_AND_SDK_LINKAGE_STATUS[adAccountLinkStatus]

            dispatch(
              changeCampaignViewStateByKeyV2('trackInfo', {
                ...body,
                trackStatus: status,
              })
            )
          }

          /**
           *  Fetch catalog info by id
           */
          if (campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG) {
            const catalogId = campaign.objective?.value

            if (catalogId > -1) {
              try {
                const response = await api.showcase.fetchCatalogInfoById(
                  adAccountId,
                  catalogId
                )
                const { availability, deletedDate } = response.data

                response.data['isDeleted'] =
                  !availability && checkNotEmpty(deletedDate)

                // 소재 광고요약과 통일
                dispatch(
                  changeCampaignFormByKey(
                    ['objective', 'description'],
                    response.data.name
                  )
                )

                dispatch(
                  changeCampaignViewStateByKeyV2('catalogInfo', {
                    ...response.data,
                  })
                )
              } catch (e) {
                handleCampaignFormExceptionV2(dispatch, e)
              }
            }
          }

          /**
           * Fetch contract info by id
           */
          if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
            const { contractId } = campaign

            if (checkNotEmpty(contractId) && contractId > -1) {
              try {
                const response = await api.campaign.fetchAdProductContractById(
                  adAccountId,
                  contractId
                )

                dispatch(
                  changeCampaignViewStateByKeyV2('contractInfo', response.data)
                )
              } catch (e) {
                handleCampaignFormExceptionV2(dispatch, e)
              }
            }
          }

          /**
           * Set campaign form
           */
          dispatch(receiveCampaignInfo(campaign))

          /**
           * DailyBudget 미설정 체크
           */
          dispatch(
            changeCampaignViewStateByKeyV2(
              'isDailyBudgetUnlimited',
              !checkNotEmpty(campaign.dailyBudget)
            )
          )

          // 전환추적 미설정 세팅
          dispatch(
            changeCampaignViewStateByKeyV2(
              'isConversionNotSet',
              !checkNotEmpty(campaign.trackId)
            )
          )

          /**
           * 광고목표대상이 톡 채널인경우 프로필 단건조회로 정보 조회.
           */
          if (
            ObjectiveTypeEnum.Type.TALK_CHANNEL === campaign.objective?.type
          ) {
            const plusFriendProfileResponse =
              await api.campaign.fetchPlusFriendProfilesInCampaign(
                adAccountId,
                campaignId
              )

            const plusFriendProfile = plusFriendProfileResponse?.data || {}

            dispatch(
              receivePlusFriendProfileInfoByCampaignId(plusFriendProfile)
            )

            // https://jira.daumkakao.com/browse/KAMOQA-9240 이슈에 대한 예외처리.
            dispatch(
              changeCampaignFormByKey(
                ['objective', 'description'],
                plusFriendProfile?.name || ''
              )
            )
          }

          dispatch(changeCampaignViewStateByKeyV2('isFormDirty', true))
        }
      } catch (e) {
        console.log(e.message)
        handleCampaignFormExceptionV2(dispatch, e)
      } finally {
        dispatch(hideLoading(LOADING_KEY.SETUP_AD_CAMPAIGN))
      }
    }
  }
}

function batchCampaignOnUnmountV2() {
  return dispatch => {
    dispatch(clearAllCampaign())
    dispatch(clearContract())
    dispatch(clearTrackListByAdAccount())
  }
}

export { batchCampaignOnMountV2, batchCampaignOnUnmountV2 }
