import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { replace } from 'connected-react-router'
import { keyMirror } from '../../utils/utils'
import { showErrorMessage } from '../../utils/alertUtils'
import { toInvalidApproachPath } from '../../utils/router/routeUtils'
import { coerceToArray } from '../../utils/stringUtils'
import { setLnbAdAccountInfo } from '../lnb/mLnb'
import { ERROR_CODE } from '../../utils/errorCode'
import AdAccountExtraEnum from '../../enums/AdAccountExtraEnum'

export const AD_ACCOUNT_TYPE = keyMirror({
  BUSINESS: null,
  INDIVIDUAL: null,
  OVERSEAS: null,
})

export const AD_ACCOUNT_COMPANY_TYPE = keyMirror({
  ADVERTISER: null,
  AGENCY: null,
  INDIVIDUAL: null,
})

export const AD_ACCOUNT_STATUS = keyMirror({
  OPERATING: null,
  OFF: null,
  PENDING_DELETE: null,
  PROCESSING_DELETE: null,
  DELETED: null,
  STOPPED: null,
  OUT_OF_BALANCE: null,
})

const AdAccount = keyMirror(
  {
    GET_ADACCOUNT_INFO_BY_ID: null,

    // notification
    MY_NOTIFICATION_SETTING: null,
    CHANGE_MY_NOTIFICATION_SETTING: null,
    INIT_MY_NOTIFICATION_SETTING: null,

    // adView
    GET_AD_ACCOUNT_ADVIEW_LIST: null,

    SET_STATE_BY_KEY_PATH: null,
  },
  'AD_ACCOUNT'
)

const initialState = fromJS({
  adAccountInfo: {
    id: -1,
    createdDate: '',
    lastModifiedDate: '',
    name: '',
    advertiserId: -1, // 광고계정 생성시에 사용.
    ownerCompanyId: -1, // 에이전시 광고계정 생성시에 사용.
    ownerCompany: {},
    advertiser: {},
    userConfig: 'OFF',
    adminStop: false,
    outOfBalance: false,
    additionalRole: 'NONE', // or BETA.  PIXEL 권한인 경우에만 노출. 다음 스프린트 제거
    memberRequestable: true,
    deals: undefined,
    extra: {},
  },

  myNotificationSettingForm: {
    // kakaoTalk: false,
    // sms: false,
    // balance: false,
    // budget: false,
    // review: false,
    // autoPayment: true,
    // midnight: true,
  },

  adViewList: [],
})

export default createReducer(initialState, {
  [AdAccount.GET_ADACCOUNT_INFO_BY_ID]: (state, { data }) => {
    return state.set('adAccountInfo', fromJS(data))
  },

  [AdAccount.MY_NOTIFICATION_SETTING]: (state, { data }) =>
    state.set('myNotificationSettingForm', fromJS(data)),

  [AdAccount.CHANGE_MY_NOTIFICATION_SETTING]: (state, { key, value }) =>
    state.setIn(
      ['myNotificationSettingForm', ...coerceToArray(key)],
      fromJS(value)
    ),

  [AdAccount.INIT_MY_NOTIFICATION_SETTING]: state =>
    state.set(
      'myNotificationSettingForm',
      initialState.get('myNotificationSettingForm')
    ),

  [AdAccount.GET_AD_ACCOUNT_ADVIEW_LIST]: (state, { data }) =>
    state.set('adViewList', fromJS(data)),

  [AdAccount.SET_STATE_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn(keyPath, fromJS(value)),
})

/**
 * 3.28 광고 계정 정보 조회
 */
export function receiveAdAccountInfoById(data) {
  return {
    type: AdAccount.GET_ADACCOUNT_INFO_BY_ID,
    data,
  }
}

export const setAdAccountStateByKeyPath = (keyPath, value) => ({
  type: AdAccount.SET_STATE_BY_KEY_PATH,
  keyPath: [...coerceToArray(keyPath)],
  value,
})

export function getAdAccountInfoById(adAccountId) {
  return (dispatch, getState, api) => {
    return api.adAccount
      .fetchAdAccountInfoByAdAccountId(adAccountId)
      .then(response => {
        const adAccountInfo = response.data || {}
        dispatch(receiveAdAccountInfoById(adAccountInfo))
        dispatch(setLnbAdAccountInfo({ adAccountInfo }))
        if (
          adAccountInfo.extras &&
          adAccountInfo.extras[AdAccountExtraEnum.Type.MESSAGE_AD_PARTNER]
        ) {
          dispatch(replace(toInvalidApproachPath()))
        }
      })
      .catch(e => {
        handleAdAccountException(dispatch, e)
      })
  }
}

/**
 * 개인광고계정 환불계좌 실명정보 조회
 * @param adAccountId
 * @param onSuccess {function(*)}
 * @return {*}
 */
export function getAdAccountRefundOwnName(adAccountId, onSuccess) {
  return (dispatch, getState, api) => {
    return api.adAccount
      .fetchAdAccountRefundOwnName(adAccountId)
      .then(response => {
        dispatch(getAdAccountInfoById(adAccountId))

        if (typeof onSuccess === 'function') {
          onSuccess(response.data || {})
        }
      })
      .catch(e => {
        handleAdAccountException(dispatch, e)
      })
  }
}

export function getAdAccountAdViewList(adAccountId, onSuccess, onFail) {
  return (dispatch, getState, api) => {
    dispatch({
      type: AdAccount.GET_AD_ACCOUNT_ADVIEW_LIST,
      data: [],
    })

    return api.adView
      .fetchAdAccountAdViewList(adAccountId)
      .then(response => {
        const data = response.data || []

        dispatch({
          type: AdAccount.GET_AD_ACCOUNT_ADVIEW_LIST,
          data,
        })

        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      })
      .catch(e => {
        handleAdAccountException(dispatch, e)

        if (typeof onFail === 'function') {
          onFail()
        }
      })
  }
}

export function modifyDelegationRequest(
  adAccountId,
  bizRightId,
  delegationStatus
) {
  return (dispatch, getState, api) => {
    return api.adAccount
      .modifyDelegationInvoiceStatus(adAccountId, bizRightId, delegationStatus)
      .catch(e => {
        handleAdAccountException(dispatch, e)
      })
      .finally(() => {
        dispatch(getAdAccountInfoById(adAccountId))
      })
  }
}

function handleAdAccountException(dispatch, e) {
  if (!e || !e.response || !e.response.data) {
    return
  }

  const { errorCode, message } = e.response.data

  switch (Number(errorCode)) {
    // 존재하지 않는 광고계정.
    case 6003:
    case 6009: {
      dispatch(replace(toInvalidApproachPath()))
      break
    }

    // 대행사 사업자번호 당 광고계정 수 초과(생성).
    case 6012: {
      showErrorMessage(
        '동일 대행사 사업자 번호에 허용된 광고계정 수 초과로 인해 더 이상의 진행이 불가합니다.'
      )
      break
    }

    // 사업자번호 당 광고계정 수 초과(생성).
    case 6013: {
      showErrorMessage(
        '동일 사업자 번호에 허용된 광고계정 수 초과로 인해 더 이상의 진행이 불가합니다.'
      )
      break
    }

    // DSP 계정 당 광고계정 수 초과(생성).
    case 6014: {
      showErrorMessage(
        '멤버로 있을 수 있는 광고계정 수 초과로 인해 더 이상의 계정 생성이 불가합니다.'
      )
      break
    }

    case 42000: {
      showErrorMessage('영업권이 없는 상태에서 위임발행을 수락할 수 없습니다.')
      break
    }

    case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN:
      break

    default: {
      showErrorMessage(message)
      break
    }
  }
}
