import { CATALOG, fetchGet, queryString } from '../../utils/fetchUtils'

function getCatalogIds(adAccountId, pixelAndSdkIds) {
  return fetchGet(
    `${CATALOG}/catalogs?${queryString({ adAccountId, pixelAndSdkIds })}`,
    adAccountId
  )
}

function getCatalogById(adAccountId, id) {
  return fetchGet(`${CATALOG}/catalogs/${id}`, adAccountId)
}

/**
 * @param adAccountId
 * @param catalogId
 * @param order - CREATED_DATE, DISCOUNT_RATE
 * @param searchType - TITLE, ITEM_ID
 * @param searchValue
 * @param constraints - image 등의 제약조건
 * @param pageRequest
 */
function getCatalogProductsByCatalogId(
  adAccountId,
  catalogId,
  order,
  searchType,
  searchValue,
  constraints,
  pageRequest
) {
  return fetchGet(
    `${CATALOG}/products?${queryString({
      catalogId,
      order,
      searchType,
      searchValue,
      ...constraints,
      ...pageRequest,
    })}`,
    adAccountId
  )
}

export default {
  getCatalogIds,
  getCatalogProductsByCatalogId,
}
