import { keyMirror } from '../utils/utils'
import DeviceTypeEnum from './DeviceTypeEnum'
import { List } from 'immutable'

export default {
  Type: keyMirror({
    PC: null,
    ANDROID: null,
    IOS: null,
    ETC: null,
  }),

  Name: {
    PC: 'PC',
    ANDROID: 'Android',
    IOS: 'iOS',
    ETC: '기타',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type)).filter(
      v => v !== this.Type.ETC // report filter 에서만 사용
    )
  },

  /**
   * TODO: 제거
   * param & return type 이 불명확(immutable, array?)
   */
  getOrderedValues(deviceTypes) {
    const seq = this.values()
    return deviceTypes?.sort((a, b) => seq.indexOf(a) - seq.indexOf(b)) || []
  },

  getValuesByDeviceType(deviceType) {
    switch (deviceType) {
      case DeviceTypeEnum.Type.PC:
        return [this.Type.PC]
      case DeviceTypeEnum.Type.MOBILE:
        return [this.Type.ANDROID, this.Type.IOS]
      case DeviceTypeEnum.Type.ALL:
        return this.values()
      default:
        return this.values()
    }
  },

  /**
   * ReportV2
   */
  reportFilterLayerValues() {
    return [...this.values(), 'ETC']
  },

  toString(
    allAvailableDeviceType,
    deviceTypes = List(),
    allAvailableLabel = '가능한 모든 디바이스'
  ) {
    const selectedDevices = []

    deviceTypes.includes(this.Type.PC) &&
      selectedDevices.push(this.getName(this.Type.PC))

    const mobileLabel = this.getOrderedValues(deviceTypes)
      .filter(v => this.isMobileOsType(v))
      .map(v => this.getName(v))
      .join(', ')

    if (mobileLabel) {
      selectedDevices.push(`모바일(${mobileLabel})`)
    }

    return allAvailableDeviceType
      ? allAvailableLabel
      : selectedDevices.length > 0
      ? selectedDevices.join(', ')
      : '전체'
  },

  isMobileOsType(osType) {
    return [this.Type.ANDROID, this.Type.IOS].includes(osType)
  },

  isPcOsType(osType) {
    return [this.Type.PC].includes(osType)
  },

  hasMobileOsType(deviceOsTypes) {
    return deviceOsTypes?.some(osType => this.isMobileOsType(osType))
  },

  hasPcOsType(deviceOsTypes) {
    return deviceOsTypes?.some(osType => this.isPcOsType(osType))
  },
}
