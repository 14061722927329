import Gnb from '../Navigation/Gnb/Gnb'
import Lnb from '../Navigation/Lnb/Lnb'
import React, { useMemo } from 'react'
import Footer from '../Footer/Footer'
import MicroFrontend from './MicroFrontend'
import { Redirect, useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import BasicLoader from '../Common/Loader/BasicLoader'
import sessionStorage, {
  SESSION_KEY_OBSERVER_SERVICE_TYPE,
} from '../../utils/storage/momentSessionStorage'
import { ApiPhase, getApiPhase } from '../../utils/app/services/kakaoMoment'

const SHOWCASE_HOST = {
  production: 'https://catalog.moment.kakao.com',
  cbt: 'https://boomerang-cbt.devel.kakao.com',
  sandbox: 'https://boomerang-sandbox.devel.kakao.com',
  develop: 'https://boomerang-dev.devel.kakao.com',
}
const host = __PRODUCTION__
  ? SHOWCASE_HOST.production
  : __STAGE__
  ? SHOWCASE_HOST.cbt
  : getApiPhase() === ApiPhase.adhoc
  ? SHOWCASE_HOST.develop
  : SHOWCASE_HOST.sandbox

const Showcase = () => {
  const { campaignTypes } = useSelector(
    state => state.get('adConstraints'),
    shallowEqual
  )
  const observerMode =
    sessionStorage.getItem(SESSION_KEY_OBSERVER_SERVICE_TYPE) === 'ADMIN'
  const hasCatalog = useMemo(
    () =>
      campaignTypes.includes(CampaignTypeEnum.Type.PRODUCT_CATALOG) ||
      observerMode,
    [campaignTypes, observerMode]
  )
  const history = useHistory()
  return (
    <div id="kakaoWrap">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb />
        <article id="mArticle">
          {campaignTypes.size === 0 ? (
            <BasicLoader />
          ) : hasCatalog ? (
            <MicroFrontend name="boomerang" host={host} history={history} />
          ) : (
            <Redirect to="/invalid_approach" />
          )}
        </article>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}
export default Showcase
