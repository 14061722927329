import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Switch } from 'react-router-dom'
import {
  initializeDsp,
  initializeDspInfoOnly,
} from '../../modules/common/commonActions/aInitializeDsp'
import { isObserved } from '../../utils/authenticationUtils'
import AdViewWrapper from '../../components/AdViewV2/AdViewWrapper'
import { AdvertiseRouterV2 } from '../../utils/advertise/advertiseRouterV2'
import AdWrapper from '../../components/AdvertiseV2/AdWrapper'
import MyInfoWrapper from '../../components/My/MyInfo/MyInfoWrapper'
import LayoutLinkageWrapper from './layoutLinkageWrapper'
import LayoutSettingsWrapper from './layoutSettingsWrapper'
import LayoutTargetingWrapper from './layoutTargetingWrapper'
import MessageWrapper from './messageWrapper'
import DashboardRedirectRegexArray from '../../components/DashboardV3/DashboardRedirect/dashboardRedirectRegex'
import DashboardRedirect from '../../components/DashboardV3/DashboardRedirect/DashboardRedirect'
import { connect } from 'react-redux'
import withLocationChangeHandler from '../../components/Common/withLocationChangeHandler/withLocationChangeHandler'
import AdAccountWrapper from './AdAccountWrapper'
import Showcase from '../../components/Showcase/Showcase'
import ReportWrapper from '../../components/ReportV2/ReportWrapper'
import BulletinPopupCore from '@kakao/bzm-lounge-bulletin-optin/dist/bulletin-popup.umd.min.js'
import { SERVICE_TYPE } from '../../utils/loungeBoardFetchUtils'
import { KakaoLoungeBoard } from '../../utils/app/services/kakaoLoungeBoard'
import {
  toLoungeBoardNoticeDetailPath,
  toLoungeBoardNoticePath,
} from '../../utils/router/routeUtils'
import PromotionRibbon from '../../components/Navigation/Gnb/PromotionRibbon'

const InviteListV2 = React.lazy(() => import('../member/InviteListV2'))

const Dashboard = React.lazy(() =>
  import('../../components/DashboardV3/Dashboard')
)

/**
 * 로그인이 필요한 페이지
 */
class AuthenticationWrapper extends React.PureComponent {
  componentDidMount() {
    this.props.initializeDsp({
      adAccountId: this.props.match.params.adaccountid,
    })

    // 공지사항 옵트인 팝업
    BulletinPopupCore.create(SERVICE_TYPE, KakaoLoungeBoard.getPhase()).open()
  }

  componentDidUpdate(prevProps) {
    const prevAdAccountId = prevProps.match.params.adaccountid
    const adAccountId = this.props.match.params.adaccountid

    if (adAccountId > 0 && prevAdAccountId !== adAccountId) {
      this.props.initializeDspInfoOnly({
        pathname: this.props.location.pathname,
        adAccountId,
      })
    }
  }

  render() {
    if (!this.props.readyToRender) {
      return null
    }

    return (
      <>
        <PromotionRibbon />
        <React.Suspense fallback="">
          <Switch>
            <Route path="/myinfo" component={MyInfoWrapper} />
            <Route path="/adaccount" component={AdAccountWrapper} />
            <Route exact path="/invite" component={InviteListV2} />
            <Route
              path={[
                AdvertiseRouterV2.Matcher.getCampaignAndGroupPageV2(),
                AdvertiseRouterV2.Matcher.getCreativePageV2(),
              ]}
              component={AdWrapper}
            />
            <Route
              exact
              path={['/dashboard/:adaccountid(\\d+)']}
              component={Dashboard}
            />
            <Route
              exact
              path="/noticeboard"
              render={() => {
                location.href = toLoungeBoardNoticePath()
              }}
            />
            <Route
              exact
              path={'/noticeboard/:noticeId(\\d+)'}
              render={({ match }) => {
                const { noticeId } = match.params
                const newNoticeId = Number(noticeId) + 10000
                location.href = toLoungeBoardNoticeDetailPath(newNoticeId)
              }}
            />
            <Route
              path={'/:adaccountid(\\d+)/settings'}
              component={LayoutSettingsWrapper}
            />
            <Route
              path={'/:adaccountid(\\d+)/targeting'}
              component={LayoutTargetingWrapper}
            />
            <Route
              path={'/:adaccountid(\\d+)/report'}
              component={ReportWrapper}
            />
            <Route
              path={'/:adaccountid(\\d+)/adview'}
              component={AdViewWrapper}
            />
            <Route
              path={'/:adaccountid(\\d+)/linkage'}
              component={LayoutLinkageWrapper}
            />
            <Route
              path={'/:adaccountid(\\d+)/message'}
              component={MessageWrapper}
            />
            <Route
              path={'/:adaccountid(\\d+)/catalog-manager'}
              component={Showcase}
            />
            {/* legacy dashboard redirect */}
            <Route
              path={DashboardRedirectRegexArray}
              component={DashboardRedirect}
            />
          </Switch>
        </React.Suspense>
      </>
    )
  }
}

AuthenticationWrapper.propTypes = {
  match: ReactRouterPropTypes.match,
  location: ReactRouterPropTypes.location,
  initializeDsp: PropTypes.func,
  initializeDspInfoOnly: PropTypes.func,
  readyToRender: PropTypes.bool,
}

const mapDispatchToProps = {
  initializeDsp,
  initializeDspInfoOnly,
}

const mapStateToProps = ({
  user: {
    userInfo: { id },
  },
}) => {
  const isValidUserInfo = id > 0

  return {
    readyToRender: isValidUserInfo || isObserved(), // observed 인 경우 refresh 시 user info 를 fetch 하지 않는다.
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocationChangeHandler(AuthenticationWrapper))
