import React from 'react'
import { replace } from 'connected-react-router'
import { showErrorMessage } from '../../../utils/alertUtils'
import { openPopupByProxy, POPUP_KEY } from '../../common/mPopup'
import { getAdAccountSelectorRefresh } from '../../common/mAdAccountListSelector'
import PopupProxy from '../../../components/Popup/Common/PopupProxy'
import { coerceAtLeast } from '../../../utils/utils'
import { checkNoneEmpty } from '../../../utils/regexUtils'
import {
  changeCreativeCreateFormByKey,
  setCreativeCompletedValidationErrors,
} from '../mCreativeCreateV2'
import { changeCreativeModifyFormByKey } from '../mCreativeModifyV2'
import { CREATIVE_FORM_VALIDATION_KEY } from '../../../validators/advertise/creativeV2/creativeFormValidationKey'
import {
  setCreativeCommonByKeyPath,
  setIsValidCreativeByKey,
} from '../mCreativeCommonV2'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../enums/GoalEnum'
import AdStatusEnum from '../../../enums/AdStatusEnum'
import moment from 'moment'
import { BizBoardLandingInfo } from '../../../models/model-creative'
import DashboardRouter from '../../../components/DashboardV3/dashboardRouter'
import { showDynamicCatalogAndProductSetIsNotValidError } from '../mDynamicCatalog'
import { handleMessageError } from '../../message/mMessageCommon'
import { carouselCommerceInvalidOrderingDialog } from '../../../components/Message/Dialog/MessageDialogV2'

/**
 * landingType 이 변경되면 landingInfo object 를 초기화 한다.
 * landing scheme 에 대한 validation 결과를 초기화 한다.
 */
export function changeLandingScheme(adCreativeId, landingType) {
  return dispatch => {
    const isNew = !adCreativeId || adCreativeId === -1
    const updater = isNew
      ? changeCreativeCreateFormByKey
      : changeCreativeModifyFormByKey

    dispatch(
      updater(
        ['landingInfo'],
        BizBoardLandingInfo.set('landingType', landingType)
      )
    )

    dispatch(
      setIsValidCreativeByKey(
        CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME,
        true,
        ''
      )
    )
  }
}

export const getCreativeVideoUploadExceptionMessageByErrorCode = ({
  errorCode,
  defaultMessage,
  detail = null,
}) => {
  switch (Number(errorCode)) {
    // invalid video format
    case 33209: {
      return '등록 가능한 동영상 포맷을 확인하신 후 다시 시도하세요.'
    }
    // invalid video size or radio
    case 33210: {
      return '등록 가능한 동영상 사이즈 및 비율을 확인하신 후 다시 시도하세요.'
    }
    // invalid play time error
    case 33215: {
      return `등록 가능한 동영상 길이를 확인하신 후 다시 시도하세요. ${
        detail?.length > 0 ? `(${detail[0].description})` : ''
      }`
    }

    case 33258: {
      return '동영상 파일 이름은 필수 입니다. 확인하신 후 다시 시도하세요.'
    }

    default:
      return (
        defaultMessage ||
        '동영상을 처리 하는 중, 문제가 발생하였습니다. 잠시 후, 다시 홍보동영상을 등록하세요.'
      )
  }
}

export const handleCreativeVideoUploadException = ({ e = {} } = {}) => {
  return () => {
    const { errorCode, message, detail } = e?.response?.data || {}

    showErrorMessage(
      getCreativeVideoUploadExceptionMessageByErrorCode({
        errorCode,
        defaultMessage: message,
        detail,
      })
    )
  }
}

/**
 * 소재 상태 변경으로 인한 수정(or 수정 진입) 불가.
 */
export function invalidAdCreativeModifyTarget(replacePath) {
  return dispatch => {
    const onDismiss = () => {
      if (replacePath) dispatch(replace(replacePath))
    }

    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy onDismiss={onDismiss}>
          <strong className="tit_layer">소재 수정 불가</strong>
          <p className="txt_layer">
            소재의 상태가 변경되어 수정할 수 없습니다.
            <br />
            수정할 소재를 다시 선택하세요.
          </p>
        </PopupProxy>
      )
    )
  }
}

function messageTemplateCountLimitExceed(detail) {
  return dispatch => {
    const { value } = Array.isArray(detail) ? detail[0] : detail
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">메시지 관리에 사본 저장 불가</strong>
          <p className="txt_layer">
            메시지 관리에는 100개까지 저장할 수 있습니다.
            <br />
            (저장된 메시지 개수 : {value}개)
          </p>
        </PopupProxy>
      )
    )
  }
}

function invalidAdGroupTargetInfoException(adAccountId, adGroupId) {
  return async (dispatch, getState, api) => {
    // reload adGroup
    const adGroupResponse = await api.adGroup.fetchAdGroupInfoById(
      adAccountId,
      adGroupId
    )

    dispatch(
      setCreativeCommonByKeyPath(['adGroup'], adGroupResponse?.data || {})
    )

    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">광고그룹 정보 변경</strong>
          <p className="txt_layer">
            광고그룹에 등록된 값이 변경되어
            <br />
            정보 갱신이 필요합니다.
            <br />
            광고그룹 설정 정보를 다시 확인하세요.
          </p>
        </PopupProxy>
      )
    )
  }
}

// 메시지 에디터 이전에 생성한 메시지 소재 수정 불가 처리
export function invalidMessageModifyTarget(replacePath) {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy
          onDismiss={() => {
            dispatch(replace(replacePath))
          }}>
          <strong className="tit_layer">소재 수정 불가</strong>
          <p className="txt_layer">
            모먼트에서 작성한 메시지 또는 메시지 관리를 통해 불러온 카카오톡
            채널 메시지만 수정할 수 있습니다.
          </p>
        </PopupProxy>
      )
    )
  }
}

/**
 * 소재 생성, 수정 시 사용. (설정완료 때는 No)
 */
function invalidLandingUrlException() {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">랜딩 URL 형식 오류</strong>
          <p className="txt_layer">
            랜딩 URL 형식이 유효하지 않습니다.
            <br />
            정상적인 URL을 입력하세요.
          </p>
        </PopupProxy>
      )
    )
  }
}

function invalidCreativeBidAmount() {
  return dispatch => {
    return dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 입찰금액 오류</strong>
          <p className="txt_layer">
            소재 입찰금액이 설정한 과금방식의 최소 입찰금액보다 작습니다.
            <br />
            과금방식의 최소 입찰금액 이상으로 설정하세요.
          </p>
        </PopupProxy>
      )
    )
  }
}

function invalidPricingType() {
  return dispatch => {
    return dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 저장 불가</strong>
          <p className="txt_layer">
            상위 캠페인에서 지원 중단된 과금방식의 소재로
            <br />
            등록할 수 없습니다.
          </p>
        </PopupProxy>
      )
    )
  }
}

function invalidLongTermSettingException() {
  showErrorMessage('롱텀메시지를 설정할 수 없습니다.')
}

function invalidAdCreativeExecutiveTimeException(errorCode) {
  return dispatch => {
    switch (errorCode) {
      case 33227: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 집행시간 오류</strong>
              <p className="txt_layer">
                집행시간이 지난 소재가 존재합니다.
                <br />
                현재 이후의 일시를 입력하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33228: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 집행시간 오류</strong>
              <p className="txt_layer">
                소재의 집행시간이 광고그룹 집행기간을 벗어납니다.
                <br />
                소재 집행시간을 확인하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      default: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 집행시간 오류</strong>
              <p className="txt_layer">
                집행시간 설정에 오류가 있는 소재가 존재합니다. <br />
                소재 집행시간을 수정하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }
    }
  }
}

function invalidAdCreativeDealPriceInfo() {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 저장 오류</strong>
          <p className="txt_layer">광고그룹이나 소재 상태를 확인하세요.</p>
        </PopupProxy>
      )
    )
  }
}

export function invalidCreativeByAdGroupStatusAndSchedule() {
  return dispatch => {
    return dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 저장 불가</strong>
          <p className="txt_layer">
            광고그룹의 집행기간이 지났거나 계약이 해지되어
            <br />
            소재 정보를 저장할 수 없습니다.
          </p>
        </PopupProxy>
      )
    )
  }
}

function unknownAdCreativeException(errorCode, message) {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">시스템 오류</strong>
          <p className="txt_layer">
            시스템 오류가 발생하였습니다.
            <br />
            잠시 후 다시 시도하세요.
            <br />
            시스템 오류가 반복될 경우 처음부터 다시 진행하세요.
            <br />
            (errorCode: {errorCode}, message: {message})
          </p>
        </PopupProxy>
      )
    )
  }
}

export function noLongerSupportedImageSizeException() {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 저장 불가</strong>
          <p className="txt_layer">
            게재지면 노출 지원이 중단된 사이즈의 소재입니다.
            <br />새 소재 만들기로 등록하세요.
          </p>
        </PopupProxy>
      )
    )
  }
}

function invalidCreativeImageSizeOrCreativeFormat() {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 저장 불가</strong>
          <p className="txt_layer">
            해당 소재 유형이나 사이즈는 상위에서 설정한
            <br />
            목표-유형 또는 게재지면-디바이스에서 등록할 수 없습니다.
          </p>
        </PopupProxy>
      )
    )
  }
}

function handleCreativeCompletedItemException({
  errorCode,
  message,
  detail,
  ...rest
}) {
  return async (dispatch, getState, api) => {
    const { adAccountId, adGroupId } = rest

    if (Array.isArray(detail)) {
      const { errorCode, message, description } = detail[0] || {}
      switch (errorCode) {
        case 33243: // 행동유도문구 누락
        case 32008: // CPM, CPC, CPA 의 최소 입찰금액 오류
        case 32010:
        case 32011:
        case 33104:
        case 33107:
        case 33108:
        case 32919: {
          // 소재 등록 시점에 입찰금액 변경이 이루어졌을 경우 광고그룹을 새로 로드해준다.
          if (checkNoneEmpty(adAccountId, adGroupId)) {
            // reload adGroup
            const adGroupResponse = await api.adGroup.fetchAdGroupInfoById(
              adAccountId,
              adGroupId
            )

            dispatch(
              setCreativeCommonByKeyPath(
                ['adGroup'],
                adGroupResponse?.data || {}
              )
            )
          }
          break
        }
        default: {
          break
        }
      }

      dispatch(setCreativeCompletedValidationErrors(detail))
      dispatch(
        handleCreativeFormException({
          errorCode,
          message: message || description,
          detail,
          ...rest,
        })
      )
    } else {
      showErrorMessage(message)
    }
  }
}

export const openMessageCreativeNotEditable = () => {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy>
          <strong className="tit_layer">소재 저장 불가</strong>
          <p className="txt_layer">
            수정 가능한 기간이 지나 소재를 저장할 수 없습니다.
            <br />
            광고그룹 집행 5분 전까지만 소재 수정이 가능합니다.
          </p>
        </PopupProxy>
      )
    )
  }
}

const handleMessageNotEditableTimeException = () => {
  return (dispatch, getState) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType, goal },
          },
          adGroup: {
            schedule: { endDate },
            status: adGroupStatus,
          },
        },
      },
    } = getState()

    const isAdGroupFinishedOrCanceled =
      adGroupStatus?.some(
        v =>
          v === AdStatusEnum.Type.FINISHED || v === AdStatusEnum.Type.CANCELED
      ) || moment().isAfter(moment(endDate), 'd')

    if (
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH
    ) {
      if (isAdGroupFinishedOrCanceled) {
        dispatch(invalidCreativeByAdGroupStatusAndSchedule())
      } else {
        dispatch(openMessageCreativeNotEditable())
      }
    } else {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">발송일시 오류</strong>
            <p className="txt_layer">
              광고그룹에서 설정한 발송일시가 지난 경우
              <br />
              소재 저장을 할 수 없습니다.
              <br />
              광고그룹의 발송일시를 확인하세요.
            </p>
          </PopupProxy>
        )
      )
    }
  }
}

export function handleCreativeFormException({
  errorCode,
  message = '',
  detail,
  ...rest
}) {
  return dispatch => {
    switch (errorCode) {
      case 33204: {
        dispatch(
          handleCreativeCompletedItemException({
            errorCode,
            message,
            detail,
            ...rest,
          })
        )
        break
      }

      // 랜딩 URL 포맷 오류.
      case 93001: {
        dispatch(invalidLandingUrlException())
        break
      }

      // 랜딩 유형 URL 오류.
      case 33123: {
        dispatch(invalidLandingUrlException())
        break
      }

      // 지원 종료된 과금방식
      case 31027: {
        dispatch(invalidPricingType())
        break
      }

      // 소재 집행시간 오류
      case 33226:
      case 33227:
      case 33228:
      case 33229:
      case 33230:
      case 33231:
      case 33232: {
        dispatch(invalidAdCreativeExecutiveTimeException(detail))
        break
      }
      // 비즈보드 CPT 사용불가 유형
      case 35002: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                사용가능한 캠페인 유형과 목표가 아닙니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 집행기간 종료된 상태에서 소재 생성시 오류
      case 36002: {
        dispatch(invalidAdCreativeDealPriceInfo())
        break
      }

      // 광고그룹이 종료 or 계약해지 or 소재 수정 불가(Not Editable)
      case 32047: // 생성
      case 38016: // 수정
      case 39813: {
        // CPT 계약 해지
        dispatch(invalidCreativeByAdGroupStatusAndSchedule())
        break
      }

      /**
       * 광고계정 내 소재 생성 가능 개수 제한.
       * - detail: [{ index: 0, description: '최대 300개 중 343개 존재', value: -243, errorCode: 0 }]
       */
      case 25023: {
        const count = detail?.[0]?.value
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 제한 수 초과</strong>
              <p className="txt_layer">
                광고계정 내 소재 개수 제한으로
                <br />
                모든 소재를 저장할 수 없습니다.
                <br />
                기존 소재를 삭제한 후 다시 시도하세요.
                <br />
                (이미지의 경우 사이즈별 1개의 소재에 해당합니다.)
                <br />
                현재 등록 가능 소재 수: {coerceAtLeast(count, 0) || 0}개
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 하위 소재 생성 가능 개수 제한(디스플레이).
      case 33002: {
        const count = detail?.[0]?.value
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 제한 수 초과</strong>
              <p className="txt_layer">
                광고그룹 내 소재 개수 제한으로
                <br />
                모든 소재를 저장할 수 없습니다.
                <br />
                기존 소재를 삭제한 후 다시 시도하세요.
                <br />
                (이미지의 경우 사이즈별 1개의 소재에 해당합니다.)
                <br />
                현재 등록 가능 소재 수: {coerceAtLeast(count, 0) || 0}개
              </p>
            </PopupProxy>
          )
        )
        break
      }
      // 카카오톡 채널 X 도달 스마트메시지 미설정 광고그룹 하위 소재 생성 가능 개수(1개) 제한
      case 33004: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 제한 수 초과</strong>
              <p className="txt_layer">
                스마트메시지를 설정하지 않은 광고그룹의
                <br />
                메시지 소재는 그룹 당 1개만 등록 가능합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 상품 카탈로그 x  스마트메시지 미설정 광고그룹 하위 소재 생성 가능 개수(1개) 제한
      case 33405: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 제한 수 초과</strong>
              <p className="txt_layer">
                광고그룹 당 소재 수 제한으로
                <br />
                소재 만들기를 할 수 없습니다.
                <br />
                소재 삭제 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 카카오톡 채널 X 도달 스마트메시지 설정 광고그룹 하위 소재 생성 가능 개수(10개) 제한
      case 33006: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 제한 수 초과</strong>
              <p className="txt_layer">
                스마트메시지를 설정한 광고그룹의
                <br />
                메시지 소재는 그룹 당 최대 10개까지 등록 가능합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // url 파라미터 값에 특수문자가 포함된 랜딩 url
      case 33133: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                랜딩 URL의 파라미터에 연결 시 오류가 발생할 수 있는 특수문자가
                포함되어있습니다. <br />
                문제가 될 수 있는 부분을 인코딩한 후 다시 진행하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33134: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                트래킹 URL의 파라미터에 연결 시 오류가 발생할 수 있는 특수문자가
                포함되어있습니다. 문제가 될 수 있는 부분을 인코딩한 후 다시
                진행하세요.
                <br />이 때, 매크로는 인코딩 처리하지 않도록 주의하세요
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33135: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                트래킹 URL의 필수 매크로가 포함되지 않았습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }
      case 33136: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                노출수 추적 URL의 파라미터에 연결 시 오류가 발생할 수 있는
                특수문자가 포함되어있습니다. <br />
                문제가 될 수 있는 부분을 인코딩한 후 다시 진행하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33137: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                클릭수 추적 URL의 파라미터에 연결 시 오류가 발생할 수 있는
                특수문자가 포함되어있습니다. <br />
                문제가 될 수 있는 부분을 인코딩한 후 다시 진행하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }
      /**
       * 건스미스 + 소재최적화 메시지
       * 카카오톡 채널 X 도달 스마트메시지 설정 광고그룹 발송 시작 5분 전 ~ 집행기간내 userConfig ON 상태에서 소재 추가
       */
      case 33247: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                소재최적화 광고그룹은 OFF 상태에서 소재를 추가 등록 또는 삭제할
                수 있습니다.
                <br />
                광고그룹을 OFF 상태로 변경 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      /**
       * 큐라스 메시지
       */
      case 33245: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                스마트메시지 광고그룹은 집행 5분 전부터 집행기간 내에는
                <br />
                소재를 추가 등록 또는 삭제할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 유효하지 않은 소재 이미지 사이즈(해상도).
      case 33106: {
        showErrorMessage(
          '등록 가능한 이미지 사이즈를 확인하신 후 다시 시도하세요.'
        )
        break
      }

      // 유효하지 않은 파일 포맷
      case 33203: {
        showErrorMessage(
          '등록 가능한 이미지 포맷을 확인하신 후 다시 시도하세요.'
        )
        break
      }

      // PNG-24 만 가능
      case 33222: {
        showErrorMessage(
          '등록 가능한 이미지 포맷을 확인하신 후 다시 시도하세요.'
        )
        break
      }

      // 메시지 등록 시점에 광고그룹 정보 변경 시.
      case 38012: {
        const { adAccountId, adGroupId } = rest
        dispatch(invalidAdGroupTargetInfoException(adAccountId, adGroupId))
        break
      }

      // 유효하지 않은 이미지 용량.
      case 33205: {
        showErrorMessage(
          '등록 가능한 이미지 용량을 확인하신 후 다시 시도하세요.'
        )
        break
      }
      // 맞춤 썸네일 사이즈 오류.
      case 33211: {
        showErrorMessage(
          '등록 가능한 맞춤 썸네일 사이즈를 확인하신 후 다시 시도하세요.'
        )
        break
      }

      case 88000: // 타겟 모수가 부족함.
      case 33207: // 이미지 크롭 에러.
      case 33216: {
        // 동영상 소재 생성시 video url 없을 경우 오류
        showErrorMessage(message)
        break
      }
      // Rotate 된 이미지.
      case 33224: {
        showErrorMessage('회전된 이미지는 등록할 수 없습니다.')
        break
      }

      // 지원하지 않는 이미지 타입(CMYK)
      case 33217:
      case 33218: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">이미지 색상 체계 오류</strong>
              <p className="txt_layer">RGB 색상 체계 이미지가 아닙니다.</p>
            </PopupProxy>
          )
        )
        break
      }

      /**
       * 지원하지 않는 소재 유형
       * 상위 목표 유형에 해당하는 소재유형이 아닌경우
       * 이미지 사이즈가 배너 사이즈 요구사항에 맞지 않음(수정)
       */
      case 31028:
      case 33101:
      case 33201:
      case 33202:
      case 35003: {
        dispatch(invalidCreativeImageSizeOrCreativeFormat())
        break
      }

      // 서비스에서 더이상 지원하지 않는 소재 이미지 사이즈(수정)
      case 33128: {
        dispatch(noLongerSupportedImageSizeException())
        break
      }

      // CPM, CPC, CPA 의 최소 입찰금액 오류
      case 32008:
      case 32010:
      case 32011:
      case 33104:
      case 33107:
      case 33108:
      case 32919: {
        dispatch(invalidCreativeBidAmount())
        break
      }

      // 메시지 소재 등록 허용 개수 초과.
      case 31010: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">메시지 제한 수 초과</strong>
              <p className="txt_layer">
                캠페인 내 메시지 개수 제한으로
                <br />
                메시지를 저장할 수 없습니다.
                <br />
                기존 메시지를 삭제한 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 지원하지 않는 캠페인 유형
      case 31019: {
        showErrorMessage(message)
        break
      }

      // 카카오톡 채널 연결 에러.
      case 84001: {
        showErrorMessage(
          '카카오톡 채널 연결에 실패하였습니다. 다시 시도하세요.'
        )
        break
      }

      // 유효하지 않은 카카오톡 채널 정보.
      case 84002: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">카카오톡 채널 오류</strong>
              <p className="txt_layer">
                캠페인에 등록된 카카오톡 채널 오류로 인해
                <br />
                메시지를 등록할 수 없습니다.
                <br />
                카카오톡 채널 관리자센터에서 확인하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 유효하지 않은 소식 정보
      case 84006: {
        showErrorMessage(message)
        break
      }

      // 유효하지 않은 메시지(소재) 상태(수정용).
      case 38004: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">메시지 발송 오류</strong>
              <p className="txt_layer">
                메시지가 임시 저장 상태가 아닙니다.
                <br />
                임시 저장 상태의 메시지만 발송 처리가 가능합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 메시지 소재 상위 광고 단위(기존 광고계정, 신규 캠페인, 신규 광고그룹) 관리자 정지 에러
      case 28011:
      case 28016:
      case 28017: {
        const { adAccountId } = rest
        dispatch(getAdAccountSelectorRefresh(adAccountId))
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                상위 광고단위가 운영불가 상태인 경우
                <br />
                메시지 발송 저장이 불가합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 롱텀 설정 에러.
      case 28012: {
        invalidLongTermSettingException()
        break
      }

      // 프로필명 누락
      case 33239: {
        showErrorMessage('프로필 이름이 누락되었습니다.')
        break
      }

      case 33240: {
        showErrorMessage('프로필 이미지가 유효하지 않습니다.')
        break
      }

      // 행동유도문구 누락
      case 33243: {
        showErrorMessage('행동유도문구가 누락되었습니다.')
        break
      }

      // video preset error
      case 33212: {
        showErrorMessage(message)
        break
      }

      // not exist adCreative
      case 33003: {
        // 소재 수정 시점에 타겟(수정 소재)의 상태가 변경(심사로 인한 modifyTarget 변경), 혹은 삭제되었을 경우 상위 광고그룹 대시보드로 이동.
        const { adAccountId } = rest

        dispatch(
          invalidAdCreativeModifyTarget(
            DashboardRouter.Path.Creative({ adAccountId })
          )
        )
        break
      }

      // 톡 채널 메시지 생성 / 수정 가능한 기간이 아님.
      case 38021: {
        dispatch(handleMessageNotEditableTimeException())
        break
      }

      case 34504: {
        dispatch(messageTemplateCountLimitExceed(detail))
        break
      }

      // 캐러셀 커머스 유형에서 buttonAssetGroups 와 itemAssetGroups ordering이 일치하지 않을 경우 에러
      case 34565: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            carouselCommerceInvalidOrderingDialog(message)
          )
        )
        break
      }

      case 84007: {
        showErrorMessage(
          '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
        )

        break
      }

      // 비즈폼의 기간이 만료되었거나 긴급종료(status:off) 상태인 경우
      case 86102: {
        showErrorMessage(
          '애드뷰 내 톡비즈니스폼이 운영 가능한 상태가 아니거나, 유효하지 않습니다.'
        )
        break
      }

      case 39203: {
        showErrorMessage(
          '삭제된 애드뷰가 있습니다. 애드뷰의 상태를 확인하세요.'
        )
        break
      }

      // 비즈보드 익스펜더블 권한 없음
      case 33304: {
        showErrorMessage(
          '확장 요소를 등록할 수 있는 권한이 없습니다. 확장 요소 삭제 후 다시 진행하세요.'
        )
        break
      }

      // 지원 중단된 랜딩 유형의 소재
      case 33132: {
        showErrorMessage(message)
        break
      }

      // 유효하지 않은 홍보문구 소재
      case 33241: {
        showErrorMessage(message)
        break
      }

      // 이미지 네이티브, 동영상 네이티브 홍보 문구 중복
      case 33252: {
        showErrorMessage(message)
        break
      }

      // 서비스 콘텐츠 정보 or 보드가 유효하지 않음
      case 33253: {
        showErrorMessage(message)
        break
      }

      case 33256: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                운영불가(관리자 정지) 상태에서는
                <br />
                일부 소재 요소 수정이 불가능합니다.
                <br />새 소재 만들기로 등록하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33260: {
        showErrorMessage(message)
        break
      }

      case 33262: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                운영 불가(연결 서비스 제한) 상태에서는
                <br />
                일부 소재 요소 수정이 불가능합니다.
                <br />새 소재 만들기로 등록하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33401: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                웹임베드 채널에 연결된 URL과
                <br />
                입력한 URL의 도메인이 일치해야 합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33402: {
        showErrorMessage(
          '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
        )
        break
      }

      case 33403: {
        showDynamicCatalogAndProductSetIsNotValidError()

        break
      }

      case 51610: {
        showErrorMessage(message)
        break
      }

      case 51620: {
        showErrorMessage(
          '카탈로그에 문제가 있어 오류가 발생했습니다.\n카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
        )

        const { adAccountId } = rest
        dispatch(replace(DashboardRouter.Path.Creative({ adAccountId })))

        break
      }

      case 51621: {
        showErrorMessage(
          '해당 카탈로그의 권한이 없습니다.\n카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
        )

        const { adAccountId } = rest
        dispatch(replace(DashboardRouter.Path.Creative({ adAccountId })))

        break
      }

      case 33507:
      case 33510: {
        detail.forEach(detail => {
          showErrorMessage(`${detail.name} 소재의 ${message}`)
        })
        break
      }

      // 개인화메시지 https://jira.daumkakao.com/browse/KAMOQA-24874
      case 33502:
      case 33503:
      case 34507:
      case 34580:
      case 34512:
      case 33513:
      case 34506:
      case 34578:
      case 34579:
      case 34509:
      case 34554:
      case 34547:
      case 34561:
      case 34562:
      case 34588: {
        showErrorMessage(message)
        break
      }

      case 34593: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">소재 저장 불가</strong>
              <p className="txt_layer">
                발송 가능한 메시지 크기를 초과하였습니다.
                <br />
                소재 항목별 입력값 크기를 조정한 후 다시 등록해주세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 33517: {
        showErrorMessage('랜딩 URL로 1개의 변수 항목만 추가할 수 있습니다.')
        break
      }

      case 90001:
      case 90006: {
        showErrorMessage('잘못된 요청입니다.')
        break
      }

      default: {
        /**
         * 코어에 정의된 메시지 관련 에러코드 범위
         * https://github.daumkakao.com/b2/dsp-core/blob/89ca53a540531985a72832892e62bb8508cc994c/common-core/src/main/java/com/kakao/biz/dsp/exception/enums/ErrorMessage.java
         * 개인화 메시지 관련된 부분은 위에서 먼저 처리 ( 아래 코드값 범위 중복되는 경우는 없음)
         */
        if (errorCode >= 34500 && errorCode <= 34587) {
          const error = { errorCode, message }
          handleMessageError({ error, dispatch })
        } else {
          dispatch(unknownAdCreativeException(errorCode, message))
        }

        break
      }
    }
  }
}
