import { bizNumberValidation } from './bizNumberValidation/bizNumberValidation'

/**
 * isUndefinedOrNull(null) // true,
 * isUndefinedOrNull(undefined) // true
 * isUndefinedOrNull('null') // true  // TODO 검토 중.
 */
export function isUndefinedOrNull(char) {
  return char === null || char === undefined || char === 'null'
}

/**
 * isNoneUndefinedOrNull(null, 1, 'a')  // false
 */
export function isNoneUndefinedOrNull(...char) {
  return char.every(c => !isUndefinedOrNull(c))
}

export function isAnyUndefinedOrNull(...char) {
  return char.some(c => isUndefinedOrNull(c))
}

/**
 * checkNotEmpty('a') // true
 * checkNotEmpty('') // false
 * checkNotEmpty(null) // false
 */
export function checkNotEmpty(char) {
  return !isUndefinedOrNull(char) && char !== ''
}

export function checkEmpty(char) {
  return !checkNotEmpty(char)
}

/**
 * checkNoneEmpty('a', 1) // true
 */
export function checkNoneEmpty(...char) {
  return char.every(c => checkNotEmpty(c))
}

/**
 * checkEveryEmpty('', 1) // false
 * checkEveryEmpty('a', 1) // true
 */
export function checkEveryEmpty(...char) {
  return char.every(c => checkEmpty(c))
}

/**
 * checkAnyEmpty('a', 1, 0, '') // false
 */
export function checkAnyEmpty(...char) {
  return char.some(c => checkEmpty(c))
}

export function checkEmail(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return emailRegex.test(email)
}

export function checkStringLength(string, min = 2, max = 1000) {
  if (typeof string === 'string') {
    const trimmed = string.replace(/^\s+|\s+$/gm, '')
    return min <= trimmed.length && trimmed.length <= max
  }
  return false
}

export function checkNameQuery(nameQuery, preventShort = true, max = 100) {
  const trimmed = nameQuery.replace(/^\s+|\s+$/gm, '')
  return trimmed.length <= max && (preventShort ? trimmed.length !== 1 : true)
}

export function checkPhoneNumber(phone) {
  const phoneRegex = /^0([0-9]{1,2})-?([0-9]{3,4})-?([0-9]{4})$/
  return phoneRegex.test(phone)
}

export function checkName(name) {
  const nameRegex = /^[a-zA-Z가-힣]{2,10}$/
  return nameRegex.test(name)
}

export function checkHttp(url) {
  const httpRegex = /^(http:\/\/)/
  return httpRegex.test(url)
}

export function checkHttps(url) {
  const httpsRegex = /^(https:\/\/)/
  return httpsRegex.test(url)
}

/**
 * protocol + domain 체크하는 정규식
 */
export function checkHttpsUrlWithParamsRegex(url) {
  const urlRegex = /^(https:\/\/)([0-9a-zA-Z]+\.)+[a-zA-Z]{2,6}([0-9]+)?/
  // /^(https:\/\/)([0-9a-zA-Z]+\.)+[a-zA-Z]{2,6}([0-9]+)?(\?)([^=]+)([^&]+)/

  return urlRegex.test(url)
}

export function checkUrlWithDomain(url) {
  const urlRegex =
    /^(http(s)?:\/\/)([0-9a-zA-Z-._~!$&'"(){}[\]%*+,;=:@/?₩#\\]+\.)+[a-zA-Z-._~!$&'"(){}[\]%*+,;=:@/?₩#\\]{2,6}([0-9]+)?/

  return urlRegex.test(url?.trim())
}

/**
 * @param url
 * @constructor
 *
 * URL에서 특수문자를 허용하지 않도록 검사하는 것이 아니라, 느슨하게 특수문자도 허용하도록 검사한다.
 */
export function looslyCheckHttpsUrlWithParamRegex(url) {
  const urlRegex = /^(https:\/\/)(.+\.)+.{2,6}([0-9]+)?/

  return urlRegex.test(url)
}

/**
 * http://www.faqs.org/rfcs/rfc3987.html
 * https://code-examples.net/ko/q/277ca
 * https://mathiasbynens.be/notes/javascript-unicode
 * @param url {string}
 * @return {boolean}
 */
export function checkUrl(url) {
  // const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  return checkHttp(url) || checkHttps(url)
}

export function checkTalkScheme(url) {
  const urlRegex =
    /^(kakaoplus:\/\/|kakaotalk:\/\/|alphaplus:\/\/|alphatalk:\/\/)/
  return urlRegex.test(url)
}

export function checkSecureUrl(url) {
  const urlRegex = /^(https:\/\/)/
  return urlRegex.test(url)
}

export function checkKakaoTvUrl(url) {
  return checkUrl(url) && /tv.kakao.com/g.test(url)
}

export function checkUrlHasWhiteSpace(url) {
  return hasFullWidthWhitespace(url) || hasWhitespace(url) || hasNewLine(url)
}

export function checkTalkServiceUrl(url) {
  return checkUrl(url) && /\.kakao.com\//.test(url)
}

/**
 * 사업자 번호 체크
 * @param input
 * @param strict (true : 등록, false : 검색 )
 * @returns {boolean}
 */
export function validateBizNumber(input, strict = true) {
  const bizNumberRegex = /^\d{3}-\d{2}-\d{5}$/
  return strict
    ? bizNumberRegex.test(input) && bizNumberValidation(input)
    : bizNumberRegex.test(input)
}

export function checkDateFormatWithHyphen(input) {
  const dateFormatRegex = /^\d{4}-\d{1,2}-\d{1,2}$/
  return dateFormatRegex.test(input)
}

export function checkDateFormatWithDot(input) {
  const dateFormatRegex = /^\d{4}.\d{1,2}.\d{1,2}$/
  return dateFormatRegex.test(input)
}

// FIXME 현재는 Negative number 를 허용하지 않고 있다.
export const isIntegerSequence = input => /^[0-9]\d*$/g.test(input) // \d*$ 로 sequential numeric 만 허용.

export const isPositiveIntegerSequence = input => /^[1-9]\d*$/g.test(input) // \d*$ 로 sequential numeric 만 허용.

// 숫자, 영문, 한글, 공백을 제외한 문자 검출
export const hasSpecialCharacter = (input, allowedChars = '') => {
  if (checkEmpty(allowedChars))
    return !/^[a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\d\s]*$/gm.test(input)
  return !new RegExp(
    eval(`/^[a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\\d\\s${allowedChars}]*$/gm`)
  ).test(input)
}

// 키보드에 존재하는 숫자, 영문, 한글(자/모), 특문, 공백 true, 그 외(유니코드, 이모지 등) false
export const hasOnlyKeyboardChars = (input, allowedChars = '') => {
  return new RegExp(
    eval(
      `/^[a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\\d\\s~!@#$%^&*()_+{}:"<>?\`\\-=[\\];',./\\₩${allowedChars}]*$/gm`
    )
  ).test(input)
}

// 반각(half-width) 공백(space 키), unicode = U+0020
export const hasWhitespace = input => /\s/g.test(input)

// 전각(full-width) 공백(ㄱ + 한자), unicode = U+3000
export const hasFullWidthWhitespace = input => /[ㅤ]/gm.test(input)

export const hasNewLine = input => /\n/g.test(input)

export const hasHtml = input => /<.+>/g.test(input)

/**
 * hasKoreanInitialOrMedial('ㄱ') // true
 * hasKoreanInitialOrMedial('ㄱabc') // true
 * hasKoreanInitialOrMedial('abc') // false
 * hasKoreanInitialOrMedial('없음') // false
 */
export const hasKoreanInitialOrMedial = input =>
  hasKoreanInitial(input) || hasKoreanMedial(input)

export const hasKoreanInitial = input => /[ㄱ-ㅎ]/gm.test(input)

export const hasKoreanMedial = input => /[ㅏ-ㅣ]/gm.test(input)

export const hasKorean = input => /[가-힣ㄱ-ㅎㅏ-ㅣ]/gm.test(input)

export const validateInputLength = (
  input,
  min = 1,
  max = Number.MAX_SAFE_INTEGER
) => {
  return String(input).length >= min && String(input).length <= max
}

export const hasOnlyAlphanumeric = input => /^[a-z0-9]+$/i.test(input)

export const hasNumberAndComma = input => /^[0-9,]+$/.test(input) // \d*$ 로 sequential numeric 만 허용.

export const hasUrl = input => /(http:\/\/|https:\/\/|www\.)/.test(input)

export const hasContinuousSharp = input => /#{2,}/g.test(input)
export const hasNotAllowedSpecialCharacter = input =>
  /[^0-9a-zA-Zㄱ-ㅎ가-힣ㅏ-ㅣ-._~!$&'"(){}[\]%*+,;=:@/?₩#\\]/.test(input)

export const hasNotAllowedEncodedSpecialCharacter = input => /["|]/.test(input)

export const extractUrlFileName = url =>
  /[^/\\&\\?]+\.\w{3,}(?=([\\?&].*$|$))/.exec(url)?.[0]

export const removeSpace = input => {
  return input.replace(/\s/gm, '')
}

export const checkClickId = input => {
  return /^(\{click_id})$/.test(input)
}

export const checkGoogleAidOrAppleIfa = input => {
  return /^(?:\{(?:google_aid|apple_ifa)}){1,2}$/.test(input)
}

export const validateColorCodeValue = input => {
  return /^#[0-9a-f]{6}/i.test(input)
}
