import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import { push, replace } from 'connected-react-router'
import { toReportModifyPath, toReportPath } from '../../utils/router/routeUtils'
import { batchInitReportDetail } from '../../modules-batch/batch-reportV2'
import { convertStateToRequest } from '../../components/ReportV2/Common/reportRequestConverter'
import moment from 'moment/moment'
import CalendarEnum from '../../enums/CalendarEnum'
import DimensionEnum from '../../enums/DimensionEnum'
import SortEnum from '../../enums/SortEnum'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import ReportFilterEnum from '../../enums/ReportFilterEnum'

const ReportDetail = keyMirror(
  {
    CLEAR: null,
    RESET: null,
    SET_DETAIL_NANE: null,
    SET_CHANGED: null,
    RESET_CHANGED: null,
    INIT_FROM_DASHBOARD: null,
  },
  'REPORT_DETAIL'
)

const initialState = fromJS({
  loaded: false,
  name: undefined,
  changed: false,
  fromDashboard: false,
  dashboardCalendar: {
    startDate: moment().subtract(6, 'day'),
    endDate: moment(),
    periodType: CalendarEnum.Preset.Type.LAST7DAYS,
  },
  dashboardCommon: {
    adUnits: [DimensionEnum.AdUnit.Type.CAMPAIGN_NAME],
    dimensions: [DimensionEnum.PeriodUnit.Type.DAY],
    metrics: [
      'cost',
      'imp',
      'click',
      'ctr',
      'msg_send',
      'video_play_3s',
      'vtr',
    ],
  },
  dashboardFilter: [
    {
      adUnitType: DashboardEnumV2.Type.CAMPAIGN,
      filterType: ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC,
      filterSubType: 'cost',
      filterOperator: ReportFilterEnum.Operator.Type.GE,
      filterValue: [{ min: '1', max: '' }],
    },
  ],
  dashboardSort: {
    sort: 'cost',
    order: SortEnum.Type.DESC,
  },
})

export default createReducer(initialState, {
  [ReportDetail.CLEAR]: () => initialState,
  [ReportDetail.RESET]: state =>
    state.withMutations(mutable =>
      mutable.set('loaded', true).set('changed', false)
    ),
  [ReportDetail.SET_DETAIL_NANE]: (state, { name }) =>
    state.withMutations(mutable =>
      mutable.set('name', name).set('loaded', true).set('changed', false)
    ),
  [ReportDetail.SET_CHANGED]: state => state.set('changed', true),
  [ReportDetail.RESET_CHANGED]: state => state.set('changed', false),
  [ReportDetail.INIT_FROM_DASHBOARD]: (
    state,
    { dashboardCalendar, dashboardCommon, dashboardFilter, dashboardSort }
  ) =>
    state.withMutations(mutable =>
      mutable
        .set('fromDashboard', true)
        .set('dashboardCalendar', dashboardCalendar)
        .set('dashboardCommon', dashboardCommon)
        .set('dashboardFilter', dashboardFilter)
        .set('dashboardSort', dashboardSort)
    ),
})

export function setReportDetail(name) {
  return {
    type: ReportDetail.SET_DETAIL_NANE,
    name,
  }
}

export function setReportChanged() {
  return {
    type: ReportDetail.SET_CHANGED,
  }
}

export function getReportDetail({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.REPORT_V2_DETAIL))
    try {
      const { data } = await api.reportV2.fetchReportItem(adAccountId, id)
      dispatch(batchInitReportDetail(data))
    } catch (e) {
      console.error(e)
      showErrorMessage('저장된 보고서를 가져오는 중 오류가 발생했습니다.')
      dispatch(replace(toReportPath(adAccountId)))
    } finally {
      dispatch(hideLoading(LOADING_KEY.REPORT_V2_DETAIL))
    }
  }
}

export function createReport({ adAccountId, name, type }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.REPORT_V2_COMMAND))
    try {
      const {
        reportV2: {
          calendar: { startDate, endDate, periodType },
          filter: { filterConditions },
          common: { adUnits, dimensions, metrics },
          table: { sort, order },
        },
      } = getState()
      const body = {
        ...convertStateToRequest({
          adAccountId,
          startDate,
          endDate,
          periodType,
          filterConditions,
          adUnits,
          dimensions,
          metrics,
          sort,
          order,
        }),
        name,
      }
      const {
        data: { id },
      } = await api.reportV2.createReport(adAccountId, body)
      showSuccessMessage(
        type === 'saveAs'
          ? '보고서 다른 이름으로 저장이 완료되었습니다.'
          : '보고서 저장이 완료되었습니다.'
      )
      dispatch(push(toReportModifyPath(adAccountId, id)))
    } catch (e) {
      console.error(e)
      showErrorMessage('보고서 저장 중 오류가 발생했습니다.')
    } finally {
      dispatch(hideLoading(LOADING_KEY.REPORT_V2_COMMAND))
    }
  }
}

export function modifyReport({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.REPORT_V2_COMMAND))
    try {
      const {
        reportV2: {
          calendar: { startDate, endDate, periodType },
          filter: { filterConditions },
          common: { adUnits, dimensions, metrics },
          table: { sort, order },
          detail: { name },
        },
      } = getState()
      const body = {
        ...convertStateToRequest({
          adAccountId,
          startDate,
          endDate,
          periodType,
          filterConditions,
          adUnits,
          dimensions,
          metrics,
          sort,
          order,
        }),
        id,
        name,
      }
      await api.reportV2.modifyReport(adAccountId, id, body)
      dispatch({
        type: ReportDetail.RESET_CHANGED,
      })
      showSuccessMessage('보고서 저장이 완료되었습니다.')
    } catch (e) {
      showErrorMessage('보고서 저장 중 오류가 발생했습니다.')
    } finally {
      dispatch(hideLoading(LOADING_KEY.REPORT_V2_COMMAND))
    }
  }
}

export function initFromDashboard({
  dashboardCalendar,
  dashboardCommon,
  dashboardFilter,
  dashboardSort,
}) {
  return {
    type: ReportDetail.INIT_FROM_DASHBOARD,
    dashboardCalendar,
    dashboardCommon,
    dashboardFilter,
    dashboardSort,
  }
}

export function clearReportDetail() {
  return {
    type: ReportDetail.CLEAR,
  }
}

export function resetReportDetail() {
  return {
    type: ReportDetail.RESET,
  }
}
