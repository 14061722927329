import {
  CONTEXTPATH,
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPostForm,
  fetchPut,
  queryString,
} from '../../utils/fetchUtils'

const adViewUrl = `${CONTEXTPATH}/communicator/adViews`

/**
 * 광고계정에 생성된 전체 애드뷰 목록
 * @param adAccountId
 * @returns {AxiosPromise}
 */
function fetchAdAccountAdViewList(adAccountId) {
  return fetchGet(`${adViewUrl}/all`, adAccountId)
}

/**
 * 광고계정에 생성된 메시지 랜딩이 설정된 애드뷰를 제외한 목록 조회
 * @param adAccountId
 * @returns {AxiosPromise}
 */
function fetchAdAccountAdViewListExcludingMessageLanding(adAccountId) {
  return fetchGet(`${adViewUrl}/all/?filterType=excludeMessage`, adAccountId)
}

/**
 * 애드뷰 목록 조회 페이징 (애드뷰 불러오기 포함)
 * @param adAccountId
 * @param params (page, size, name, isTenthInclude)
 * @returns {AxiosPromise}
 */
function fetchAdViewListPaging(adAccountId, params) {
  return fetchGet(`${adViewUrl}?${queryString(params)}`, adAccountId)
}

/**
 * 애드뷰 상세조회
 * @param adAccountId
 * @param adViewId
 * @returns {AxiosPromise}
 */
function fetchAdViewInfoById(adAccountId, adViewId) {
  return fetchGet(`${adViewUrl}/${adViewId}`, adAccountId)
}

/**
 * 애드뷰 상세 - 연결된 소재현황 목록 조회
 * @param adAccountId
 * @param params (page, size, campaignType, goal)
 * @returns {AxiosPromise}
 */
function fetchConnectedCreativeList(adAccountId, params) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/linkagedAdViews?${queryString(params)}`,
    adAccountId
  )
}

/**
 * 애드뷰 생성
 * @param adAccountId
 * @param requestBody
 * @returns {AxiosPromise}
 */
function createAdView(adAccountId, requestBody) {
  return fetchPost(`${adViewUrl}/create`, requestBody, adAccountId)
}

/**
 * 애드뷰 수정
 * @param adAccountId
 * @param adViewId
 * @param requestBody
 * @returns {AxiosPromise}
 */
function modifyAdView(adAccountId, adViewId, requestBody) {
  return fetchPut(`${adViewUrl}/${adViewId}`, requestBody, adAccountId)
}

/**
 * 애드뷰 삭제
 * @param adAccountId
 * @param adViewId
 * @returns {AxiosPromise}
 */
function deleteAdView(adAccountId, adViewId) {
  return fetchDelete(`${adViewUrl}/${adViewId}`, null, adAccountId)
}

/**
 * communicatorAdViewId 로 adView 단일 항목 조회
 */
function fetchAdViewById(adAccountId, id) {
  return fetchGet(`${adViewUrl}/${id}`, adAccountId)
}

/**
 * 12.5 애드뷰 이미지 파일 업로드
 */
function uploadAdViewAssets(formData, adAccountId, params) {
  return fetchPostForm(
    `${adViewUrl}/upload/assets?${queryString(params)}`,
    formData,
    adAccountId
  )
}

/**
 * 12.6.1 동영상 소재 Tenth 업로드를 위한 url
 */
function fetchAdViewVideoUploadPath(adAccountId, fileName) {
  return fetchGet(
    `${adViewUrl}/video/uploadPath?fileName=${fileName}`,
    adAccountId
  )
}

/**
 * 12.6.2 동영상 업로드 응답 처리
 */
function uploadedAdViewVideoInfos(body, adAccountId) {
  return fetchPost(`${adViewUrl}/video`, body, adAccountId)
}

/**
 * 12,6,3 동영상 소재 파일 트랜스코딩 체크
 */
function checkTranscoding(adAccountId, videoPresetIds, cancelToken) {
  return fetchGet(
    `${adViewUrl}/video/encoding?videoPresetId=${videoPresetIds}`,
    adAccountId,
    cancelToken
  )
}

/**
 * 12.6.4 동영상 소재 파일 트랜스코딩 체크 재요청
 */
function retryCheckTranscoding(adAccountId, videoPresetIds, cancelToken) {
  return fetchPost(
    `${adViewUrl}/video/encoding/retry?videoPresetId=${videoPresetIds}&loudnorm=${encodeURI(
      'I=-24'
    )}`,
    undefined,
    adAccountId,
    cancelToken
  )
}

/**
 * 12.11 카카오TV 원본 동영상을 Tenth에 업로드
 * @param adAccountId
 * @param requestBody
 * @param cancelToken
 * @returns {AxiosPromise}
 */
function uploadKakaoTvVideo(adAccountId, requestBody, cancelToken) {
  return fetchPost(
    `${adViewUrl}/kakaotv/video/upload`,
    requestBody,
    adAccountId,
    cancelToken
  )
}

export default {
  fetchAdAccountAdViewList,
  fetchAdAccountAdViewListExcludingMessageLanding,
  fetchAdViewById,
  fetchAdViewListPaging,
  fetchAdViewInfoById,
  fetchConnectedCreativeList,
  createAdView,
  modifyAdView,
  deleteAdView,
  uploadAdViewAssets,
  fetchAdViewVideoUploadPath,
  checkTranscoding,
  retryCheckTranscoding,
  uploadedAdViewVideoInfos,
  uploadKakaoTvVideo,
}
