import { keyMirror } from '../utils/utils'

export default {
  PlusFriendGroupType: keyMirror({
    PHONE_NUMBER: null,
    APP_USER_ID: null,
    MESSAGE_RETARGET: null,
    ACCOUNT_ID: null,
  }),

  PlusFriendGroupTypeName: {
    PHONE_NUMBER: '전화번호',
    APP_USER_ID: '앱유저아이디',
    MESSAGE_RETARGET: '메시지 발송 대상자',
    ACCOUNT_ID: '어카운트아이디',
  },

  isFileUploadType(type) {
    return [
      this.PlusFriendGroupType.PHONE_NUMBER,
      this.PlusFriendGroupType.APP_USER_ID,
      this.PlusFriendGroupType.ACCOUNT_ID,
    ].includes(type)
  },

  getPlusFriendGroupTypeValues() {
    return Object.freeze(Object.keys(this.PlusFriendGroupType))
  },

  getPlusFriendGroupTypeName(type) {
    return this.PlusFriendGroupTypeName[type]
  },
}
