const CREATIVE_FORM_VALIDATION_MESSAGE = {
  IS_REQUIRED: '필수 입력 항목입니다.',
  LANDING_URL:
    'http:// 혹은 https:// 를 포함하여 정상적인 형식의 URL을 입력하세요.',
  TALK_CALENDAR: '정상적인 형식의 이벤트ID를 입력하세요.',
  HAS_WHITE_SPACE_BY_URL: 'URL에는 공백 및 줄바꿈을 포함할 수 없습니다.',
  HAS_WHITE_SPACE_BY_EVENT_CLICK_URL:
    'URL에는 공백 및 줄바꿈을 포함할 수 없습니다.',
  HAS_FULL_WIDTH_WHITE_SPACE: '전각 공백문자는 입력할 수 없습니다.',
  DISCOUNT_PRICE:
    '할인가격정보는 가격정보보다 1% 이상 할인된 가격이어야 합니다.',
  DIFFERENT_VALUE:
    '타이틀, 프로필 이름, 홍보문구는 서로 다른 문구를 입력하세요.',
  DIFFERENT_VALUE_COMPATIBLE_TITLE:
    '하위버전 타이틀, 프로필 이름은 서로 다른 문구를 입력하세요.',
  HAS_DIFFERENT_DEVICE_LANDING_URL:
    '대표 랜딩 URL과 슬라이드 URL의 디바이스 설정이 상이하여 저장할 수 없습니다. 동일하게 설정 후 다시 시도하세요.',
  TRACKING_LANDING_URL:
    'https:// 를 포함하여 정상적인 형식의 트래킹 URL을 입력하세요.',
  HAS_WHITE_SPACE_BY_TRACKING_URL:
    '트래킹 URL에는 공백 및 줄바꿈을 포함할 수 없습니다.',
  HAS_NOT_ALLOWED_SPECIAL_CHARACTER:
    '유효하지 않은 특수문자가 포함되어 있습니다.',
  MACRO_REQUIRED:
    '필수 매크로인 클릭 ID, 식별자 ID가 누락되었습니다. {click_id}를 포함하고 {google_aid} 혹은 {apple_ifa}도 포함하여 다시 시도하세요.',
  PARAMETER_KEY_REQUIRED: '매개변수 Key 중 입력되지 않은 항목이 있습니다.',
  HAS_INVALID_CHARACTER_WITH_PARAMETER:
    'URL의 파라미터에 연결 시 오류가 발생할 수 있는 특수문자가 포함되어있습니다. 문제가 될 수 있는 부분을 인코딩한 후 다시 진행하세요.이 때, 매크로는 인코딩 처리하지 않도록 주의하세요.',
}

export { CREATIVE_FORM_VALIDATION_MESSAGE }
