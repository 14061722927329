import AdViewTemplateEnum from '../enums/AdViewTemplateEnum'
import { v4 as uuid } from 'uuid'
import {
  changeAdViewForm,
  changeAdViewStateByKeyPath,
  initAdViewFileUploadCancelKeys,
  initAdViewForm,
  initAdViewState,
  initMultiUploadList,
} from '../modules/adViewV2/mAdViewV2'
import { fromJS } from 'immutable'
import { replace } from 'connected-react-router'
import { isUndefinedOrNull } from '../utils/regexUtils'
import {
  hideLoading,
  LOADING_KEY,
  showLoading,
} from '../modules/common/mLoading'
import AdViewEnum from '../enums/AdViewEnum'
import { AdViewHelperV2 } from '../utils/helper/helper-adViewV2'
import { showErrorMessage } from '../utils/alertUtils'
import { toAdViewManagementPath } from '../utils/router/routeUtils'

function batchAdViewOnMount(adAccountId, modifyAdViewForm) {
  return async (dispatch, getState, api) => {
    const {
      adViewV2: { adViewState },
    } = getState()

    dispatch(showLoading(LOADING_KEY.SETUP_AD_VIEW))
    dispatch(initAdViewForm())
    dispatch(initAdViewFileUploadCancelKeys())

    const isNew =
      isUndefinedOrNull(modifyAdViewForm) || modifyAdViewForm.isEmpty()

    if (isNew) {
      /**
       *  애드뷰 생성
       */
      const { templateType } = adViewState

      const isMultiUploadType =
        AdViewTemplateEnum.isMultiUploadType(templateType)

      // 캐러셀, 스크롤형 템플릿 선택시 선처리.
      if (isMultiUploadType) {
        dispatch(initMultiUploadList())
      }

      dispatch(changeAdViewForm(['formUUID'], uuid()))
      dispatch(hideLoading(LOADING_KEY.SETUP_AD_VIEW))
    } else {
      /**
       *  애드뷰 불러오기 및 애드뷰 수정
       */
      try {
        const { type, templateType, componentData, talkShareContents } =
          fromJS(modifyAdViewForm)

        const adViewForm =
          AdViewHelperV2.Form.updateLoadAdViewForm(componentData)

        const { button1, button2 } = adViewForm
        const messageInfo = AdViewHelperV2.Button.getMessageInfos(
          button1,
          button2
        ).first()

        const { profileId } = messageInfo || {}

        const plusFriendInfo = AdViewHelperV2.Button.getPlusFriendInfos(
          button1,
          button2
        ).first()
        const { id } = plusFriendInfo || {}

        // 유효한 버튼 유형인지 체크하여 inputValue 초기화
        const validAdViewForm =
          AdViewHelperV2.Form.updateFormButtonValue(adViewForm)

        // 기존에 저장된 애드뷰에는 없는 값과 뷰 스테이트 업데이트
        dispatch(changeAdViewStateByKeyPath(['adViewForm'], validAdViewForm))

        const isTalkChannelType = profileId > 0 || id > 0

        // 카카오톡 채널 선택 유형의 권한 체크
        if (isTalkChannelType) {
          const {
            data: { authority },
          } = await api.user.getPlusFriendProfile(adAccountId, profileId || id)

          if (!authority) {
            showErrorMessage(
              '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
            )
            dispatch(replace(toAdViewManagementPath(adAccountId)))
          }
        }

        // 애드뷰 불러오기시 기 저장된 애드뷰 유형 및 템플릿 유형으로 변경
        dispatch(
          changeAdViewStateByKeyPath(
            ['adViewState'],
            adViewState.withMutations(s =>
              s.set('adViewType', type).set('templateType', templateType)
            )
          )
        )

        // 공유 말풍선 폼 저장
        if (type === AdViewEnum.Type.FULL) {
          dispatch(
            changeAdViewStateByKeyPath(['talkShareForm'], talkShareContents)
          )
        }

        dispatch(hideLoading(LOADING_KEY.SETUP_AD_VIEW))
      } catch (e) {
        console.log(e.message)
        dispatch(hideLoading(LOADING_KEY.SETUP_AD_VIEW))
      }
    }
  }
}

function batchAdViewOnUnmount() {
  return dispatch => {
    dispatch(initAdViewForm())
    dispatch(initAdViewState())
  }
}

export { batchAdViewOnMount, batchAdViewOnUnmount }
