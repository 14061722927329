import campaign from './advertise/campaignApi'
import adGroup from './advertise/adGroupApi'
import adCreative from './advertise/adCreativeApi'
import adAccount from './adAccount/adAccountApi'
import company from './common/companyApi'
import user from './common/userApi'
import fileUpload from './common/fileuploadApi'
import review from './settings/reviewApi'
import editLog from './settings/editLogApi'
import cash from './settings/cashApi'
import card from './settings/cardApi'
import invoice from './settings/invoiceApi'
import customerFile from './targeting/customerFileApi'
import reportV2 from './reportV2/reportApiV2'
import dashboard from './dashboard/dashboardApi'
import population from './targeting/populationApi'
import cohort from './targeting/cohortApi'
import pixelV2 from './pixelAndSDK/pixelAndSDKApiV2'
import bconV2 from './pixelAndSDK/bconApiV2'
import moment from './targeting/momentApi'
import common from './common/commonApi'
import plusFriendGroup from './targeting/plusFriendGroupApi'
import adView from './adView/adViewApi'
import targeting from './targeting/targetingApi'
import adConstraints from './advertise/adConstraintApi'
import bizForm from './linkage/bizForm/bizFormApi'
import robotReports from './robotReports/robotReports'
import creativeAsset from './advertise/creativeAssetApi'
import catalog from './advertise/catalogApi'
import message from './message/messageApi'
import audience from './targeting/audienceApi'
import search from './search/searchApi'
import dashboardChart from './dashboard/dashboardChartApi'
import dashboardTable from './dashboard/dashboardTableApi'
import dashboardSummary from './dashboard/dashboardSummaryApi'
import bizBoardEditor from './advertise/bizBoardEditorApi'
import cmp from './advertise/cmpApi'
import showcase from './showcase/showcaseApi'
import contract from './advertise/contractApi'

export default {
  campaign,
  adGroup,
  adCreative,
  adAccount,
  company,
  user,
  review,
  editLog,
  customerFile,
  fileUpload,
  cash,
  card,
  invoice,
  reportV2,
  dashboard,
  population,
  cohort,
  pixelV2,
  bconV2,
  moment,
  common,
  plusFriendGroup,
  adView,
  targeting,
  adConstraints,
  bizForm,
  robotReports,
  creativeAsset,
  catalog,
  message,
  audience,
  search,
  dashboardChart,
  dashboardTable,
  dashboardSummary,
  bizBoardEditor,
  cmp,
  showcase,
  contract,
}
