import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { changeCampaignViewStateByKeyV2 } from './mCampaignV2'
import axios from 'axios'

const Contract = keyMirror(
  {
    SET_CONTRACT_PRODUCT_INFO: null,
    SET_CONTRACT_SELECTED_DATE: null,
    SET_CONTRACT_VIEW_STATE_BY_KEY: null,
    SET_CONTRACT_AVAILABLE_DAYS: null,
    SET_CONTRACT_PRICES: null,
    SET_CONTRACT_BOOKINGS: null,
    SET_CONTRACT_NAME: null,
    CLEAR_CONTRACT: null,
    CLEAR_CONTRACT_EXCEPT_PRODUCT_INFO: null,
  },
  'CONTRACT'
)

const initialState = fromJS({
  productInfo: {},
  name: '',
  contractProductItems: [
    {
      beginDate: null,
      endDate: null,
      productPriceId: -1,
    },
  ],

  availableDays: {},
  prices: [],
  bookings: [],

  viewState: {
    selectedDate: null,
    isNewContract: null,
    subType: null,
    beginDateTime: null,
    endDateTime: null,
    isHoliday: null,
  },
})

export default createReducer(initialState, {
  [Contract.SET_CONTRACT_PRODUCT_INFO]: (state, { productInfo }) =>
    state.set('productInfo', fromJS(productInfo)),

  [Contract.SET_CONTRACT_SELECTED_DATE]: (state, { selectedDate }) =>
    state.setIn(['viewState', 'selectedDate'], fromJS(selectedDate)),

  [Contract.SET_CONTRACT_VIEW_STATE_BY_KEY]: (state, { key, value }) =>
    state.setIn(['viewState', ...coerceToArray(key)], fromJS(value)),

  [Contract.CLEAR_CONTRACT_EXCEPT_PRODUCT_INFO]: state =>
    initialState.set('productInfo', state.get('productInfo')),

  [Contract.SET_CONTRACT_AVAILABLE_DAYS]: (state, { availableDays }) =>
    state.set('availableDays', fromJS(availableDays)),

  [Contract.SET_CONTRACT_PRICES]: (state, { prices }) =>
    state.set('prices', fromJS(prices)),

  [Contract.SET_CONTRACT_BOOKINGS]: (state, { bookings }) =>
    state.set('bookings', fromJS(bookings)),

  [Contract.SET_CONTRACT_NAME]: (state, { name }) =>
    state.set('name', fromJS(name)),

  [Contract.CLEAR_CONTRACT]: () => initialState,
})

export function setContractProductInfo(productInfo) {
  return {
    type: Contract.SET_CONTRACT_PRODUCT_INFO,
    productInfo,
  }
}

export function setContractSelectedDate(selectedDate) {
  return {
    type: Contract.SET_CONTRACT_SELECTED_DATE,
    selectedDate,
  }
}

export function setContractAvailableDays(availableDays) {
  return {
    type: Contract.SET_CONTRACT_AVAILABLE_DAYS,
    availableDays,
  }
}

export function setContractPrices(prices) {
  return {
    type: Contract.SET_CONTRACT_PRICES,
    prices,
  }
}

export function setContractBookings(bookings) {
  return {
    type: Contract.SET_CONTRACT_BOOKINGS,
    bookings,
  }
}

export function setContractName(name) {
  return {
    type: Contract.SET_CONTRACT_NAME,
    name,
  }
}

export function clearContract() {
  return {
    type: Contract.CLEAR_CONTRACT,
  }
}

export function clearContractExceptProductInfo() {
  return {
    type: Contract.CLEAR_CONTRACT_EXCEPT_PRODUCT_INFO,
  }
}

export function setContractViewStateByKey(key, value) {
  return {
    type: Contract.SET_CONTRACT_VIEW_STATE_BY_KEY,
    key,
    value,
  }
}

export function fetchProductList(adAccountId) {
  return async (dispatch, getState, api) => {
    const {
      contract: {
        productInfo: { id: productId, selfBookable },
        viewState: { isNewContract },
      },
    } = getState()

    try {
      const response = await api.campaign.fetchAdProductContract(
        adAccountId,
        productId
      )
      dispatch(changeCampaignViewStateByKeyV2('contracts', response.data))
      if (response.data.length > 0) {
        if (isNewContract === null) {
          dispatch(setContractViewStateByKey('isNewContract', false))
        }
      } else {
        dispatch(setContractViewStateByKey('isNewContract', selfBookable))
      }
    } catch (e) {
      dispatch(changeCampaignViewStateByKeyV2('contracts', null))
      console.log(e.message)
    }
  }
}

export function fetchBookingSituation(adAccountId, selectedDate) {
  return async (dispatch, getState, api) => {
    const {
      contract: {
        viewState: { subType },
        productInfo: { id: productId },
      },
    } = getState()

    try {
      if (selectedDate) {
        const [{ data: allTimeslot }, { data: availableTimeslot }] =
          await axios.all([
            api.contract.fetchPrices(
              adAccountId,
              productId,
              subType,
              selectedDate
            ),
            api.contract.fetchBookingCount(adAccountId, {
              productId,
              beginDate: selectedDate,
              endDate: selectedDate,
            }),
          ])
        dispatch(setContractPrices(allTimeslot))
        dispatch(setContractBookings(availableTimeslot))
      }
    } catch (e) {
      console.log(e.message)
    }
  }
}

export function fetchPrices(adAccountId, subType) {
  return async (dispatch, getState, api) => {
    const {
      contract: {
        viewState: { selectedDate },
        productInfo: { id: productId },
      },
    } = getState()

    try {
      if (selectedDate) {
        const { data: allTimeslot } = await api.contract.fetchPrices(
          adAccountId,
          productId,
          subType,
          selectedDate
        )
        dispatch(setContractPrices(allTimeslot))
      }
    } catch (e) {
      console.log(e.message)
    }
  }
}
