import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router/immutable'
import rootReducer from '../modules'
import api from '../modules-api'
import { crashReportMiddleware } from './middleware/crashReportMiddleware'
import validateActionMiddleware from './middleware/validateActionMiddleware'

export default function configureStore(initialState, history) {
  return createStore(
    rootReducer(history),
    initialState,
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument(api),
      crashReportMiddleware,
      validateActionMiddleware
    )
  )
}
