import React from 'react'
import { replace } from 'connected-react-router'
import { combineReducers, createReducer } from 'redux-immutablejs'
import cohortList from './cohort/mCohortList'
import cohortDetail from './cohort/mCohortDetail'
import cohortCreate from './cohort/mCohortCreate'
import cohortLoad from './cohort/mCohortLoad'
import { openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import { showErrorMessage } from '../../utils/alertUtils'
import { COHORT_TARGET_DATA_MAX } from '../../utils/target/cohort'
import { keyMirror } from '../../utils/utils'
import { fromJS, List } from 'immutable'
import { COHORT_FORM_VALIDATION_KEY } from '../../validators/targeting/cohortFormValidator'
import { toTargetingCohortPath } from '../../utils/router/routeUtils'
import { ERROR_CODE } from '../../utils/errorCode'
import { handleAdGroupFormExceptionV2 } from '../advertise/adGroupActions/aAdGroupCommonV2'
import { isEmpty } from 'lodash'

const Cohort = keyMirror({
  INIT_COHORT_VALIDATION_ERROR_KEYS: null,
  SET_IS_VALID_COHORT_BY_KEY: null,
})

export const COHORT_TRIM_KEY_PATH_LIST = [['name']]
export const COHORT_MAX_COUNT = 50

const initialState = fromJS({
  validationErrorKeyForCohort: {},
})

const cohortCommon = createReducer(initialState, {
  [Cohort.INIT_COHORT_VALIDATION_ERROR_KEYS]: state =>
    state.set(
      'validationErrorKeyForCohort',
      initialState.get('validationErrorKeyForCohort')
    ),

  [Cohort.SET_IS_VALID_COHORT_BY_KEY]: (state, { data }) => {
    const { validationErrorKeyForCohort } = state
    const { key, isValid, message } = data
    const newErrorKeys = isValid
      ? validationErrorKeyForCohort.delete(key)
      : validationErrorKeyForCohort.set(key, message)
    return state.set('validationErrorKeyForCohort', newErrorKeys)
  },
})

export function initCohortValidationErrorKeys() {
  return {
    type: Cohort.INIT_COHORT_VALIDATION_ERROR_KEYS,
  }
}

export function setIsValidCohortByKey(key, isValid, message) {
  return {
    type: Cohort.SET_IS_VALID_COHORT_BY_KEY,
    data: { key, isValid, message },
  }
}

export function fetchCohortBaseAdsAdGroupInfo({
  adAccountId,
  cohortBaseAdsWithOutAdGroupInfo = List(),
  onSuccess,
}) {
  return async (dispatch, getState, api) => {
    try {
      const adGroupIds = cohortBaseAdsWithOutAdGroupInfo.reduce(
        (prev, { adGroupId }) => prev.concat(adGroupId),
        []
      )
      if (isEmpty(adGroupIds)) {
        dispatch(onSuccess([]))
      } else {
        const { data: adGroupsInfo } = await api.adGroup.fetchAdGroupsInfoByIds(
          adAccountId,
          adGroupIds
        )
        const campaignAdGroupsInfo = cohortBaseAdsWithOutAdGroupInfo.map(
          cohortBaseAd => {
            const {
              adGroupId: cohortBaseAdGroupId,
              cohortBaseAdType,
              firstIndicator,
              indicatorSet,
              operation,
              secondIndicator,
              thirdIndicator,
            } = cohortBaseAd || {}
            const adGroupInfo = adGroupsInfo.find(
              ({ adGroupId }) => cohortBaseAdGroupId === adGroupId
            )
            return {
              cohortBaseAdType,
              firstIndicator,
              indicatorSet,
              operation,
              secondIndicator,
              thirdIndicator,
              ...adGroupInfo,
            }
          }
        )
        dispatch(onSuccess(campaignAdGroupsInfo))
      }
    } catch (e) {
      dispatch(handleAdGroupFormExceptionV2(e))
    }
  }
}

export function handleCohortException(dispatch, e, adAccountId) {
  if (!e || !e.response || !e.response.data) return

  const { errorCode, message } = e.response.data

  switch (Number(errorCode)) {
    case 3002:
    case 3007: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">광고반응타겟 삭제 불가</strong>
            <p className="txt_layer">
              해당 타겟을 사용 중인 광고그룹 또는 오디언스가 있습니다.
              <br />
              타겟 사용현황을 참고하여 모든 광고그룹, 오디언스에서
              <br />
              타겟을 삭제한 후 다시 시도하세요.
            </p>
          </PopupProxy>
        )
      )
      break
    }

    case 3000: {
      showErrorMessage('존재하지 않는 광고반응타겟입니다.')
      dispatch(replace(toTargetingCohortPath(adAccountId)))
      break
    }

    case 3001: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">광고반응타겟 삭제 불가</strong>
            <p className="txt_layer">이미 지워진 내 광고반응 입니다.</p>
          </PopupProxy>
        )
      )
      break
    }

    case 3003: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">광고반응타겟 등록 개수 초과</strong>
            <p className="txt_layer">
              광고반응타겟은 계정당 {COHORT_MAX_COUNT}개까지 등록 가능합니다.
              <br />
              기존 파일을 삭제 후 다시 시도하세요.
            </p>
          </PopupProxy>
        )
      )
      break
    }

    case 3004: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">선택 가능 개수 초과</strong>
            <p className="txt_layer">
              광고그룹은 최대 {COHORT_TARGET_DATA_MAX}
              개까지 선택 가능합니다.
            </p>
          </PopupProxy>
        )
      )
      break
    }

    case 3005: {
      dispatch(
        setIsValidCohortByKey(
          COHORT_FORM_VALIDATION_KEY.COHORT_NAME,
          false,
          '이미 사용 중인 광고반응타겟 이름입니다.'
        )
      )
      break
    }

    case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN: {
      break
    }
    default:
      showErrorMessage(message)
      break
  }
}

export default combineReducers({
  cohortCommon,
  cohortList,
  cohortDetail,
  cohortCreate,
  cohortLoad,
})
