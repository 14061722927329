import React from 'react'
import Gnb from '../../components/Navigation/Gnb/Gnb'
import Footer from '../../components/Footer/Footer'

const CruxAdAccount = React.lazy(() =>
  import('../../components/AdAccount/CruxAdAccount')
)

const AdAccountWrapper = () => {
  return (
    <div id="kakaoWrap" className="layout_fix">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <React.Suspense fallback="">
          <CruxAdAccount />
        </React.Suspense>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

export default AdAccountWrapper
