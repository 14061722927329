import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  REPORT_COMMAND,
  REPORT_QUERY,
} from '../../utils/fetchUtils'

/**
 * 보고서 목록 조회
 */
function fetchReportList(
  adAccountId,
  { pageNumber, pageSize, sortKey, sortOrder }
) {
  return fetchGet(
    `${REPORT_COMMAND}/api/v1/customReport?page=${pageNumber}&size=${pageSize}&sort=${sortKey},${sortOrder}`,
    adAccountId
  )
}

/**
 * 보고서 지표 조회
 */
function searchReport(adAccountId, body, page, size) {
  return fetchPost(
    `${REPORT_QUERY}/report/search?page=${page}&size=${size}`,
    body,
    adAccountId
  )
}

/**
 * 보고서 생성
 */
function createReport(adAccountId, body) {
  return fetchPost(`${REPORT_COMMAND}/api/v1/customReport`, body, adAccountId)
}

/**
 * 보고서 단건 조회
 */
function fetchReportItem(adAccountId, id, cancelToken) {
  return fetchGet(
    `${REPORT_COMMAND}/api/v1/customReport/${id}`,
    adAccountId,
    cancelToken
  )
}

/**
 * 보고서 수정
 */
function modifyReport(adAccountId, id, body) {
  return fetchPut(
    `${REPORT_COMMAND}/api/v1/customReport/${id}`,
    body,
    adAccountId
  )
}

/**
 * 보고서 삭제
 */
function deleteReport(adAccountId, reportIds) {
  return fetchDelete(
    `${REPORT_COMMAND}/api/v1/customReport?customReportIds=${reportIds.join(
      ','
    )}`,
    undefined,
    adAccountId
  )
}

/**
 * 보고서 이름 수정
 */
function modifyReportName(adAccountId, id, name) {
  return fetchPut(
    `${REPORT_COMMAND}/api/v1/customReport/${id}/name`,
    {
      serviceAccountId: adAccountId,
      id,
      name,
    },
    adAccountId
  )
}

/**
 *  10.7 보고서 다운로드
 */
function downloadReport(adAccountId, body, cancelToken) {
  return fetchPost(
    `${REPORT_COMMAND}/reportDownloadHistory`,
    body,
    adAccountId,
    cancelToken
  )
}

/**
 *  10.8 보고서 다운로드 폴링
 */
function pollingDownloadReport(id, adAccountId, cancelToken) {
  return fetchGet(
    `${REPORT_COMMAND}/reportDownloadHistory/${id}`,
    adAccountId,
    cancelToken
  )
}

export default {
  downloadReport,
  pollingDownloadReport,
  searchReport,
  createReport,
  fetchReportItem,
  modifyReport,
  fetchReportList,
  deleteReport,
  modifyReportName,
}
