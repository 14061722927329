import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import { checkEmpty, checkNotEmpty, isUndefinedOrNull } from '../regexUtils'
import { List } from 'immutable'
import AdGroupConstraints from './constraints-adGroup'

const CampaignConstraints = {
  // 전환 추적
  isConversionRequired({
    campaignType,
    goal,
    trackConstraints,
    objectiveType,
  }) {
    if (
      (campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED &&
        goal === GoalEnum.Type.REACH) ||
      (campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
        goal === GoalEnum.Type.CONVERSION) ||
      (campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE &&
        goal === GoalEnum.Type.REACH)
    ) {
      return true
    } else {
      if (checkNotEmpty(trackConstraints)) {
        const isObjectiveRequired = AdGroupConstraints.isObjectiveRequired({
          campaignType,
          goal,
        })

        const { required = false, available = false } =
          trackConstraints
            .filter(v =>
              isObjectiveRequired
                ? v.get('objectiveType') === objectiveType
                : true
            )
            .first() || {}

        return required || available
      }

      return false
    }
  },

  isConversionDefaultRequired({ campaignType, goal }) {
    switch (campaignType) {
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE:
      case CampaignTypeEnum.Type.TALK_CHANNEL: {
        return false
      }

      case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
        return !(
          goal === GoalEnum.Type.VISITING || goal === GoalEnum.Type.REACH
        )
      }

      default: {
        return true
      }
    }
  },

  // 광고목표 설정
  isAvailableObjectiveTypes({ constraint, objective, campaignType }) {
    if (
      campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG ||
      campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE
    ) {
      // 고정값
      const { type: objectiveType } = objective || {}
      return Array.of(objectiveType)
    }

    if (checkEmpty(constraint) || !constraint.size) return List()

    return constraint.groupBy(v => v.get('objectiveType')).keySeq()
  },

  // 일예산
  isAvailableDailyBudget({ campaignType, goal, constraint }) {
    if (
      campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      goal === GoalEnum.Type.CONVERSION
    ) {
      return true
    } else {
      if (checkNotEmpty(constraint)) {
        return Object.keys(constraint.toJS()).every(
          k => !isUndefinedOrNull(constraint.get(k))
        )
      }

      return false
    }
  },

  /**
   * 전환추적 선택시 detailType 초기화 여부
   */
  isObjectiveDetailTypeClearRequired({ campaignType }) {
    return (
      campaignType !== CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL &&
      campaignType !== CampaignTypeEnum.Type.PERSONAL_MESSAGE
    )
  },
}

export default CampaignConstraints
