import './assets/css/rc-slider.css'
import './styles.css'
import './utils/chromeTranslateResolver'
import 'animate.css/animate.compat.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { Provider } from 'react-redux'
import { Map } from 'immutable'
import configureStore from './stores/configureStore'
import { createBrowserHistory } from 'history'
import App from './App'
import ErrorBoundary from './components/Common/ErrorBoundary/ErrorBoundary'
import * as Sentry from '@sentry/browser'
import { getKakaoSentryURL } from './utils/app/services/kakaoSentry'
import SentryUtils from './utils/sentryUtils'
import ReactGA from 'react-ga4'
import { QueryClient, QueryClientProvider } from 'react-query'

const initialState = Map()
const history = createBrowserHistory()
const store = configureStore(initialState, history)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const sentryUrl = getKakaoSentryURL()
if (sentryUrl) {
  Sentry.init({
    dsn: sentryUrl,
    whitelistUrls: SentryUtils.options.whitelistUrls,
    ignoreErrors: SentryUtils.options.ignoreErrors,
  })
}

if (__PRODUCTION__ && ReactGA) {
  ReactGA.initialize('G-WQ4QQSMTEV')
  history.listen(location =>
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  )
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
)
