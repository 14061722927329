import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  BIZ_BOARD_BADGE_TYPE,
  BIZ_BOARD_BANNER_TYPE,
  BIZ_BOARD_IMAGE_TYPE,
  BIZ_BOARD_MASK_TYPE,
} from '../bizBoardUtils'
import BizBoardBannerImageMaskSelect from './BizBoardBannerImageMaskSelect'
import BizBoardBannerImageResizer from './BizBoardBannerImageResizer'

const BizBoardBannerImageControl = ({
  index,
  bannerType,
  maskType,
  imageType,
  imageCount,
  availableMaskOptions = [],
  active,
  customEventHandlerRef,
  handleTemplateChange,
  onMaskTypeChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(true)

  const maskOptions = availableMaskOptions.filter(
    v =>
      v.bannerType === bannerType &&
      (v.imageCount ? v.imageCount === imageCount : true)
  )

  const isAvailableImageMaskSelect = React.useMemo(
    () =>
      ((bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && imageCount === 1) ||
        bannerType === BIZ_BOARD_BANNER_TYPE.MASKING) &&
      index === 0,
    [bannerType, imageCount, index]
  )

  return (
    <div
      className={cx('box_imgedit', { fold_on: isOpen })}
      style={{ display: 'block' }}>
      <strong className="tit_bnrimg">
        {imageType === BIZ_BOARD_IMAGE_TYPE.LOGO ? '로고' : '이미지'}
        {bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && imageCount > 1
          ? index + 1
          : undefined}{' '}
        편집
      </strong>
      <a
        className="btn_more"
        onClick={e => {
          e.preventDefault()
          setIsOpen(prev => !prev)
        }}>
        <span className="ico_comm ico_more">내용 더보기</span>
      </a>
      <div
        className={cx('list_imgedit', {
          list_masking: bannerType === BIZ_BOARD_BANNER_TYPE.MASKING,
        })}>
        {isAvailableImageMaskSelect && (
          <BizBoardBannerImageMaskSelect
            maskOptions={maskOptions}
            maskType={maskType}
            handleMaskTypeChange={onMaskTypeChange}
          />
        )}
        <BizBoardBannerImageResizer
          index={index}
          active={active}
          customEventHandlerRef={customEventHandlerRef}
          handleTemplateChange={handleTemplateChange}
        />
      </div>
    </div>
  )
}

BizBoardBannerImageControl.propTypes = {
  index: PropTypes.number,
  badgeType: PropTypes.oneOf(Object.keys(BIZ_BOARD_BADGE_TYPE)),
  bannerType: PropTypes.oneOf(Object.keys(BIZ_BOARD_BANNER_TYPE)),
  maskType: PropTypes.oneOf(Object.keys(BIZ_BOARD_MASK_TYPE)),
  imageType: PropTypes.oneOf(Object.keys(BIZ_BOARD_IMAGE_TYPE)),
  imageCount: PropTypes.number,
  availableMaskOptions: PropTypes.array,
  active: PropTypes.bool,
  customEventHandlerRef: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
  onMaskTypeChange: PropTypes.func,
}

export default BizBoardBannerImageControl
