import {
  CONTEXTPATH,
  CRUX_ACCOUNT,
  fetchGet,
  fetchGetNoToken,
  fetchPost,
} from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'
import CruxFetchUtils from '../../utils/cruxFetchUtils'

export const UserAPI = {
  /**
   * DSP 로그인
   */
  advertiserLogin(header) {
    return fetchGetNoToken('/login/moment', header)
  },

  /**
   * 99.03 kakaoAccountId로 dspAccount 상세 조회
   * 내 DSP 계정 정보 조회
   */
  fetchMyDspAccount() {
    return CruxFetchUtils.get({
      url: `${CRUX_ACCOUNT}/dspAccount`,
    })
  },

  /**
   * 03.21 dspAccount 카카오페이 사용 동의(paw 1.7)
   * 카카오페이 결제 사용 동의
   * todo 사용 확인
   */
  fetchKakaoPayServiceAgreement(dspAccountId) {
    return CruxFetchUtils.put({
      url: `${CRUX_ACCOUNT}/dspAccounts/${dspAccountId}/kakaoPayAgreement`,
    })
  },

  /**
   * 광고계정 초대 목록 조회
   */
  getDspAccountInviteList() {
    return CruxFetchUtils.get({
      url: `${CRUX_ACCOUNT}/adAccountMembers:Page?adAccountMemberStatuses=PENDING_INVITE&adPlatformTypes=KAKAOMOMENT`,
    })
  },

  /**
   * 01.04 점유 인증- 탈퇴용 핀 번호 전송 for Agency
   * 점유인증 번호 발송(탈퇴)
   */
  publishPinNumberForWithdrawal() {
    return CruxFetchUtils.post({
      url: `${CRUX_ACCOUNT}/publishPinNumberForWithdrawal`,
    })
  },

  /**
   * 01.05 점유 인증 - 탈퇴용 핀 번호 검증 for Agency
   * 점유인증 번호 검증(탈퇴)
   *
   */
  verifyPinNumberForWithdrawal(pin) {
    return CruxFetchUtils.post({
      url: `${CRUX_ACCOUNT}/verifyPinNumberForWithdrawal`,
      requestPayload: { pinNumber: pin },
    })
  },

  /**
   * 모먼트 탈퇴 가능 여부 체크
   */
  checkWithdrawalAccount(dspAccountId) {
    return CruxFetchUtils.get({
      url: `${CRUX_ACCOUNT}/dspAccounts/${dspAccountId}/withdrawal/check`,
    })
  },

  /**
   * 03.06 dspAccount 탈퇴
   * 모먼트 탈퇴
   */
  withdrawalAccount(dspAccountId) {
    return CruxFetchUtils.post({
      url: `${CRUX_ACCOUNT}/dspAccounts/${dspAccountId}/withdrawal`,
    })
  },

  otpLogin(accountId, otp, serviceType, adAccountId) {
    return fetchGetNoToken(
      `/login/otp?accountId=${accountId}&otp=${otp}&serviceType=${serviceType}&adAccountId=${adAccountId}`
    )
  },

  /**
   * DSP 계정 Preference 가져오기.
   * { dspAccounts: 0, preferenceData: '' }
   */
  getDspAccountPreference(dspAccountId) {
    return fetchGet(`${CONTEXTPATH}/dspAccounts/${dspAccountId}/preference`)
  },

  /**
   * DSP 광고계정 Preference 저장.
   */
  updateDspAccountPreference(dspAccountId, body) {
    return fetchPost(
      `${CONTEXTPATH}/dspAccounts/${dspAccountId}/preference`,
      body
    )
  },

  /**
   * 1.3 카카오톡 채널 프로필 리스트 조회
   */
  getPlusFriendProfileList() {
    return fetchGet(`${CONTEXTPATH}/dspAccounts/plusFriend/profiles`)
  },

  /**
   * 1.3.1 카카오톡 채널 프로필 조회
   */
  getPlusFriendProfile(adAccountId, id, cancelToken) {
    return fetchGet(
      `${CONTEXTPATH}/dspAccounts/plusFriend/profiles/${id}`,
      adAccountId,
      cancelToken
    )
  },

  /**
   * 03.53 dspAccount 모든 광고계정의 알림 설정 page 조회. (03.51 대체)
   */
  getAdAccountNotificationSettings(dspAccountId, queryParams) {
    return CruxFetchUtils.get({
      url: `${CRUX_ACCOUNT}/dspAccounts/${dspAccountId}/notificationSettings:Page?adPlatformTypes=KAKAOMOMENT&${queryString(
        queryParams
      )}`,
    })
  },

  getTalkChannelPost(profileId, postId) {
    return fetchGet(
      `${CONTEXTPATH}/dspAccounts/channel/${profileId}/posts/${postId}`
    )
  },

  /**
   * sort === lastSort 인 item 의 next item 부터 limit 만큼 GET
   * @param profileId
   * @param lastSort
   * @param limit
   */
  getTalkChannelPosts(profileId, lastSort = undefined, limit = 20) {
    return fetchGet(
      `${CONTEXTPATH}/dspAccounts/channel/${profileId}/posts?${queryString({
        lastSort,
        limit,
      })}`
    )
  },

  /**
   *
   * 03.52 dspAccount 복수 광고계정의 알림 설정 등록
   * body: {
   *  kakaoTalk: true,
   *  sms: true,
   *  balance: true,
   *  budget: true,
   *  review: true,
   *  adminStop: true,
   *  operation: true,
   *  report: true,
   *  cash: true,
   *  autoPayment: true,
   *  midnight: true
   * }
   */
  modifyAdAccountNotificationSetting(
    dspAccountId,
    adAccountIds,
    notificationSetting
  ) {
    return CruxFetchUtils.put({
      url: `${CRUX_ACCOUNT}/dspAccounts/${dspAccountId}/adAccounts/${encodeURIComponent(
        adAccountIds
      )}/notificationSettings`,
      requestPayload: notificationSetting,
    })
  },
}

export default {
  ...UserAPI,
}
