import React from 'react'
import PropTypes from 'prop-types'
import CreativeLibraryImageView from './CreativeLibraryImageView'
import OptionSelectList from '../../../../Common/OptionSelectList/OptionSelectList'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MomentLoader } from '../../../../Common/Loader/MomentLoader'
import TablePaging from '../../../../Common/TablePaging/TablePaging'
import {
  fetchCreativeLibraryRecentData,
  setCreativeLibraryRecentSearchOption,
} from '../../../../../modules/advertise/mCreativeLibrary'
import { cancelRequest } from '../../../../../utils/cancellation/cancellation'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import { getCalculatedMinHeightByMinWidthAndRatio } from '../../../../../utils/advertise/creativeImage'
import {
  checkNotEmpty,
  isUndefinedOrNull,
} from '../../../../../utils/regexUtils'
import { calculateAspectRatio } from '../../../../../utils/mathUtils'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import { DEFAULT_PAGE_SIZE_ARRAY } from '../../../../Common/Pagenation/Pagenation'

const CANCEL_KEY_FETCH_RECENT_DATA = 'fetchCreativeLibraryRecentData'

const selector = ({
  creativeV2: {
    creativeLibrary: {
      recent: { searchOptions, recentData },
      selectedItems,
      viewState: { isFetchingRecent },
    },
  },
}) => {
  return {
    searchOptions,
    recentData,
    selectedItems,
    isFetchingRecent,
  }
}

const createImageSizeLabel = ({ width, height, minWidth, ratio }) => {
  const hasSpecificSize = width > 0 && height > 0
  const hasRatio = ratio > 0

  if (hasSpecificSize) {
    return [width, height].join('x')
  } else if (hasRatio) {
    const calculatedMinHeight = getCalculatedMinHeightByMinWidthAndRatio({
      minWidth,
      ratio,
    })
    return `${
      calculateAspectRatio(minWidth, calculatedMinHeight).aspect
    } 비율 (최소 ${[minWidth, calculatedMinHeight].join('x')})`
  } else {
    return ''
  }
}

const createImageSizeOptionItems = imageSizes => {
  const initialOptions = [
    { id: 'ALL', label: '사이즈 전체', value: imageSizes },
  ]

  return (
    imageSizes
      ?.sort((a, b) => {
        const w1 = a.width
        const w2 = b.width
        const h1 = a.height
        const h2 = b.height
        return w1 > w2 ? 1 : w1 < w2 ? -1 : h1 > h2 ? 1 : h1 < h2 ? -1 : 0
      })
      ?.reduce((prev, imageSize, index) => {
        const { width, height, minWidth, ratio } = imageSize
        const label = createImageSizeLabel({
          width,
          height,
          minWidth,
          ratio,
        })

        return checkNotEmpty(label)
          ? prev.concat({ id: index, label, value: [imageSize] })
          : undefined
      }, initialOptions)
      ?.filter(Boolean) || initialOptions
  )
}
/**
 * @deprecated
 */
const CreativeLibraryRecent = ({
  adAccountId,
  multiSelect,
  campaignType,
  creativeFormat,
  creativeUploadAssetPropertyType,
  imageSizes = [],
  renderGuideView,
  handleImageCropperOpen,
}) => {
  const dispatch = useDispatch()

  const {
    searchOptions: { imageSizeOptionId },
    recentData,
    selectedItems,
    isFetchingRecent,
  } = useSelector(selector, shallowEqual)

  const imageSizeOptionItems = React.useMemo(
    () => createImageSizeOptionItems(imageSizes),
    [imageSizes]
  )

  const [imageSizeOption, setImageSizeOption] = React.useState(
    imageSizeOptionId
      ? imageSizeOptionItems.find(v => v.id === imageSizeOptionId)
      : imageSizeOptionItems[0]
  )

  const { content: items, number, size, totalElements } = recentData || {}

  const fetchRecent = React.useCallback(
    (page = 0, size = 50, imageSizeOption = imageSizeOption) => {
      const isImageSizeOptionValid = imageSizeOption.value?.length > 0

      /**
       * assetPropTypes 와 imageSizes 는 상호 베타적으로 요청된다.
       */
      dispatch(
        fetchCreativeLibraryRecentData(
          adAccountId,
          {
            assetPropertyTypes:
              isImageSizeOptionValid ||
              isUndefinedOrNull(creativeUploadAssetPropertyType)
                ? undefined
                : [creativeUploadAssetPropertyType],
            imageSizes: isImageSizeOptionValid
              ? imageSizeOption.value
              : undefined,
          },
          {
            page,
            size,
          },
          CANCEL_KEY_FETCH_RECENT_DATA
        )
      )
    },
    [adAccountId, creativeUploadAssetPropertyType, dispatch]
  )

  React.useEffect(() => {
    // empty 일 떄만 로드하도록 한다(탭 변경 시 유지되어야 한다).
    if (items.isEmpty()) {
      fetchRecent(undefined, undefined, imageSizeOption)
    }

    return () => {
      cancelRequest(CANCEL_KEY_FETCH_RECENT_DATA)
    }
  }, [])

  const handleImageSizeOptionChange = React.useCallback(
    item => {
      setImageSizeOption(item)

      dispatch(
        setCreativeLibraryRecentSearchOption(['imageSizeOptionId'], item.id)
      )

      // 사이즈 옵션 변경 시 pageIndex, pageSize 초기화.
      fetchRecent(undefined, undefined, item)
    },
    [dispatch, fetchRecent]
  )

  const handlePageChange = React.useCallback(
    ({ currentIndex }) => {
      if (currentIndex !== number) {
        fetchRecent(currentIndex, size, imageSizeOption)
      }
    },
    [fetchRecent, imageSizeOption, number, size]
  )

  const handlePageSizeChange = React.useCallback(
    pageSize => {
      if (pageSize !== size) {
        fetchRecent(0, pageSize, imageSizeOption)
      }
    },
    [fetchRecent, imageSizeOption, size]
  )

  const imageSizeOptionIndex = React.useMemo(
    () =>
      imageSizeOptionItems.findIndex(item => item.id === imageSizeOption.id),
    [imageSizeOptionItems, imageSizeOption.id]
  )

  const hasSizeOption = React.useMemo(
    () =>
      creativeUploadAssetPropertyType ===
        CreativeUploadAssetPropertyEnum.Type.IMAGE && imageSizes?.length > 1,
    [creativeUploadAssetPropertyType, imageSizes]
  )

  const isBizBoardBanner =
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

  return (
    <div className="wrap_material">
      <div className="group_info">
        {typeof renderGuideView === 'function' ? renderGuideView() : null}
        {hasSizeOption && (
          <div className="group_option">
            <OptionSelectList
              items={imageSizeOptionItems}
              defaultIndex={imageSizeOptionIndex}
              onChange={handleImageSizeOptionChange}
              hasScroll={true}
            />
          </div>
        )}
      </div>
      <div className="group_material">
        {isFetchingRecent ? (
          <div className="reform_box_nodata">
            <div className="txt_nodata">
              <MomentLoader />
            </div>
          </div>
        ) : totalElements > 0 ? (
          <div className="inner_material">
            <ul className="list_material">
              {items.map((item, i) => {
                const { imageUUID } = item
                const selectedOrder =
                  selectedItems.findIndex(
                    v => v.get('imageUUID') === imageUUID
                  ) + 1

                return (
                  <CreativeLibraryImageView
                    key={imageUUID}
                    index={i}
                    selectedOrder={selectedOrder}
                    item={item}
                    isVisibleAnim={false}
                    multiSelect={multiSelect}
                    isBizBoardBanner={isBizBoardBanner}
                    creativeUploadAssetPropertyType={
                      creativeUploadAssetPropertyType
                    }
                    creativeFormat={creativeFormat}
                    campaignType={campaignType}
                    handleImageCropperOpen={handleImageCropperOpen}
                  />
                )
              })}
            </ul>
          </div>
        ) : (
          <div className="reform_box_nodata">
            <div className="txt_nodata">최근 사용한 이미지가 없습니다.</div>
          </div>
        )}
      </div>
      {!isFetchingRecent && totalElements > 0 && (
        <TablePaging
          defaultIndex={number}
          itemSize={totalElements}
          pageSize={size}
          buttonSize={10}
          hasPageSizeSelect={true}
          pageItems={DEFAULT_PAGE_SIZE_ARRAY}
          listener={handlePageChange}
          onChangeNumberOfPages={handlePageSizeChange}
        />
      )}
    </div>
  )
}

CreativeLibraryRecent.propTypes = {
  adAccountId: PropTypes.any,
  multiSelect: PropTypes.bool,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()),
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  imageSizes: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      minWidth: PropTypes.number,
      ratio: PropTypes.number,
    })
  ),
  renderGuideView: PropTypes.func,
  handleImageCropperOpen: PropTypes.func,
}

export default CreativeLibraryRecent
