import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LnbMenu from './LnbMenu'
import { debounce } from 'lodash'
import LnbTop from './LnbTop'
import { toggleLnbExpander } from '../../../modules/lnb/mLnb'
import PropTypes from 'prop-types'
import { useLocation, useParams } from 'react-router-dom'
import { usePrevious } from '../../../utils/hook/usePrevious'
import { LNB_MENU } from './LnbMenuUtils'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import sessionStorage, {
  SESSION_KEY_OBSERVER_SERVICE_TYPE,
} from '../../../utils/storage/momentSessionStorage'
import AdAccountExtraEnum from '../../../enums/AdAccountExtraEnum'

const selector = ({
  lnb: {
    isExpanded,
    adAccountInfo: { extras },
  },
  adAccountListSelector: {
    adAccountCashInfo: { deferredPayment },
  },
  adConstraints: { campaignTypes },
}) => {
  return {
    isLnbExpanded: isExpanded,
    deferredPayment,
    campaignTypes,
    extras,
  }
}

const Lnb = ({ state: injectedLnbState }) => {
  const dispatch = useDispatch()

  const adAccountId = useParams().adaccountid
  const prevAdAccountId = usePrevious(adAccountId)

  const location = useLocation()

  const { isLnbExpanded, deferredPayment, campaignTypes, extras } = useSelector(
    selector,
    shallowEqual
  )

  const isExpanded = injectedLnbState?.isExpanded ?? isLnbExpanded

  const observerMode =
    sessionStorage.getItem(SESSION_KEY_OBSERVER_SERVICE_TYPE) === 'ADMIN'

  const rootRef = React.useRef()
  const menuListNodeRef = React.useRef()
  const expanderNodeRef = React.useRef()
  const resize = React.useCallback(() => {
    if (rootRef.current && menuListNodeRef.current && expanderNodeRef.current) {
      const height =
        rootRef.current.offsetHeight -
        expanderNodeRef.current.offsetHeight -
        menuListNodeRef.current.offsetTop

      menuListNodeRef.current.style.height = `${height}px`
    }
  }, [])

  React.useLayoutEffect(() => {
    if (isExpanded) {
      Lnb.open()
    } else {
      Lnb.close()
    }
    resize()
  }, [isExpanded, location, resize])

  React.useLayoutEffect(() => {
    const debouncedResize = debounce(resize, 500)

    window.addEventListener('resize', debouncedResize)

    resize()

    return () => {
      window.removeEventListener('resize', debouncedResize)
    }
  }, [resize])

  const toggleExpander = React.useCallback(() => {
    if (typeof injectedLnbState?.toggleExpander === 'function') {
      injectedLnbState.toggleExpander()
    } else {
      dispatch(toggleLnbExpander())
    }
  }, [dispatch, injectedLnbState])

  const collapse = React.useCallback(() => {
    Lnb.collapse()

    resize()
  }, [resize])

  const onMouseEnter = React.useCallback(() => {
    if (!isExpanded) {
      Lnb.expand()

      resize()
    }
  }, [isExpanded, resize])

  const onMouseLeave = React.useCallback(() => {
    collapse()
  }, [collapse])

  React.useEffect(() => {
    if (prevAdAccountId !== adAccountId) {
      collapse()
    }
  }, [adAccountId, collapse, prevAdAccountId])

  const isMenuVisible = React.useCallback(
    ({ menu }) => {
      if (menu === LNB_MENU.PAYMENT_CARD) {
        // 후불계정의 경우 결제카드 메뉴 노출 X
        return !deferredPayment
      } else if (menu === LNB_MENU.CATALOG) {
        return (
          campaignTypes.contains(CampaignTypeEnum.Type.PRODUCT_CATALOG) ||
          observerMode
        )
      } else if (menu === LNB_MENU.MESSAGE) {
        return !extras?.has(AdAccountExtraEnum.Type.MESSAGE_AD_SELLER)
      }
      return true
    },
    [deferredPayment, campaignTypes, observerMode, extras]
  )

  return (
    <div ref={rootRef} id="mFeature" role="navigation">
      <div className="feature_wrap">
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <LnbTop />
          <LnbMenu
            ref={menuListNodeRef}
            onExpand={resize}
            isMenuVisible={isMenuVisible}
          />
        </div>
      </div>
      <div ref={expanderNodeRef} className="fold_btn">
        <button type="button" className="btn_opcl" onClick={toggleExpander}>
          <span className="ico_gnb">펼침/닫힘</span>
        </button>
      </div>
    </div>
  )
}

Lnb.propTypes = {
  state: PropTypes.shape({
    isExpanded: PropTypes.bool.isRequired,
    toggleExpander: PropTypes.func.isRequired,
  }),
}

/**
 * 필침 고정
 */
Lnb.open = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    kakaoWrapNode.classList.add('lnb_on')
  }
}

/**
 * 닫힘 고정
 */
Lnb.close = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    kakaoWrapNode.classList.remove('lnb_on')
  }
}

/**
 * 펼침 비고정
 */
Lnb.expand = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    kakaoWrapNode.classList.add('lnb_over')
  }
}

/**
 * 펼침 비고정 해제
 */
Lnb.collapse = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    kakaoWrapNode.classList.remove('lnb_over')
  }
}

export default Lnb
