import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import deepEqual from 'deep-equal'
import { isIntegerSequence } from '../../../utils/regexUtils'
import FloatingViewHOC from '../HOC/FloatingViewHOC'
import { convertStringToDOMArray } from '../../../utils/stringUtils'

/***
 * OptionSelectList
 *
 * params:
 *  items {arrayOf({ label: string, link: any})
 *  iconClassName
 *    <a className="link_selected">
 *        <span className={iconClassName}/>
 *    </a>
 *  accessibilityLabel {string}
 *  onChange {callback function(item=Object)}
 */

class OptionSelectList extends React.PureComponent {
  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  state = {
    active: this.props.active,
    selectedItemIndex: this.props.defaultIndex || 0,
    items: this.props.items,
    focusedItemDescription:
      this.props.items[this.props.defaultIndex || 0]?.description || '',
    isVisible: this.props.isVisible,
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isVisible !== prevProps.isVisible &&
      this.props.isVisible &&
      this.state.selectedItemIndex === 0
    ) {
      this.ref.current.scrollTo(0, 0)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}

    const a1 = !deepEqual(prevState.items, nextProps.items)
    const a2 =
      prevState.selectedItemIndex === -1 &&
      isIntegerSequence(nextProps.defaultIndex)
    const a3 =
      prevState.selectedItemIndex !== nextProps.defaultIndex &&
      isIntegerSequence(nextProps.defaultIndex)

    if (a1 || a2 || a3) {
      nextState['selectedItemIndex'] =
        nextProps.defaultIndex >= 0
          ? nextProps.defaultIndex
          : prevState.selectedItemIndex
    }

    if (prevState.active !== nextProps.active) {
      nextState['active'] = nextProps.active
    }

    if (prevState.isVisible !== nextProps.isVisible) {
      nextState['isVisible'] = nextProps.isVisible

      if (nextProps.isShowDescription) {
        // 설명을 보여줄 필요가 없는 경우에는 무시
        if (!prevState.isVisible && prevState.selectedItemIndex === 0) {
          nextState['focusedItemDescription'] = nextProps.items[0].description
        } else if (!prevState.isVisible && !a1) {
          nextState['focusedItemDescription'] =
            nextProps.items[prevState.selectedItemIndex]?.description
        }
      }
    }

    return Object.keys(nextState).length > 0 ? nextState : null
  }

  render() {
    const {
      isVisible,
      items,
      iconClassName,
      accessibilityLabel,
      className,
      labelPrefix,
      labelSuffix,
      hasScroll,
      hideInputWhenInactive,
      hideLabel,
      defaultText,
      hasImageItem,
      hasSubItem,
      error,
      subTitle,
      labelRenderer,
      subLabelRenderer,
    } = this.props

    const selectedItem =
      items?.length > this.state.selectedItemIndex
        ? items[this.state.selectedItemIndex]
        : null

    const selectedItemLabel = selectedItem
      ? `${labelPrefix}${selectedItem.label}${labelSuffix}`
      : defaultText || null

    const hideInputValue =
      (hideInputWhenInactive && !this.state.active) || hideLabel

    return (
      <div
        className={cx(
          'opt_select',
          {
            opt_open: isVisible,
            in_active: !this.state.active,
            reform_select_img: hasImageItem,
            in_error: error,
          },
          className
        )}
        onClick={e => e.stopPropagation()}>
        <div className="screen_out">{accessibilityLabel}</div>
        <span className="screen_out">선택내용 : </span>
        <a
          className={cx('link_selected', {
            reform_link_default: defaultText,
          })}>
          {iconClassName && <span className={cx(iconClassName)} />}
          {hasImageItem && selectedItem?.imageUrl && (
            <span className="reform_opt_img">
              <img src={selectedItem.imageUrl} alt={selectedItemLabel} />
            </span>
          )}
          {(!hideInputValue && selectedItemLabel) || ''}
        </a>
        <span className="ico_arr" />
        <div className="screen_out">선택옵션</div>
        <div
          className={cx('opt_list', {
            opt_scroll: hasScroll,
            reform_opt_list: hasSubItem,
            reform_img_opt: hasImageItem,
          })}>
          {subTitle && (
            <span className="reform_subtit_opt">
              {subTitle}
              &nbsp;<em className="fc_emph">{items?.length || 0}</em>
            </span>
          )}
          {this.state.focusedItemDescription && (
            <div className="opt_guide">
              {convertStringToDOMArray(this.state.focusedItemDescription)}
            </div>
          )}
          <ul className="list_opt" ref={this.ref}>
            {items.map((item, i) => {
              const { label, link, imageUrl, description, inActive } = item
              const Element = link ? 'Link' : 'a'

              return (
                <li
                  key={i}
                  onMouseEnter={() =>
                    this.setState({ focusedItemDescription: description })
                  }
                  className={cx({
                    on: i === this.state.selectedItemIndex,
                    in_active: inActive,
                  })}
                  style={{ ...(inActive && { pointerEvents: 'none' }) }}>
                  {hasImageItem && imageUrl && (
                    <span className="reform_opt_img">
                      <img src={imageUrl} alt={label} />
                    </span>
                  )}
                  {React.isValidElement(subLabelRenderer)
                    ? React.cloneElement(subLabelRenderer, { item })
                    : typeof subLabelRenderer === 'function'
                    ? subLabelRenderer(item)
                    : subLabelRenderer}
                  <Element
                    to={link}
                    className="link_option in_active"
                    onClick={
                      !inActive
                        ? this.updateSelectedItemIndex(i, item)
                        : undefined
                    }>
                    {React.isValidElement(labelRenderer)
                      ? React.cloneElement(labelRenderer, { item })
                      : typeof labelRenderer === 'function'
                      ? labelRenderer(item)
                      : label}
                  </Element>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  updateSelectedItemIndex = (index, item) => () => {
    this.setState({ selectedItemIndex: index }, () => {
      this.props.onChange(item, index)

      if (this.props.autoClose) {
        this.props.dismiss()
      }
    })
  }
}

OptionSelectList.defaultProps = {
  items: [{ label: '선택상자' }],
  iconClassName: undefined,
  accessibilityLabel: '데이터종류 선택상자',
  defaultIndex: undefined,
  onChange: () => {},
  active: true,
  labelPrefix: '',
  labelSuffix: '',
  subTitle: '',
  hasScroll: false,
  hideInputWhenInactive: false,
  hideLabel: false,
  autoClose: true,
  defaultText: '',
  hasImageItem: false,
  hasSubItem: false,
  error: false,
  labelRenderer: undefined,
  subLabelRenderer: undefined,
  isShowDescription: true,
}

OptionSelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  accessibilityLabel: PropTypes.string,
  defaultIndex: PropTypes.any,
  active: PropTypes.bool,
  labelPrefix: PropTypes.string,
  labelSuffix: PropTypes.string,
  hasScroll: PropTypes.bool,
  handleClick: PropTypes.func,
  hideInputWhenInactive: PropTypes.bool,
  hideLabel: PropTypes.bool,
  autoClose: PropTypes.bool,
  hasImageItem: PropTypes.bool,
  hasSubItem: PropTypes.bool,
  onChange: PropTypes.func,
  defaultText: PropTypes.string,
  subTitle: PropTypes.string,
  labelRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  subLabelRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isShowDescription: PropTypes.bool,
  // hoc props
  isVisible: PropTypes.bool,
  error: PropTypes.bool,
  dismiss: PropTypes.func,
}

export default FloatingViewHOC(OptionSelectList)
