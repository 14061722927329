import {
  isNoneUndefinedOrNull,
  isUndefinedOrNull,
} from '../../utils/regexUtils'

/**
 *  대시보드 cell, 차트 영역에 %표기가 필요한 metric 관리
 */
const DASHBOARD_CVR_SUFFIX_AVAILABLE_METRICS = [
  'ctr',
  'conv_cmpt_reg_1d_rate',
  'conv_cmpt_reg_7d_rate',
  'conv_view_cart_7d_rate',
  'conv_view_cart_1d_rate',
  'conv_view_cart_7d_rate',
  'conv_purchase_1d_rate',
  'conv_purchase_7d_rate',
  'conv_purchase_p_per_cost_1d',
  'conv_purchase_p_per_cost_7d',
  'conv_estimated_purchase_p_per_cost_1d',
  'conv_estimated_purchase_p_per_cost_7d',
  'conv_participation_1d_rate',
  'conv_participation_7d_rate',
  'conv_signup_1d_rate',
  'conv_signup_7d_rate',
  'conv_app_install_1d_rate',
  'conv_app_install_7d_rate',
  'conv_kf_pf_add_1d_rate',
  'conv_kf_pf_add_7d_rate',
  'msg_open_rate',
  'msg_click_rate',
  'vtr',
]

const DASHBOARD_NODE_ID = {
  HEADER: 'DashboardHeader',
}

const DashboardUtils = {
  isSummarySelected({ dashboardType, id, prevDashboardType, prevId }) {
    const isDashboardTypeValid = isNoneUndefinedOrNull(
      dashboardType,
      prevDashboardType
    )
    const isDashboardTypeSame = dashboardType === prevDashboardType
    const isIdSame = id > 0 && prevId > 0 && Number(id) === Number(prevId)
    const isIdEmpty = isUndefinedOrNull(id) && isUndefinedOrNull(prevId)

    return (
      isDashboardTypeValid && isDashboardTypeSame && (isIdSame || isIdEmpty)
    )
  },
}

export {
  DashboardUtils,
  DASHBOARD_NODE_ID,
  DASHBOARD_CVR_SUFFIX_AVAILABLE_METRICS,
}
