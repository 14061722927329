import { combineReducers } from 'redux-immutablejs'
import { connectRouter } from 'connected-react-router/immutable'
import user from './common/mUser'
import popup from './common/mPopup'
import popupV2 from './common/mPopupV2'
import notification from './common/mNotification'
import role from './common/mRole'
import advertiseV2 from './advertise/mAdvertiseV2'
import campaignV2 from './advertise/mCampaignV2'
import adGroupV2 from './advertise/mAdGroupV2'
import contract from './advertise/mContract'
import adAccount from './adaccount/mAdAccount'
import adAccountListSelector from './common/mAdAccountListSelector'
import cohort from './targeting/mCohort'
import targeting from './targeting/mTargeting'
import customerFilesUpload from './targeting/mCustomerFilesUpload'
import plusFriendGroup from './targeting/mPlusFriendGroup'
import account from './settings/account/mAccount'
import review from './settings/reviewDocument/mReview'
import invoice from './settings/mInvoice'
import editLog from './settings/mEditLog'
import cash from './settings/mCash'
import card from './settings/mCard'
import loading from './common/mLoading'
import addressSearch from './common/mAddressSearch'
import notice from './notice/mNotice'
import population from './targeting/mPopulation'
import myInfo from './myInfo/mMyInfo'
import accountInformation from './settings/account/mAccountInformation'
import download from './common/mDownload'
import adViewV2 from './adViewV2/mAdViewV2'
import preference from './adaccount/mPreference'
import creativeV2 from './advertise/mCreativeV2'
import adConstraints from './advertise/mAdConstraints'
import bizForm from './linkage/bizForm/mBizForm'
import message from './message/mMessage'
import audience from './targeting/audience/mAudience'
import dashboardV3 from './dashboardV3/mDashboard'
import lnb from './lnb/mLnb'
import pixelV2 from './linkage/pixelAndSDK/mPixelAndSDKV2'
import cmpAssetLibrary from './cmp/mCmpAssetLibrary'
import reportV2 from './reportV2/mReportV2'

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    popup,
    adAccount,
    notification,
    addressSearch,
    adAccountListSelector,
    cohort,
    targeting,
    customerFilesUpload,
    plusFriendGroup,
    account,
    review,
    invoice,
    editLog,
    cash,
    card,
    role,
    loading,
    notice,
    population,
    myInfo,
    accountInformation,
    download,
    preference,
    contract,
    // v2
    campaignV2,
    adGroupV2,
    creativeV2,
    advertiseV2,
    adConstraints,
    bizForm,
    adViewV2,
    message,
    audience,
    dashboardV3,
    lnb,
    pixelV2,
    cmpAssetLibrary,
    reportV2,
    popupV2,
  })

export default rootReducer
