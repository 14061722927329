import { keyMirror } from '../utils/utils'

// ImageEditor - opener 생성을 위한
const CmpAssetPropertyEnum = {
  Type: keyMirror({
    PROFILE_IMAGE: null, // 프로필 이미지
    MAIN_IMAGE: null, // 소재 메인 이미지, 이미지네이티브 - 홍보이미지
    VIDEO_IMAGE: null, //
    MESSAGE_WIDE_LIST_FIRST_IMAGE: null, // 와이드리스트1 이미지
    MESSAGE_WIDE_IMAGE: null, // 와이드리스트2~4 이미지
    MESSAGE_UPLOAD_THUMBNAIL_IMAGE: null, // 메시지 동영상 업로드 맞춤 썸네일 ( 이미지 에디터 열리지 않음)
    MAIN_IMAGE_ELECTION_2024: null, // 이미지네이티브 - 홍보이미지 - 선거
    COMPATIBLE_IMAGE: null, // pc 톡 하위버전 이미지
  }),

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

export default CmpAssetPropertyEnum
