import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { push } from 'connected-react-router'
import { clearSelectedAdAccount } from '../../common/mAdAccountListSelector'
import { showErrorMessage } from '../../../utils/alertUtils'
import {
  toMyAdAccountListPath,
  toSettingsPaymentCardPath,
} from '../../../utils/router/routeUtils'
import { initStoredAdAccountInfo } from '../../../utils/authenticationUtils'
import { openPopupByProxy, POPUP_KEY } from '../../common/mPopup'

export const ACCOUNT_MEMBER_STATUS_TYPE = keyMirror({
  INITIAL: null,
  PENDING_MASTER: null,
  PENDING_INVITE: null,
  PENDING_REQUEST: null,
  APPROVED: null,
})

export const AD_ACCOUNT_SEARCH_TYPE = keyMirror({
  BUSINESS_REGISTRATION_NUMBER: null,
  AD_ACCOUNT_NAME: null,
  AD_ACCOUNT_ID: null,
  AD_ACCOUNT_MASTER_EMAIL: null,
})

export const AD_ACCOUNT_SEARCH_TYPE_TEXT = {
  BUSINESS_REGISTRATION_NUMBER: '사업자등록번호',
  AD_ACCOUNT_NAME: '광고계정 이름 ',
  AD_ACCOUNT_ID: '광고계정 ID',
  AD_ACCOUNT_MASTER_EMAIL: '마스터 카카오계정',
}

const Account = keyMirror({
  GET_PLUS_FRIEND_PROFILE_LIST: null,
  CLEAR_PLUS_FRIEND_LIST: null,
})

export const MEMBER_REQUEST_FROM = keyMirror({
  INVITE: null, // 멤버 초대
  ACCEPT: null, // 멤버 초대 수락
  REQUEST: null, // 멤버 요청
  APPROVE: null, // 멤버 요청 승인
})

const initialState = fromJS({
  plusFriendProfileList: null,
})

export default createReducer(initialState, {
  [Account.GET_PLUS_FRIEND_PROFILE_LIST]: (state, { data }) =>
    state.set('plusFriendProfileList', fromJS(data)),

  [Account.CLEAR_PLUS_FRIEND_LIST]: state =>
    state.set(
      'plusFriendProfileList',
      initialState.get('plusFriendProfileList')
    ),
})

export function clearPlusFriendList() {
  return {
    type: Account.CLEAR_PLUS_FRIEND_LIST,
  }
}

/**
 * 카카오톡 채널 프로필 조회
 * 1. adAccountId, id param 이 없는 경우 -> DSP 계정 하위 list 조회 API
 * 2. adAccountId, id param 이 있는 경우 -> 특정 카카오톡 채널 조회 API
 * - Observer 일 경우 혹은 해당 DSP 계정이 카카오톡 채널 접근 권한이 없을 경우에도 조회할 수 있어야 한다.
 * - 1, 2 모두 동일한 형태(list)로 적재한다.
 */
export function getPlusFriendProfileList(
  adAccountId = undefined,
  profileId = undefined,
  onFail
) {
  return async (dispatch, getState, api) => {
    dispatch(clearPlusFriendList())

    /**
     *  adAccountId, id 가 정의된 경우 다건 조회(권한 필요)
     *  adAccountId, id 가 없는 경우 단건 조회(권한 불필요)
     */
    try {
      if (adAccountId > 0 && profileId > 0) {
        const response = await api.user.getPlusFriendProfile(
          adAccountId,
          profileId
        )
        const data = [response.data].filter(Boolean)

        dispatch({
          type: Account.GET_PLUS_FRIEND_PROFILE_LIST,
          data,
        })
      } else {
        const response = await api.user.getPlusFriendProfileList()
        const data = response.data || []

        dispatch({
          type: Account.GET_PLUS_FRIEND_PROFILE_LIST,
          data,
        })
      }
    } catch (e) {
      dispatch(handleAccountExceptionV2({ e }))
      if (typeof onFail === 'function') onFail(e)
    }
  }
}

const handleAccountMemberException = (requestFrom, message) => {
  switch (requestFrom) {
    case MEMBER_REQUEST_FROM.INVITE: {
      showErrorMessage(
        '해당 카카오 계정이 속한 광고계정 수가 허용된 수를 초과하여 멤버 초대가 불가합니다.'
      )
      break
    }
    case MEMBER_REQUEST_FROM.ACCEPT: {
      showErrorMessage(
        '멤버로 있을 수 있는 광고계정 수 초과로 인해 멤버 초대 수락이 불가합니다.'
      )
      break
    }
    case MEMBER_REQUEST_FROM.REQUEST: {
      showErrorMessage(
        '멤버로 있을 수 있는 광고계정 수 초과로 인해 멤버 요청이 불가합니다.'
      )
      break
    }
    case MEMBER_REQUEST_FROM.APPROVE: {
      showErrorMessage(
        '해당 카카오 계정이 속한 광고계정 수가 허용된 수를 초과하여 멤버요청 수락이 불가합니다.'
      )
      break
    }
    default: {
      showErrorMessage(message)
      break
    }
  }
}

export const handleAccountExceptionV2 =
  ({ e, rest = {} }) =>
  dispatch => {
    if (!e || !e.response || !e.response.data) return

    const { errorCode, message } = e.response.data
    switch (Number(errorCode)) {
      // 광고계정 삭제 관련 에러
      case 6001:
      case 6002: {
        showErrorMessage(message)
        break
      }

      case 6004: {
        const { adAccountId = -1, errorDialog = () => undefined } = rest
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            errorDialog(() =>
              dispatch(push(toSettingsPaymentCardPath(adAccountId)))
            )
          )
        )
        break
      }

      case 6005: {
        showErrorMessage('멤버 요청 거부된 광고계정이 포함되어 있습니다.')
        break
      }

      case 6009: {
        showErrorMessage('삭제신청중인 광고계정이 포함되어 있습니다.')
        break
      }

      // 멤버 초대, 초대 수락, 요청, 요청 승인 exception
      case 6014: {
        const { requestFrom } = rest
        handleAccountMemberException(requestFrom, message)
        break
      }

      case 6301: {
        showErrorMessage('초대가 불가한 카카오계정입니다.')
        break
      }

      case 6402: {
        showErrorMessage('이미 마스터/멤버로 속한 광고계정입니다.')
        break
      }

      // 해당 광고계정의 멤버가 아님
      case 6405: {
        showErrorMessage(message)
        initStoredAdAccountInfo()
        dispatch(clearSelectedAdAccount())
        dispatch(push(toMyAdAccountListPath())) // 내 광고계정 관리 페이지로 이동
        break
      }

      default: {
        showErrorMessage(message)
        break
      }
    }
  }
