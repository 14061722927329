import { createReducer } from 'redux-immutablejs'
import { push } from 'connected-react-router'
import { fromJS, Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { coerceToArray } from '../../utils/stringUtils'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import {
  campaignPersonalMessageConversionChanged,
  checkCampaignDailyBudgetChanging,
  handleCampaignFormExceptionV2,
} from './campaignActions/aCampaign'
import { Trim } from '../../utils/formTrim'
import { scrollByValidationKeys, Validation } from '../../validators/validation'
import {
  NEW_CAMPAIGN_FORM_VALIDATION_KEY,
  NEW_CAMPAIGN_FORM_VALIDATION_KEY_PATH,
  NEW_CAMPAIGN_FORM_VALIDATOR,
  NEW_CAMPAIGN_TRIM_KEY_PATH_LIST,
} from '../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newCampaignFormValidator'
import { batchAdGroupOnMountV2 } from '../../modules-batch/batch-new-adGroup'
import AdGroupConstraints from '../../utils/constraints/constraints-adGroup'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import { openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import {
  campaignDailyBudgetConfirmDialog,
  campaignDailyBudgetModifyReconfirmDialog,
} from '../../components/DashboardV3/Dialog/CampaignDialog'
import { checkEmpty, checkNotEmpty } from '../../utils/regexUtils'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'

const initialState = fromJS({
  validationErrorKeys: {},
  campaignForm: {
    // v2
    adAccountId: -1,
    id: -1,
    name: '',
    dailyBudget: null,
    totalBudget: null,
    totalBudgetWithVAT: null,
    /**
     * {
     *   campaignType: { CampaignTypeEnum.Type },
     *   goal: { GoalEnum.Type }
     * }
     */
    campaignTypeGoal: {
      id: -1,
      campaignType: '',
      goal: '',
    },
    /**
     * @nullable
     *
     * {
     *   type: TALK_CHANNEL | PIXEL_AND_SDK
     *   detailType: PURCHASE | SEND_MESSAGE | ADD_FRIEND | INSTALL | PARTICIPATE
     *   value: profile_id | track_id
     *   description: profile_name(@deprecated: 제거 예정)
     * }
     */
    objective: {
      type: null,
      detailType: null,
      value: null,
      description: null, // @deprecated
    },
    status: [],
    trackId: null, // 전환추적 픽셀 & SDK trackId
    dailyBudgetConstraints: {
      max: 0,
      min: 0,
      guide: null,
    },
    contractId: null, // 비즈보드 CPT 에서 선택된 계약 정보 (기본값 null 로 지정)
  },
  campaignViewState: {
    isDailyBudgetUnlimited: true,
    isConversionNotSet: true, // 전환추적 미설정 체크박스
    isFormDirty: false,
    trackInfo: {}, // 캠페인에 사용된 트랙정보
    catalogInfo: {}, // 상품카탈로그 캠페인에 사용된 카탈로그 정보
    contractInfo: {}, // 비즈보드 CPT 캠페인에서 선택한 계약 정보
    contracts: null, // 비즈보드 CPT 드롭다운 list 목록 저장 (광고만들기로 넘어갔다가 캠페인 되돌아올 경우, 드롭다운 정보 유지를 위해 redux 사용)
    freeCash: {
      // 광고그룹 생성 시 budget 내부에 노출되는 이벤트 무상캐시 정보
      available: false,
      useEventFreeCash: false,
      totalEventFreeCash: {
        totalAmount: 0,
        freeCashEventItemName: null,
      },
    },
  },
  plusFriendProfileInfo: {}, // 플친 정보 단건 조회 저장
  plusFriendProfileMessageListWithPaging: {},

  oldCampaignForm: {},
})

const Campaign = keyMirror(
  {
    CHANGE_CAMPAIGN_FORM_BY_KEY: null,
    SET_IS_VALID_CAMPAIGN_BY_KEY: null,
    SET_VALIDATION_ERROR_MESSAGE_FROM_SERVER: null,
    INIT_CAMPAIGN_VALIDATION_ERROR_KEYS: null,
    GET_CAMPAIGN_INFO_BY_ID: null,
    CLEAR_ALL_CAMPAIGN: null,
    GET_PLUS_FRIEND_PROFILE_INFO_BY_CAMPAIGN_ID: null,
    CLEAR_PLUS_FRIEND_PROFILE_MESSAGE_LIST: null,
    CHANGE_CAMPAIGN_VIEW_STATE_BY_KEY: null,

    UPDATE_OLD_CAMPAIGN_INFO: null,
  },
  'CAMPAIGN_NEW'
)

export default createReducer(initialState, {
  [Campaign.CHANGE_CAMPAIGN_FORM_BY_KEY]: (state, { key, value }) =>
    state.withMutations(s => {
      s.setIn(['campaignForm', ...coerceToArray(key)], fromJS(value))
    }),

  [Campaign.SET_IS_VALID_CAMPAIGN_BY_KEY]: (
    state,
    { key, isValid, message }
  ) => {
    const { validationErrorKeys } = state
    const errorKeys = !validationErrorKeys ? Map() : validationErrorKeys
    return state.set(
      'validationErrorKeys',
      isValid ? errorKeys.delete(key) : errorKeys.set(key, message)
    )
  },

  [Campaign.INIT_CAMPAIGN_VALIDATION_ERROR_KEYS]: state => {
    return state.set(
      'validationErrorKeys',
      initialState.get('validationErrorKeys')
    )
  },

  [Campaign.GET_CAMPAIGN_INFO_BY_ID]: (state, { data }) => {
    const campaignForm = fromJS(data)
    const { dailyBudget } = campaignForm

    return state.withMutations(s =>
      s
        .set('campaignForm', campaignForm)
        .setIn(
          ['campaignViewState', 'isDailyBudgetUnlimited'],
          dailyBudget === null
        )
    )
  },

  [Campaign.CLEAR_ALL_CAMPAIGN]: state => {
    return state.merge(initialState)
  },

  [Campaign.GET_PLUS_FRIEND_PROFILE_INFO_BY_CAMPAIGN_ID]: (state, { data }) => {
    return state.set('plusFriendProfileInfo', fromJS(data))
  },

  [Campaign.CHANGE_CAMPAIGN_VIEW_STATE_BY_KEY]: (state, { key, value }) => {
    return state.setIn(
      ['campaignViewState', ...coerceToArray(key)],
      fromJS(value)
    )
  },

  [Campaign.UPDATE_OLD_CAMPAIGN_INFO]: (state, { data }) => {
    return state.set('oldCampaignForm', fromJS(data))
  },
})

export function changeCampaignFormByKey(key, value) {
  return {
    type: Campaign.CHANGE_CAMPAIGN_FORM_BY_KEY,
    key,
    value,
  }
}

export function setIsValidCampaignByKey(key, isValid, message) {
  return {
    type: Campaign.SET_IS_VALID_CAMPAIGN_BY_KEY,
    key,
    isValid,
    message,
  }
}

export function initCampaignValidationErrorKeys() {
  return {
    type: Campaign.INIT_CAMPAIGN_VALIDATION_ERROR_KEYS,
  }
}

export function changeCampaignViewStateByKeyV2(key, value) {
  return {
    type: Campaign.CHANGE_CAMPAIGN_VIEW_STATE_BY_KEY,
    key,
    value,
  }
}

export function receiveCampaignInfo(data) {
  return dispatch => {
    dispatch(updateOldCampaignInfo(data))

    return dispatch({
      type: Campaign.GET_CAMPAIGN_INFO_BY_ID,
      data,
    })
  }
}

export function validateCampaignForm(adAccountId, campaignId, onSuccess) {
  return async (dispatch, getState, api) => {
    const {
      campaignV2: { campaignForm },
    } = getState()

    const {
      campaignTypeGoal: { campaignType, goal },
    } = campaignForm

    const formData = Trim(
      campaignForm,
      NEW_CAMPAIGN_TRIM_KEY_PATH_LIST
    ).withMutations(s =>
      s
        .set('adAccountId', adAccountId)
        .update('objective', objective =>
          AdGroupConstraints.isObjectiveRequired({ campaignType, goal })
            ? objective
            : null
        )
    )

    // validation
    const validationResult = Validation(
      formData,
      NEW_CAMPAIGN_FORM_VALIDATION_KEY,
      NEW_CAMPAIGN_FORM_VALIDATION_KEY_PATH,
      NEW_CAMPAIGN_FORM_VALIDATOR,
      getState,
      setIsValidCampaignByKey,
      dispatch
    )

    if (!validationResult) {
      const {
        campaignV2: { validationErrorKeys },
      } = getState()

      scrollByValidationKeys(
        validationErrorKeys,
        NEW_CAMPAIGN_FORM_VALIDATION_KEY
      )

      return
    }

    const onSubmit = () => {
      onSuccess()
      dispatch(batchAdGroupOnMountV2(adAccountId, campaignId, 0, undefined))
    }

    try {
      dispatch(showLoading(LOADING_KEY.GLOBAL))
      await api.campaign.validateCampaignOnCreate(adAccountId, formData)
      dispatch(hideLoading(LOADING_KEY.GLOBAL))
      const { dailyBudget } = formData || {}

      // KAMOQA-24859 캠페인 일예산이 하위 광고그룹 일예산 total 보다 작은 케이스에 대한 경고 추가
      if (checkNotEmpty(dailyBudget)) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            campaignDailyBudgetConfirmDialog(onSubmit)
          )
        )
        return
      }

      onSubmit()
    } catch (e) {
      dispatch(hideLoading(LOADING_KEY.GLOBAL))

      handleCampaignFormExceptionV2(dispatch, e)
    }
  }
}

export function modifyCampaignV2(adAccountId, campaignId, formData) {
  return (dispatch, getState, api) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
      },
    } = getState()

    // pre-processing
    const campaign = Trim(
      formData,
      NEW_CAMPAIGN_TRIM_KEY_PATH_LIST
    ).withMutations(s =>
      s.update('objective', o =>
        AdGroupConstraints.isObjectiveRequired({ campaignType, goal })
          ? o
          : null
      )
    )

    const result = Validation(
      campaign,
      NEW_CAMPAIGN_FORM_VALIDATION_KEY,
      NEW_CAMPAIGN_FORM_VALIDATION_KEY_PATH,
      NEW_CAMPAIGN_FORM_VALIDATOR,
      getState,
      setIsValidCampaignByKey,
      dispatch
    )

    if (!result) return

    const {
      campaignV2: { oldCampaignForm },
    } = getState()

    const onSubmit = async () => {
      try {
        dispatch(showLoading())

        await api.campaign.modifyCampaign(adAccountId, campaignId, campaign)

        dispatch(push(DashboardRouter.Path.Prev(adAccountId)))
      } catch (e) {
        handleCampaignFormExceptionV2(dispatch, e)
      } finally {
        dispatch(hideLoading())
      }
    }

    if (campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE) {
      const { trackId } = campaign
      const { trackId: prevTrackId } = oldCampaignForm
      const isTrackIdChanged = trackId !== prevTrackId

      if (isTrackIdChanged) {
        dispatch(
          campaignPersonalMessageConversionChanged(isTrackIdChanged, onSubmit)
        )
      } else {
        onSubmit()
      }
    } else {
      const { dailyBudget } = campaign
      const { dailyBudget: prevDailyBudget } = oldCampaignForm

      const onOK = () => {
        dispatch(
          checkCampaignDailyBudgetChanging(dailyBudget, prevDailyBudget, () => {
            if (prevIsUnlimited && isLimited) {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  campaignDailyBudgetConfirmDialog(onSubmit)
                )
              )
            } else {
              onSubmit()
            }
          })
        )
      }
      const isLimited = checkNotEmpty(dailyBudget)
      const isUnlimited = checkEmpty(dailyBudget)
      const prevIsUnlimited = checkEmpty(prevDailyBudget)
      const unlimitedChanged = isUnlimited !== prevIsUnlimited
      const dailyBudgetChanged =
        dailyBudget > 0 && dailyBudget !== prevDailyBudget

      // KAMOQA-24859 캠페인 일예산이 하위 광고그룹 일예산 total 보다 작은 케이스에 대한 경고 추가

      if (unlimitedChanged || dailyBudgetChanged) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            campaignDailyBudgetModifyReconfirmDialog({
              dailyBudget,
              onOK,
            })
          )
        )
      } else {
        onSubmit()
      }
    }
  }
}

export function clearAllCampaign() {
  return {
    type: Campaign.CLEAR_ALL_CAMPAIGN,
  }
}

export function receivePlusFriendProfileInfoByCampaignId(data) {
  return {
    type: Campaign.GET_PLUS_FRIEND_PROFILE_INFO_BY_CAMPAIGN_ID,
    data,
  }
}

/**
 * get, create, modify 시 항상 마지막 campaign info 를 보관한다.
 */
export function updateOldCampaignInfo(data) {
  return {
    type: Campaign.UPDATE_OLD_CAMPAIGN_INFO,
    data,
  }
}

export function fetchEventFreeCash(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const {
        campaignV2: {
          campaignForm: {
            campaignTypeGoal: { campaignType },
          },
        },
      } = getState()

      const { data } = await api.campaign.fetchEventFreeCash(
        adAccountId,
        campaignType
      )
      dispatch(changeCampaignViewStateByKeyV2('freeCash', data))
    } catch (e) {
      console.log(e.message)
    }
  }
}
