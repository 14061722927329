import { keyMirror } from '../../utils/utils'
import { Map } from 'immutable'
import { isUndefinedOrNull } from '../../utils/regexUtils'
import { NumberUtils } from '../../utils/numberUtils'

export const CASH_DEFERRED_PAYMENT_VALIDATION_KEY = keyMirror({
  REPAYMENT_AMOUNT: null,
})

export const CASH_DEFERRED_PAYMENT_VALIDATION_KEY_PATH = Map({
  [CASH_DEFERRED_PAYMENT_VALIDATION_KEY.REPAYMENT_AMOUNT]: ['amount'],
})

const REPAYMENT_AMOUNT_MAX = 1_000_000_000

export const CASH_DEFERRED_PAYMENT_VALIDATOR = Map({
  [CASH_DEFERRED_PAYMENT_VALIDATION_KEY.REPAYMENT_AMOUNT]: (
    amount,
    formData,
    getState
  ) => {
    const {
      cash: {
        deferredPaymentStatus: { repayment },
      },
    } = getState()

    const { bondRepays } = repayment

    if (!bondRepays || bondRepays.isEmpty())
      return { isValid: false, message: '결제할 금액이 없습니다.' }

    const {
      remainedBondAmount, // 전체 미결제 금액
      planRepayAmount, // 기발급 가상계좌 입금대기 금액
    } = bondRepays.first()

    // 최대 결제 가능 금액
    const maxPayableAmount = Math.min(
      remainedBondAmount - planRepayAmount,
      REPAYMENT_AMOUNT_MAX
    )

    if (isUndefinedOrNull(amount)) {
      return { isValid: false, message: '결제 금액을 입력하세요.' }
    } else if (amount > maxPayableAmount) {
      return {
        isValid: false,
        message: `${NumberUtils.toShorten(
          maxPayableAmount
        )}원 이하로 입력하세요.`,
      }
    }

    return { isValid: true, message: '' }
  },
})
