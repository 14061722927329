import React, { Fragment, useMemo } from 'react'
import {
  checkNotEmpty,
  hasFullWidthWhitespace,
  hasOnlyKeyboardChars,
  hasWhitespace,
} from '../../../../../utils/regexUtils'

import CheckBox from '../../../../Common/CheckBox'
import InputBox from '../../../../Common/InputBox'
import ColorDropDown from '../../../Common/ColorDropDown'
import {
  BIZ_BOARD_BADGE_TYPE,
  BIZ_BOARD_BANNER_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_MASK_TYPE,
  BIZBOARD_COPY_ALLOWED_CHAR,
} from '../bizBoardUtils'
import PropTypes from 'prop-types'
import useComponentId from '../../../../../utils/hook/useComponentId'

BizBoardBannerBadge.propTypes = {
  formData: PropTypes.object,
  templateData: PropTypes.object,
  validationState: PropTypes.object,
  handleFormChange: PropTypes.func,
  setValidationState: PropTypes.func,
}

const availableColors = [
  '#EE2E04',
  '#FF6229',
  '#ED6689',
  '#0072DD',
  '#25B06D',
  '#6B52CB',
  '#121212',
]

const badgeDescriptionMaxLengthMap = {
  [BIZ_BOARD_BADGE_TYPE.FLAG]: 5,
  [BIZ_BOARD_BADGE_TYPE.BELT]: 8,
}

function BizBoardBannerBadge({
  formData,
  templateData,
  validationState,
  handleFormChange,
  setValidationState,
}) {
  const { badge, badgeColor, badgeDescription, badgeDescription2 } =
    formData || {}
  const { bannerType, maskType } = templateData

  const badgeDescriptionId = useComponentId()
  const badgeDescription2Id = useComponentId()

  const onChangeBadge = badge => {
    handleFormChange('badge', badge)
    handleFormChange('badgeColor', '')
    handleFormChange('badgeDescription', '')
    handleFormChange('badgeDescription2', '')

    const {
      BADGE_DESCRIPTION,
      BADGE_DESCRIPTION2,
      BADGE_COLOR,
      ...newValidationState
    } = validationState
    setValidationState(newValidationState)
  }

  const onChangeBadgeColor = color => {
    handleFormChange('badgeColor', color)
  }

  const onChangeBadgeDescription = e => {
    const { value } = e.target
    if (
      hasOnlyKeyboardChars(value, BIZBOARD_COPY_ALLOWED_CHAR) &&
      !hasWhitespace(value) &&
      !hasFullWidthWhitespace(value)
    ) {
      handleFormChange('badgeDescription', value)
    }
  }

  const onChangeBadgeDescription2 = e => {
    const { value } = e.target
    if (
      hasOnlyKeyboardChars(value, BIZBOARD_COPY_ALLOWED_CHAR) &&
      !hasWhitespace(value) &&
      !hasFullWidthWhitespace(value)
    ) {
      handleFormChange('badgeDescription2', value)
    }
  }

  const isBeltAvailable = useMemo(() => {
    return (
      bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL &&
      (maskType === BIZ_BOARD_MASK_TYPE.SQUARE ||
        maskType === BIZ_BOARD_MASK_TYPE.BLUR)
    )
  }, [bannerType, maskType])

  const isBeltActive = useMemo(() => {
    return maskType === BIZ_BOARD_MASK_TYPE.SQUARE
  }, [maskType])

  const firstBadgeDescriptionTitle = useMemo(() => {
    return badge === BIZ_BOARD_BADGE_TYPE.FLAG ? '한 줄 배지 문구' : '배지 문구'
  }, [badge])

  const firstBadgePlaceHolder = useMemo(() => {
    return badge === BIZ_BOARD_BADGE_TYPE.FLAG
      ? '한 줄 배지 문구를 입력하세요.'
      : '배지 문구를 입력하세요.'
  }, [badge])

  return (
    <Fragment>
      <BadgeSetting
        badge={badge}
        isBeltAvailable={isBeltAvailable}
        isBeltActive={isBeltActive}
        onBadgeChange={onChangeBadge}
      />
      {checkNotEmpty(badge) && (
        <Fragment>
          <ColorSetting
            badgeColor={badgeColor}
            onSelectColor={onChangeBadgeColor}
            errorMessage={
              validationState[BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_COLOR]
            }
          />
          <BadgeCopy
            id={badgeDescriptionId}
            title={firstBadgeDescriptionTitle}
            placeholder={firstBadgePlaceHolder}
            badgeDescription={badgeDescription}
            onChange={onChangeBadgeDescription}
            errorMessage={
              validationState[BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_DESCRIPTION]
            }
            maxLength={badgeDescriptionMaxLengthMap[badge]}
            isNecessary={true}
          />
          {badge === BIZ_BOARD_BADGE_TYPE.FLAG && (
            <BadgeCopy
              id={badgeDescription2Id}
              title={'두 줄 배지 문구'}
              placeholder={'두 줄 배지 문구를 입력하세요.'}
              badgeDescription={badgeDescription2}
              onChange={onChangeBadgeDescription2}
              errorMessage={
                validationState[
                  BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_DESCRIPTION2
                ]
              }
              maxLength={badgeDescriptionMaxLengthMap[badge]}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

const BadgeSetting = ({
  badge,
  onBadgeChange,
  isBeltAvailable,
  isBeltActive,
}) => {
  const onFlagChange = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      onBadgeChange(BIZ_BOARD_BADGE_TYPE.FLAG)
    } else {
      onBadgeChange(null)
    }
  }

  const onBeltChange = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      onBadgeChange(BIZ_BOARD_BADGE_TYPE.BELT)
    } else {
      onBadgeChange(null)
    }
  }

  return (
    <div className="bnrimg_area type_check">
      <strong className="tit_bnrimg">
        배지 설정<span className="txt_sub">(선택)</span>
      </strong>
      <div className="bnrimg_con">
        <CheckBox
          label={'플래그형'}
          id={'badge_flag'}
          isChecked={badge === BIZ_BOARD_BADGE_TYPE.FLAG}
          onChange={onFlagChange}
        />
        {isBeltAvailable && (
          <CheckBox
            label={'띠형'}
            id={'badge_belt'}
            isChecked={badge === BIZ_BOARD_BADGE_TYPE.BELT}
            onChange={onBeltChange}
            active={isBeltActive}
          />
        )}
      </div>
    </div>
  )
}

BadgeSetting.propTypes = {
  badge: PropTypes.string,
  onBadgeChange: PropTypes.func,
  isBeltAvailable: PropTypes.bool,
  isBeltActive: PropTypes.bool,
}
const ColorSetting = ({ badgeColor, onSelectColor, errorMessage }) => {
  return (
    <div className="bnrimg_area type_subtit">
      <strong className="subtit_bnrimg">
        색상 선택<span className="emph_color">*</span>
      </strong>
      <div className="bnrimg_con">
        <ColorDropDown
          items={availableColors}
          selectedItem={badgeColor}
          onSelectItem={onSelectColor}
          error={checkNotEmpty(errorMessage)}
        />
        {checkNotEmpty(errorMessage) && (
          <p className="txt_error">{errorMessage}</p>
        )}
      </div>
    </div>
  )
}

ColorSetting.propTypes = {
  badgeColor: PropTypes.string,
  onSelectColor: PropTypes.func,
  errorMessage: PropTypes.string,
}

const BadgeCopy = ({
  id,
  title,
  placeholder,
  badgeDescription,
  errorMessage,
  maxLength,
  isNecessary = false,
  onChange,
}) => {
  return (
    <div className="bnrimg_area type_subtit">
      <strong className="subtit_bnrimg">
        {title}
        {isNecessary && <span className="emph_color">*</span>}
      </strong>
      <div className="bnrimg_con">
        <div className="bnrimg_con">
          <InputBox
            labelClassName="lab_txt"
            id={`badge_copy|${id}`}
            placeholder={placeholder}
            value={badgeDescription}
            onChange={onChange}
            error={checkNotEmpty(errorMessage)}
            maxLength={maxLength}
            isShowingInputLength={true}
          />
          {checkNotEmpty(errorMessage) && (
            <p className="txt_error">{errorMessage}</p>
          )}
        </div>
      </div>
    </div>
  )
}

BadgeCopy.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  badgeDescription: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  isNecessary: PropTypes.bool,
  maxLength: PropTypes.number,
}
export default BizBoardBannerBadge
