import React, { Fragment } from 'react'
import CheckBox from '../../../../Common/CheckBox/CheckBox'
import cx from 'classnames'
import {
  checkNotEmpty,
  hasOnlyKeyboardChars,
  validateInputLength,
} from '../../../../../utils/regexUtils'
import { ScaleImageFitCenter } from '../../../../../utils/imageUtils'
import { useDispatch } from 'react-redux'
import { useAnimateCSS } from '../../../../../utils/hook/useAnimateCSS'
import { openPopup, POPUP_KEY } from '../../../../../modules/common/mPopup'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import {
  BIZ_BOARD_APP_DOWNLOAD_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_NOTICE_TYPE,
} from '../bizBoardUtils'
import PropTypes from 'prop-types'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import {
  extractMimeSubType,
  formatBytes,
} from '../../../../../utils/stringUtils'
import { CmpAssetLibraryUtils } from '../../Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'
import CmpTextInput from '../../Cmp/CmpInput/CmpTextInput'
import { Tooltip } from '../../../../../resources/locale'
import DynamicToolTip from '../../../../Tooltip/DynamicTooltip'

const BizBoardBannerBehavior = ({
  adAccountId,
  formData,
  templateData,
  validationState,
  handleFormChange,
  handleTemplateChange,
}) => {
  const useAppDownload =
    templateData.appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE

  const isSubCopyEmpty = React.useMemo(
    () =>
      !formData.description || String(formData.description).trim().length === 0,
    [formData.description]
  )

  const handleAppDownloadUsageChange = React.useCallback(
    e => {
      const { checked } = e.target

      if (checked) {
        // 배지 해제

        // 채팅방 랜딩 고지문일 경우 해제
        // 법적 고지문이면서 서브카피가 있을 경우, 법적 고지문 해제
        const shouldNoticeOff =
          templateData.noticeType === BIZ_BOARD_NOTICE_TYPE.TALK_LANDING ||
          (templateData.noticeType === BIZ_BOARD_NOTICE_TYPE.LEGAL &&
            !isSubCopyEmpty)

        if (shouldNoticeOff) {
          handleTemplateChange('noticeType', BIZ_BOARD_NOTICE_TYPE.NONE)
        }
      }

      handleTemplateChange(
        'appDownloadType',
        checked
          ? BIZ_BOARD_APP_DOWNLOAD_TYPE.USE
          : BIZ_BOARD_APP_DOWNLOAD_TYPE.NONE
      )
    },
    [handleTemplateChange, isSubCopyEmpty, templateData.noticeType]
  )

  return (
    <Fragment>
      <div className="bnrimg_area type_check">
        <strong className="tit_bnrimg">
          행동 유도<span className="txt_sub">(선택)</span>
        </strong>
        <div className="bnrimg_con">
          <CheckBox
            label="앱 아이콘 설정"
            id="inputCheck|useAppDownload"
            isChecked={useAppDownload}
            onChange={handleAppDownloadUsageChange}
          />
        </div>
      </div>
      {useAppDownload && (
        <AppIcon
          adAccountId={adAccountId}
          formData={formData}
          validationState={validationState}
          handleFormChange={handleFormChange}
        />
      )}
    </Fragment>
  )
}

BizBoardBannerBehavior.propTypes = {
  adAccountId: PropTypes.any,
  formData: PropTypes.object,
  templateData: PropTypes.object,
  validationState: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
}
const AppIcon = ({
  adAccountId,
  formData,
  validationState,
  handleFormChange,
}) => {
  const dispatch = useDispatch()

  const [isVisibleAnim, setIsVisibleAnim] = React.useState(false)

  const { useTalkIcon } = formData || {}
  const { url } = formData.icon || {}

  const handleAppIconImageChange = React.useCallback(
    image => {
      handleFormChange('icon', image)
    },
    [handleFormChange]
  )

  const handleAppDownloadCopyChange = React.useCallback(
    e => {
      const { value } = e.target

      const isValid =
        hasOnlyKeyboardChars(value) && validateInputLength(value, 0, 50)

      if (isValid) {
        handleFormChange('subtitle', value)
      }
    },
    [handleFormChange]
  )

  const handleAppIconDelete = React.useCallback(() => {
    handleFormChange('icon', null)
    handleFormChange('useTalkIcon', false)
  }, [handleFormChange])

  const handleUseTalkIcon = React.useCallback(() => {
    handleFormChange('useTalkIcon', true)
  }, [handleFormChange])

  const openCreativeImportForAppIcon = React.useCallback(
    e => {
      e.preventDefault()

      dispatch(
        openPopup(POPUP_KEY.CREATIVE_LIBRARY, {
          adAccountId,
          campaignType: CampaignTypeEnum.Type.TALK_BIZ_BOARD,
          creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
          creativeUploadAssetPropertyType:
            CreativeUploadAssetPropertyEnum.Type.ICON_IMAGE,
          multiSelect: false,
          onSave: ({ items, onSuccess }) => {
            setIsVisibleAnim(true)

            handleAppIconImageChange(items.first().toJS())

            onSuccess()
          },
          guideTooltipContent: CmpAssetLibraryUtils.Upload.guideTooltip({
            creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
            creativeUploadAssetPropertyType:
              CreativeUploadAssetPropertyEnum.Type.ICON_IMAGE,
          }),
          onlyPNG24: true,
        })
      )
    },
    [adAccountId, dispatch, handleAppIconImageChange]
  )

  React.useEffect(() => {
    return () => {
      handleFormChange('icon', null)
      handleFormChange('useTalkIcon', false)
      handleFormChange('subtitle', '')
    }
  }, [handleAppIconImageChange, handleFormChange])

  const errorMessageAppIcon =
    validationState[BIZ_BOARD_FORM_VALIDATION_KEY.APP_ICON_IMAGE]
  const errorMessageAppDownloadCopy =
    validationState[BIZ_BOARD_FORM_VALIDATION_KEY.APP_DOWNLOAD_COPY]

  return (
    <Fragment>
      <div className="bnrimg_area type_subtit">
        <strong className="subtit_bnrimg">
          앱 아이콘<span className="emph_color">*</span>
        </strong>
        <div className="bnrimg_con">
          {checkNotEmpty(url) || useTalkIcon ? (
            <SelectedAppIconItem
              url={url}
              icon={formData?.icon}
              isVisibleAnim={isVisibleAnim}
              handleDelete={handleAppIconDelete}
              useTalkIcon={useTalkIcon}
            />
          ) : (
            <>
              <a className="btn_gw" onClick={openCreativeImportForAppIcon}>
                <span className="inner_g">
                  <span className="img_comm ico_add" />
                  이미지 추가
                </span>
              </a>
              <a className="btn_gw" onClick={handleUseTalkIcon}>
                <span className="inner_g">
                  <span className="img_comm ico_add" />톡 아이콘 사용
                  <DynamicToolTip
                    content={Tooltip(
                      'create_ads.v2.biz_board_banner_create.behavior.talk_icon'
                    )}>
                    <a className="link_g link_help">
                      <span className="ico_comm ico_help">도움말</span>
                    </a>
                  </DynamicToolTip>
                </span>
              </a>
            </>
          )}
          {errorMessageAppIcon && (
            <p className="txt_error">{errorMessageAppIcon}</p>
          )}
        </div>
      </div>
      <div className="bnrimg_area type_subtit">
        <strong className="subtit_bnrimg">
          앱 아이콘 카피<span className="emph_color">*</span>
        </strong>
        <div className="bnrimg_con">
          <CmpTextInput
            id="inputText|appDownloadCopy"
            labelClassName="lab_txt"
            placeholder="앱 아이콘 카피를 입력하세요."
            value={formData.subtitle}
            error={checkNotEmpty(errorMessageAppDownloadCopy)}
            onChange={handleAppDownloadCopyChange}
            maxLength={30}
          />
          {errorMessageAppDownloadCopy && (
            <p className="txt_error">{errorMessageAppDownloadCopy}</p>
          )}
        </div>
      </div>
      <div className="bnrimg_area type_cancle">
        <div className="reform_group_cancle">
          <a
            className="reform_btn_cancle"
            href="https://kakaobusiness.gitbook.io/main/ad/moment/start/talkboard/content-guide#2-5."
            rel="noopener noreferrer"
            target="_blank">
            앱 아이콘 카피 가이드
          </a>
        </div>
      </div>
    </Fragment>
  )
}

AppIcon.propTypes = {
  adAccountId: PropTypes.any,
  formData: PropTypes.object,
  validationState: PropTypes.object,
  handleFormChange: PropTypes.func,
}

const SelectedAppIconItem = ({
  url,
  icon,
  isVisibleAnim,
  handleDelete,
  useTalkIcon,
}) => {
  const { ref, onLoad, isAnimStarted } = useAnimateCSS()
  const { imageWidth, imageHeight, originalFileName, fileSize, mimeType } =
    icon || {}
  const loadOk = checkNotEmpty(url)
  const { scaledWidth: appIconScaledWidth, scaledHeight: appIconScaledHeight } =
    ScaleImageFitCenter(32, 32, 29, 29)

  const { scaledWidth, scaledHeight } = ScaleImageFitCenter(
    imageWidth,
    imageHeight,
    29,
    29
  )

  return useTalkIcon ? (
    <div className="reform_regist_img load_ok">
      <div className="reform_item_file">
        <span className="inner_info">
          <span className="reform_img_file">
            <span className="inner_img_file">
              <img
                width={appIconScaledWidth}
                height={appIconScaledHeight}
                alt="앱 아이콘 이미지"
                className="img_thumb"
                src="https://t1.daumcdn.net/b2/express/images/icon/app_ico/app_ico.png"
              />
              <span className="frame_g" />
            </span>
          </span>
          <span className="reform_txt_file">
            <span className="txt_upload">{'톡 아이콘'}</span>
            <span className="txt_byte">
              {'38X38'}
              <span className="txt_bar">|</span>
              {'PNG'}
              <span className="txt_bar">|</span>
              {'1.6KB'}
            </span>
          </span>
          <button type="button" className="btn_del" onClick={handleDelete}>
            <span className="ico_comm ico_del">삭제</span>
          </button>
        </span>
      </div>
    </div>
  ) : (
    <div className={cx('reform_regist_img', { load_ok: loadOk })}>
      <div className="reform_item_file">
        <span className="inner_info">
          <span className="reform_img_file">
            <span className="inner_img_file">
              <img
                width={scaledWidth}
                height={scaledHeight}
                alt="앱 아이콘 이미지"
                className="img_thumb"
                src={url}
                onLoad={onLoad}
              />
              <span className="frame_g" />
              {isVisibleAnim && (
                <span
                  ref={ref}
                  className={cx({
                    load_img: loadOk,
                    'animated fadeOut': isAnimStarted,
                  })}>
                  <span className="ico_comm ico_loading">로딩완료</span>
                </span>
              )}
            </span>
          </span>
          <span className="reform_txt_file">
            <span className="txt_upload">
              {String(originalFileName).normalize('NFC')}
            </span>
            <span className="txt_byte">
              {imageWidth}x{imageHeight}
              <span className="txt_bar">|</span>
              {extractMimeSubType({ mimeType, fallback: '-' })}
              <span className="txt_bar">|</span>
              {formatBytes({ bytes: fileSize, fallback: '-' })}
            </span>
          </span>
          <button type="button" className="btn_del" onClick={handleDelete}>
            <span className="ico_comm ico_del">삭제</span>
          </button>
        </span>
      </div>
    </div>
  )
}
SelectedAppIconItem.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.object,
  isVisibleAnim: PropTypes.bool,
  handleDelete: PropTypes.func,
  useTalkIcon: PropTypes.bool,
}

export default BizBoardBannerBehavior
