import { keyMirror } from '../utils'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { floor } from 'lodash'
import { calculateAspectRatio } from '../mathUtils'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { CreativePreviewUtils } from '../../components/AdvertiseV2/Creative/Preview/creativePreviewUtils'

const CATALOG_SLIDE_MIN = 4
const CATALOG_SLIDE_MAX = 10

const CATALOG_SLIDE_TYPE = keyMirror({
  MOBILE_TEMPLATE: null,
  PC_RATIO_1_1_TEMPLATE: null,
  PC_RATIO_5_1_TEMPLATE: null,
})

const CATALOG_SLIDE_SPEC = {
  WIDTH: {
    CREATIVE: {
      MOBILE_TEMPLATE: 124,
    },
    DASHBOARD: {
      MOBILE_TEMPLATE: 136,
    },
  },
  SIZE: {
    MOBILE_TEMPLATE: 4,
    PC_RATIO_1_1_TEMPLATE: 1,
    PC_RATIO_5_1_TEMPLATE: 2,
  },
}

const CREATIVE_CREATE_IMAGE_MAX = {
  BANNER: 100,
  BIZ_BOARD: 100,
  NATIVE: 100,
  MOTION_BOARD: 1,
  OBJECT_IMAGE: 3,
  PC_TALK_BOTTOM_BACKGROUND: 30,
}

const BIZ_BOARD_SPEC = {
  WIDTH: 1029,
  HEIGHT: 258,
}

const DEPRECATED_BIZ_BOARD_SPEC = {
  WIDTH: 1029,
  HEIGHT: 222,
}

const MOTION_BOARD_SPEC = {
  WIDTH: 1290,
  HEIGHT: 258,
}

const PC_TALK_BOTTOM_SPEC = {
  WIDTH: 1504,
  HEIGHT: 336,
}

const PC_TALK_RICH_POP_SPEC = {
  WIDTH: 1200,
  HEIGHT: 756,
}

const BIZ_BOARD_OBJET_SPEC = {
  WIDTH: 315,
  HEIGHT: 258,
}

const BIZ_BOARD_MULTI_OBJET_SPEC = {
  WIDTH: 172,
  HEIGHT: 172,
}

const BIZ_BOARD_ICON_SPEC = {
  WIDTH: 38,
  HEIGHT: 38,
}

const BIZ_BOARD_EXPANDABLE_IMAGE_CONSTRAINTS = {
  IMAGE: [{ minWidth: 1200, ratio: 2 }], // 1200x600 이상의 2:1 비율
  MULTI: [{ minWidth: 1080, ratio: 1 }], // 1080x1080 이상의 1:1 비율
}

const BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MIN = 3
const BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MAX = 5

const CREATIVE_IMAGE_TYPE = keyMirror({
  PROMOTIONAL: null,
  PROFILE: null,
})

const CREATIVE_IMAGE_PROFILE_SPEC = {
  WIDTH: 300,
  HEIGHT: 300,
  RATIO: 1,
}

const CREATIVE_IMAGE_PROFILE_SIZE_CONSTRAINT = {
  minWidth: 300,
  ratio: 1,
}

const CREATIVE_CREATE_PROFILE_KEY = `${CREATIVE_IMAGE_TYPE.PROFILE}_RATIO_${CREATIVE_IMAGE_PROFILE_SPEC.RATIO}`

const CREATIVE_IMAGE_BANNER_PREVIEW_MAX_WIDTH = {
  2: '200px',
  3: '126px',
}

const getCreativeImageSizeText = ({
  width,
  height,
  minWidth,
  ratio,
  hideRatio = false, // 비즈보드 확장형 이미지형 & 멀티형
}) => {
  const hasSpecificSize = width > 0 && height > 0
  const hasRatio = ratio > 0

  if (hasSpecificSize) {
    return [width, height].join('x')
  } else if (hasRatio) {
    const calculatedMinHeight = getCalculatedMinHeightByMinWidthAndRatio({
      minWidth,
      ratio,
    })
    if (hideRatio) {
      return `${[minWidth, calculatedMinHeight].join('x')} 이상`
    } else {
      return `${[minWidth, calculatedMinHeight].join('x')} 이상의 ${
        calculateAspectRatio(minWidth, calculatedMinHeight).aspect
      } 비율`
    }
  } else {
    return ''
  }
}

const getCreativeImageFileSizeKB = ({
  width,
  height,
  minWidth,
  ratio,
  creativeFormat,
}) => {
  // 비율 사이즈
  if (minWidth === 300 && ratio === 1) {
    return 500
  }

  if (minWidth === 500 && ratio === 1) {
    return 500
  }

  if (minWidth === 1200 && ratio === 2) {
    return 500
  }

  if (minWidth === 1280 && ratio === 1.7777) {
    return 500
  }

  if (minWidth === 720 && ratio === 0.5625) {
    return 500
  }

  const size = [width, height].join('x')

  /**
   * 구 배너 사이즈 참고
   * https://wiki.daumkakao.com/pages/viewpage.action?pageId=560494902
   */
  switch (size) {
    case '1504x336':
    case '1200x756':
      return 500
    case '300x150': // 600x300
    case '640x160':
    case '640x200':
    case '320x50':
    case '320x100':
    case '640x100':
      return 100
    case '170x128': // 340x256
    case '250x250': // 500x500
    case '468x60': // 936x120
    case '560x120': // 1120x240
    case '130x250':
    case '1200x600':
    case '315x222': // deprecated
    case '315x258':
      return 150
    case '1029x174': // deprecated
    case '1029x186': // deprecated
    case '1029x204': // deprecated
    case '1029x222': // deprecated
    case '1029x258':
    case '1290x258':
      return 300
    case '120x600': // 240x1200
    case '160x600': // 320x1200
    case '200x200': // 400x400
    case '336x280': // 672x560
    case '655x120': // 1310x240
    case '728x90': // 1456x180
    case '970x90': // 1940x180
    case '300x300':
    case '1190x48':
      return 200
    case '1120x240':
    case '600x300':
    case '1310x240':
    case '340x256':
    case '936x120':
    case '320x1200':
    case '400x400':
    case '1456x180':
    case '240x1200':
    case '672x560':
    case '1940x180':
    case '600x500':
    case '530x640':
      return 300
    case '500x500': {
      return creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL
        ? 400
        : 300
    }
    case '1200x628':
    case '640x480':
    case '640x360':
    case '360x640':
    case '768x1024':
    case '1024x768':
    case '1280x628':
    case '720x1000':
    case '1280x720':
    case '720x1560':
    case '720x1150':
      return 400
    case '32x32': // deprecated
    case '38x38':
      return 80
    default:
      return 150
  }
}

const getCreativePreviewColumnSize = size => {
  switch (size) {
    case '640x100':
    case '640x200':
    case '1200x600':
    case '600x300':
    case '1120x240':
    case '1310x240':
    case '936x120':
    case '1456x180':
    case '1940x180':
      return 1
    case '672x560':
    case '400x400':
    case '600x500':
    case '340x256':
    case '500x500':
      return 2
    case '240x1200':
    case '320x1200':
      return 3
    default:
      return 1
  }
}

const getCreativePreviewSize = ({
  creativeFormatOrTemplateType,
  showAddFriendPage = false, // image native only
  showAllImageNativePages = false, // 2:1 + 1:1
  campaignType,
  isSpecialBoard = false,
  isDynamicBizboardAvailable = false,
  bizBoardNativeServing = false,
  isPreviewInDashBoard = false,
}) => {
  if (isSpecialBoard) {
    return 2
  }

  if (bizBoardNativeServing) {
    return isPreviewInDashBoard ? 4 : 3
  }

  if (CampaignTypeEnum.isElectionCampaignType(campaignType)) {
    return CreativePreviewUtils.Template.PageSequence
      .IMAGE_NATIVE_RATIO_2_1_ELECTION_202203.length
  }

  if (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04) {
    if (
      creativeFormatOrTemplateType === CreativeFormatEnum.Type.RATIO_16_TO_9
    ) {
      return 2
    }
    return 4
  }

  if (showAllImageNativePages) {
    // 2:1, 1:1, 9:16 병합 후 중복되는 페이지 소거(컴포넌트 name 으로 중복 제거
    // 병합 후 중복되는 페이지 제거할때 name 으로 구분되어 다른 페이지도 삭제되는 이슈 발견되어 새로운 템플릿 리스트 추가
    return (
      CreativePreviewUtils.Template.PageSequence.IMAGE_NATIVE_ALL_TEMPLATE
        .length - (showAddFriendPage ? 0 : 1)
    )
  }

  if (campaignType === CampaignTypeEnum.Type.VIDEO) {
    if (
      creativeFormatOrTemplateType === CreativeFormatEnum.Type.RATIO_9_TO_16
    ) {
      return 1
    } else {
      return 5
    }
  }

  switch (creativeFormatOrTemplateType) {
    case CreativeFormatEnum.Type.RATIO_2_TO_1:
      return (
        CreativePreviewUtils.Template.PageSequence.IMAGE_NATIVE_RATIO_2_1
          .length - (showAddFriendPage ? 0 : 1)
      )

    case CreativeFormatEnum.Type.RATIO_1_TO_1: {
      return (
        CreativePreviewUtils.Template.PageSequence.IMAGE_NATIVE_RATIO_1_1
          .length - (showAddFriendPage ? 0 : 1)
      )
    }

    case CreativeFormatEnum.Type.RATIO_9_TO_16: {
      return (
        CreativePreviewUtils.Template.PageSequence.IMAGE_NATIVE_RATIO_9_16
          .length - (showAddFriendPage ? 0 : 1)
      )
    }

    case CreativeFormatEnum.Type.THUMBNAIL_FEED:
    case CreativeFormatEnum.Type.IMAGE_FEED: {
      return 4
    }

    case CreativeFormatEnum.Type.VIDEO_FEED: {
      return 3
    }

    case CreativeFormatEnum.Type.NATIVE_INTERSTITIAL:
    case CreativeFormatEnum.Type.RICH_NATIVE: {
      return 2
    }
    case CreativeFormatEnum.Type.CATALOG_MANUAL: {
      return 3
    }

    case CreativeFormatEnum.Type.IMAGE_BANNER:
    case CreativeFormatEnum.Type.IMAGE_BOX:
    case CreativeFormatEnum.Type.VIDEO_BANNER:
    case CreativeFormatEnum.Type.VIDEO_INSTREAM: {
      return 1
    }

    case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
      return isDynamicBizboardAvailable ? 6 : 5
    }

    default: {
      return 0
    }
  }
}

const getCreativePreviewBannerPageSize = imageSize => {
  switch (imageSize) {
    case '640x100':
    case '640x200':
    case '1200x600':
    case '600x300':
    case '1120x240':
    case '1310x240':
    case '936x120':
    case '1456x180':
    case '1940x180':
      return 5
    case '672x560':
    case '400x400':
    case '600x500':
    case '340x256':
    case '500x500':
      return 4
    case '240x1200':
    case '320x1200':
      return 3
    default:
      return 1
  }
}

const createCreativeImageGroupId = ({
  width,
  imageWidth,
  height,
  imageHeight,
}) =>
  width > 0 && height > 0
    ? `${width}x${height}`
    : `${imageWidth}x${imageHeight}`

/**
 * 소수점 4자리 이하 버림 후 비교(core)
 */
const isCreativeRatioMatched = ({ width, height, expectRatio }) => {
  return floor(width / height, 4) === expectRatio
}

const getCalculatedMinHeightByMinWidthAndRatio = ({ minWidth, ratio }) => {
  // 최소 높이를 최소 너비와 비율을 통해서 계산 (소숫점 이하 버림)
  return floor(minWidth / ratio, 0)
}

export {
  CATALOG_SLIDE_MIN,
  CATALOG_SLIDE_MAX,
  CATALOG_SLIDE_TYPE,
  CATALOG_SLIDE_SPEC,
  CREATIVE_CREATE_IMAGE_MAX,
  BIZ_BOARD_SPEC,
  DEPRECATED_BIZ_BOARD_SPEC,
  BIZ_BOARD_OBJET_SPEC,
  BIZ_BOARD_MULTI_OBJET_SPEC,
  BIZ_BOARD_ICON_SPEC,
  MOTION_BOARD_SPEC,
  PC_TALK_BOTTOM_SPEC,
  PC_TALK_RICH_POP_SPEC,
  BIZ_BOARD_EXPANDABLE_IMAGE_CONSTRAINTS,
  BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MIN,
  BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MAX,
  CREATIVE_IMAGE_TYPE,
  CREATIVE_CREATE_PROFILE_KEY,
  CREATIVE_IMAGE_PROFILE_SPEC,
  CREATIVE_IMAGE_PROFILE_SIZE_CONSTRAINT,
  CREATIVE_IMAGE_BANNER_PREVIEW_MAX_WIDTH,
  getCreativeImageSizeText,
  getCreativeImageFileSizeKB,
  getCreativePreviewColumnSize,
  getCreativePreviewBannerPageSize,
  getCreativePreviewSize,
  getCalculatedMinHeightByMinWidthAndRatio,
  createCreativeImageGroupId,
  isCreativeRatioMatched,
}
