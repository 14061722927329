import { keyMirror } from '../utils/utils'
import PfProfileMessageEnum from './PfProfileMessageEnum'
import CreativeCategoryEnum from './CreativeCategoryEnum'
import { floor } from 'lodash'
/**
 * 이미지 > 네이티브
 * 이미지 > 배너
 * 동영상 > 네이티브
 * 이미지 > 이미지박스
 * 메시지 > 기본텍스트
 * 메시지 > 와이드리스트
 * 메시지 > 와이드이미지
 * 메시지 > 캐러셀
 * 메시지 > 프리미엄동영상
 */
export default {
  Type: keyMirror({
    IMAGE_NATIVE: null, // image feed + thumbnail + interstitial,
    IMAGE_BANNER: null, // single banner
    IMAGE_BOX: null,
    CATALOG_MANUAL: null,
    VIDEO_NATIVE: null, // video feed + video banner
    BASIC_TEXT_MESSAGE: null,
    WIDE_MESSAGE: null,
    WIDE_LIST_MESSAGE: null,
    CAROUSEL_COMMERCE_MESSAGE: null,
    CAROUSEL_FEED_MESSAGE: null,
    CATALOG_DYNAMIC: null,
    PREMIUM_VIDEO_MESSAGE: null,
    RICH_NATIVE: null,

    // dev
    RATIO_2_TO_1: null,
    RATIO_1_TO_1: null,
    RATIO_9_TO_16: null,
    RATIO_16_TO_9: null,
    RATIO_9_TO_16_VIDEO: null,
    RATIO_16_TO_9_VIDEO: null,

    // legacy
    SINGLE_BANNER: null, // -> image banner
    IMAGE_FEED: null, // deprecated(migrated to IMAGE_NATIVE)
    THUMBNAIL_FEED: null, // deprecated(migrated to IMAGE_NATIVE)
    NATIVE_INTERSTITIAL: null, // deprecated(migrated to IMAGE_NATIVE)
    INTERSTITIAL_IMAGE: null, // deprecated
    SERVICE_CONTENT: null, // deprecated
    VIDEO_FEED: null,
    VIDEO_BANNER: null, // deprecated(migrated to VIDEO_FEED)
    VIDEO_INSTREAM: null,
  }),
  Name: {
    IMAGE_NATIVE: '네이티브',
    IMAGE_BANNER: '배너',
    IMAGE_BOX: '이미지박스',
    CATALOG_MANUAL: '카탈로그',
    VIDEO_NATIVE: '네이티브',
    BASIC_TEXT_MESSAGE: '기본텍스트',
    WIDE_MESSAGE: '와이드이미지',
    WIDE_LIST_MESSAGE: '와이드리스트',
    CAROUSEL_COMMERCE_MESSAGE: '캐러셀커머스',
    CAROUSEL_FEED_MESSAGE: '캐러셀피드',
    VIDEO_INSTREAM: '인스트림',
    CATALOG_DYNAMIC: '카탈로그',
    PREMIUM_VIDEO_MESSAGE: '프리미엄동영상',
    RICH_NATIVE: '네이티브',

    // 소재유형 필터에만 쓰이는 항목

    // legacy
    SINGLE_BANNER: '단일 이미지',
    IMAGE_FEED: '이미지피드',
    THUMBNAIL_FEED: '썸네일피드',
    NATIVE_INTERSTITIAL: '전면',
    INTERSTITIAL_IMAGE: '전면 이미지', // deprecated
    SERVICE_CONTENT: '콘텐츠', // deprecated
    VIDEO_FEED: '동영상피드',
    VIDEO_BANNER: '동영상배너',
  },
  values() {
    return Object.freeze(Object.keys(this.Type))
  },
  messageValues() {
    return Object.freeze([
      this.Type.BASIC_TEXT_MESSAGE,
      this.Type.WIDE_MESSAGE,
      this.Type.WIDE_LIST_MESSAGE,
      this.Type.CAROUSEL_COMMERCE_MESSAGE,
      this.Type.CAROUSEL_FEED_MESSAGE,
      this.Type.PREMIUM_VIDEO_MESSAGE,
    ])
  },

  getName(type) {
    return this.Name[type]
  },
  isDisplay(type) {
    return [
      this.Type.IMAGE_BANNER,
      this.Type.IMAGE_NATIVE,
      this.Type.IMAGE_BOX,
      this.Type.CATALOG_MANUAL,
      this.Type.VIDEO_NATIVE,
      this.Type.VIDEO_INSTREAM,
      // dev
      this.Type.RATIO_1_TO_1,
      this.Type.RATIO_2_TO_1,
      this.Type.RATIO_9_TO_16,
      this.Type.RATIO_16_TO_9,
      // legacy
      this.Type.SERVICE_CONTENT,
      this.Type.SINGLE_BANNER,
      this.Type.IMAGE_FEED,
      this.Type.THUMBNAIL_FEED,
      this.Type.NATIVE_INTERSTITIAL,
      this.Type.INTERSTITIAL_IMAGE,
      this.Type.VIDEO_FEED,
      this.Type.VIDEO_BANNER,
    ].includes(type)
  },
  isMessage(type) {
    return [
      this.Type.WIDE_MESSAGE,
      this.Type.WIDE_LIST_MESSAGE,
      this.Type.BASIC_TEXT_MESSAGE,
      this.Type.CAROUSEL_COMMERCE_MESSAGE,
      this.Type.CAROUSEL_FEED_MESSAGE,
      this.Type.PREMIUM_VIDEO_MESSAGE,
    ].includes(type)
  },

  isVideo(type) {
    return [
      this.Type.VIDEO_NATIVE,
      this.Type.VIDEO_FEED,
      this.Type.VIDEO_BANNER,
      this.Type.VIDEO_INSTREAM,
    ].includes(type)
  },

  isLegacyNative(type) {
    return [
      this.Type.IMAGE_FEED,
      this.Type.THUMBNAIL_FEED,
      this.Type.NATIVE_INTERSTITIAL,
      this.Type.INTERSTITIAL_IMAGE,
    ].includes(type)
  },

  needReview(type) {
    return !this.isMessage(type)
  },

  /**
   * FIXME 메시지쪽에서는 이제 사용 안하는듯?
   *  - FullScreenMessagePreview.jsx
   *
   * 카카오톡 채널 프로파일 메시지 타입 -> 소재 포맷으로 변환.
   * 캠페인 카카오톡 채널 메시지 목록에서 선택 시 creativeFormat 으로 변환 후 form 에 저장.
   *
   * profile message type -> creative format
   * @param pfProfileMessageType {PfProfileMessageEnum.Type}
   * @return {this.Type | null}
   */
  getValueByPfProfileMessageType(pfProfileMessageType) {
    switch (pfProfileMessageType) {
      case PfProfileMessageEnum.Type.WIDE:
        return this.Type.WIDE_MESSAGE
      case PfProfileMessageEnum.Type.WIDE_LIST:
        return this.Type.WIDE_LIST_MESSAGE
      case PfProfileMessageEnum.Type.BASIC:
        return this.Type.BASIC_TEXT_MESSAGE
      case PfProfileMessageEnum.Type.CAROUSEL:
        return this.Type.CAROUSEL_COMMERCE_MESSAGE
      default:
        return null
    }
  },

  getValuesByCreativeCategoryType(creativeCategoryType) {
    switch (creativeCategoryType) {
      case CreativeCategoryEnum.Type.IMAGE:
        return [
          this.Type.IMAGE_NATIVE,
          this.Type.IMAGE_BANNER,
          this.Type.IMAGE_BOX,
          this.Type.CATALOG_MANUAL,
        ]
      case CreativeCategoryEnum.Type.VIDEO:
        return [this.Type.VIDEO_NATIVE]
      case CreativeCategoryEnum.Type.MESSAGE:
        return [
          this.Type.BASIC_TEXT_MESSAGE,
          this.Type.WIDE_MESSAGE,
          this.Type.WIDE_LIST_MESSAGE,
          this.Type.CAROUSEL_COMMERCE_MESSAGE,
          this.Type.CAROUSEL_FEED_MESSAGE,
        ]
      case CreativeCategoryEnum.Type.SERVICE: {
        return [this.Type.SERVICE_CONTENT]
      }
      default:
        return []
    }
  },

  convertV2(creativeFormat) {
    if (this.isLegacyNative(creativeFormat)) {
      return this.Type.IMAGE_NATIVE
    }

    if (creativeFormat === this.Type.SINGLE_BANNER) {
      return this.Type.IMAGE_BANNER
    }

    if (
      this.isVideo(creativeFormat) &&
      creativeFormat !== this.Type.VIDEO_INSTREAM
    ) {
      return this.Type.VIDEO_NATIVE
    }

    return creativeFormat
  },

  getRatio({ width, height }) {
    const ratio = floor(width / height, 4)
    if (ratio === 1) {
      return this.Type.RATIO_1_TO_1
    }
    if (ratio === 2) {
      return this.Type.RATIO_2_TO_1
    }
    if (ratio === 1.7777) {
      return this.Type.RATIO_16_TO_9
    }
    if (ratio === 0.5625) {
      return this.Type.RATIO_9_TO_16
    }
  },

  isRatio9To16Image({ width, height }) {
    const ratio = floor(width / height, 4)
    return ratio === 0.5625
  },

  isDeprecatedCreativeFormat(type) {
    return [this.Type.SERVICE_CONTENT].includes(type)
  },

  getLandingUrlTooltipKey(type) {
    switch (type) {
      case this.Type.IMAGE_BOX: {
        return 'create_ads.v2.ad_content.image_box.landing_url'
      }
      case this.Type.CATALOG_DYNAMIC:
      case this.Type.CATALOG_MANUAL: {
        return 'create_ads.v2.ad_content.catalog.representative_landing_url'
      }
      default: {
        return 'create_ads.v2.ad_content.common.landing_url'
      }
    }
  },
}
