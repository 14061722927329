import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import DashboardRouter from '../../DashboardV3/dashboardRouter'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  setDashboardFilterConditions,
  setDashboardFilterUri,
} from '../../../modules/dashboardV3/mDashboardFilter'
import {
  DashboardUriUtils,
  URI_PARAM_NAME,
} from '../../DashboardV3/dashboardUriUtils'
import { toUpper } from 'lodash'
import {
  DashboardFilterUtils,
  FILTER_OPERATOR,
  FILTER_TYPE,
} from '../../DashboardV3/DashboardHeader/DashboardFilter/dashboardFilterUtils'
import { replace } from 'connected-react-router'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageSession,
} from '../../../utils/storage/storageFactoryImpl'

const dashboardLocationHandler = ({ location, prevLocation }) => {
  return dispatch => {
    const isDashboard = DashboardRouter.Path.isDashboard({
      pathname: location.pathname,
    })
    const prevIsDashboard = DashboardRouter.Path.isDashboard({
      pathname: prevLocation.pathname,
    })

    const searchParams = new URLSearchParams(location.search)

    const tableRowIdsFilter = searchParams
      .get(URI_PARAM_NAME.TABLE_ROW_IDS_FILTER)
      ?.split('~')

    /**
     * rowIds: 특정 ids 를 필터에 추가. 해당 ids 항목만을 보여주기 위함.
     */
    if (Array.isArray(tableRowIdsFilter)) {
      const [rowIdType, rowIds] = tableRowIdsFilter
      const rowIdArray = rowIds?.split(',')

      if (rowIdType && rowIdArray?.filter(Boolean)?.length > 0) {
        dispatch(
          setDashboardFilterConditions({
            conditions: [
              {
                dashboardType: toUpper(rowIdType),
                filterType: FILTER_TYPE.ID,
                filterOperator: FILTER_OPERATOR.EQ,
                filterValue: rowIdArray.slice(
                  0,
                  DashboardFilterUtils.maxCountOfFilterValueArray({
                    filterType: FILTER_TYPE.ID,
                  })
                ), // maxCount 만큼만 취함
              },
            ],
          })
        )

        // filter condition 세팅 후 반드시 제거.
        const url = DashboardUriUtils.deleteSearchParam({
          paramName: URI_PARAM_NAME.TABLE_ROW_IDS_FILTER,
        })

        dispatch(replace({ search: url.search }))

        return
      }
    }

    /**
     * somewhere -> 대시보드
     * - 저장된 search param string 이 있는 경우 적용.
     */
    if (isDashboard && !prevIsDashboard) {
      const storedDashboardSearchParamString = DashboardStorageSession.get(
        DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS
      )

      if (storedDashboardSearchParamString) {
        const dashboardSearchParams = new URLSearchParams(
          storedDashboardSearchParamString
        )

        const filter = dashboardSearchParams.get(URI_PARAM_NAME.FILTER)

        if (filter) {
          dispatch(
            setDashboardFilterUri({
              searchParamString: filter,
            })
          )
        }
      }

      DashboardStorageSession.set(DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS, '')
    } else if (!isDashboard && prevIsDashboard) {
      /**
       * 대시보드 -> somewhere
       * - 대시보드 이탈 시 마지막 search param 을 기억.
       */
      const storedDashboardSearchParamString = new URLSearchParams(
        prevLocation.search
      ).toString()

      if (storedDashboardSearchParamString) {
        DashboardStorageSession.set(
          DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS,
          storedDashboardSearchParamString
        )
      }
    }
  }
}

const handlers = [dashboardLocationHandler]

const withLocationChangeHandler = Component => {
  class C extends React.PureComponent {
    invokeHandlers = ({ location = {}, prevLocation = {} }) => {
      handlers.forEach(handler => {
        if (typeof handler === 'function') {
          this.props.dispatch(
            handler({
              location,
              prevLocation,
            })
          )
        }
      })
    }

    componentDidMount() {
      this.invokeHandlers({ location: this.props.location })
    }

    componentDidUpdate(prevProps) {
      this.invokeHandlers({
        location: this.props.location,
        prevLocation: prevProps.location,
      })
    }

    render() {
      return <Component {...this.props} />
    }
  }

  C.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
  }

  const mapDispatchToProps = dispatch => {
    return {
      dispatch,
    }
  }

  return withRouter(connect(null, mapDispatchToProps)(C))
}

export default withLocationChangeHandler
