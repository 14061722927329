import { fromJS, Map } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import {
  handlePlusFriendGroupFileException,
  initPlusFriendGroupFileValidationErrorKeys,
  setIsValidPlusFriendGroupByKey,
} from '../mPlusFriendGroup'

import { Trim } from '../../../utils/formTrim'
import { Validation } from '../../../validators/validation'
import {
  PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY,
  PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY_PATH,
  PLUS_FRIEND_GROUP_FORM_VALIDATOR,
} from '../../../validators/targeting/plusFriendGroupFormValidator'
import { hideLoading, showLoading } from '../../common/mLoading'
import { replace } from 'connected-react-router'
import { toPlusFriendGroupPath } from '../../../utils/router/routeUtils'

const PLUS_FRIEND_GROUP_FILE_UPLOAD_TRIM_KEY_PATH_LIST = [['name']]

const PlusFriendGroupDetailKey = keyMirror({
  INIT_PLUS_FRIEND_GROUP_DETAIL: null,
  GET_PLUS_FRIEND_GROUP_DETAIL_INFO: null,
  GET_PLUS_FRIEND_GROUP_AD_GROUP_LIST: null,
  GET_PLUS_FRIEND_GROUP_DETAIL_POPULATION: null,
})

const initialState = fromJS({
  plusFriendProfileId: '',

  plusFriendGroupDetailAdGroupList: {
    content: [],
    totalElements: 0,
  },

  selectedPlusFriendProfile: {
    id: -1,
  },

  plusFriendGroupDetailInfo: {},
})

export default createReducer(initialState, {
  [PlusFriendGroupDetailKey.INIT_PLUS_FRIEND_GROUP_DETAIL]: state => {
    return state
      .set(
        'plusFriendGroupDetailInfo',
        initialState.get('plusFriendGroupDetailInfo')
      )
      .set(
        'plusFriendGroupDetailAdGroupList',
        initialState.get('plusFriendGroupDetailAdGroupList')
      )
  },

  [PlusFriendGroupDetailKey.GET_PLUS_FRIEND_GROUP_DETAIL_INFO]: (
    state,
    { data }
  ) => {
    return state.set(
      'plusFriendGroupDetailInfo',
      fromJS(data).set('populationScore', null)
    )
  },

  [PlusFriendGroupDetailKey.GET_PLUS_FRIEND_GROUP_DETAIL_POPULATION]: (
    state,
    { data }
  ) => {
    return state.setIn(
      ['plusFriendGroupDetailInfo', 'populationScore'],
      data.populationScore
    )
  },

  [PlusFriendGroupDetailKey.GET_PLUS_FRIEND_GROUP_AD_GROUP_LIST]: (
    state,
    { data }
  ) => {
    return state.set('plusFriendGroupDetailAdGroupList', fromJS(data))
  },
})

export function initPlusFriendGroupFileDetail() {
  return {
    type: PlusFriendGroupDetailKey.INIT_PLUS_FRIEND_GROUP_DETAIL,
  }
}

function receivePlusFriendGroupDetailInfo(data) {
  return {
    type: PlusFriendGroupDetailKey.GET_PLUS_FRIEND_GROUP_DETAIL_INFO,
    data,
  }
}

export function getPlusFriendGroupFileDetail(
  adAccountId,
  plusFriendGroupId,
  onFinish
) {
  return (dispatch, getState, api) => {
    return api.plusFriendGroup
      .getPlusFriendGroupFileDetail(adAccountId, plusFriendGroupId)
      .then(response => {
        const plusFriendGroupFileDetail = response.data
        const { plusFriendProfileId, groupKey } = plusFriendGroupFileDetail

        dispatch(
          receivePlusFriendGroupDetailInfo(plusFriendGroupFileDetail || {})
        )

        dispatch(
          getPlusFriendGroupFileDetailAdGroupList(
            adAccountId,
            plusFriendGroupId,
            plusFriendProfileId
          )
        )

        onFinish && onFinish()
      })
      .catch(e => {
        handlePlusFriendGroupFileException(dispatch, e, adAccountId)
      })
  }
}

function receivePlusFriendGroupFileDetailInfoAdGroupsList(data) {
  return {
    type: PlusFriendGroupDetailKey.GET_PLUS_FRIEND_GROUP_AD_GROUP_LIST,
    data,
  }
}

export function getPlusFriendGroupFilesDetailPopulation(data) {
  return {
    type: PlusFriendGroupDetailKey.GET_PLUS_FRIEND_GROUP_DETAIL_POPULATION,
    data,
  }
}

export function getPlusFriendGroupFileDetailAdGroupList(
  adAccountId,
  plusFriendGroupFileId,
  plusFriendProfileId,
  currentPage,
  size
) {
  return (dispatch, getState, api) => {
    dispatch(showLoading('getPlusFriendGroupFileDetailAdGroupList'))

    return api.plusFriendGroup
      .getPlusFriendGroupFileDetailAdGroupList(
        adAccountId,
        plusFriendGroupFileId,
        plusFriendProfileId,
        currentPage,
        size
      )
      .then(response => {
        dispatch(
          receivePlusFriendGroupFileDetailInfoAdGroupsList(response.data) || {}
        )
      })
      .catch(e => {
        handlePlusFriendGroupFileException(dispatch, e)
      })
      .finally(() => {
        dispatch(hideLoading('getPlusFriendGroupFileDetailAdGroupList'))
      })
  }
}

export function modifyPlusFriendGroupFileName(
  adAccountId,
  fileName,
  plusFriendGroupId,
  onSuccess
) {
  return (dispatch, getState, api) => {
    const formData = Trim(
      Map({ name: fileName }),
      PLUS_FRIEND_GROUP_FILE_UPLOAD_TRIM_KEY_PATH_LIST
    )

    // validation
    const validationResult = Validation(
      formData,
      PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY,
      PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY_PATH,
      PLUS_FRIEND_GROUP_FORM_VALIDATOR,
      getState,
      setIsValidPlusFriendGroupByKey,
      dispatch
    )

    if (!validationResult) return null

    return api.plusFriendGroup
      .modifyPlusFriendGroupFileName(
        adAccountId,
        formData.toJS(),
        plusFriendGroupId
      )
      .then(() => {
        dispatch(getPlusFriendGroupFileDetail(adAccountId, plusFriendGroupId))
        dispatch(initPlusFriendGroupFileValidationErrorKeys())
        onSuccess && onSuccess()
      })
      .catch(e => {
        handlePlusFriendGroupFileException(dispatch, e)
      })
  }
}

export function deletePlusFriendGroupFile(adAccountId, plusFriendGroupFileId) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())
      await api.plusFriendGroup.deletePlusFriendGroupFile(
        adAccountId,
        plusFriendGroupFileId
      )

      dispatch(replace(toPlusFriendGroupPath(adAccountId)))
    } catch (e) {
      handlePlusFriendGroupFileException(dispatch, e)
    } finally {
      dispatch(hideLoading())
    }
  }
}
