import React from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'
import { coerceAtMost } from '../../../utils/utils'
import { calculateMaximumAvailablePageIndex } from '../../../utils/mathUtils'

/**
 * Paging 기능만을 구현해 놓은 C 클래스
 *
 * properties
 * - itemSize: 전체 아이템 사이즈 (number, 필수)
 * - pageSize: 페이지 당 보여질 아이템 수 (number, 필수)
 * - listener: 현재 페이징 상태(immutable Map) return (func, optional)
 * - resetPageIndex: 페이지 인덱스 초기화 여부
 *
 * @param Component {React.Component}
 * @constructor
 */
const PagingHOC = Component =>
  class C extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        currentIndex: props.defaultIndex,
        itemSize: props.itemSize,
        pageSize: props.pageSize,
        buttonSize: props.buttonSize,
        totalPages: C.getTotalPages(props.itemSize, props.pageSize)
      }
    }

    static propTypes = {
      defaultIndex: PropTypes.number,
      itemSize: PropTypes.number,
      pageSize: PropTypes.number,
      buttonSize: PropTypes.number,
      resetPageIndex: PropTypes.bool,
      listener: PropTypes.func
    }

    static defaultProps = {
      defaultIndex: 0,
      itemSize: 0,
      pageSize: 10,
      buttonSize: 10,
      resetPageIndex: false,
      listener: () => {}
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const a = prevState.itemSize !== nextProps.itemSize
      const b = prevState.pageSize !== nextProps.pageSize
      const c = prevState.currentIndex > 0 && nextProps.resetPageIndex

      const maxAvailablePageIndex = calculateMaximumAvailablePageIndex(
        nextProps.itemSize,
        nextProps.pageSize
      )

      const nextPageIndex = coerceAtMost(
        prevState.currentIndex,
        maxAvailablePageIndex
      )

      const d = prevState.currentIndex !== nextPageIndex

      if (a || b || c || d) {
        return {
          currentIndex: nextPageIndex,
          itemSize: nextProps.itemSize,
          pageSize: nextProps.pageSize,
          totalPages: C.getTotalPages(nextProps.itemSize, nextProps.pageSize)
        }
      }

      return null
    }

    componentDidUpdate(prevProps, prevState) {
      const updateKeys = ['currentIndex', 'itemSize', 'pageSize', 'buttonSize']
      const updated = updateKeys.some(key => {
        return prevState[key] !== this.state[key]
      })

      if (updated) {
        const { listener } = this.props
        if (typeof listener === 'function') {
          listener(fromJS(this.state))
        }
      }
    }

    static getTotalPages = (itemSize, pageSize) =>
      itemSize > 0 ? Math.ceil(itemSize / pageSize) : 0

    render() {
      return (
        <Component
          currentIndex={this.state.currentIndex}
          totalPages={this.state.totalPages}
          pageSize={this.state.pageSize}
          buttonSize={this.state.buttonSize}
          goFirst={this.handleGoFirst}
          goPrev={this.handleGoPrev}
          goNext={this.handleGoNext}
          goLast={this.handleGoLast}
          goPage={this.handleGoPage}
          isGoFirstActive={this.isGoFirstActive}
          isGoLastActive={this.isGoLastActive}
          isGoPrevActive={this.isGoPrevActive}
          isGoNextActive={this.isGoNextActive}
          {...this.props}
        />
      )
    }

    isGoFirstActive = () => this.state.currentIndex > 0

    isGoLastActive = () => this.state.currentIndex < this.state.totalPages - 1

    isGoPrevActive = () => this.state.currentIndex >= this.state.buttonSize

    isGoNextActive = () =>
      Math.ceil(this.state.totalPages / this.state.buttonSize) >
      Math.ceil((this.state.currentIndex + 1) / this.state.buttonSize)

    handleGoFirst = () => this.setState({ currentIndex: 0 })

    handleGoPrev = () =>
      this.setState(prevState => ({
        currentIndex:
          (Math.floor(prevState.currentIndex / this.state.buttonSize) - 1) *
            prevState.buttonSize +
          (prevState.buttonSize - 1)
      }))

    handleGoNext = () =>
      this.setState(prevState => ({
        currentIndex:
          (Math.floor(prevState.currentIndex / prevState.buttonSize) + 1) *
          prevState.buttonSize
      }))

    handleGoLast = () =>
      this.setState(prevState => ({ currentIndex: prevState.totalPages - 1 }))

    handleGoPage = currentIndex => this.setState({ currentIndex })
  }

export default PagingHOC
