import { createReducer } from 'redux-immutablejs'
import moment from 'moment'
import { fromJS, List, Map } from 'immutable'
import { push } from 'connected-react-router'
import { keyMirror } from '../../utils/utils'
import { coerceToArray } from '../../utils/stringUtils'
import { PRICE_IMPRESSION_TYPE } from '../../utils/advertise/adGroup'
import {
  DemoAgeHelper,
  DemoLocationHelper,
} from '../../utils/helper/helper-demoGraphic'
import {
  LOCATION_TYPE,
  MY_DATA_TARGET_KEY,
} from '../../utils/advertise/targeting'
import { getCombinedPopulationV2 } from '../targeting/mPopulation'
import {
  closeAllPopup,
  openPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../common/mPopup'
import { Trim } from '../../utils/formTrim'
import { PLUS_FRIEND_PROFILE_TRIM_KEY_PATH_LIST } from '../targeting/mTargeting'
import { scrollByValidationKeys, Validation } from '../../validators/validation'
import {
  NEW_CAMPAIGN_FORM_VALIDATION_KEY,
  NEW_CAMPAIGN_FORM_VALIDATION_KEY_PATH,
  NEW_CAMPAIGN_FORM_VALIDATOR,
  NEW_CAMPAIGN_TRIM_KEY_PATH_LIST,
} from '../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newCampaignFormValidator'
import {
  NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY,
  NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY_PATH,
  NEW_TRACKER_TARGETING_FORM_VALIDATOR_FOR_ADGROUP,
} from '../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newTrackerTargetingValidator'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import {
  checkEmpty,
  checkNotEmpty,
  isUndefinedOrNull,
} from '../../utils/regexUtils'
import DemoGraphicEnum from '../../enums/DemoGraphicEnum'
import AdGroupTargetEnum from '../../enums/AdGroupTargetEnum'
import {
  NEW_AD_GROUP_FORM_TRIM_KEY_PATH_LIST,
  NEW_AD_GROUP_FORM_VALIDATION_KEY,
  NEW_AD_GROUP_FORM_VALIDATION_KEY_PATH,
  NEW_AD_GROUP_FORM_VALIDATOR,
} from '../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newAdGroupFormValidator'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { AdGroupHelper } from '../../utils/helper/helper-adGroup'
import {
  checkAgeVerificationChange,
  checkBidStrategyOrPricingTypeChangeV2,
  checkDeviceOrPlacementChangeV2,
  checkTalkChannelReachModify,
  handleAdGroupFormExceptionV2,
} from './adGroupActions/aAdGroupCommonV2'
import {
  NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY,
  NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY_PATH,
  NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATOR_FOR_AD_GROUP,
} from '../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newKakaoUsersValidator'
import GoalEnum from '../../enums/GoalEnum'
import { setIsValidCampaignByKey } from './mCampaignV2'
import PlacementEnum from '../../enums/PlacementEnum'
import DeviceOsTypeEnum from '../../enums/DeviceOsTypeEnum'
import BidStrategyEnum from '../../enums/BidStrategyEnum'
import PacingEnum from '../../enums/PacingEnum'
import AdGroupConstraints from '../../utils/constraints/constraints-adGroup'
import {
  checkInvalidAdGroupSettings,
  isAdGroupScheduleReady,
  isTalkChannelReach,
} from '../../utils/advertise/campaignAdGroup'
import { AUDIENCE_TARGET } from '../../components/Targeting/Audience/Common/audience'
import ObjectiveTypeEnum from '../../enums/ObjectiveTypeEnum'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import { AdvertiseRouterV2 } from '../../utils/advertise/advertiseRouterV2'
import AudienceTypeEnum from '../../enums/AudienceTypeEnum'
import {
  adGroupBidAmountModifyReconfirmDialog,
  adGroupDailyBudgetModifyReconfirmDialog,
} from '../../components/DashboardV3/Dialog/AdGroupDialog'
import { createAdGroupAgeVerificationSettingDialog } from '../../components/AdvertiseV2/CampaignAndGroupV2/AdGroup/Common/AdGroupDialog'
import { campaignDailyBudgetConfirmDialog } from '../../components/DashboardV3/Dialog/CampaignDialog'
import { adGroupInvalidSettingDialog } from '../../components/AdvertiseV2/Common/AdvertiseDialog'

const availableTargetingTypes = {
  // 맞춤타겟 > 내데이터
  [AdGroupTargetEnum.Type.COHORT]: false,
  [AdGroupTargetEnum.Type.PIXEL_AND_SDK]: false,
  [AdGroupTargetEnum.Type.KAKAO_USER]: false,
  [AdGroupTargetEnum.Type.CUSTOMER_FILE]: false,
  [AdGroupTargetEnum.Type.FRIEND_GROUP]: false,

  // 맞춤타겟 > 추가 설정
  [AdGroupTargetEnum.Type.CATEGORY]: false,

  // 데모그래픽
  [AdGroupTargetEnum.Type.AGE]: false,
  [AdGroupTargetEnum.Type.GENDER]: false,
  [AdGroupTargetEnum.Type.LOCATION]: false,
}

const AdGroup = keyMirror(
  {
    // new
    INIT_AD_GROUPS: null,
    ADD_EMPTY_AD_GROUP: null,
    SET_OPEN_AD_GROUP_INDEX: null,
    DELETE_AD_GROUP_AT_INDEX: null,
    DELETE_ALL_AD_GROUPS: null,
    SET_IS_VALID_AD_GROUP_AT_INDEX_BY_KEY: null,
    CHANGE_AD_GROUP_FORM_BY_KEY: null,
    CHANGE_AD_GROUP_FORM_AT_INDEX_BY_KEY: null,
    UPDATE_AD_GROUP_VIEW_DATA: null,
    UPDATE_AD_GROUP_VIEW_STATE: null,
    UPDATE_AD_GROUP_VIEW_STATE_AT_INDEX_BY_KEY: null,

    // modify
    ADD_MODIFY_AD_GROUP: null,
    CHANGE_MODIFY_AD_GROUP_FORM_BY_KEY: null,

    // old
    INIT_ADGROUP_FORM_BY_KEY: null,
    INIT_ALL_ADGROUP_FORM: null,
    GET_ADGROUP_INFO_BY_ID: null,
    SET_IS_VALID_ADGROUP_BY_KEY: null,
    INIT_ADGROUP_VALIDATION_ERROR_KEYS: null,

    CLEAR_ALL_ADGROUP: null,

    ADD_DEFAULT_PLUS_FRIEND_TARGET_EXCLUDE: null,

    UPDATE_OLD_ADGROUP_INFO: null,
  },
  'AD_GROUP_NEW'
)

export const initialMessageSendingInfo = Map({
  sendRate: 0,
  pushAlarm: true,
  contractCount: 0,
  longTerm: false,
  ageVerification: false,
})

const initialAdGroupViewState = {
  availableTargetingTypes, // 광고그룹 제약조건 view state
  selectedPriceImpressionType: PRICE_IMPRESSION_TYPE.PRICE, // 구매노출수 or 구매 금액 선택값
  selectedLocationType: DemoGraphicEnum.Location.Type.CategoryV2.ALL,
  availablePlacements: [],
  pricingTypes: [],
  bidStrategyTypes: [BidStrategyEnum.Type.MANUAL, BidStrategyEnum.Type.AUTOBID], // 입찰방식 items
  pacingTypes: [PacingEnum.Type.QUICK, PacingEnum.Type.NORMAL], // 게재방식 items
  modifiableAdult: true, // 성인 타겟을 설정 가능한 지 여부(미설정으로 저장 -> 설정으로 수정만 가능함)
  modifiableLateNight: true, // 심야 타겟을 설정 가능한 지 여부(미설정으로 저장 -> 설정으로 수정만 가능함)
  talkChannelBeginDate: moment().format('YYYY-MM-DD'), // schedule 수정시 submit 직전에 변경
  talkChannelBeginTime: moment().format('HH:mm:00'),
  totalBudgetVAT: 0, // 도달( 카카오톡채널+다음쇼핑)일 때 totalBudget에 vat 계산된 금액을 담기 위해 사용
  oldFiveAges: [], // 오디언스 불러오기 전 설정된 fiveAges 값을 저장 (추후 오디언스 삭제시 원복 용도로 사용)
  isAvailableDailyBudget: true, // 일 예산 사용가능 여부
  isEndDateUnlimited: true, // 집행기간 미설정
  isConfirmedTotalBudget: false, // 기간 예산 설정 확인 여부
  isTalkBizBoardReservedBudgetChecked: false, // 비즈보드 CPT 기간예산 동의 여부
  isElectionBudgetChecked: false,
  isFreeCashReservedBudgetChecked: false, // 이벤트 무상캐시 기간예산 동의 여부
}

const initialState = fromJS({
  initialAdGroup: {
    key: '',
    adGroupForm: {
      key: '',
      // form submit 시에는 필터링한다.
      campaignId: -1,
      id: -1,
      name: '',
      status: [],
      dailyBudget: null, // 일 예산 (다음쇼핑 생성시 null 아니면 에러남.)
      totalBudget: 0, // 기간 예산
      totalBudgetWithVAT: 0,
      totalImpression: 0, // 구매 노출수
      bidStrategy: BidStrategyEnum.Type.MANUAL, // 자동입찰, 수동입찰
      bidStrategyTarget: null,
      bidAmount: 0, // 수동입찰일때 입력금액
      pricingType: null, // 과금타입
      pacing: PacingEnum.Type.QUICK, // 게재방식 (autoBid 이면 NONE)
      creativeOptimization: false,
      directMessagePrice: 15, // read-only (그룹 생성, 수정 시 core 에서 결정함)

      adServingCategories: [],
      sectionCategories: [], // 카카오톡 게재지면 하위 옵션
      deviceTypes: [],
      placements: [],
      allAvailableDeviceType: true, // display 기본: true, message 기본: false
      allAvailablePlacement: true, // display 기본: true, message 기본: null

      profileId: -1, // 카카오톡 채널

      adult: false, // 성인 타게팅,

      populationScore: 0, // 광고그룹 모수가 특정 값 이하면 validation 처리하기위해 추가

      messageSendingInfo: initialMessageSendingInfo,

      syncStatus: null, // AdGroupSyncStatusEnum

      retargetingFilters: [], // 카탈로그 유형 상품반응이벤트

      targeting: {
        id: -1,
        // 광고그룹에서 생성시 normal, 오디언스에서 생성시 audience
        audienceTarget: AUDIENCE_TARGET.AD_GROUP,
        audienceType: AudienceTypeEnum.Type.NORMAL,

        fiveAges: [],
        genders: [],
        locations: DemoLocationHelper().createNational(),

        // 맞춤타겟
        cohortTargetings: [],
        trackerTargetings: [],
        plusFriendTargetings: [],
        syncAppTargetings: [],
        customerFileTargetings: [],
        retargetingApps: [],

        // 톡채널 유형 target (message)
        plusFriendGroupTargetings: [],

        // 추가설정 타겟
        ufoInterests: [],
        ufoBusinessTypes: [],

        /**
         * @deprecated
         */
        services: [], // 서비스
        lookalikeTargetings: [], // 유사타겟
        keywords: [], // 키워드 타겟
        contents: [], // kako tv 동영상 타겟
        momentLocations: [], // 현재위치 타겟
        depth2Locations: [], // 시/군/구 (locations 와 상호 배타적)
      },

      schedule: {
        detailTime: false,
        lateNight: false, // 심야 타게팅
        periodUnit: null,
        beginDate: moment().format('YYYY-MM-DD'),
        beginTime: '08:00:00',
        endDate: null,
        endTime: null,
        mondayTime: [],
        tuesdayTime: [],
        wednesdayTime: [],
        thursdayTime: [],
        fridayTime: [],
        saturdayTime: [],
        sundayTime: [],
      },
      useWifiOnly: false,
      dynamicTarget: false,
      smartMessage: false,
    },

    adGroupViewState: {
      ...initialAdGroupViewState,
      depositCancellationFeeAgreement: false,
    },
    validationErrorKeys: {},
  },
  creatingAdGroups: [],
  openAdGroupIndex: -1,

  /**
   * Modify AdGroup
   */
  modifyAdGroup: {
    adGroupForm: {},
    validationErrorKeys: {},
    adGroupViewState: {
      ...initialAdGroupViewState,
      isMobileOSOpened: false,
      isExpandedNetwork: true,
      isExpandedCategoryTargeting: false,
      isExpandedKeywordTargeting: false,
      isExpandedMyDataTargeting: false,
      isExpandedAdultTargeting: false,
      isExpandedAgeVerification: false,
      isExpandedLateNightTargeting: false,
      isExpandedCreativeOptimization: false,
      isExpandedUseWifiOnlyTargeting: false,
    },
  },

  /**
   * GET, Create, Modify 때마다 갱신한다.
   */
  oldAdGroupForm: {},
})

const POPULATION_TARGET_KEYS = [
  'campaign',
  'deviceTypes',
  'placements',
  'adServingCategories',
  'targeting',
  'pricingType',
  'allAvailableDeviceType',
  'dynamicTarget',
  'smartMessage',
]

export default createReducer(initialState, {
  [AdGroup.INIT_AD_GROUPS]: state => state.set('creatingAdGroups', fromJS([])),

  [AdGroup.ADD_EMPTY_AD_GROUP]: (state, { adGroupKey }) => {
    const { creatingAdGroups } = state

    return state.set(
      'creatingAdGroups',
      creatingAdGroups.push(
        state
          .get('initialAdGroup')
          .withMutations(s =>
            s.setIn(['adGroupForm', 'key'], adGroupKey).set('key', adGroupKey)
          )
      )
    )
  },

  [AdGroup.SET_OPEN_AD_GROUP_INDEX]: (state, { adGroupIndex }) =>
    state.set('openAdGroupIndex', adGroupIndex),

  [AdGroup.DELETE_AD_GROUP_AT_INDEX]: (state, { index }) => {
    const { creatingAdGroups } = state
    return state.set('creatingAdGroups', creatingAdGroups.delete(index))
  },

  [AdGroup.DELETE_ALL_AD_GROUPS]: state => {
    return state.set('creatingAdGroups', initialState.get('creatingAdGroups'))
  },

  [AdGroup.INIT_ADGROUP_FORM_BY_KEY]: (state, { key }) =>
    state.setIn(
      ['adGroupForm', ...coerceToArray(key)],
      initialState.getIn([
        'modifyAdGroup',
        'adGroupForm',
        ...coerceToArray(key),
      ])
    ),

  [AdGroup.INIT_ALL_ADGROUP_FORM]: state =>
    state.withMutations(s => {
      s.set(
        'adGroupForm',
        initialState.getIn(['initialAdGroup', 'adGroupForm'])
      ).set('oldAdGroupForm', initialState.get('oldAdGroupForm'))
    }),

  [AdGroup.SET_IS_VALID_ADGROUP_BY_KEY]: (state, { key, isValid, message }) =>
    state.setIn(
      ['modifyAdGroup', 'validationErrorKeys'],
      isValid
        ? state.getIn(['modifyAdGroup', 'validationErrorKeys']).delete(key)
        : state
            .getIn(['modifyAdGroup', 'validationErrorKeys'])
            .set(key, message)
    ),

  [AdGroup.SET_IS_VALID_AD_GROUP_AT_INDEX_BY_KEY]: (
    state,
    { index, key, isValid, message }
  ) => {
    const { creatingAdGroups } = state
    const { validationErrorKeys } = creatingAdGroups.get(index)
    const errorKeys = !validationErrorKeys ? Map() : validationErrorKeys
    const newErrorKeys = isValid
      ? errorKeys.delete(key)
      : errorKeys.set(key, message)

    return state.setIn(
      ['creatingAdGroups', index, 'validationErrorKeys'],
      newErrorKeys
    )
  },

  [AdGroup.INIT_ADGROUP_VALIDATION_ERROR_KEYS]: state =>
    state.setIn(
      ['modifyAdGroup', 'validationErrorKeys'],
      initialState.getIn(['modifyAdGroup', 'validationErrorKeys'])
    ),

  [AdGroup.GET_ADGROUP_INFO_BY_ID]: (state, { data }) => {
    const {
      modifyAdGroup: { adGroupForm, adGroupViewState },
    } = state

    // 맞춤타겟 object 를 name or trackRuleName 오름차순으로 정렬한다.
    const targeting = Map.isMap(data) ? data.get('targeting') : data.targeting
    const sortedCustomTargetingObject = fromJS(targeting)
      .filter((v, k) => Object.keys(MY_DATA_TARGET_KEY).includes(k))
      .map(v =>
        v.sort((v1, v2) => {
          if (v.has('name')) {
            return v1.get('name').localeCompare(v2.get('name'), 'ko')
          } else if (v.has('trackRuleName')) {
            return v1
              .get('trackRuleName')
              .localeCompare(v2.get('trackRuleName'), 'ko')
          } else {
            return 0
          }
        })
      )

    // update form data
    const newAdGroupForm = adGroupForm.withMutations(s => {
      s.merge(fromJS(data))
        .mergeIn(['targeting'], sortedCustomTargetingObject)
        .updateIn(['targeting', 'locations'], locations =>
          !locations || locations.size === 0
            ? locations
            : DemoLocationHelper(locations).getOrdered()
        )
    })

    // update view state
    const { deviceTypes, schedule } = newAdGroupForm

    const newAdGroupViewState = adGroupViewState.withMutations(s => {
      s.set(
        'isMobileOsOpened',
        deviceTypes &&
          (deviceTypes.includes(DeviceOsTypeEnum.Type.IOS) ||
            deviceTypes.includes(DeviceOsTypeEnum.Type.ANDROID))
      )
        .set(
          'selectedLocationType',
          AdGroupHelper.Location.getSelectedType(targeting)
        )
        .set(
          'isEndDateUnlimited',
          schedule && checkEmpty(schedule.get('endDate'))
        )
    })

    return state.withMutations(s => {
      s.setIn(['modifyAdGroup', 'adGroupForm'], newAdGroupForm).setIn(
        ['modifyAdGroup', 'adGroupViewState'],
        newAdGroupViewState
      )
    })
  },

  [AdGroup.CLEAR_ALL_ADGROUP]: state => state.merge(initialState),

  [AdGroup.UPDATE_OLD_ADGROUP_INFO]: (state, { data }) => {
    return state.set('oldAdGroupForm', fromJS(data))
  },

  [AdGroup.CHANGE_AD_GROUP_FORM_BY_KEY]: (state, { key, value }) =>
    state.withMutations(s => {
      s.setIn(
        ['modifyAdGroup', 'adGroupForm', ...coerceToArray(key)],
        fromJS(value)
      )
    }),

  [AdGroup.CHANGE_AD_GROUP_FORM_AT_INDEX_BY_KEY]: (
    state,
    { index, key, value }
  ) =>
    state.withMutations(s => {
      const keyPath = ['creatingAdGroups', index, 'adGroupForm']

      s.setIn(keyPath.concat(...coerceToArray(key)), fromJS(value)).setIn(
        keyPath.concat(['isFormDirty']),
        true
      )
    }),

  [AdGroup.UPDATE_AD_GROUP_VIEW_STATE_AT_INDEX_BY_KEY]: (
    state,
    { index, key, value }
  ) =>
    state.setIn(
      ['creatingAdGroups', index, 'adGroupViewState', ...coerceToArray(key)],
      value
    ),

  [AdGroup.UPDATE_AD_GROUP_VIEW_STATE]: (state, { key, value }) =>
    state.setIn(
      ['modifyAdGroup', 'adGroupViewState', ...coerceToArray(key)],
      value
    ),

  [AdGroup.ADD_MODIFY_AD_GROUP]: (state, { data }) =>
    state.setIn(['modifyAdGroup', 'adGroupForm'], fromJS(data)),
})

export function initAdGroups() {
  return {
    type: AdGroup.INIT_AD_GROUPS,
  }
}

export function addEmptyAdGroup(adGroupKey) {
  return {
    type: AdGroup.ADD_EMPTY_AD_GROUP,
    adGroupKey,
  }
}

export function deleteAdGroupAtIndex(index) {
  return {
    type: AdGroup.DELETE_AD_GROUP_AT_INDEX,
    index,
  }
}

export function deleteAllAdGroups() {
  return {
    type: AdGroup.DELETE_ALL_AD_GROUPS,
  }
}

// 게재지면에 network 추가되었을 시 상세 메뉴 open
export function updatePlacementNetworkView(adGroupForm) {
  return dispatch => {
    const { placements } = adGroupForm

    dispatch(
      updateAdGroupViewState(
        'isExpandedNetwork',
        placements?.includes(PlacementEnum.Type.NETWORK)
      )
    )
  }
}

export function changeAdGroupForm(key, value) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex },
    } = getState()

    const isCreating = !creatingAdGroups.isEmpty()

    if (isCreating) {
      dispatch(changeAdGroupFormAtIndexByKey(openAdGroupIndex, key, value))
    } else {
      dispatch(changeAdGroupFormByKey(key, value))
    }
  }
}

export function changeAdGroupFormByKey(key, value) {
  return (dispatch, getState) => {
    dispatch({
      type: AdGroup.CHANGE_AD_GROUP_FORM_BY_KEY,
      key,
      value,
    })

    const {
      adGroupV2: {
        modifyAdGroup: { adGroupForm },
      },
      loading,
    } = getState()

    const targetChanged = POPULATION_TARGET_KEYS.some(t => {
      return Array.isArray(key) ? key.some(k => k === t) : key === t
    })

    const isAdGroupLoading = loading.has(LOADING_KEY.SETUP_AD_GROUP)

    if (targetChanged && !isAdGroupLoading) {
      const newAdGroupForm = adGroupForm.setIn(
        coerceToArray(key),
        fromJS(value)
      )

      dispatch(updateAdGroupPopulation(newAdGroupForm))
    }
  }
}

export function changeAdGroupFormAtIndexByKey(index, key, value) {
  return (dispatch, getState) => {
    dispatch({
      type: AdGroup.CHANGE_AD_GROUP_FORM_AT_INDEX_BY_KEY,
      index,
      key,
      value,
    })

    const {
      adGroupV2: { creatingAdGroups },
      loading,
    } = getState()

    const targetChanged = POPULATION_TARGET_KEYS.some(t => {
      return Array.isArray(key) ? key.some(k => k === t) : key === t
    })

    const isAdGroupLoading = loading.has(LOADING_KEY.SETUP_AD_GROUP)

    if (targetChanged && !isAdGroupLoading) {
      const { adGroupForm } = creatingAdGroups.get(index)

      const newAdGroupForm = adGroupForm.setIn(
        coerceToArray(key),
        fromJS(value)
      )

      dispatch(updateAdGroupPopulation(newAdGroupForm))
    }
  }
}

export function updateAdGroupPopulation(adGroupForm) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
      campaignV2: {
        campaignForm: {
          campaignTypeGoal,
          campaignTypeGoal: { campaignType, goal },
          objective,
        },
      },
    } = getState()

    const { placements = List() } = adGroupForm

    const populationRequired = AdGroupConstraints.isPopulationRequired({
      campaignType,
      goal,
    })

    if (!populationRequired || placements.isEmpty()) return

    if (adAccountId > 0) {
      // 7.4.8 조회로 통일
      dispatch(
        getCombinedPopulationV2(
          adAccountId,
          adGroupForm.set('campaign', { campaignTypeGoal, objective })
        )
      )
    }
  }
}

export function setOpenAdGroupIndex(adGroupIndex) {
  return {
    type: AdGroup.SET_OPEN_AD_GROUP_INDEX,
    adGroupIndex,
  }
}

export function updateAdGroupViewStateV2(key, value) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex },
    } = getState()

    const isCreating = !creatingAdGroups.isEmpty()

    if (isCreating) {
      dispatch(updateAdGroupViewStateAtIndexByKey(openAdGroupIndex, key, value))
    } else {
      dispatch(updateAdGroupViewState(key, value))
    }
  }
}

export function updateAdGroupViewStateAtIndexByKey(index, key, value) {
  return {
    type: AdGroup.UPDATE_AD_GROUP_VIEW_STATE_AT_INDEX_BY_KEY,
    index,
    key,
    value,
  }
}

export function updateAdGroupViewState(key, value) {
  return {
    type: AdGroup.UPDATE_AD_GROUP_VIEW_STATE,
    key,
    value,
  }
}

export function setIsValidAdGroup(key, isValid, message) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex },
    } = getState()

    const isCreating = !creatingAdGroups.isEmpty()

    if (isCreating) {
      dispatch(
        setIsValidAdGroupAtIndexByKey(openAdGroupIndex, key, isValid, message)
      )
    } else {
      dispatch(setIsValidAdGroupByKey(key, isValid, message))
    }
  }
}

export function setIsValidAdGroupByKey(key, isValid, message) {
  return {
    type: AdGroup.SET_IS_VALID_ADGROUP_BY_KEY,
    key,
    isValid,
    message,
  }
}

export function setIsValidAdGroupAtIndexByKey(index, key, isValid, message) {
  return {
    type: AdGroup.SET_IS_VALID_AD_GROUP_AT_INDEX_BY_KEY,
    index,
    key,
    isValid,
    message,
  }
}

export function modifyAdGroupV2(adAccountId, adGroupId, formData) {
  return (dispatch, getState, api) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
      },
      adGroupV2: {
        modifyAdGroup: {
          adGroupViewState: { talkChannelBeginDate, talkChannelBeginTime },
        },
        oldAdGroupForm: {
          schedule: {
            beginDate: oldFormBeginDate,
            beginTime: oldFormBeginTime,
          },
        },
      },
    } = getState()

    const isMessage = isTalkChannelReach(campaignType, goal)

    const { messageSendingInfo } = formData
    const { longTerm } = messageSendingInfo || {} // nullable

    // pre-processing
    const adGroup = Trim(
      formData,
      NEW_AD_GROUP_FORM_TRIM_KEY_PATH_LIST
    ).withMutations(s =>
      s
        .updateIn(['schedule', 'beginDate'], beginDate =>
          isMessage ? talkChannelBeginDate : beginDate
        )
        .updateIn(['schedule', 'beginTime'], beginTime =>
          isMessage ? talkChannelBeginTime : beginTime
        )
        .updateIn(['schedule', 'endDate'], endDate =>
          isMessage && longTerm === false ? null : endDate
        )
        .updateIn(['schedule', 'endTime'], endTime =>
          isMessage && longTerm === false ? null : endTime
        )
        .update(
          'smartMessage',
          smartMessage => (isMessage ? smartMessage : false) // 메시지를 제외하고 기존의 스마트메시지 설정을 무시한다.
        )
        // dynamicTarget, creativeOptimization 이제 미사용(이전 광고그룹으로 설정된 아이들이 남아 있어서 초기화)
        .set('dynamicTarget', false)
        .set('creativeOptimization', false)
    )

    // const newAdGroupForm = formData
    const result = Validation(
      adGroup,
      NEW_AD_GROUP_FORM_VALIDATION_KEY,
      NEW_AD_GROUP_FORM_VALIDATION_KEY_PATH,
      NEW_AD_GROUP_FORM_VALIDATOR,
      getState,
      setIsValidAdGroup,
      dispatch
    )

    if (!result) {
      const {
        adGroupV2: {
          modifyAdGroup: { validationErrorKeys },
        },
      } = getState()

      if (
        // 톡채널 구매발송수 input 초기화를 위한 처리''
        campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
        (validationErrorKeys.has(
          NEW_AD_GROUP_FORM_VALIDATION_KEY.TOTAL_BUDGET
        ) ||
          validationErrorKeys.has(NEW_AD_GROUP_FORM_VALIDATION_KEY.BID_AMOUNT))
      ) {
        dispatch(updateAdGroupViewStateV2('isConfirmedTotalBudget', false))
      }

      scrollByValidationKeys(
        validationErrorKeys,
        NEW_CAMPAIGN_FORM_VALIDATION_KEY
      )

      return
    }

    const {
      adGroupV2: { oldAdGroupForm },
      adConstraints: {
        adGroupConstraints: { pricingTypeConstraints },
      },
    } = getState()

    const {
      bidAmount,
      bidStrategy,
      bidStrategyTarget,
      pricingType,
      dailyBudget,
    } = formData

    const prevBidStrategy = oldAdGroupForm.get('bidStrategy')
    const prevBidStrategyTarget = oldAdGroupForm.get('bidStrategyTarget')
    const prevBidAmount = oldAdGroupForm.get('bidAmount')
    const prevPricingType = oldAdGroupForm.get('pricingType')
    const prevDailyBudget = oldAdGroupForm.get('dailyBudget')
    const prevAgeVerification = oldAdGroupForm.getIn([
      'messageSendingInfo',
      'ageVerification',
    ])

    const onSubmit = async () => {
      try {
        dispatch(showLoading())

        await api.adGroup.modifyAdGroup(adAccountId, adGroupId, adGroup)

        dispatch(push(DashboardRouter.Path.Prev(adAccountId)))
      } catch (e) {
        dispatch(handleAdGroupFormExceptionV2(e))
      } finally {
        dispatch(hideLoading())
      }
    }

    const hasInvalidAdGroupSetting = checkInvalidAdGroupSettings(formData)

    if (hasInvalidAdGroupSetting) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          adGroupInvalidSettingDialog(onSubmit)
        )
      )
    } else {
      if (
        campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
        goal === GoalEnum.Type.REACH
      ) {
        const ageVerification = formData.getIn([
          'messageSendingInfo',
          'ageVerification',
        ])

        checkAgeVerificationChange(
          dispatch,
          prevAgeVerification,
          ageVerification,
          () => {
            /*
             * viewState의 talkChannelBeginTime, talkChannelBeginDate을 사용하지 않은것은
             * 메시지 발송시작 시점을 더 빠르게 변경하는 경우에 (i.e. 11시 50분 -> 11시 10분)
             * 11시 7분에 타게팅을 변경가능한데 viewState의 값을 이용하는 경우에 팝업을 띄어줄수 가 없어서
             * oldForm의 begineTime, beginDate를 기준으로 분기
             */
            const isAdGroupReadyStatus = isAdGroupScheduleReady({
              beginDate: oldFormBeginDate,
              beginTime: oldFormBeginTime,
              campaignType,
              goal,
            })

            checkTalkChannelReachModify(
              dispatch,
              isAdGroupReadyStatus,
              onSubmit
            )
          }
        )
      } else {
        checkDeviceOrPlacementChangeV2(
          dispatch,
          oldAdGroupForm,
          formData,
          () => {
            checkBidStrategyOrPricingTypeChangeV2(
              dispatch,
              bidAmount,
              bidStrategy,
              prevBidStrategy,
              bidStrategyTarget,
              prevBidStrategyTarget,
              pricingType,
              prevPricingType,
              pricingTypeConstraints,
              adAccountId,
              () => {
                const reconfirmDailyBudget = () => {
                  if (dailyBudget !== prevDailyBudget) {
                    dispatch(
                      openPopupByProxy(
                        POPUP_KEY.SIMPLE_POPUP,
                        adGroupDailyBudgetModifyReconfirmDialog({
                          dailyBudget,
                          onOK: onSubmit,
                        })
                      )
                    )
                  } else {
                    onSubmit()
                  }
                }

                if (
                  bidAmount !== prevBidAmount &&
                  !BidStrategyEnum.isAdGroupAutoBid(bidStrategy)
                ) {
                  dispatch(
                    openPopupByProxy(
                      POPUP_KEY.SIMPLE_POPUP,
                      adGroupBidAmountModifyReconfirmDialog({
                        bidAmount,
                        onOK: reconfirmDailyBudget,
                      })
                    )
                  )
                } else {
                  reconfirmDailyBudget()
                }
              }
            )
          }
        )
      }
    }
  }
}

export function createCampaignAdGroup(
  adAccountId,
  campaignForm,
  creatingAdGroups,
  contractInfo,
  moveToCreative = false
) {
  return async (dispatch, getState, api) => {
    const {
      campaignTypeGoal: { campaignType, goal },
    } = campaignForm

    // pre-processing
    const campaign = Trim(campaignForm, NEW_CAMPAIGN_TRIM_KEY_PATH_LIST)
      .withMutations(s => s.set('adAccountId', adAccountId))
      .update('objective', o =>
        AdGroupConstraints.isObjectiveRequired({ campaignType, goal })
          ? o
          : null
      )

    const { id: campaignId, dailyBudget } = campaign
    const validationChecker = []

    const campaignValidationResult = Validation(
      campaign,
      NEW_CAMPAIGN_FORM_VALIDATION_KEY,
      NEW_CAMPAIGN_FORM_VALIDATION_KEY_PATH,
      NEW_CAMPAIGN_FORM_VALIDATOR,
      getState,
      setIsValidCampaignByKey,
      dispatch
    )

    const adGroups = creatingAdGroups.map((adGroup, index) => {
      const { adGroupForm, adGroupViewState } = adGroup
      const { isFreeCashReservedBudgetChecked } = adGroupViewState
      const setIsValidAdGroupByKey = (key, isValid, message) =>
        setIsValidAdGroupAtIndexByKey(index, key, isValid, message)
      const adGroupFormData = Trim(
        adGroupForm,
        NEW_AD_GROUP_FORM_TRIM_KEY_PATH_LIST
      )

      validationChecker.push(
        Validation(
          adGroupFormData,
          NEW_AD_GROUP_FORM_VALIDATION_KEY,
          NEW_AD_GROUP_FORM_VALIDATION_KEY_PATH,
          NEW_AD_GROUP_FORM_VALIDATOR,
          getState,
          setIsValidAdGroupByKey,
          dispatch
        )
      )

      return adGroupFormData.withMutations(s =>
        s
          .set('adAccountId', adAccountId)
          .set('campaignId', campaignId)
          .set('useEventFreeCash', isFreeCashReservedBudgetChecked)
      )
    })

    const {
      adGroupV2: { openAdGroupIndex },
    } = getState()

    if (!campaignValidationResult) {
      const {
        campaignV2: { validationErrorKeys },
      } = getState()

      scrollByValidationKeys(
        validationErrorKeys,
        NEW_CAMPAIGN_FORM_VALIDATION_KEY
      )

      return
    }

    if (validationChecker.some(v => !v)) {
      const {
        adGroupV2: {
          creatingAdGroups: currentList,
          openAdGroupIndex: currentIndex,
        },
      } = getState()
      const { validationErrorKeys } = currentList.get(currentIndex)

      if (
        // 톡채널 구매발송수 input 초기화를 위한 처리
        (campaignType === CampaignTypeEnum.Type.TALK_CHANNEL ||
          campaignType === CampaignTypeEnum.Type.KAKAO_TV) &&
        (validationErrorKeys.has(
          NEW_AD_GROUP_FORM_VALIDATION_KEY.TOTAL_BUDGET
        ) ||
          validationErrorKeys.has(NEW_AD_GROUP_FORM_VALIDATION_KEY.BID_AMOUNT))
      ) {
        dispatch(
          updateAdGroupViewStateAtIndexByKey(
            currentIndex,
            'isConfirmedTotalBudget',
            false
          )
        )
      }

      scrollByValidationKeys(
        validationErrorKeys,
        NEW_AD_GROUP_FORM_VALIDATION_KEY
      )

      return
    }

    const handleSubmit = async () => {
      try {
        dispatch(showLoading())

        const createAdGroupsOnly = campaignId > 0

        const fetchCreateApi = createAdGroupsOnly
          ? api.adGroup.createMultiAdGroups
          : api.adGroup.createCampaignAdGroups

        const contract = Map().withMutations(s => {
          const {
            name,
            prices,
            viewState: { selectedDate, beginDateTime, isNewContract },
          } = contractInfo

          if (isNewContract) {
            const { id: productPriceId = -1 } =
              prices.find(
                ({ timeslot: { beginTime } }) => beginTime === beginDateTime
              ) || {}

            const newContractProductItems = [
              {
                productPriceId,
                beginDate: selectedDate,
                endDate: selectedDate,
              },
            ]

            return s
              .set('contractProductItems', newContractProductItems)
              .set('name', name)
          }

          return s
        })

        const { data } = await fetchCreateApi(
          {
            adGroups,
            campaign,
            contract,
          },
          adAccountId
        )

        const createdAdGroupIds = data.adGroups.map(({ id }) => id)

        if (moveToCreative) {
          dispatch(closeAllPopup())

          /**
           * 생성 완료 후 소재 만들기 이동
           * 소재 만들기 대상 그룹 선택 or not
           */
          if (createdAdGroupIds.length > 1) {
            // 소재 만들기 대상 그룹 선택
            dispatch(
              openPopup(POPUP_KEY.SELECT_AD_GROUP, {
                adAccountId,
                campaignId: data.campaign.id,
                adGroups: data.adGroups,
              })
            )
          } else {
            dispatch(
              push(
                AdvertiseRouterV2.Path.getCreativePageV2(adAccountId, {
                  campaignId: data.campaign.id,
                  adGroupId: data.adGroups[0].id,
                })
              )
            )
          }
        } else {
          dispatch(push(DashboardRouter.Path.Prev(adAccountId)))
        }
      } catch (e) {
        dispatch(handleAdGroupFormExceptionV2(e, openAdGroupIndex))
      } finally {
        dispatch(hideLoading())
      }
    }

    const isSetAgeVerification = adGroups.some(_adGroupForm =>
      _adGroupForm.getIn(['messageSendingInfo', 'ageVerification'])
    )

    const hasInvalidAdGroupSetting = adGroups.some(_adGroupForm =>
      checkInvalidAdGroupSettings(_adGroupForm)
    )

    if (isSetAgeVerification) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          createAdGroupAgeVerificationSettingDialog(handleSubmit)
        )
      )
    } else if (checkNotEmpty(dailyBudget)) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          campaignDailyBudgetConfirmDialog(handleSubmit)
        )
      )
    } else if (hasInvalidAdGroupSetting) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          adGroupInvalidSettingDialog(handleSubmit)
        )
      )
    } else {
      await handleSubmit()
    }
  }
}

export function initAdGroupFormByKey(key) {
  return {
    type: AdGroup.INIT_ADGROUP_FORM_BY_KEY,
    key,
  }
}

export function initAllAdGroupForm() {
  return {
    type: AdGroup.INIT_ALL_ADGROUP_FORM,
  }
}

export function initAdGroupValidationErrorKeys() {
  return {
    type: AdGroup.INIT_ADGROUP_VALIDATION_ERROR_KEYS,
  }
}

export function receiveAdGroupInfoV2(data) {
  return dispatch => {
    dispatch(updateOldAdGroupInfo(data))

    return dispatch({
      type: AdGroup.GET_ADGROUP_INFO_BY_ID,
      data,
    })
  }
}

export function clearAllAdGroup() {
  return {
    type: AdGroup.CLEAR_ALL_ADGROUP,
  }
}

export function savePlusFriendProfileFormToAdGroup(
  plusFriendProfileForm,
  validationCallback
) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const { adGroupForm } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const {
      targeting: { plusFriendTargetings },
    } = adGroupForm

    // pre-processing
    const formData = Trim(
      plusFriendProfileForm,
      PLUS_FRIEND_PROFILE_TRIM_KEY_PATH_LIST
    )

    // validation
    const validationResult = Validation(
      formData,
      NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY,
      NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY_PATH,
      NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATOR_FOR_AD_GROUP,
      getState,
      validationCallback
    )

    if (!validationResult) return

    // save and init, close popup
    const newPlusFriendTargetings = plusFriendTargetings.unshift(formData)

    dispatch(
      changeAdGroupForm(
        ['targeting', 'plusFriendTargetings'],
        newPlusFriendTargetings
      )
    )

    dispatch(closeAllPopup())
  }
}

export function saveLoginUserProfileFormToAdGroup(profileFormData) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const { adGroupForm } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const {
      targeting: { syncAppTargetings },
    } = adGroupForm

    const newSyncAppTargetings = syncAppTargetings.unshift(profileFormData)

    dispatch(
      changeAdGroupForm(
        ['targeting', 'syncAppTargetings'],
        newSyncAppTargetings
      )
    )

    dispatch(closeAllPopup())
  }
}

export function saveCustomerFileTargetingFormToAdGroup(
  customerFileTargetingForm
) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const { adGroupForm } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const {
      targeting: { customerFileTargetings },
    } = adGroupForm

    // save and init, close popup
    const newCustomerFileTargetings = customerFileTargetings.unshift(
      customerFileTargetingForm
    )

    dispatch(
      changeAdGroupForm(
        ['targeting', 'customerFileTargetings'],
        newCustomerFileTargetings
      )
    )

    dispatch(closeAllPopup())
  }
}

export function saveCohortTargetingFormToAdGroup(cohortTargetingForm) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const { adGroupForm } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const {
      targeting: { cohortTargetings },
    } = adGroupForm

    // save and init, close popup
    const newCohortTargetings = cohortTargetings.unshift(cohortTargetingForm)

    dispatch(
      changeAdGroupForm(['targeting', 'cohortTargetings'], newCohortTargetings)
    )

    dispatch(closeAllPopup())
  }
}

export function savePlusFriendGroupTargetingFormToAdGroup(
  plusFriendGroupTargetingForm
) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const { adGroupForm } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const {
      targeting: { plusFriendGroupTargetings },
    } = adGroupForm

    // save and init, close popup
    const newFriendGroupTargetings = plusFriendGroupTargetings.unshift(
      plusFriendGroupTargetingForm
    )

    dispatch(
      changeAdGroupForm(
        ['targeting', 'plusFriendGroupTargetings'],
        newFriendGroupTargetings
      )
    )

    // 친구그룹 선택 시 디바이스 - 가능한 모든 디바이스 노출 (KAMOQA-10427)
    dispatch(changeAdGroupForm('allAvailableDeviceType', true))
    dispatch(changeAdGroupForm('deviceTypes', []))

    dispatch(closeAllPopup())
  }
}

export function saveTrackerTargetingFormToAdGroup(
  trackerTargetingForm,
  validationCallback
) {
  return (dispatch, getState) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const { adGroupForm } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const {
      targeting: { trackerTargetings },
    } = adGroupForm

    const validationResult = Validation(
      trackerTargetingForm,
      NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY,
      NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY_PATH,
      NEW_TRACKER_TARGETING_FORM_VALIDATOR_FOR_ADGROUP,
      getState,
      validationCallback
    )

    if (!validationResult) return

    // save and init, close popup
    const newTrackerTargetings = trackerTargetings.unshift(trackerTargetingForm)

    dispatch(
      changeAdGroupForm(
        ['targeting', 'trackerTargetings'],
        newTrackerTargetings
      )
    )

    dispatch(closeAllPopup())
  }
}

/**
 * get, create, modify 시 항상 마지막 adGroup info 를 보관한다.
 */
export function updateOldAdGroupInfo(data) {
  return {
    type: AdGroup.UPDATE_OLD_ADGROUP_INFO,
    data,
  }
}

export function setModifyAdGroupForm(data) {
  return dispatch => {
    dispatch({
      type: AdGroup.ADD_MODIFY_AD_GROUP,
      data,
    })

    dispatch(receiveAdGroupInfoV2(data))
  }
}

const getInitialAdGroupTargetingForm = campaignType => {
  const isOnlyNationalLocation =
    AdGroupConstraints.Demographic.isNationalOnlyLocationRequired({
      campaignType,
    })

  return initialState
    .getIn(['initialAdGroup', 'adGroupForm', 'targeting'])
    .set(
      'locations',
      isOnlyNationalLocation
        ? DemoLocationHelper().createNational()
        : DemoLocationHelper().createGlobal()
    )
    .set('audienceType', AudienceTypeEnum.Type.NORMAL)
}

export const initAdGroupTargetingForm = () => {
  return (dispatch, getState) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType },
        },
      },

      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const oldAdGroup = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    const targeting = getInitialAdGroupTargetingForm(campaignType)
    dispatch(changeAdGroupForm('targeting', targeting))

    dispatch(
      updateAdGroupViewStateV2('selectedLocationType', LOCATION_TYPE.ALL)
    )

    dispatch(updateAdGroupFormByDefaultSetting(oldAdGroup, targeting))
  }
}

const updateAdGroupFormByDefaultSetting = (oldAdGroup, targeting) => {
  return (dispatch, getState) => {
    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { goal },
          objective,
        },
      },
    } = getState()

    const {
      adGroupForm: { adult: adultChecked, targeting: oldTargeting },
      adGroupViewState: { oldFiveAges },
    } = oldAdGroup

    const isNotAdultSelected =
      oldFiveAges.isEmpty() || DemoAgeHelper(oldFiveAges).has('15')
    const needDefaultAdultValue = adultChecked && isNotAdultSelected
    const fiveAges = needDefaultAdultValue
      ? DemoAgeHelper().createAdult()
      : oldFiveAges

    const { value, type } = objective || {}
    const isConversionGoal = goal === GoalEnum.Type.CONVERSION
    const isTalkChannelObjectiveType =
      type === ObjectiveTypeEnum.Type.TALK_CHANNEL

    if (isConversionGoal && isTalkChannelObjectiveType) {
      const { plusFriendTargetings } = oldTargeting
      const { plusFriendTargetings: newPlusFriendTargetings } = targeting

      const selectedObjectiveItem = plusFriendTargetings.find(
        v => v.get('profileId') === Number(value)
      )

      if (!isUndefinedOrNull(selectedObjectiveItem)) {
        const newTargeting = targeting.withMutations(s =>
          s
            .set('fiveAges', fiveAges)
            .set(
              'plusFriendTargetings',
              newPlusFriendTargetings.unshift(selectedObjectiveItem)
            )
        )

        dispatch(changeAdGroupForm('targeting', newTargeting))
      }
    } else {
      const newTargeting = targeting.set('fiveAges', fiveAges)
      dispatch(changeAdGroupForm('targeting', newTargeting))
    }
  }
}

export const updateProductCatalogEventItem = (
  eventCode,
  value,
  retargetingFilters
) => {
  return dispatch => {
    const newRetargetingFilters = retargetingFilters.update(
      retargetingFilters.findIndex(
        ({ eventCode: _eventCode }) => _eventCode === eventCode
      ),
      item => item.set('term', value)
    )

    dispatch(changeAdGroupForm('retargetingFilters', newRetargetingFilters))
  }
}

export const buildProductCatalogEventItem = (eventCode, retargetingFilters) => {
  return (dispatch, getState) => {
    const {
      campaignV2: {
        campaignForm: { trackId },
      },
      adConstraints: {
        adGroupConstraints: { retargetingFilterConstraints },
      },
    } = getState()

    const {
      eventCode: code,
      unit,
      term: { guide },
      inclusionType,
    } = retargetingFilterConstraints.find(
      ({ eventCode: _eventCode }) => _eventCode === eventCode
    )

    const newRetargetingFilters = retargetingFilters.push(
      Map({
        trackId,
        unit,
        inclusionType,
        eventCode: code,
        term: guide,
      })
    )

    dispatch(changeAdGroupForm('retargetingFilters', newRetargetingFilters))
  }
}
