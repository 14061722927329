import React from 'react'
import PropTypes from 'prop-types'
import { Range } from 'immutable'
import PagingHOC from '../PagingHOC'
import OptionSelectList from '../OptionSelectList'
import cx from 'classnames'

const DEFAULT_PAGE_ITEMS = [10, 20, 50, 100]

const TablePaging = ({
  className,
  controlButtonClassName = 'num_paging',
  numberButtonClassName = 'num_paging',
  isGoFirstActive,
  isGoPrevActive,
  isGoNextActive,
  isGoLastActive,
  hasGoFirstButton = true,
  hasGoLastButton = true,
  hasPageSizeSelect = false,
  goPage,
  goFirst,
  goPrev,
  goNext,
  goLast,
  itemSize,
  pageSize = 10,
  currentIndex = 0,
  totalPages,
  buttonSize = 10,
  pageItems = DEFAULT_PAGE_ITEMS,
  onChangeNumberOfPages = () => {},
}) => {
  const begin = Math.floor(currentIndex / buttonSize) * buttonSize
  const end = Math.min(begin + buttonSize, totalPages)

  return (
    <div className={cx('paging_wrap', className)}>
      <div className="inner_paging">
        {hasGoFirstButton && (
          <ControlButton
            active={isGoFirstActive()}
            onClick={goFirst}
            className={controlButtonClassName}
            iconClassName="ico_prevs"
            label="맨처음"
          />
        )}
        <ControlButton
          active={isGoPrevActive()}
          onClick={goPrev}
          className={controlButtonClassName}
          iconClassName="ico_prev"
          label="이전"
        />
        <PageNumberButtons
          currentIndex={currentIndex}
          begin={begin}
          end={end}
          className={numberButtonClassName}
          onClick={goPage}
        />
        <ControlButton
          active={isGoNextActive()}
          onClick={goNext}
          className={controlButtonClassName}
          iconClassName="ico_next"
          label="다음"
        />
        {hasGoLastButton && (
          <ControlButton
            active={isGoLastActive()}
            onClick={goLast}
            className={controlButtonClassName}
            iconClassName="ico_nexts"
            label="맨끝"
          />
        )}
        {hasPageSizeSelect && (
          <div className="single_wrap">
            <OptionSelectList
              label="데이터종류 선택상자"
              labelPrefix="행: "
              className="opt_row"
              defaultIndex={pageItems.findIndex(v => v === pageSize)}
              items={pageItems.map(v => ({ id: v, label: `${v}개` }))}
              active={itemSize > 0}
              onChange={({ id }) => onChangeNumberOfPages(id)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const PageNumberButtons = ({
  begin,
  end,
  currentIndex,
  className,
  onClick,
}) => {
  return Range(begin, end).map(page => {
    const Element = page === currentIndex ? 'em' : 'a'
    const label = page + 1
    return (
      <Element key={page} className={className} onClick={() => onClick(page)}>
        <span className="screen_out">현재페이지</span>
        {label}
      </Element>
    )
  })
}

const ControlButton = ({
  className,
  active,
  onClick,
  label,
  iconClassName,
}) => {
  const El = active ? 'a' : 'span'
  return (
    <El className={className} onClick={active ? onClick : undefined}>
      <span className={`ico_comm ${iconClassName}`}>{label}</span>
    </El>
  )
}

ControlButton.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  iconClassName: PropTypes.string,
}

TablePaging.propTypes = {
  className: PropTypes.string,
  defaultIndex: PropTypes.number,
  itemSize: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  listener: PropTypes.func,
  onChangeNumberOfPages: PropTypes.func,
  hasGoFirstButton: PropTypes.bool,
  hasGoLastButton: PropTypes.bool,
  hasPageSizeSelect: PropTypes.bool,
  buttonSize: PropTypes.number,
  resetPageIndex: PropTypes.bool,
  controlButtonClassName: PropTypes.string,
  numberButtonClassName: PropTypes.string,
  currentIndex: PropTypes.number,
  totalPages: PropTypes.number,
  pageItems: PropTypes.array,
  isGoFirstActive: PropTypes.func,
  isGoPrevActive: PropTypes.func,
  isGoNextActive: PropTypes.func,
  isGoLastActive: PropTypes.func,
  goFirst: PropTypes.func,
  goPrev: PropTypes.func,
  goNext: PropTypes.func,
  goLast: PropTypes.func,
  goPage: PropTypes.func,
}

export default PagingHOC(TablePaging)
