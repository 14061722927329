import { keyMirror } from '../../utils/utils'
import { fromJS, List, Set } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { DashboardFilterUtils } from '../../components/DashboardV3/DashboardHeader/DashboardFilter/dashboardFilterUtils'
import { DashboardUriUtils } from '../../components/DashboardV3/dashboardUriUtils'
import { replace } from 'connected-react-router'
import { hideLoading, showLoading } from '../common/mLoading'
import {
  PREFERENCE_ITEM,
  PreferenceHelper,
} from '../../utils/helper/helper-preference'
import { DashboardToastMessage } from '../../utils/dashboard/dashboardToastMessage'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import { closePopup, POPUP_KEY } from '../common/mPopup'
import { CommonActionType } from '../../modules-actionTypes/actionTypes'
import { invalidateDashboardTableRows } from './mDashboardTable'
import { invalidateDashboardSummary } from './mDashboardSummary'

const DashboardFilter = keyMirror(
  {
    SET_QUERY: null,

    SET_CONDITIONS: null,

    ADD_OR_UPDATE_CONDITION: null,
    ADD_OR_UPDATE_CONDITION_BY_SUGGEST: null,

    DELETE_CONDITION: null,

    SET_STORED_FILTERS: null,

    CLEAR: null,
  },
  'DASHBOARD_FILTER'
)

const initialState = fromJS({
  query: '',
  /**
   * {
   *   dashboardType: DashboardEnumV2.Type,
   *   filterType: FILTER_TYPE,
   *   filterOperator FILTER_OPERATOR,
   *   filterValue: []
   * }
   */
  conditions: [],

  storedFilters: [],
})

export default createReducer(initialState, {
  [DashboardFilter.SET_QUERY]: (state, { query }) => {
    return state.set('query', query)
  },

  [DashboardFilter.SET_CONDITIONS]: (state, { conditions }) => {
    return state.set('conditions', fromJS(conditions || []))
  },

  [DashboardFilter.ADD_OR_UPDATE_CONDITION]: (state, { index, condition }) => {
    const nextCondition = fromJS(condition)

    const alreadyAddedIndex =
      index ??
      state.get('conditions').findIndex(v =>
        DashboardFilterUtils.isConditionTypeSame({
          filterConditionA: v,
          filterConditionB: nextCondition,
        })
      )

    return alreadyAddedIndex >= 0
      ? state.setIn(['conditions', alreadyAddedIndex], nextCondition)
      : state.update('conditions', prevConditions =>
          prevConditions.push(nextCondition)
        )
  },

  [DashboardFilter.ADD_OR_UPDATE_CONDITION_BY_SUGGEST]: (
    state,
    { condition }
  ) => {
    const nextCondition = fromJS(condition)

    const alreadyAddedIndex = state.get('conditions').findIndex(v =>
      DashboardFilterUtils.isConditionTypeSame({
        filterConditionA: v,
        filterConditionB: nextCondition,
      })
    )

    const alreadyAddedCondition =
      alreadyAddedIndex >= 0
        ? state.getIn(['conditions', alreadyAddedIndex])
        : undefined

    return alreadyAddedIndex >= 0
      ? state.update('conditions', prevConditions =>
          prevConditions
            // 이미 추가된 동일 유형의 항목을 제거
            .filter((_, i) => i !== alreadyAddedIndex)
            // 끝 부분에 삽입
            .push(
              // 한 개의 value 만 설정 가능한 경우 replace
              DashboardFilterUtils.takeOnlyFirstValueOfArray(nextCondition)
                ? nextCondition
                : alreadyAddedCondition.mergeWith((valueOld, valueNew, key) => {
                    if (key === 'filterValue') {
                      // 다중 value 설정 가능한 경우, max 만큼만 take
                      const maxCount =
                        DashboardFilterUtils.maxCountOfFilterValueArray(
                          nextCondition
                        )
                      const list = Set(valueOld.concat(valueNew)).toList()
                      return maxCount >= 0 ? list.take(maxCount) : list
                    }

                    return valueNew
                  }, nextCondition)
            )
        )
      : state.update('conditions', prevConditions =>
          prevConditions.push(nextCondition)
        )
  },

  [DashboardFilter.DELETE_CONDITION]: (state, { index }) => {
    return state.update('conditions', prevConditions =>
      prevConditions.delete(index)
    )
  },

  [DashboardFilter.SET_STORED_FILTERS]: (state, { filters }) => {
    return state.set('storedFilters', fromJS(filters || []))
  },

  [DashboardFilter.CLEAR]: () => initialState,

  [CommonActionType.SET_BY_URL_SEARCH_PARAMS]: (state, { urlSearchParams }) => {
    const { conditions } = DashboardUriUtils.Filter.getObjectValue({
      urlSearchParams,
    })

    return state.set('conditions', fromJS(conditions || []))
  },

  [CommonActionType.SET_PREFERENCE]: (state, { value }) => {
    const { dashboardFilter } = fromJS(PreferenceHelper.initialize(value))

    return state.set(
      'storedFilters',
      dashboardFilter?.size > 0 ? dashboardFilter : List()
    )
  },
})

function invalidateDashboardFilterSearchParams() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        filter: { conditions },
      },
    } = getState()

    const searchParamString = DashboardUriUtils.Filter.createSearchParamString({
      conditionValueArray: conditions.toJS(),
    })

    if (searchParamString) {
      dispatch(setDashboardFilterUri({ searchParamString }))
    } else {
      dispatch(clearDashboardFilterUri())
    }
  }
}

export function setDashboardFilterQuery({ query }) {
  return {
    type: DashboardFilter.SET_QUERY,
    query,
  }
}

export function setDashboardFilterConditions({ conditions }) {
  return dispatch => {
    dispatch({
      type: DashboardFilter.SET_CONDITIONS,
      conditions,
    })

    dispatch(invalidateDashboardFilterSearchParams())

    dispatch(invalidateDashboardTableRows())
    dispatch(invalidateDashboardSummary())
  }
}

export function addOrUpdateDashboardFilterCondition({ index, condition }) {
  return dispatch => {
    dispatch({
      type: DashboardFilter.ADD_OR_UPDATE_CONDITION,
      index,
      condition,
    })

    dispatch(invalidateDashboardFilterSearchParams())

    dispatch(invalidateDashboardTableRows())
    dispatch(invalidateDashboardSummary())
  }
}

export function addOrUpdateDashboardFilterConditionBySuggest({ condition }) {
  return dispatch => {
    dispatch({
      type: DashboardFilter.ADD_OR_UPDATE_CONDITION_BY_SUGGEST,
      condition,
    })

    dispatch(setDashboardFilterQuery({ query: '' }))

    dispatch(invalidateDashboardFilterSearchParams())

    dispatch(invalidateDashboardTableRows())
    dispatch(invalidateDashboardSummary())
  }
}

export function deleteDashboardFilterCondition({ index }) {
  return dispatch => {
    dispatch({
      type: DashboardFilter.DELETE_CONDITION,
      index,
    })

    dispatch(invalidateDashboardFilterSearchParams())

    dispatch(invalidateDashboardTableRows())
    dispatch(invalidateDashboardSummary())
  }
}

export function setDashboardStoredFilter({ adAccountId, filters }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      const { preference } = getState()

      const nextPreference = preference
        .set(PREFERENCE_ITEM.DASHBOARD_FILTER, filters)
        .toJS()

      await api.adAccount.updateAdAccountPreference(adAccountId, nextPreference)

      dispatch({
        type: DashboardFilter.SET_STORED_FILTERS,
        filters,
      })

      dispatch(closePopup(POPUP_KEY.DASHBOARD_FILTER_STORE))

      showSuccessMessage(DashboardToastMessage.modifySuccess())
    } catch (e) {
      showErrorMessage(DashboardToastMessage.modifyFailure())
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function deleteStoredDashboardFilter({ adAccountId, filterId }) {
  return async (dispatch, getState) => {
    const {
      dashboardV3: {
        filter: { storedFilters },
      },
    } = getState()

    const nextStoredFilters = storedFilters.filter(({ id }) => id !== filterId)

    await dispatch(
      setDashboardStoredFilter({ adAccountId, filters: nextStoredFilters })
    )
  }
}

export function setDashboardFilterUri({ searchParamString }) {
  return dispatch => {
    const url = DashboardUriUtils.Filter.set({ searchParamString })

    dispatch(replace({ search: url.search }))
  }
}

export function clearDashboardFilterUri() {
  return dispatch => {
    const url = DashboardUriUtils.Filter.clear()

    dispatch(replace({ search: url.search }))
  }
}

export function clearDashboardFilter() {
  return dispatch => {
    dispatch({
      type: DashboardFilter.CLEAR,
    })
  }
}
