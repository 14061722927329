import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { keyMirror } from '../../../utils/utils'
import useComponentId from '../../../utils/hook/useComponentId'

export const NUMBER_FORMAT_INPUT_ALIGN = keyMirror({
  LEFT: null,
  RIGHT: null,
})

/**
 * https://github.com/s-yadav/react-number-format
 */
const NumberFormatInput = ({
  id,
  className,
  labelClassName,
  placeholder,
  maxLength = 255,
  active = true,
  error = false,
  suffix = '',
  align = NUMBER_FORMAT_INPUT_ALIGN.RIGHT,
  value: _value = '',
  onValueChange,
  onChange = e => undefined,
  onFocus: _onFocus = e => undefined,
  onBlur: _onBlur = e => undefined,
  children,
  ...rest
}) => {
  const value = _value ?? '' // avoid `null` exception

  const [hasFocus, setHasFocus] = React.useState(false)

  const _inputId = useComponentId()
  const inputId = id ?? _inputId

  const onFocus = React.useCallback(
    e => {
      setHasFocus(true)

      _onFocus(e)
    },
    [_onFocus]
  )

  const onBlur = React.useCallback(
    e => {
      /**
       * Firefox issue
       * http://tirdadc.github.io/blog/2015/06/11/react-dot-js-firefox-issue-with-onblur/
       */
      const { explicitOriginalTarget, originalTarget } = e.nativeEvent

      if (explicitOriginalTarget && explicitOriginalTarget === originalTarget)
        return

      setHasFocus(false)

      _onBlur(e)
    },
    [_onBlur]
  )

  return (
    <span
      className={cx('box_inptxt', className, {
        on: hasFocus,
        align_r: align === NUMBER_FORMAT_INPUT_ALIGN.RIGHT,
        in_active: !active,
        in_error: error,
      })}>
      <span className="inner_inp">
        <label
          className={placeholder ? 'lab_txt' : labelClassName}
          htmlFor={inputId}>
          {value.length === 0 && placeholder}
        </label>
        <NumberFormat
          id={inputId}
          name={inputId}
          className="inp_txt"
          onValueChange={onValueChange}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={!active}
          value={value}
          suffix={suffix}
          isAllowed={({ value }) => value.length <= maxLength}
          {...rest}
        />
      </span>
      {children}
    </span>
  )
}

NumberFormatInput.propTypes = {
  id: PropTypes.any.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  active: PropTypes.bool,
  error: PropTypes.bool,
  suffix: PropTypes.string,
  align: PropTypes.oneOf(Object.keys(NUMBER_FORMAT_INPUT_ALIGN)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * NumberFormat.NumberFormatValues
   * @return {{ floatValue: number | undefined, formattedValue: string, value: string }}
   */
  onValueChange: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

export default NumberFormatInput
