import { keyMirror } from '../../../utils/utils'

// 실제 화면과 동일한 순서로
const CREATIVE_FORM_VALIDATION_KEY = keyMirror({
  // 상품 카탈로그
  PRODUCT_CATALOG_SET_ID: null,

  PROFILE_IMAGE_CREATE: null,
  PROFILE_IMAGE_MODIFY: null,
  PROMOTIONAL_IMAGE_CREATE: null,
  PROMOTIONAL_IMAGE_MODIFY: null,
  PROMOTIONAL_VIDEO_CREATE: null,
  PROMOTIONAL_VIDEO_MODIFY: null,
  MESSAGE_SELECT: null,
  BOARD_SELECT: null,
  TITLE: null,
  PROFILE_NAME: null,
  PROMOTIONAL_WORD: null,
  ACTION_BUTTON: null,
  // 비즈보드 랜딩
  LANDING_SCHEME: null,
  LANDING_URL: null,
  // 트래킹 url
  TRACKING_URL: null,
  CLICK_TRACKER_URL: null,
  EVENT_TRACKER_URL: null,
  EXECUTIVE_TIME: null,
  ALT_TEXT: null,
  // 모션보드 요소
  MOTION_BOARD_VIDEO: null,
  BACKGROUND_IMAGE: null,
  TEXT_IMAGE: null,
  OBJECT_IMAGE: null,
  // 비즈보드 익스펜더블(대표)
  EXPANDABLE_ASSET_GROUPS: null,
  // 카탈로그 소재
  CATALOG_ASSET_GROUPS: null,
  // 추가설정
  BID_AMOUNT: null,
  FREQUENCY_CAP: null,
  // 의견 & 증빙
  OPINION_PROOF_COMMENT: null,

  // 리치팝
  RICH_POP_BANNER_IMAGE: null,
  RICH_POP_IMAGE_CREATE: null,
  RICH_POP_IMAGE_MODIFY: null,
  ACTION_BUTTON_BACKGROUND_COLOR: null,
  ACTION_BUTTON_FONT_COLOR: null,

  // pc톡 하단
  PC_TALK_BOTTOM_BACKGROUND_IMAGE_CREATE: null,
  PC_TALK_BOTTOM_BACKGROUND_IMAGE_MODIFY: null,
  PC_TALK_BOTTOM_OBJECT_IMAGE_CREATE: null,
  PC_TALK_BOTTOM_OBJECT_IMAGE_MODIFY: null,

  // pc 톡 보장형
  COMPATIBLE_PROMOTIONAL_IMAGE: null,
  COMPATIBLE_LANDING_URL: null,
  COMPATIBLE_TITLE: null,
  // 소재명
  NAME: null,
})

export { CREATIVE_FORM_VALIDATION_KEY }
