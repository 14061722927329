import { keyMirror } from '../../../../utils/utils'
import { showErrorMessage } from '../../../../utils/alertUtils'

export const PIXEL_AND_SDK_PAGE_TAB = keyMirror({
  LINKAGE_LIST: null,
  AVAILABLE_LIST: null,
})

export const PIXEL_AND_SDK_SERVICE_KEY = keyMirror({
  plusfriend: null,
  talkstore: null,
  gift: null,
  checkout: null,
  web: null,
  app: null,
})

export const PIXEL_AND_SDK_SERVICE_NAME = {
  plusfriend: '카카오톡 채널',
  gift: '선물하기',
  talkstore: '톡스토어',
  checkout: '톡체크아웃',
  web: '웹사이트',
  app: '앱',
}

export const PIXEL_AND_SDK_ROLE_KEY = keyMirror({
  REQUEST: null,
  MASTER: null,
  MEMBER: null,
})

export const PIXEL_AND_SDK_ROLE_TEXT = {
  REQUEST: '멤버 권한 요청 중',
  MASTER: '마스터',
  MEMBER: '멤버',
}

// adAccountLinkStatus
export const PIXEL_AND_SDK_AD_ACCOUNT_LINKAGE_STATUS = keyMirror({
  LINKED: null,
  UNLINKED: null,
  NONE: null,
})

export const PIXEL_AND_SDK_LINKAGE_STATUS = keyMirror({
  LINKED: null,
  UNLINKED: null,
  DELETED: null,
  NONE: null,
})

export const PIXEL_AND_SDK_LINKAGE_STATUS_TEXT = {
  LINKED: '연동됨',
  UNLINKED: '연동 해제됨',
  DELETED: '삭제됨',
  NONE: '미연동',
}

export const handlePixelAndSdkException = ({ e }) => {
  if (!e || !e.response || !e.response.data) return

  const { errorCode, message } = e.response.data

  switch (errorCode) {
    case 1106: {
      showErrorMessage('이미 삭제된 트랙입니다.')
      break
    }

    case 1201: {
      showErrorMessage(
        '사용할 수 있는 픽셀 & SDK 제한 수를 초과하여 생성이 불가합니다.'
      )
      break
    }

    case 1202: // 연동중인 트랙이 100개 초과한 경우
    case 1203: {
      // 권한있는 트랙이 100개 초과한 경우
      showErrorMessage(
        '해당 광고계정에 연동할 수 있는 픽셀 & SDK 개수 초과로 인해 연동이 불가합니다.'
      )
      break
    }

    case 1207: {
      showErrorMessage('픽셀 & SDK를 생성할 수 없습니다.')
      break
    }

    case 1402: {
      showErrorMessage(message)
      break
    }

    case 3001: {
      showErrorMessage('이미 멤버 초대받은 픽셀 & SDK입니다.')
      break
    }

    case 3002: {
      showErrorMessage('이미 멤버 권한 요청 중인 픽셀 & SDK입니다.')
      break
    }

    case 1204:
    case 3003:
    case 3006: {
      showErrorMessage(
        '사용할 수 있는 픽셀 & SDK 제한 수를 초과하여 멤버 권한 요청이 불가합니다.'
      )
      break
    }

    case 3301: {
      showErrorMessage('픽셀 & SDK의 마스터가 존재하지 않습니다.')
      break
    }

    case 3401: {
      showErrorMessage('해당 픽셀 & SDK에 대한 멤버/마스터 권한이 없습니다.')
      break
    }

    default: {
      showErrorMessage(
        `시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요. (errorCode: ${errorCode}, message: ${message})`
      )
      break
    }
  }
}
