import React, { createContext, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import {
  DndProvider,
  MouseTransition,
  TouchTransition,
} from 'react-dnd-multi-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { AdvertiseRouterV2 } from './utils/advertise/advertiseRouterV2'
import { invalidateObserverInfo, login } from './utils/authenticationUtils'
import Api from './components/Api'
import { toNotFoundPath } from './utils/router/routeUtils'
import AuthenticationWrapper from './containers/wrappers/authenticationWrapper'
import AgencyLoginCheck from './containers/member/AgencyLoginCheck'
import OtpLoginCheck from './containers/member/OtpLoginCheck'
import GlobalLoadingView from './components/Common/GlobalLoadingView/GlobalLoadingView'
import PopupContainer from './components/Popup/PopupContainer'
import DashboardRedirectRegexArray from './components/DashboardV3/DashboardRedirect/dashboardRedirectRegex'
import { AppCommitInfo } from './components/AppInfo'
import { isIE, isMacOs } from 'react-device-detect'
import NotSupportedBrowserPage from './components/StaticPage/NotSupportedBrowserPage'
import { MomentToaster } from './components/MomentToaster/MomentToaster'
import { ReactQueryDevtools } from 'react-query/devtools'
import PopupContainerV2 from './components/PopupV2/PopupContainerV2'

const NotFoundPage = React.lazy(() =>
  import('./components/StaticPage/NotFoundPage')
)
const ErrorPage = React.lazy(() => import('./components/StaticPage/ErrorPage'))
const InvalidApproachPage = React.lazy(() =>
  import('./components/StaticPage/InvalidApproachPage')
)
const SuccessWithdrawPage = React.lazy(() =>
  import('./components/StaticPage/SuccessWithdrawPage')
)
const ServiceUnavailablePage = React.lazy(() =>
  import('./components/StaticPage/ServiceUnavailablePage')
)

const dndOptions = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
      },
      preview: true,
      transition: TouchTransition,
    },
  ],
}

export const PopupContainerContext = createContext({})

const App = () => {
  const location = useLocation()

  React.useLayoutEffect(() => {
    invalidateObserverInfo()

    if (isMacOs) {
      document.getElementsByTagName('html').item(0).classList.add('os_mac')
    }

    console.info(`%c kakaomoment advertiser ${__VERSION__}`, 'font-size:20px')
  }, [])

  // 페이지 이동 시 강제 스크롤 업
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const popupContainerDivRef = useRef(null)
  const value = {
    popupContainerDivRef,
  }

  return isIE ? (
    <NotSupportedBrowserPage />
  ) : (
    <DndProvider options={dndOptions}>
      <PopupContainerContext.Provider value={value}>
        <section style={{ height: '100%' }}>
          <div id="kakaoIndex">
            <a href="#kakaoBody">본문 바로가기</a>
            <a href="#kakaoGnb">메뉴 바로가기</a>
          </div>
          <React.Suspense fallback={''}>
            <Switch>
              {/* 로그인이 필요 없는 Route */}
              {__LOCAL__ && <Route exact path="/api" component={Api} />}
              <Route
                exact
                path="/login"
                render={() => {
                  login()
                  return null
                }}
              />
              <Redirect exact from="/loginCheck" to="/" />
              <Route exact path="/otp" component={OtpLoginCheck} />
              <Route
                exact
                path={'/:adaccountid(\\d+)/loginCheck'}
                component={AgencyLoginCheck}
              />
              <Route exact path="/not_found" component={NotFoundPage} />
              <Route exact path="/error" component={ErrorPage} />
              <Route
                exact
                path="/service_unavailable"
                component={ServiceUnavailablePage}
              />
              <Route
                exact
                path={'/invalid_approach/:errorCode?'}
                component={InvalidApproachPage}
              />
              <Route
                exact
                path="/success_withdraw"
                component={SuccessWithdrawPage}
              />
              <Route
                path={[
                  '/adaccount',
                  '/myinfo',
                  '/:adaccountid(\\d+)/settings',
                  '/:adaccountid(\\d+)/targeting',
                  '/:adaccountid(\\d+)/report',
                  '/:adaccountid(\\d+)/adview',
                  '/:adaccountid(\\d+)/message',
                  '/:adaccountid(\\d+)/linkage',
                  '/:adaccountid(\\d+)/catalog-manager',
                  AdvertiseRouterV2.Matcher.getCampaignAndGroupPageV2(),
                  AdvertiseRouterV2.Matcher.getCreativePageV2(),
                ]}
                component={AuthenticationWrapper}
              />
              <Route
                exact
                path={['/', '/dashboard', '/dashboard/:adaccountid(\\d+)']}
                component={AuthenticationWrapper}
              />
              <Route
                exact
                path={[
                  '/invite',
                  '/noticeboard',
                  '/noticeboard/:noticeid(\\d+)',
                ]}
                component={AuthenticationWrapper}
              />
              {/* legacy dashboard redirect */}
              <Route
                path={DashboardRedirectRegexArray}
                component={AuthenticationWrapper}
              />
              <Redirect to={toNotFoundPath()} />
            </Switch>
          </React.Suspense>
          <PopupContainer />
          <PopupContainerV2 ref={popupContainerDivRef} />
          <MomentToaster />
          <GlobalLoadingView />
          {__NOT_PRODUCTION__ && <AppCommitInfo />}
          {__LOCAL__ && (
            <ReactQueryDevtools
              initialIsOpen={false}
              position={'bottom-right'}
            />
          )}
        </section>
      </PopupContainerContext.Provider>
    </DndProvider>
  )
}

export default App
