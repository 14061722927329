import {
  checkClickId,
  checkEmpty,
  checkGoogleAidOrAppleIfa,
  checkNotEmpty,
  checkUrlHasWhiteSpace,
  hasContinuousSharp,
  hasNotAllowedEncodedSpecialCharacter,
  hasNotAllowedSpecialCharacter,
} from '../../../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../../../../validators/validation'
import { hasUnEncodedPercentage } from '../../../../utils/stringUtils'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from '../../../../validators/advertise/creativeV2/creativeFormValidationMessage'

const Url = {
  paramsToUrl(baseUrl, params) {
    const searchParams = params
      .filter(({ key, value }) => checkNotEmpty(key) || checkNotEmpty(value))
      .map(({ key, value }) => `${key}=${value}`)
      .join('&')

    const isAvailable = checkNotEmpty(baseUrl) && checkNotEmpty(searchParams)

    return isAvailable
      ? `${baseUrl}?${searchParams}`
      : `${baseUrl}${searchParams}`
  },

  urlToParams(url) {
    const targetUrl = checkNotEmpty(url) ? url : ''
    const [baseUrl, ...query] = targetUrl.split('?')
    const searchQuery = query.join('?')
    const [, anchor] = searchQuery.split('#')
    const searchParams =
      searchQuery.length > 0
        ? searchQuery.split('&').map(param => {
            const [key, ...values] = param.split('=')
            return { key, value: values.join('=') }
          })
        : []

    return {
      baseUrl,
      searchParams,
      anchor,
    }
  },

  checkDuplicateKey(trackingUrl) {
    const { searchParams } = this.urlToParams(trackingUrl)

    const paramSet = new Set()
    searchParams.forEach(({ key }) => {
      paramSet.add(key)
    })

    return searchParams.length !== paramSet.size
  },

  validateUrl(url) {
    if (checkUrlHasWhiteSpace(url)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
      )
    }

    // 특수문자 확인
    if (hasContinuousSharp(url) || hasNotAllowedSpecialCharacter(url)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_NOT_ALLOWED_SPECIAL_CHARACTER
      )
    }

    // 인코딩된 특수문자 확인
    const { searchParams } = this.urlToParams(url)
    const paramString = searchParams.map(({ key, value }) => `${key}=${value}`)
    if (
      hasNotAllowedEncodedSpecialCharacter(paramString) ||
      hasUnEncodedPercentage(paramString)
    ) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_INVALID_CHARACTER_WITH_PARAMETER
      )
    }

    return IS_VALID()
  },

  validateProductTrackingUrl(trackingUrl) {
    // key 값이 없을경우
    const { searchParams } = this.urlToParams(trackingUrl)
    if (searchParams.filter(({ key }) => checkEmpty(key)).length > 0) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.PARAMETER_KEY_REQUIRED
      )
    }

    // URL 공통 밸리데이션
    const urlValidationResult = this.validateUrl(trackingUrl)
    const { isValid } = urlValidationResult
    if (!isValid) {
      return urlValidationResult
    }

    // 상품별 추적 URL 밸리데이션
    const leaveOutClickId = checkEmpty(
      searchParams.find(({ value }) => checkClickId(value))
    )
    const leaveOutGoogleAdIdOrAppleIfa = checkEmpty(
      searchParams.find(({ value }) => checkGoogleAidOrAppleIfa(value))
    )

    if (leaveOutClickId || leaveOutGoogleAdIdOrAppleIfa) {
      // 필수 매크로 확인
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.MACRO_REQUIRED)
    }

    return IS_VALID()
  },
}

export default {
  Url,
  Constraint: {
    TRACKING_URL_MAX_LENGTH: 1000,
    IMAGE_BOX_PROMOTION_WORD_MAX_LENGTH: 7,
  },
}
