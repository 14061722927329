import { fromJS, Map } from 'immutable'
import moment from 'moment'
import {
  checkEmpty,
  checkNotEmpty,
  checkUrl,
  hasFullWidthWhitespace,
  isUndefinedOrNull,
} from '../../../utils/regexUtils'
import {
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
} from './creativeFormValidatorV2'
import CreativeScheduleStatusEnum from '../../../enums/CreativeScheduleStatusEnum'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import AdStatusEnum from '../../../enums/AdStatusEnum'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'

const IMAGE_BOX_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: [
    'uploadedMultiImages',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: ['image'],
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: ['title'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: ['description'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EXECUTIVE_TIME]: ['beginDateTime'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const IMAGE_BOX_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]:
    uploadedMultiImages => {
      if (!uploadedMultiImages || uploadedMultiImages.isEmpty()) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: image => {
    const imageMap = Map.isMap(image) ? image : fromJS(image)
    if (!imageMap || imageMap?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  // 상단 홍보문구
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: (title, formData) => {
    const { description } = formData || {}
    if (checkEmpty(title)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (hasFullWidthWhitespace(title)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }

    if (title === description) {
      return IS_NOT_VALID(
        '상단 홍보문구와 하단 홍보문구는 서로 다른 문구를 입력하세요.'
      )
    }

    if (
      title.length > formUtils.Constraint.IMAGE_BOX_PROMOTION_WORD_MAX_LENGTH
    ) {
      return IS_NOT_VALID(
        `홍보문구를 ${formUtils.Constraint.IMAGE_BOX_PROMOTION_WORD_MAX_LENGTH}자 이하로 입력하세요.`
      )
    }

    return IS_VALID()
  },

  // 하단 홍보문구
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: (description, formData) => {
    const { title } = formData || {}

    if (checkEmpty(description)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (hasFullWidthWhitespace(description)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }

    if (description === title) {
      return IS_NOT_VALID(
        '상단 홍보문구와 하단 홍보문구는 서로 다른 문구를 입력하세요.'
      )
    }

    if (
      description.length >
      formUtils.Constraint.IMAGE_BOX_PROMOTION_WORD_MAX_LENGTH
    ) {
      return IS_NOT_VALID(
        `홍보문구를 ${formUtils.Constraint.IMAGE_BOX_PROMOTION_WORD_MAX_LENGTH}자 이하로 입력하세요.`
      )
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          adGroup: { deviceTypes },
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeLandingURL(formData, campaignType, deviceTypes)
  },

  [CREATIVE_FORM_VALIDATION_KEY.EXECUTIVE_TIME]: (
    beginDateTime,
    formData,
    getState
  ) => {
    const { id, noSchedule, endDateTime, creativeScheduleStatus } = formData

    const {
      creativeV2: {
        common: {
          adGroup: { status: adGroupStatus },
        },
      },
    } = getState()

    const isNew = !id || id === -1
    const isAdGroupFinished =
      adGroupStatus?.includes(AdStatusEnum.Type.FINISHED) ||
      moment().isAfter(moment(endDateTime))

    const isCreativeFinished = CreativeScheduleStatusEnum.isFinished(
      creativeScheduleStatus
    )
    const isCreativeAlive = CreativeScheduleStatusEnum.isAlive(
      creativeScheduleStatus
    )
    const isFinished = isAdGroupFinished || isCreativeFinished
    const isNeedValidation = noSchedule === false && (isNew || !isFinished)

    if (isNeedValidation) {
      if (isUndefinedOrNull(beginDateTime) || isUndefinedOrNull(endDateTime)) {
        return IS_NOT_VALID('소재 집행기간을 입력하세요.')
      }

      if (isCreativeAlive && moment(endDateTime).isBefore(moment())) {
        return IS_NOT_VALID('현재 이후의 일시를 입력하세요.')
      }

      if (
        !isCreativeAlive &&
        (moment(beginDateTime).isBefore(moment()) ||
          moment(endDateTime).isBefore(moment()))
      ) {
        return IS_NOT_VALID('현재 이후의 일시를 입력하세요.')
      }

      if (moment(endDateTime).isSameOrBefore(beginDateTime)) {
        return IS_NOT_VALID('시작일시를 종료일시보다 이전으로 입력하세요.')
      }
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: (name, formData) => {
    const { id } = formData
    const isModify = id > 0

    return validateCreativeName(
      name,
      CreativeConstraints.getNameMaxLength({
        creativeFormat: CreativeFormatEnum.Type.IMAGE_BOX,
        isNew: !isModify,
      })
    )
  },
})

export { IMAGE_BOX_FORM_VALIDATION_KEY_PATH, IMAGE_BOX_FORM_VALIDATOR }
