import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    // ObjectiveTypeEnum.Type.PIXEL_AND_SDK
    PURCHASE: null,
    APP_INSTALL: null,
    COMPLETE_REGISTRATION: null,
    PARTICIPATION: null,
    SIGN_UP: null,
    CART: null,
    ALIM_TALK: null,

    // ObjectiveTypeEnum.Type.TALK_CHANNEL
    SEND_MESSAGE: null,
    ADD_FRIEND: null,

    CATALOG_PRODUCT_PURCHASE: null,
  }),

  Name: {
    PURCHASE: '구매',
    APP_INSTALL: '앱설치',
    COMPLETE_REGISTRATION: '회원가입',
    PARTICIPATION: '잠재고객',
    SIGN_UP: '서비스신청',
    CART: '장바구니',
    SEND_MESSAGE: '채널 메시지 발송',
    ADD_FRIEND: '채널 친구 추가',
    ALIM_TALK: '알림톡',
    CATALOG_PRODUCT_PURCHASE: '구매',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  reportFilterObjectiveValues() {
    return this.values().filter(
      v =>
        ![this.Type.ALIM_TALK, this.Type.CATALOG_PRODUCT_PURCHASE].includes(v)
    )
  },
}
