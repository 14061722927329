import { queryString } from '../fetchUtils'

export const KAKAOCORP_URL = 'http://kakaocorp.com/main'

export const KAKAO_API_HUB_URL = 'http://apihub.daum.net'

export const KAKAO_BIZ_WITH_KAKAO_URL = 'https://with.kakao.com'

export const KAKAO_BIZ_DEVELOPERS_URL = 'https://developers.kakao.com'

export const CUSTOMER_FILE_UPLOAD_CSV_TEMPLATE_DOWNLOAD_URL =
  'https://t1.daumcdn.net/b2/templates/csv/example_audience_file.csv?download'

export const PLUS_FRIEND_GROUP_FILE_UPLOAD_CSV_TEMPLATE_DOWNLOAD_URL =
  'https://t1.daumcdn.net/b2/templates/csv/example_friend_group.csv?download'

// 동영상 소재 플레이어 (일단 애드뷰꺼 정리되면 합치던지 하자)
export const irisVideoPreviewUrl = params =>
  `https://t1.daumcdn.net/b2/templates/html/moment-video-preview.html?${queryString(
    params
  )}`

export const adViewVideoPlayerUrl = params =>
  `https://t1.daumcdn.net/b2/mango/moment-preview/adview-player.html?${queryString(
    params
  )}`

export const MESSAGE_GUIDE_URL =
  'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/guide'

export const AD_GROUP_OPTIMIZATION_STATUS_GUIDE =
  'https://kakaobusiness.gitbook.io/main/ad/moment/run/optimization'

// 불법 스팸 방지 가이드
export const MESSAGE_SPAM_GUIDE =
  'https://t1.daumcdn.net/b2/user-guide/latest-kisa-guide.pdf'
