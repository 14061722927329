import { fetchPost, queryString } from '../../utils/fetchUtils'

const PAGE_REQUEST = {
  SIZE: 20_000,
  SORT: 'id,DESC',
}

/**
 * 캠페인 테이블
 */
function fetchCampaignTableRows({
  adAccountId,
  page = 0,
  size = PAGE_REQUEST.SIZE,
  sort = PAGE_REQUEST.SORT,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    `/aggregation/report/dashboard/table/campaigns?${queryString({
      page,
      size,
      sort,
    })}`,
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 그룹 테이블
 */
function fetchAdGroupTableRows({
  adAccountId,
  page = 0,
  size = PAGE_REQUEST.SIZE,
  sort = PAGE_REQUEST.SORT,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    `/aggregation/report/dashboard/table/adGroups?${queryString({
      page,
      size,
      sort,
    })}`,
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 소재 테이블
 */
function fetchCreativeTableRows({
  adAccountId,
  page = 0,
  size = PAGE_REQUEST.SIZE,
  sort = PAGE_REQUEST.SORT,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    `/aggregation/report/dashboard/table/creatives?${queryString({
      page,
      size,
      sort,
    })}`,
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 캠페인 테이블 SUM
 */
function fetchCampaignTableRowSum({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/table/campaigns/summary',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 그룹 테이블 SUM
 */
function fetchAdGroupTableRowSum({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/table/adGroups/summary',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 소재 테이블 SUM
 */
function fetchCreativeTableRowSum({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/table/creatives/summary',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

export default {
  fetchCampaignTableRows,
  fetchAdGroupTableRows,
  fetchCreativeTableRows,

  fetchCampaignTableRowSum,
  fetchAdGroupTableRowSum,
  fetchCreativeTableRowSum,
}
