import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    URL: null,
    SHARP_TAB: null, // deprecated
    AD_VIEW: null,
    CHANNEL_WEBVIEW: null,
    PF_BOT: null,
    BIZ_FORM: null,
    TALK_CALENDAR: null,
    CHANNEL_POST: null,
    CHANNEL_ADD: null, // 비즈보드 X 전환 채널 친구 추가 전용
    WEBEMBED_CHANNEL: null,
  }),

  Name: {
    URL: 'URL',
    SHARP_TAB: '샾탭',
    AD_VIEW: '애드뷰',
    CHANNEL_WEBVIEW: '채널웹뷰',
    PF_BOT: '챗봇',
    BIZ_FORM: '비즈니스폼',
    TALK_CALENDAR: '톡캘린더',
    CHANNEL_POST: '소식',
    CHANNEL_ADD: '채널 친구 추가',
    WEBEMBED_CHANNEL: '웹임베드 채널',
  },

  Description: {
    URL: '광고주 사이트 등 외부 랜딩을 설정하거나, 선물하기 등 URL 형식의 랜딩이 허용된 카카오 서비스를 랜딩으로 설정할 경우 선택합니다. \nhttp:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    AD_VIEW:
      '카카오에서 제공하는 비즈솔루션인 애드뷰를 생성하여 비즈보드 랜딩으로 활용할 수 있습니다. 이미지/동영상 등을 활용하여 애드뷰를 생성 후 랜딩으로 선택할 경우 별도의 외부 사이트로 이동 없이 카카오톡 내에서 바로 홍보할 수 있습니다.',
    CHANNEL_WEBVIEW:
      '카카오톡 채널에서 톡 채널 특정 페이지를 랜딩으로 선택하여 광고주의 채널, 정보 등을 홍보할 수 있습니다.',
    PF_BOT:
      '챗봇이 설정된 카카오톡 채널을 랜딩으로 선택하여 챗봇과의 상담/문의를 바로 할 수 있으며 채널 추가까지 유도할 수 있습니다. 챗봇과 대화 시작이 가능한 키워드를 입력하세요.',
    BIZ_FORM:
      "설문, 사전신청 등의 응모를 하려고 할 때 카카오가 제공하는 설문 비즈도구인 '비즈니스폼'을 사용할 수 있습니다. 카카오비즈니스 관리자센터에서 비즈니스폼을 생성 후 광고계정에 연동하면 랜딩으로 사용할 수 있습니다. 별도의 설문 프로그램 사용 없이 작성 가능하며 카카오톡 내에서 즉시 참여 가능합니다.",
    TALK_CALENDAR:
      '톡캘린더를 랜딩으로 설정할 경우 톡캘린더 일정 확인 및 등록을 할 수 있습니다. 사용자에게 일정 안내 및 저장, 일정 알림을 통해 행사 참여를 유도할 수 있습니다. 톡캘린더 일정 생성은 카카오 내부에서 가능하므로 담당자에게 문의하세요.',
    CHANNEL_POST:
      '카카오톡 채널에서 등록한 소식을 랜딩으로 선택하여 채널을 홍보할 수 있습니다.',
    WEBEMBED_CHANNEL:
      '웹임베드 채널에서 랜딩URL이 열리도록 설정하여 채널을 홍보할 수 있습니다.',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(
      Object.keys(this.Type)
        .filter(v => v !== this.Type.CHANNEL_ADD)
        .filter(v => !this.isDeprecated(v))
    )
  },

  isDeprecated(type) {
    return [this.Type.SHARP_TAB, this.Type.TALK_CALENDAR].includes(type)
  },

  isAvailableLandingPreview(type) {
    return [this.Type.AD_VIEW].includes(type)
  },

  getDescription(type) {
    return this.Description[type]
  },
}
