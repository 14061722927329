import AdViewTemplateEnum from '../../enums/AdViewTemplateEnum'
import AdViewEnum from '../../enums/AdViewEnum'
import { createReducer } from 'redux-immutablejs'
import { fromJS, List, Map, Range, Set } from 'immutable'
import { keyMirror, makeRandomId } from '../../utils/utils'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import {
  cancelRequest,
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import { coerceToArray } from '../../utils/stringUtils'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import { AdViewHelperV2 } from '../../utils/helper/helper-adViewV2'
import { isUndefinedOrNull } from '../../utils/regexUtils'
import VideoStatusEnum from '../../enums/VideoStatusEnum'
import axios from 'axios'
import { toAdViewManagementPath } from '../../utils/router/routeUtils'
import { push, replace } from 'connected-react-router'
import { scrollByValidationKeys, Validation } from '../../validators/validation'
import { openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import {
  adViewInvalidLandingUrl,
  adViewInvalidThumbnailImage,
  adViewNotAvailableDeleteDialog,
} from '../../components/AdViewV2/Common/AdViewDialog'
import { batchAdViewOnMount } from '../../modules-batch/batch-adView'
import {
  COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_PATH_V2,
  COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2,
  COMPACT_VIEW_IMAGE_FORM_VALIDATOR_V2,
} from '../../validators/adView/compactViewImageFormValidatorV2'
import {
  COMPACT_VIEW_VIDEO_FORM_VALIDATION_KEY_PATH_V2,
  COMPACT_VIEW_VIDEO_FORM_VALIDATION_KEY_V2,
  COMPACT_VIEW_VIDEO_FORM_VALIDATOR_V2,
} from '../../validators/adView/compactViewVideoFormValidatorV2'
import {
  COMPACT_VIEW_CAROUSEL_FORM_VALIDATION_KEY_PATH_V2,
  COMPACT_VIEW_CAROUSEL_FORM_VALIDATION_KEY_V2,
  COMPACT_VIEW_CAROUSEL_FORM_VALIDATOR_V2,
} from '../../validators/adView/compactViewCarouselFormValidatorV2'
import {
  FULL_VIEW_IMAGE_FORM_VALIDATION_KEY_PATH_V2,
  FULL_VIEW_IMAGE_FORM_VALIDATION_KEY_V2,
  FULL_VIEW_IMAGE_FORM_VALIDATOR_V2,
} from '../../validators/adView/fullViewImageFormValidatorV2'
import {
  FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_PATH_V2,
  FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2,
  FULL_VIEW_VIDEO_FORM_VALIDATOR_V2,
} from '../../validators/adView/fullViewVideoFormValidatorV2'
import {
  FULL_VIEW_SCROLL_FORM_VALIDATION_KEY_PATH_V2,
  FULL_VIEW_SCROLL_FORM_VALIDATION_KEY_V2,
  FULL_VIEW_SCROLL_FORM_VALIDATOR_V2,
} from '../../validators/adView/fullViewScrollFormValidatorV2'
import { Trim } from '../../utils/formTrim'
import {
  TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_PATH_V2,
  TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2,
  TALK_SHARE_CONTENTS_FORM_VALIDATOR_V2,
} from '../../validators/adView/talkShareContentsFormValidatorV2'
import { getAdViewFormValidationKey } from '../../utils/adView/adViewV2'
import messageApi from '../../modules-api/message/messageApi'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import React from 'react'

const TALK_SHARE_CONTENTS_TRIM_KEY_PATH = [['title'], ['brandName']]
const AD_VIEW_TRIM_KEY_PATH = [
  ['name'],
  ['brandName'],
  ['title'],
  ['button1', 'inputValue'],
  ['button2', 'inputValue'],
  ['button1', 'label'],
  ['button2', 'label'],
]

const AdView = keyMirror(
  {
    CHANGE_FORM: null,
    INITIAL_FORM: null,
    CHANGE_TALK_SHARE_FORM: null,
    CHANGE_STATE_BY_KEY_PATH: null,
    SET_CONTENT_ITEM: null,
    REMOVE_CONTENT_ITEM: null,
    REMOVE_CONTENT_ITEM_IN_INDEX: null,
    SET_TALK_SHARE_CONTENT_ITEM: null,
    REMOVE_TALK_SHARE_CONTENT_ITEM: null,
    SET_CUSTOM_THUMBNAIL_ITEM: null,
    INIT_CUSTOM_THUMBNAIL_ITEM: null,
    INITIAL_BUTTON_FORM_BY_KEY: null,
    INITIAL_MULTI_UPLOAD_LIST: null,
    INITIAL_VIEW_STATE: null,
    CHANGE_AD_VIEW_FORM_VALIDATION_ERROR_KEYS: null,
    CHANGE_TALK_SHARE_FORM_VALIDATION_ERROR_KEYS: null,
    SET_UPLOAD_CANCEL_KEYS: null,
    INIT_UPLOAD_CANCEL_KEYS: null,
  },
  'AD_VIEW_V2'
)

export const initialAdViewButton = fromJS({
  type: null,
  inputValue: '',
  label: '',
  buttonClass: '',
  landingUrl: '',
  selectedBizForm: {},
  selectedPlusFriend: {},
  selectedMessageInfo: {},
})

const initialState = fromJS({
  adViewForm: {
    name: '',
    brandName: '',
    title: '',
    button1: initialAdViewButton,
    button2: initialAdViewButton,
    contents: [],
  },
  talkShareForm: {
    brandName: '',
    title: '',
    contents: [],
  },
  validation: {
    adViewFormErrorKeys: {},
    talkShareFormErrorKeys: {},
  },
  plusFriendProfileList: [], // 카카오톡 채널 프로필 리스트
  plusFriendMessageList: {
    hasNext: false,
    lastIndex: 0,
    messageElements: [],
    totalCount: 0,
  }, // 카카오톡 채널 메시지 리스트
  talkBizFormList: [], // 비즈니스폼 리스트
  connectedCreativeList: [], // 애드뷰 상세페이지 내 연결된 소재 목
  connectedMessageList: [], // 애드뷰 상세페이지 내 연결된 메시지 개수
  adViewState: {
    adViewType: AdViewEnum.Type.FULL,
    templateType: AdViewTemplateEnum.Type.SCROLL,
    cancelKeySet: Set(), // 동영상 업로드 중 애드뷰 유형 변경 및 애드뷰 불러오기 할때 이전 request 모두 취소하기 위해 사용
  },
})

export default createReducer(initialState, {
  [AdView.CHANGE_STATE_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn([...coerceToArray(keyPath)], fromJS(value)),

  [AdView.CHANGE_AD_VIEW_FORM_VALIDATION_ERROR_KEYS]: (
    state,
    { key, isValid, message }
  ) =>
    state.setIn(
      ['validation', 'adViewFormErrorKeys'],
      isValid
        ? state.getIn(['validation', 'adViewFormErrorKeys']).delete(key)
        : state.getIn(['validation', 'adViewFormErrorKeys']).set(key, message)
    ),

  [AdView.SET_UPLOAD_CANCEL_KEYS]: (state, { key }) =>
    state.updateIn(['adViewState', 'cancelKeySet'], cancelKeySet =>
      cancelKeySet.add(key)
    ),

  [AdView.INIT_UPLOAD_CANCEL_KEYS]: state => state.set('cancelKeySet', Set()),

  [AdView.CHANGE_TALK_SHARE_FORM_VALIDATION_ERROR_KEYS]: (
    state,
    { key, isValid, message }
  ) =>
    state.setIn(
      ['validation', 'talkShareFormErrorKeys'],
      isValid
        ? state.getIn(['validation', 'talkShareFormErrorKeys']).delete(key)
        : state
            .getIn(['validation', 'talkShareFormErrorKeys'])
            .set(key, message)
    ),

  [AdView.INITIAL_VIEW_STATE]: state =>
    state.set('adViewState', initialState.get('adViewState')),

  [AdView.INITIAL_MULTI_UPLOAD_LIST]: state => {
    const defaultSize =
      state.getIn(['adViewState', 'templateType']) ===
      AdViewTemplateEnum.Type.CAROUSEL
        ? 3
        : 1

    return state.withMutations(s =>
      s.setIn(
        ['adViewForm', 'contents'],
        List(
          Range()
            .take(defaultSize)
            .map(() =>
              Map({
                id: makeRandomId(),
                isOpen: true,
                isVisibleImageMasking: true,
              })
            )
        )
      )
    )
  },

  [AdView.INITIAL_FORM]: state =>
    state.withMutations(s =>
      s
        .set('adViewForm', initialState.get('adViewForm'))
        .set('talkShareForm', initialState.get('talkShareForm'))
        .set('validation', initialState.get('validation'))
        .set('plusFriendMessageList', initialState.get('plusFriendMessageList'))
    ),

  [AdView.INITIAL_BUTTON_FORM_BY_KEY]: (state, { key }) =>
    state.setIn(['adViewForm', key], initialAdViewButton),

  [AdView.SET_CONTENT_ITEM]: (state, { data, index = 0 }) =>
    state.updateIn(['adViewForm', 'contents'], contents =>
      contents.withMutations(s =>
        s
          .set(index, fromJS(data))
          .setIn([index, 'id'], contents.getIn([index, 'id']))
          .setIn([index, 'isOpen'], contents.getIn([index, 'isOpen']))
          .setIn([index, 'isVisibleImageMasking'], true)
      )
    ),

  [AdView.REMOVE_CONTENT_ITEM]: (state, { index }) =>
    state.updateIn(['adViewForm', 'contents'], contents =>
      contents.remove(index)
    ),

  [AdView.REMOVE_CONTENT_ITEM_IN_INDEX]: (state, { index }) =>
    state.updateIn(['adViewForm', 'contents'], contents =>
      contents.set(
        index,
        Map()
          .set('id', contents.getIn([index, 'id']))
          .set('isOpen', contents.getIn([index, 'isOpen']))
          .set('isVisibleImageMasking', true)
      )
    ),

  [AdView.SET_CUSTOM_THUMBNAIL_ITEM]: (
    state,
    { customThumbnailImage, index }
  ) =>
    state.updateIn(['adViewForm', 'contents'], contents =>
      contents.update(index, item =>
        item
          .update('data', data =>
            fromJS(data).withMutations(s =>
              s.set('customThumbnail', Map(customThumbnailImage)).set(
                'thumbnailImage', // 코어에서 필요한 값
                Map(customThumbnailImage).get('downloadUrl')
              )
            )
          )
          .set('isVisibleImageMasking', true)
      )
    ),

  [AdView.INIT_CUSTOM_THUMBNAIL_ITEM]: (state, { index }) =>
    state.updateIn(['adViewForm', 'contents'], contents =>
      contents.update(index, item =>
        item.update('data', data =>
          Map(data).withMutations(
            s =>
              s
                .remove('customThumbnail')
                .set('thumbnailImage', s.getIn(['thumbnail', 'url'])) // 코어에서 필요한 값
          )
        )
      )
    ),

  [AdView.CHANGE_TALK_SHARE_FORM]: (state, { key, value }) =>
    state.setIn(['talkShareForm', key], fromJS(value)),

  [AdView.SET_TALK_SHARE_CONTENT_ITEM]: (state, { data }) =>
    state.updateIn(['talkShareForm', 'contents'], contents =>
      contents.push(fromJS(data))
    ),

  [AdView.REMOVE_TALK_SHARE_CONTENT_ITEM]: state =>
    state.updateIn(['talkShareForm', 'contents'], contents => contents.clear()),
})

const changeAdViewFormValidation = (key, isValid, message) => ({
  type: AdView.CHANGE_AD_VIEW_FORM_VALIDATION_ERROR_KEYS,
  key,
  isValid,
  message,
})

const changeTalkShareContentsFormValidation = (key, isValid, message) => ({
  type: AdView.CHANGE_TALK_SHARE_FORM_VALIDATION_ERROR_KEYS,
  key,
  isValid,
  message,
})

export const changeTalkShareFormByKey = (key, value) => ({
  type: AdView.CHANGE_TALK_SHARE_FORM,
  key,
  value,
})

export const removeTalkShareContentItem = () => ({
  type: AdView.REMOVE_TALK_SHARE_CONTENT_ITEM,
})

export const setTalkShareContentItem = data => ({
  type: AdView.SET_TALK_SHARE_CONTENT_ITEM,
  data,
})

export const initAdViewForm = () => ({
  type: AdView.INITIAL_FORM,
})

export const initMultiUploadList = () => ({
  type: AdView.INITIAL_MULTI_UPLOAD_LIST,
})

export const initAdViewButtonByKey = key => ({
  type: AdView.INITIAL_BUTTON_FORM_BY_KEY,
  key,
})

export const initAdViewState = () => ({
  type: AdView.INITIAL_VIEW_STATE,
})

export const changeAdViewStateByKeyPath = (keyPath, value) => ({
  type: AdView.CHANGE_STATE_BY_KEY_PATH,
  keyPath,
  value,
})

export const setAdViewContentsItem = (data, index) => ({
  type: AdView.SET_CONTENT_ITEM,
  index,
  data,
})

export const removeAdViewContentsItem = (index = 0) => ({
  type: AdView.REMOVE_CONTENT_ITEM,
  index,
})

export const removeAdViewMultiUploadContentsItem = (index = 0) => ({
  type: AdView.REMOVE_CONTENT_ITEM_IN_INDEX,
  index,
})

export const setAdViewFileUploadCancelKey = key => ({
  type: AdView.SET_UPLOAD_CANCEL_KEYS,
  key,
})

export const initAdViewFileUploadCancelKeys = () => ({
  type: AdView.INIT_UPLOAD_CANCEL_KEYS,
})

export const setAdViewCustomThumbnailItem = (
  customThumbnailImage,
  index = 0
) => ({
  type: AdView.SET_CUSTOM_THUMBNAIL_ITEM,
  customThumbnailImage,
  index,
})

export const removeAdViewCustomThumbnailItem = (index = 0) => ({
  type: AdView.INIT_CUSTOM_THUMBNAIL_ITEM,
  index,
})

export const removeAdViewVideoUploadRequest = () => {
  return (dispatch, getState) => {
    const {
      adViewV2: {
        adViewState: { cancelKeySet },
      },
    } = getState()

    if (cancelKeySet.size > 0) {
      cancelKeySet.forEach(key => {
        cancelRequest(key)
        dispatch(setAdViewFileUploadCancelKey(key))
      })
    }
  }
}

const getMultiUploadIndex = (id, getState) => {
  const {
    adViewV2: {
      adViewForm,
      adViewState: { templateType },
    },
  } = getState()

  const isMultiUploadType = AdViewTemplateEnum.isMultiUploadType(templateType)

  return isMultiUploadType
    ? adViewForm.get('contents').findIndex(v => v.get('id') === id)
    : 0
}

export const changeAdViewForm = (key, value) => {
  return dispatch => {
    dispatch(
      changeAdViewStateByKeyPath(['adViewForm', ...coerceToArray(key)], value)
    )
  }
}

export const changeAdViewContentsListItemById = (id, key, data) => {
  return (dispatch, getState) => {
    const index = getMultiUploadIndex(id, getState)
    dispatch(changeAdViewForm(['contents', index, ...coerceToArray(key)], data))
  }
}

export const fetchAdViewChannelAddList = (
  adAccountId,
  profileId,
  onSuccess
) => {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.user.getPlusFriendProfile(
        adAccountId,
        profileId
      )

      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } catch (e) {
      console.log(e)
      showErrorMessage('카카오톡 채널 조회에 실패하였습니다.')
    }
  }
}

export const fetchAdViewMessageList = (
  adAccountId,
  profileId,
  success,
  onFail
) => {
  return async dispatch => {
    try {
      const { data } =
        await messageApi.fetchMessageListExcludeAdviewByProfileId(
          adAccountId,
          profileId,
          20
        )
      dispatch(changeAdViewStateByKeyPath(['plusFriendMessageList'], data))
      if (typeof success === 'function') {
        success()
      }
    } catch (e) {
      console.log(e)
      if (typeof onFail === 'function') {
        onFail()
      }
      showErrorMessage('카카오톡 채널 조회에 실패하였습니다.')
    }
  }
}

export const fetchTalkBizFormList = adAccountId => {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.bizForm.fetchAuthorizedBizFormList(
        adAccountId,
        false
      )
      dispatch(changeAdViewStateByKeyPath(['talkBizFormList'], fromJS(data)))
    } catch (e) {
      console.log(e)
    }
  }
}

export const multiUploadAdViewVideo = (adAccountId, file, id, onLoad) => {
  return async (dispatch, getState, api) => {
    const {
      adViewV2: {
        adViewState: { adViewType: type, templateType },
      },
    } = getState()

    const encodedName = encodeURIComponent(file.name)
    const cancelToken = createCancellation(id)

    try {
      dispatch(
        changeAdViewContentsListItemById(id, ['data', 'name'], file.name)
      )
      dispatch(
        changeAdViewContentsListItemById(
          id,
          ['data', 'isVideoProcessing'],
          true
        )
      )
      dispatch(
        changeAdViewContentsListItemById(id, ['data', 'videoProcessingInfo'], {
          status: VideoStatusEnum.Type.UPLOADING,
          progress: 0,
        })
      )

      const {
        data: { url, type: fileType, transCodeId },
      } = await api.adView.fetchAdViewVideoUploadPath(adAccountId, encodedName)

      const formData = new FormData()
      formData.append('type', fileType)
      formData.append('transcode', transCodeId)
      formData.append('file', file)

      const { data } = await api.common.uploadVideoFileToTenth(
        url,
        formData,
        cancelToken,
        value => {
          dispatch(
            changeAdViewContentsListItemById(
              id,
              ['data', 'videoProcessingInfo', 'progress'],
              Math.round(value * 100)
            )
          )
        }
      )

      const { data: result } = await api.adView.uploadedAdViewVideoInfos(
        {
          fileName: encodedName,
          ...data,
          type,
          templateType,
        },
        adAccountId
      )

      if (typeof onLoad === 'function') {
        onLoad(fromJS(result))
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.log(e)

        handleAdViewException({ error: e?.response?.data, dispatch })
        const _index = getMultiUploadIndex(id, getState)
        dispatch(removeAdViewMultiUploadContentsItem(_index))
      }
    }
  }
}

export const uploadKakaoTvVideo = (
  adAccountId,
  kakaoTvInfo,
  id,
  onLoad,
  onError
) => {
  return async (dispatch, getState, api) => {
    const {
      adViewV2: {
        adViewState: { adViewType, templateType },
      },
    } = getState()

    const isMultiUploadType = AdViewTemplateEnum.isMultiUploadType(templateType)
    const cancelKey = isMultiUploadType ? id : 'video_processing_cancel_key'
    const cancelToken = createCancellation(cancelKey)
    const { clipLinkId, url, displayTitle } = kakaoTvInfo

    try {
      dispatch(setAdViewFileUploadCancelKey(cancelKey))
      dispatch(
        changeAdViewContentsListItemById(
          id,
          ['data', 'isKakaoTvProcessing'],
          true
        )
      )
      dispatch(
        changeAdViewContentsListItemById(id, ['data', 'videoProcessingInfo'], {
          status: VideoStatusEnum.Type.UPLOADING,
          progress: 0,
        })
      )
      dispatch(
        changeAdViewContentsListItemById(
          id,
          ['data', 'displayTitle'],
          displayTitle
        )
      )

      const { data } = await api.adView.uploadKakaoTvVideo(
        adAccountId,
        {
          clipLinkId,
          url,
          type: adViewType,
          templateType,
        },
        cancelToken
      )

      onLoad(kakaoTvInfo.concat(fromJS(data)))
    } catch (e) {
      if (!axios.isCancel(e)) {
        handleAdViewException({ error: e?.response?.data, dispatch })

        dispatch(removeAdViewVideoUploadRequest())
        if (isMultiUploadType) {
          const _index = getMultiUploadIndex(id, getState)
          dispatch(removeAdViewMultiUploadContentsItem(_index))
        } else {
          dispatch(removeAdViewContentsItem())
        }
      }

      onError()
    } finally {
      deleteCancellation(cancelToken)
    }
  }
}

export const checkAdViewTranscoding = (
  adAccountId,
  id,
  videoData,
  onProgress,
  onComplete,
  onError,
  transcodingChecker
) => {
  return async (dispatch, getState, api) => {
    const cancelToken = createCancellation(id)
    const { transCodeIds, kakaoTVUrl } = videoData || {}

    const isKakaoTvUploadType = !isUndefinedOrNull(kakaoTVUrl)

    const initVideoUploadingFlag = () => {
      if (isKakaoTvUploadType) {
        dispatch(
          changeAdViewContentsListItemById(
            id,
            ['data', 'isKakaoTvProcessing'],
            false
          )
        )
      } else {
        dispatch(
          changeAdViewContentsListItemById(
            id,
            ['data', 'isVideoProcessing'],
            false
          )
        )
      }

      dispatch(removeAdViewVideoUploadRequest())
    }

    try {
      const {
        data: { status, totalPercent },
      } = await api.adView.checkTranscoding(
        adAccountId,
        transCodeIds.join(', '),
        cancelToken
      )

      switch (status) {
        case VideoStatusEnum.Type.TRANSCODING: {
          dispatch(
            changeAdViewContentsListItemById(
              id,
              ['data', 'videoProcessingInfo'],
              {
                status: VideoStatusEnum.Type.TRANSCODING,
                progress: totalPercent,
              }
            )
          )
          onProgress()

          break
        }

        case VideoStatusEnum.Type.FAILED: {
          await transcodingChecker.retryRequest()
          dispatch(
            changeAdViewContentsListItemById(
              id,
              ['data', 'videoProcessingInfo'],
              {
                status: VideoStatusEnum.Type.TRANSCODING,
                progress: totalPercent,
              }
            )
          )
          onProgress()

          break
        }

        case VideoStatusEnum.Type.COMPLETED: {
          dispatch(
            setAdViewContentsItem(
              {
                type: AdViewTemplateEnum.Type.VIDEO,
                data: AdViewHelperV2.Form.setAdditionalVideoProperty(
                  videoData
                ).withMutations(s =>
                  s
                    .update('originalFileName', originalFileName =>
                      isKakaoTvUploadType
                        ? s.get('displayTitle')
                        : originalFileName
                    )
                    .set('videoProcessingInfo', {
                      status: VideoStatusEnum.Type.COMPLETED,
                      progress: totalPercent,
                    })
                ),
              },
              getMultiUploadIndex(id, getState)
            )
          )

          onComplete()

          break
        }

        default:
          break
      }
    } catch (e) {
      console.log(e)
      if (!axios.isCancel(e)) {
        handleAdViewException({ error: e?.response?.data, dispatch })

        dispatch(
          changeAdViewContentsListItemById(
            id,
            ['data', 'videoProcessingInfo', 'status'],
            VideoStatusEnum.Type.FAILED
          )
        )

        initVideoUploadingFlag()
      }

      onError()
    } finally {
      deleteCancellation(cancelToken)
    }
  }
}

export const uploadAdViewImageAsset = ({
  image,
  adAccountId,
  isTalkShareContents = false,
  isCustomThumbnail = false,
  index = 0,
  videoWidth,
  videoHeight,
}) => {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      const {
        adViewV2: {
          adViewState: { adViewType, templateType },
        },
      } = getState()

      const response = await api.adView.uploadAdViewAssets(image, adAccountId, {
        templateType,
        type: adViewType,
        ...(isCustomThumbnail && { width: videoWidth, height: videoHeight }),
        ...(isTalkShareContents && { imageUploadType: 'TALK_SHARE_CONTENTS' }),
      })

      const data = AdViewHelperV2.Form.setAdditionalImageProperty(
        fromJS(response.data)
      )

      if (isTalkShareContents) {
        dispatch(
          setTalkShareContentItem({
            type: AdViewTemplateEnum.Type.IMAGE,
            data,
            isVisibleImageMasking: true,
          })
        )
      } else if (isCustomThumbnail) {
        dispatch(setAdViewCustomThumbnailItem(data, index))
      } else {
        dispatch(
          setAdViewContentsItem(
            {
              type: AdViewTemplateEnum.Type.IMAGE,
              data,
            },
            index
          )
        )
      }
    } catch (e) {
      console.log(e.message)
      handleAdViewException({ error: e?.response?.data, dispatch })
    } finally {
      dispatch(hideLoading())
    }
  }
}

const adViewFormValidation = (formData, getState, dispatch) => {
  const {
    adViewV2: {
      adViewState: { adViewType, templateType },
    },
  } = getState()

  if (adViewType === AdViewEnum.Type.COMPACT) {
    switch (templateType) {
      case AdViewTemplateEnum.Type.IMAGE: {
        return Validation(
          formData,
          COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2,
          COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_PATH_V2,
          COMPACT_VIEW_IMAGE_FORM_VALIDATOR_V2,
          getState,
          changeAdViewFormValidation,
          dispatch
        )
      }

      case AdViewTemplateEnum.Type.VIDEO: {
        return Validation(
          formData,
          COMPACT_VIEW_VIDEO_FORM_VALIDATION_KEY_V2,
          COMPACT_VIEW_VIDEO_FORM_VALIDATION_KEY_PATH_V2,
          COMPACT_VIEW_VIDEO_FORM_VALIDATOR_V2,
          getState,
          changeAdViewFormValidation,
          dispatch
        )
      }

      case AdViewTemplateEnum.Type.CAROUSEL: {
        return Validation(
          formData,
          COMPACT_VIEW_CAROUSEL_FORM_VALIDATION_KEY_V2,
          COMPACT_VIEW_CAROUSEL_FORM_VALIDATION_KEY_PATH_V2,
          COMPACT_VIEW_CAROUSEL_FORM_VALIDATOR_V2,
          getState,
          changeAdViewFormValidation,
          dispatch
        )
      }

      default:
        return false
    }
  } else {
    switch (templateType) {
      case AdViewTemplateEnum.Type.IMAGE: {
        return Validation(
          formData,
          FULL_VIEW_IMAGE_FORM_VALIDATION_KEY_V2,
          FULL_VIEW_IMAGE_FORM_VALIDATION_KEY_PATH_V2,
          FULL_VIEW_IMAGE_FORM_VALIDATOR_V2,
          getState,
          changeAdViewFormValidation,
          dispatch
        )
      }

      case AdViewTemplateEnum.Type.VIDEO: {
        return Validation(
          formData,
          FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2,
          FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_PATH_V2,
          FULL_VIEW_VIDEO_FORM_VALIDATOR_V2,
          getState,
          changeAdViewFormValidation,
          dispatch
        )
      }

      case AdViewTemplateEnum.Type.SCROLL: {
        return Validation(
          formData,
          FULL_VIEW_SCROLL_FORM_VALIDATION_KEY_V2,
          FULL_VIEW_SCROLL_FORM_VALIDATION_KEY_PATH_V2,
          FULL_VIEW_SCROLL_FORM_VALIDATOR_V2,
          getState,
          changeAdViewFormValidation,
          dispatch
        )
      }

      default:
        return false
    }
  }
}

const talkShareContentsFormValidation = (formData, getState, dispatch) => {
  return Validation(
    formData,
    TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2,
    TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_PATH_V2,
    TALK_SHARE_CONTENTS_FORM_VALIDATOR_V2,
    getState,
    changeTalkShareContentsFormValidation,
    dispatch
  )
}

export const submitAdViewForm = (adAccountId, id) => {
  return async (dispatch, getState, api) => {
    const {
      adViewV2: {
        adViewState: { adViewType: type, templateType },
        adViewForm,
        talkShareForm,
      },
    } = getState()

    const componentData = AdViewHelperV2.Form.getUploadAdViewForm(
      type,
      templateType,
      Trim(adViewForm, AD_VIEW_TRIM_KEY_PATH)
    )

    const talkShareContents = Trim(
      talkShareForm,
      TALK_SHARE_CONTENTS_TRIM_KEY_PATH
    )

    const adViewFormValidationResult = adViewFormValidation(
      componentData,
      getState,
      dispatch
    )
    const talkShareContentsFormValidationResult = AdViewEnum.isCompactView(type)
      ? true
      : talkShareContentsFormValidation(talkShareContents, getState, dispatch)

    if (adViewFormValidationResult && talkShareContentsFormValidationResult) {
      dispatch(showLoading())

      try {
        const requestBody = {
          componentData,
          name: adViewForm.get('name'),
          type,
          templateType,
          ...(!isUndefinedOrNull(id) && { id }), // modify adView
          ...(!AdViewEnum.isCompactView(type) && { talkShareContents }), // full view
        }

        if (!isUndefinedOrNull(id)) {
          await api.adView.modifyAdView(adAccountId, id, requestBody) // modify adView
        } else {
          await api.adView.createAdView(adAccountId, requestBody) // create adView, load adView
        }

        dispatch(push(toAdViewManagementPath(adAccountId)))
      } catch (e) {
        console.log(e)
        handleAdViewException({
          error: e?.response?.data,
          dispatch,
          adAccountId,
        })
      } finally {
        dispatch(hideLoading())
      }
    } else {
      const {
        adViewV2: {
          validation: { adViewFormErrorKeys, talkShareFormErrorKeys },
        },
      } = getState()

      scrollByValidationKeys(
        adViewFormErrorKeys,
        getAdViewFormValidationKey(type, templateType)
      )

      if (!AdViewEnum.isCompactView(type)) {
        scrollByValidationKeys(
          talkShareFormErrorKeys,
          TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2
        )
      }
    }
  }
}

export const fetchAdViewInfo = (adAccountId, adViewId) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading(LOADING_KEY.SETUP_AD_VIEW))

      const { data } = await api.adView.fetchAdViewInfoById(
        adAccountId,
        adViewId
      )

      dispatch(batchAdViewOnMount(adAccountId, fromJS(data)))
    } catch (e) {
      console.log(e)
      handleAdViewException({ error: e?.response?.data, dispatch })
      dispatch(hideLoading(LOADING_KEY.SETUP_AD_VIEW))
    }
  }
}

export const deleteAdView = (adAccountId, adViewId) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      await api.adView.deleteAdView(adAccountId, adViewId)

      showSuccessMessage('애드뷰 변경사항이 적용되었습니다.')
      dispatch(replace(toAdViewManagementPath(adAccountId)))
    } catch (e) {
      console.log(e)
      handleAdViewException({ error: e?.response?.data, dispatch, adAccountId })
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const fetchAdViewConnectedCreativeList = (
  adAccountId,
  params,
  onLoad = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.adView.fetchConnectedCreativeList(
        adAccountId,
        params
      )
      dispatch(
        changeAdViewStateByKeyPath(['connectedCreativeList'], fromJS(data))
      )
    } catch (e) {
      console.log(e.message)
    } finally {
      onLoad()
    }
  }
}

export const fetchAdViewConnectedMessageList = (
  adAccountId,
  adViewId,
  pageIndex,
  onLoad = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.message.fetchMessageListByAdviewId(
        adAccountId,
        adViewId,
        pageIndex
      )

      dispatch(
        changeAdViewStateByKeyPath(['connectedMessageList'], fromJS(data))
      )
    } catch (e) {
      console.log(e.message)
    } finally {
      onLoad()
    }
  }
}

export const handleAdViewException = ({ error, dispatch, ...rest }) => {
  const { errorCode, message } = error || {}

  switch (errorCode) {
    // 애드뷰 삭제 불가
    case 39020: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          adViewNotAvailableDeleteDialog()
        )
      )
      break
    }

    // 애드뷰 동영상 업로드 에러
    case 33209:
    case 33210:
    case 33215:
    case 39013:
    case 39014:
    case 33216: {
      showErrorMessage(message)
      break
    }

    // 이미지 업로드 에러
    case 33106: {
      showErrorMessage(
        '등록 가능한 이미지 사이즈를 확인하신 후 다시 시도하세요.'
      )
      break
    }

    case 33217:
    case 33218: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">이미지 색상 체계 오류</strong>
            <p className="txt_layer">RGB 색상 체계 이미지가 아닙니다.</p>
          </PopupProxy>
        )
      )
      break
    }

    case 33224: {
      showErrorMessage('회전되지 않은 이미지만 등록할 수 있습니다.')
      break
    }

    // 존재하지 않는 애드뷰
    case 39103:
    case 39203: {
      showErrorMessage('애드뷰가 존재하지 않습니다.')
      dispatch(replace(toAdViewManagementPath(rest.adAccountId)))
      break
    }

    // 채널 권한이 없는 경우
    case 39205: {
      showErrorMessage(
        '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
      )
      break
    }

    // kakao tv 조회시 에러
    case 84001: {
      showErrorMessage('카카오톡 채널 연결에 실패하였습니다. 다시 시도하세요.')
      break
    }

    // 비즈폼 scheme api 호출 실패
    case 86100: {
      showErrorMessage('비즈니스폼 연동에 실패하였습니다.')
      break
    }

    // 비즈폼의 기간이 만료되었거나 긴급종료(status:off) 상태인 경우
    case 86102: {
      showErrorMessage(
        '애드뷰 내 톡비즈니스폼이 운영 가능한 상태가 아니거나, 유효하지 않습니다.'
      )
      break
    }

    // url이 정상적이지 않은 경우 (https://jira.daumkakao.com/browse/KAMOQA-13110)
    case 39010: {
      dispatch(
        openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, adViewInvalidThumbnailImage())
      )
      break
    }

    // 랜딩URL 관련 에러 전체
    case 39011:
    case 39012:
    case 39206:
    case 39207:
    case 39209:
    case 39210:
    case 39211: {
      dispatch(
        openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, adViewInvalidLandingUrl())
      )
      break
    }

    case 34516: {
      showErrorMessage('메시지 버튼 랜딩이 유효하지 않습니다.')
      break
    }

    case 90001: {
      showErrorMessage('잘못된 요청입니다.')
      break
    }

    default: {
      showErrorMessage('시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.')
      break
    }
  }
}
