import React, { Fragment } from 'react'
import cx from 'classnames'
import {
  hasOnlyKeyboardChars,
  validateInputLength,
} from '../../../../../utils/regexUtils'
import {
  BIZ_BOARD_APP_DOWNLOAD_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_NOTICE_TYPE,
  BIZBOARD_COPY_ALLOWED_CHAR,
} from '../bizBoardUtils'
import PropTypes from 'prop-types'
import CmpTextInput from '../../Cmp/CmpInput/CmpTextInput'

const BizBoardBannerCopy = ({
  formData,
  templateData,
  validationState,
  handleFormChange,
  handleTemplateChange,
}) => {
  const [isGuideOpen, setIsGuideOpen] = React.useState(false)

  React.useEffect(() => {
    return () => {
      setIsGuideOpen(false)
    }
  }, [])

  const handleMainCopyChange = React.useCallback(
    e => {
      const { value } = e.target

      const isValid =
        hasOnlyKeyboardChars(value, BIZBOARD_COPY_ALLOWED_CHAR) &&
        validateInputLength(value, 0, 50)

      if (isValid) {
        handleFormChange('title', value)
      }
    },
    [handleFormChange]
  )

  const handleSubCopyChange = React.useCallback(
    e => {
      const { value } = e.target

      const isValid =
        hasOnlyKeyboardChars(value, BIZBOARD_COPY_ALLOWED_CHAR) &&
        validateInputLength(value, 0, 50)

      if (isValid) {
        // 서브카피(1순위) 입력 시 앱 다운로드(2순위), 고지문(3순위) 둘 다 선택된 경우, 고지문 해제
        if (
          templateData.appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE &&
          templateData.noticeType !== BIZ_BOARD_NOTICE_TYPE.NONE
        ) {
          handleTemplateChange('noticeType', BIZ_BOARD_NOTICE_TYPE.NONE)
        }

        handleFormChange('description', value)
      }
    },

    [
      handleFormChange,
      handleTemplateChange,
      templateData.appDownloadType,
      templateData.noticeType,
    ]
  )

  const errorMessageMainCopy =
    validationState[BIZ_BOARD_FORM_VALIDATION_KEY.MAIN_COPY]
  const errorMessageSubCopy =
    validationState[BIZ_BOARD_FORM_VALIDATION_KEY.SUB_COPY]

  return (
    <Fragment>
      <div className="bnrimg_area">
        <strong className="tit_bnrimg">
          메인 카피<span className="emph_color">*</span>
        </strong>
        <div className="bnrimg_con">
          <CmpTextInput
            // adAccountId={adAccountId}
            // cmpRequestCreator={CmpRequestUtils.RequestCreator.titleAssets}
            // cmpResponseTransformer={
            //   CmpRequestUtils.ResponseTransformer.titleAssets
            // }
            // cmpAssetButtonTooltip="메인 카피 불러오기"
            title="메인 카피 입력"
            placeholder="메인 카피를 입력하세요."
            maxLength={30}
            value={formData.title}
            error={!!errorMessageMainCopy}
            onChange={handleMainCopyChange}
          />
          {errorMessageMainCopy && (
            <p className="txt_error">{errorMessageMainCopy}</p>
          )}
        </div>
      </div>
      <div className="bnrimg_area">
        <strong className="tit_bnrimg">서브 카피</strong>
        <div className="bnrimg_con">
          <CmpTextInput
            // adAccountId={adAccountId}
            // cmpRequestCreator={CmpRequestUtils.RequestCreator.titleAssets}
            // cmpResponseTransformer={
            //   CmpRequestUtils.ResponseTransformer.titleAssets
            // }
            // cmpAssetButtonTooltip="서브 카피 불러오기"
            title="서브 카피 입력"
            placeholder="서브 카피를 입력하세요."
            maxLength={30}
            value={formData.description}
            error={!!errorMessageSubCopy}
            onChange={handleSubCopyChange}
          />
          {errorMessageSubCopy && (
            <p className="txt_error">{errorMessageSubCopy}</p>
          )}
        </div>
      </div>
      <div className="bnrimg_area type_cancle">
        <div className={cx('reform_group_cancle', { on: isGuideOpen })}>
          {' '}
          <a
            className="reform_btn_cancle"
            onPointerEnter={() => setIsGuideOpen(true)}
            onPointerLeave={() => setIsGuideOpen(false)}>
            취소선 입력방법
          </a>
          {isGuideOpen && (
            <div className="reform_box_cancle">
              <p className="desc_cancle">
                입력방식을 참고하여 스타일을 적용해 보세요.
              </p>
              <ul className="list_cancle">
                <li>
                  <strong className="tit_cancle">입력방식</strong>
                  <p className="txt_cancle">~취소선~</p>
                </li>
                <li>
                  <strong className="tit_cancle">적용 스타일</strong>
                  <p className="txt_cancle">
                    <span className="inner_cancle">취소선</span>
                  </p>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

BizBoardBannerCopy.propTypes = {
  formData: PropTypes.object,
  templateData: PropTypes.object,
  validationState: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
}

export default BizBoardBannerCopy
