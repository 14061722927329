import { distinct, keyMirror } from '../../utils/utils'
import { fromJS, List } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { DashboardSettingsHelper } from '../../utils/helper/helper-dashboard'
import {
  ConversionCriteriaKey,
  ConversionCriteriaType,
} from '../../components/Popup/Metric/ChangeConversionCriteriaPopup'
import DashboardMetricGroupEnumV2 from '../../enums/DashboardMetricGroupEnumV2'
import { closeAllPopup } from '../common/mPopup'
import { hideLoading, showLoading } from '../common/mLoading'
import {
  PREFERENCE_ITEM,
  PreferenceHelper,
} from '../../utils/helper/helper-preference'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import { DashboardToastMessage } from '../../utils/dashboard/dashboardToastMessage'
import { invalidateDashboardTableRows } from './mDashboardTable'
import { CommonActionType } from '../../modules-actionTypes/actionTypes'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageLocal,
} from '../../utils/storage/storageFactoryImpl'
import {
  DashboardTableMetric as DashboardTableMetricMap,
  DashboardTableMetricIds,
  DashboardTableMetricIdsByGroup,
} from '../../utils/dashboard/dashboardTableMetric'
import { isEqual } from 'lodash'

const DashboardTableMetric = keyMirror(
  {
    SET: null,
    INIT: null,

    SELECT_FIXED_GROUP: null,
    SELECT_TEMP_GROUP: null,
    SELECT_STORED_GROUP: null,

    UPDATE_TEMP_GROUP: null,

    // preference api
    SET_STORED_GROUPS: null,

    SET_SELECTED: null,

    SET_CONVERSION_CRITERIA: null,

    CLEAR: null,
  },
  'DASHBOARD_TABLE_METRIC'
)

const initialState = fromJS({
  /**
   * [{ id: string, label: string }]
   */
  selected: DashboardSettingsHelper.TableMetric.getSelectedMetricsV2(),
  /**
   * [{ id: string, name: string, metrics: [], isSelected: boolean }]
   */
  fixedGroups: DashboardSettingsHelper.TableMetric.getFixedMetricGroupsV2(),
  /**
   * { id: string, name: string, metrics: [], isSelected: boolean }
   */
  temporaryGroup: {},
  /**
   * [{ id: string, name: string, metrics: [], isSelected: boolean }]
   */
  storedGroups: [],

  conversionCriteria: ConversionCriteriaType.WEEK,
})

export default createReducer(initialState, {
  [DashboardTableMetric.SET]: (state, { tableMetricState }) => {
    return state.merge(fromJS(tableMetricState))
  },

  [DashboardTableMetric.INIT]: state => {
    return state.withMutations(s =>
      s
        .set(
          'selected',
          DashboardSettingsHelper.TableMetric.getSelectedMetricsV2()
        )
        .set(
          'fixedGroups',
          DashboardSettingsHelper.TableMetric.getFixedMetricGroupsV2()
        )
        .set('conversionCriteria', ConversionCriteriaType.WEEK)
        .update('temporaryGroup', temporaryGroup =>
          temporaryGroup.set('isSelected', false)
        )
        .update('storedGroups', storedGroups =>
          storedGroups.map(v => v.set('isSelected', false))
        )
    )
  },

  /**
   * 대시보드 기본 metric 선택 | 선택 해제
   * - N개 선택 가능
   * - temporary | stored 선택 시 해제
   */
  [DashboardTableMetric.SELECT_FIXED_GROUP]: (state, { id }) => {
    const { fixedGroups: prevFixedGroups, conversionCriteria } = state

    const nextFixedGroups = prevFixedGroups.map(fixedGroup =>
      fixedGroup.update('isSelected', isSelected =>
        fixedGroup.get('id') === id ? !isSelected : isSelected
      )
    )

    const isNotValid = nextFixedGroups.every(({ isSelected }) => !isSelected)

    return isNotValid
      ? state
      : state.withMutations(s =>
          s
            .set(
              'selected',
              distinct(
                nextFixedGroups
                  .filter(({ isSelected }) => isSelected)
                  .flatMap(({ id }) =>
                    DashboardMetricGroupEnumV2.isRelatedToConversion(id)
                      ? DashboardTableMetricMap[id].filter(
                          ({ id: metricCode }) =>
                            metricCode.includes(
                              ConversionCriteriaKey[conversionCriteria]
                            )
                        )
                      : DashboardTableMetricMap[id]
                  ),
                ({ id: metricCode }) => metricCode
              )
            )
            .set('fixedGroups', nextFixedGroups)
            .update('temporaryGroup', temporaryGroup =>
              temporaryGroup.set('isSelected', false)
            )
            .update('storedGroups', storedGroups =>
              storedGroups.map(storedGroup =>
                storedGroup.set('isSelected', false)
              )
            )
        )
  },

  /**
   * 대시보드 임시 metric 선택(Local Storage)
   * - 직접 선택 해제 불가
   * - fixed | stored 선택 시 해제
   */
  [DashboardTableMetric.SELECT_TEMP_GROUP]: state => {
    const { temporaryGroup: prevTemporaryGroup } = state

    const nextTemporaryGroup = prevTemporaryGroup.set('isSelected', true)

    const isNotValid = nextTemporaryGroup.get('metrics').isEmpty()

    return isNotValid
      ? state
      : state.withMutations(s =>
          s
            .set(
              'selected',
              distinct(
                nextTemporaryGroup.get('metrics'),
                ({ id: metricCode }) => metricCode
              )
            )
            .update('fixedGroups', fixedGroups =>
              fixedGroups.map(fixedGroup => fixedGroup.set('isSelected', false))
            )
            .set('temporaryGroup', nextTemporaryGroup)
            .update('storedGroups', storedGroups =>
              storedGroups.map(storedGroup =>
                storedGroup.set('isSelected', false)
              )
            )
        )
  },

  /**
   * 대시보드 커스텀 metric 선택(Server DB)
   * - 1개만 선택 가능
   * - fixed | temporary 선택 시 해제
   */
  [DashboardTableMetric.SELECT_STORED_GROUP]: (state, { id }) => {
    const { storedGroups: prevStoredGroups } = state

    const nextStoredGroups = prevStoredGroups.map(v =>
      v.set('isSelected', v.get('id') === id)
    )

    const selectedStoredGroup = nextStoredGroups.find(
      ({ isSelected }) => isSelected
    )

    const isNotValid = selectedStoredGroup.get('metrics').isEmpty()

    return isNotValid
      ? state
      : state.withMutations(s =>
          s
            .set(
              'selected',
              distinct(selectedStoredGroup.get('metrics'), v => v.get('id'))
            )
            .update('fixedGroups', fixedGroups =>
              fixedGroups.map(fixedGroup => fixedGroup.set('isSelected', false))
            )
            .update('temporaryGroup', temporaryGroup =>
              temporaryGroup.set('isSelected', false)
            )
            .set('storedGroups', nextStoredGroups)
        )
  },

  [DashboardTableMetric.UPDATE_TEMP_GROUP]: (state, { temporaryGroup }) => {
    return state.set('temporaryGroup', fromJS(temporaryGroup))
  },

  [DashboardTableMetric.SET_STORED_GROUPS]: (state, { storedGroups }) => {
    return state.set('storedGroups', fromJS(storedGroups || {}))
  },

  [DashboardTableMetric.SET_SELECTED]: (state, { selected }) => {
    return state.set('selected', fromJS(selected))
  },

  [DashboardTableMetric.SET_CONVERSION_CRITERIA]: (
    state,
    { conversionCriteria }
  ) => {
    const { fixedGroups: prevFixedGroups } = state

    const hasConversion = prevFixedGroups.some(
      ({ id: fixedGroupId, isSelected }) =>
        DashboardMetricGroupEnumV2.isRelatedToConversion(fixedGroupId) &&
        isSelected
    )

    return state.withMutations(s =>
      s
        .set('conversionCriteria', conversionCriteria)
        .update('selected', prevSelected =>
          hasConversion
            ? distinct(
                prevFixedGroups
                  .filter(({ isSelected }) => isSelected)
                  .flatMap(
                    ({ id: fixedGroupId }) =>
                      DashboardMetricGroupEnumV2.isRelatedToConversion(
                        fixedGroupId
                      )
                        ? DashboardTableMetricMap[fixedGroupId].filter(
                            ({ id: metricCode }) =>
                              metricCode.includes(
                                ConversionCriteriaKey[conversionCriteria]
                              )
                          )
                        : DashboardTableMetricMap[fixedGroupId],
                    ({ id: metricCode }) => metricCode
                  )
              )
            : prevSelected
        )
    )
  },

  [DashboardTableMetric.INIT_BY_KEY_PATH]: (state, { keyPath }) => {
    return state.setIn(
      coerceToArray(keyPath),
      initialState.getIn(coerceToArray(keyPath))
    )
  },

  [DashboardTableMetric.CLEAR]: () => initialState,

  /**
   * Subscribe `SET_PREFERENCE` action
   * - 해당 액션을 이 리듀서에서 동시 참조하여 dashboard preference 만을 취한다.
   */
  [CommonActionType.SET_PREFERENCE]: (state, { value }) => {
    const { dashboardCustomMetric } = fromJS(PreferenceHelper.initialize(value))

    return state.withMutations(s =>
      s.set(
        'storedGroups',
        dashboardCustomMetric?.size > 0 ? dashboardCustomMetric : List()
      )
    )
  },
})

export function initDashboardTableMetric() {
  return {
    type: DashboardTableMetric.INIT,
  }
}

/**
 * 반드시 preference load 후 & 테이블 갱신 전에 이루어져야 한다.
 */
export function initDashboardTableMetricByStorage({ adAccountId }) {
  return (dispatch, getState) => {
    // from local storage
    const tableMetricStateJson = DashboardStorageLocal.get(
      DASHBOARD_STORAGE_ITEM.TABLE_METRICS
    )?.[adAccountId]

    if (tableMetricStateJson) {
      const tableMetricStateKeys = initialState.keySeq()

      if (
        Object.keys(tableMetricStateJson).every(jsonKey =>
          tableMetricStateKeys.includes(jsonKey)
        )
      ) {
        const {
          dashboardV3: {
            tableMetric: { storedGroups },
          },
        } = getState()

        /**
         * from preference api(A) -> from local storage(B) -> 데이터는 항상 A가 최신이며, B 에서의 isSelected 상태만 취한다.
         */
        const nextStoredGroups = storedGroups.toJS()

        const storedGroupsFromStorage = tableMetricStateJson.storedGroups

        const selectedGroupFromStorage = storedGroupsFromStorage.find(
          ({ isSelected }) => isSelected
        )

        if (selectedGroupFromStorage) {
          nextStoredGroups.map(storedGroup => {
            if (storedGroup.id === selectedGroupFromStorage.id) {
              storedGroup.isSelected = true
            }

            return storedGroup
          })
        }

        tableMetricStateJson.storedGroups = nextStoredGroups

        dispatch({
          type: DashboardTableMetric.SET,
          tableMetricState: tableMetricStateJson,
        })

        const fixedSelected = tableMetricStateJson.fixedGroups.some(
          ({ isSelected }) => isSelected
        )
        // fixedGroups 기준 selected 검증
        const isValidSelected = fixedSelected
          ? isEqual(
              tableMetricStateJson.fixedGroups
                .filter(({ isSelected }) => isSelected)
                .flatMap(({ id }) => DashboardTableMetricMap[id]),
              tableMetricStateJson.selected
            )
          : true

        const temporarySelected = tableMetricStateJson.temporaryGroup.isSelected
        const storedSelected = tableMetricStateJson.storedGroups.some(
          ({ isSelected }) => isSelected
        )

        // 삭제된 지표 있다면 업데이트를 위해 사전에 체크
        const isValidStoreGroups = tableMetricStateJson.storedGroups
          .map(({ metrics }) => metrics)
          .flatMap(v => v.map(({ id }) => id))
          .every(v => DashboardTableMetricIds.map(({ id }) => id).includes(v))

        if (!isValidSelected) {
          dispatch(fixDashboardTableMetricStorage())
          return
        } else if (!isValidStoreGroups) {
          dispatch(updateDashboardTableMetricStorage())
          return
        } else if (fixedSelected || temporarySelected || storedSelected) {
          return
        }
      }
    }

    // 기본 열지표 그룹, 커스텀 열지표 그룹 중 선택된 항목이 하나도 없다면, 아래 init 을 타고, 스토리지를 업데이트 한다.
    dispatch(initDashboardTableMetric())
    dispatch(updateDashboardTableMetricStorage())
  }
}

/**
 * 지표 추가, 삭제로 selected와 fixedGroups이 다른 경우 보정
 */
function fixDashboardTableMetricStorage() {
  return (dispatch, getState) => {
    const {
      dashboardV3: { tableMetric },
    } = getState()

    const { fixedGroups, conversionCriteria } = tableMetric

    const newSelected = fixedGroups
      .filter(({ isSelected }) => isSelected)
      .flatMap(({ id }) =>
        DashboardMetricGroupEnumV2.isRelatedToConversion(id)
          ? DashboardTableMetricMap[id].filter(({ id: metricCode }) =>
              metricCode.includes(ConversionCriteriaKey[conversionCriteria])
            )
          : DashboardTableMetricMap[id]
      )
      .toJS()

    dispatch({
      type: DashboardTableMetric.SET_SELECTED,
      selected: newSelected,
    })

    const {
      dashboardV3: {
        tableMetric: newTableMetric,
        common: {
          adAccountInfo: { id: adAccountId },
        },
      },
    } = getState()

    DashboardStorageLocal.update(
      DASHBOARD_STORAGE_ITEM.TABLE_METRICS,
      (obj = {}) => {
        if (adAccountId > 0) {
          obj[adAccountId] = newTableMetric.toJS()
        }
        return obj
      }
    )
  }
}

function updateDashboardTableMetricStorage() {
  return (dispatch, getState) => {
    const {
      dashboardV3: { tableMetric },
    } = getState()

    /**
     * 저장된 사용자 맞춤 지표 중 초기화가 필요한 예외적인 케이스를 체크하여 초기화 후, 사용자 맞춤 지표 storage 업데이트
     */
    const { storedGroups } = tableMetric

    const dashboardTableMetricIds = List(
      DashboardTableMetricIds.map(({ id }) => id)
    )
    const isStoreInitializeRequired = storedGroups.some(({ metrics }) =>
      metrics.some(({ id }) => !dashboardTableMetricIds.includes(id))
    )

    if (isStoreInitializeRequired) {
      const newStoredGroups = storedGroups.map(({ metrics }, index) => {
        const isDefaultValueRequired = metrics.every(
          ({ id }) => !dashboardTableMetricIds.includes(id)
        )

        const { id, name, isSelected } = storedGroups.get(index)

        if (isDefaultValueRequired) {
          // 삭제 예정 지표만 설정된 경우, 기본 지표로 치환
          return fromJS({
            id,
            name,
            metrics: DashboardMetricGroupEnumV2.getDefaults().flatMap(
              (g, i) => DashboardTableMetricIdsByGroup[i][g]
            ),
            isSelected,
          })
        } else {
          // 삭제 예정 지표와 사용 가능 지표가 혼합된 경우, 사용 가능한 지표만 추출
          return fromJS({
            id,
            name,
            metrics: metrics.filter(({ id }) =>
              dashboardTableMetricIds.includes(id)
            ),
            isSelected,
          })
        }
      })

      dispatch({
        type: DashboardTableMetric.SET_STORED_GROUPS,
        storedGroups: newStoredGroups,
      })
    }

    const {
      dashboardV3: {
        tableMetric: newTableMetric,
        common: {
          adAccountInfo: { id: adAccountId },
        },
      },
    } = getState()

    DashboardStorageLocal.update(
      DASHBOARD_STORAGE_ITEM.TABLE_METRICS,
      (obj = {}) => {
        if (adAccountId > 0) {
          obj[adAccountId] = newTableMetric.toJS()
        }
        return obj
      }
    )
  }
}

/**
 * `기본 열 지표` 선택
 */
export function selectDashboardTableMetricFixedGroup({ id }) {
  return dispatch => {
    dispatch({
      type: DashboardTableMetric.SELECT_FIXED_GROUP,
      id,
    })

    dispatch(invalidateDashboardTableRows())
    dispatch(updateDashboardTableMetricStorage())
  }
}

/**
 * `임시 열 지표` 선택
 */
export function selectDashboardTableMetricTemporaryGroup() {
  return dispatch => {
    dispatch({
      type: DashboardTableMetric.SELECT_TEMP_GROUP,
    })

    dispatch(invalidateDashboardTableRows())

    dispatch(updateDashboardTableMetricStorage())
  }
}

/**
 * `커스텀 열 지표` 선택
 */
export function selectDashboardTableMetricStoredGroup({ id }) {
  return dispatch => {
    dispatch({
      type: DashboardTableMetric.SELECT_STORED_GROUP,
      id,
    })

    dispatch(invalidateDashboardTableRows())

    dispatch(updateDashboardTableMetricStorage())
  }
}

/**
 * `임시 열 지표` 선택
 */
export function updateDashboardTableMetricTemporaryGroup({ temporaryGroup }) {
  return dispatch => {
    dispatch({
      type: DashboardTableMetric.UPDATE_TEMP_GROUP,
      temporaryGroup,
    })

    dispatch(selectDashboardTableMetricTemporaryGroup())

    dispatch(closeAllPopup())

    dispatch(updateDashboardTableMetricStorage())
  }
}

/**
 * `커스텀 열 지표` 설정 추가(adAccountId)
 */
export function storeDashboardTableMetricGroup({
  adAccountId,
  id,
  metricGroup,
}) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      const {
        preference,
        dashboardV3: {
          tableMetric: { storedGroups },
        },
      } = getState()

      const nextStoredGroups =
        storedGroups.find(({ id: storedGroupId }) => storedGroupId === id) ===
        undefined
          ? storedGroups.unshift(fromJS(metricGroup))
          : storedGroups

      const nextPreference = preference
        .set(
          PREFERENCE_ITEM.DASHBOARD_CUSTOM_METRIC,
          nextStoredGroups.map(storedGroup =>
            storedGroup.set('isSelected', false)
          )
        )
        .toJS()

      await api.adAccount.updateAdAccountPreference(adAccountId, nextPreference)

      dispatch({
        type: DashboardTableMetric.SET_STORED_GROUPS,
        storedGroups: nextStoredGroups,
      })

      dispatch(selectDashboardTableMetricStoredGroup({ id }))

      dispatch(updateDashboardTableMetricStorage())

      dispatch(closeAllPopup())

      showSuccessMessage(DashboardToastMessage.modifySuccess())
    } catch (e) {
      showErrorMessage(DashboardToastMessage.modifyFailure())
      console.log(e.message)
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * `커스텀 열 지표` 설정 갱신(adAccountId)
 */
export function updateDashboardTableMetricStoredGroup({
  adAccountId,
  metricGroup,
}) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      const {
        preference,
        dashboardV3: {
          tableMetric: { storedGroups },
        },
      } = getState()

      const { id: metricGroupId } = metricGroup

      const index = storedGroups.findIndex(
        ({ id: storedGroupId }) => storedGroupId === metricGroupId
      )
      const nextStoredGroups = storedGroups.set(index, fromJS(metricGroup))

      const nextPreference = preference
        .set(
          PREFERENCE_ITEM.DASHBOARD_CUSTOM_METRIC,
          nextStoredGroups.map(v => v.set('isSelected', false))
        )
        .toJS()

      await api.adAccount.updateAdAccountPreference(adAccountId, nextPreference)

      dispatch({
        type: DashboardTableMetric.SET_STORED_GROUPS,
        storedGroups: nextStoredGroups,
      })

      dispatch(selectDashboardTableMetricStoredGroup({ id: metricGroupId }))

      dispatch(updateDashboardTableMetricStorage())

      dispatch(closeAllPopup())

      showSuccessMessage(DashboardToastMessage.modifySuccess())
    } catch (e) {
      showErrorMessage(DashboardToastMessage.modifyFailure())
      console.log(e.message)
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * `커스텀 열 지표` 설정 제거
 */
export function deleteDashboardTableMetricStoredGroup({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      const {
        preference,
        dashboardV3: {
          tableMetric: { storedGroups },
        },
      } = getState()

      const { isSelected } =
        storedGroups.find(({ id: storedGroupId }) => storedGroupId === id) || {}

      const nextStoredGroups = storedGroups.filter(
        ({ id: storedGroupId }) => storedGroupId !== id
      )

      const nextPreference = preference
        .set(
          PREFERENCE_ITEM.DASHBOARD_CUSTOM_METRIC,
          nextStoredGroups.map(storedGroup =>
            storedGroup.set('isSelected', false)
          )
        )
        .toJS()

      await api.adAccount.updateAdAccountPreference(adAccountId, nextPreference)

      dispatch({
        type: DashboardTableMetric.SET_STORED_GROUPS,
        storedGroups: nextStoredGroups,
      })

      // 선택된 항목이 삭제된 경우 default 로 초기화.
      if (isSelected) {
        dispatch(initDashboardTableMetric())
        dispatch(invalidateDashboardTableRows())
      }

      dispatch(updateDashboardTableMetricStorage())

      showSuccessMessage(DashboardToastMessage.modifySuccess())
    } catch (e) {
      showErrorMessage(DashboardToastMessage.modifyFailure())
      console.log(e.message)
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function setDashboardTableMetricConversionCriteria({
  conversionCriteria,
}) {
  return dispatch => {
    dispatch({
      type: DashboardTableMetric.SET_CONVERSION_CRITERIA,
      conversionCriteria,
    })

    dispatch(invalidateDashboardTableRows())

    dispatch(updateDashboardTableMetricStorage())
  }
}

export function clearDashboardTableMetric() {
  return {
    type: DashboardTableMetric.CLEAR,
  }
}
